.referral-stats-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    border: 1px solid #e1e3eb;
    padding: 20px;
    border-radius: 10px;
    background: #ffffff;

    .successful-referrals {
        border-right: 1px solid #e1e3eb;
        margin-right: 30px;
        padding-right: 30px;

        .title {
            font-weight: 600;
            color: #546285;
            margin-bottom: 15px;
        }

        .value-number {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .rewards-title {
        font-weight: 600;
        color: #546285;
        margin-bottom: 15px;
    }

    .total-rewards {
        font-weight: 500;
        font-size: 16px;
    }
}
