@import "@app/styles/sass/elements/_variables.scss";

.horizontal-banner {
    display: flex;
    padding: 24px;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    border: 1px solid var(--Gray-50, #e1e3eb);
    box-shadow: 0px 1px 2px 0px rgba(26, 40, 77, 0.08);

    @media screen and (max-width: 576px) {
        gap: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &.--no-border {
        border: none;
    }

    &.--square {
        border-radius: 0;
    }

    &.--light {
        background: #fff;
    }

    &.--grey {
        background: #f9fafc;
    }

    .icon_wrapper {
        margin-right: 20px;

        @media screen and (max-width: 576px) {
            margin-right: 0;
        }

        .icon {
            width: 50px;
            height: 50px;
            display: block;
            flex: 0 0 50px;
        }
    }

    .content {
        flex: 1;
        padding-right: 1.5rem;

        @media screen and (max-width: 576px) {
            padding-right: 0;
        }

        .title {
            color: var(--Gray-700, #1a284d);
            font-family: $font-family-content;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 4px;
        }

        .description {
            color: var(--Gray-600, #343f5c);
            font-family: $font-family-content;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .action {
        margin-right: 0;
    }

    .support-section {
        display: flex;
        align-items: center;

        .support-icon {
            background: #e8f3fa;
            border-radius: 5px;
            margin-right: 24px;
            padding: 15px;

            i {
                background-size: cover;
                width: 20px;
                height: 20px;
                display: block;
                float: left;
            }
        }

        .support {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
            line-height: 1.5em;
        }

        .contact {
            font-size: 14px;
            color: #198cca;
            font-weight: 600;
        }
    }
}
