@import "@app/styles/sass/elements/_variables.scss";

.availability-indicator {
    gap: 6px;
    cursor: pointer;
    display: inline-flex;
    padding: 6px 12px;
    align-items: center;
    border-radius: 20px;
    background: #eee; // Fallback
    border: 1px solid #ccc; // Fallback

    .dot {
        width: 10px;
        height: 10px;
        flex: 0 0 10px;
        border-radius: 50%;
        background-color: #aaa; // Fallback
    }

    .text {
        color: #888; // Fallback
        font-family: $font-family-content;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.035px;
    }

    &.available {
        background: #d3f1e1;
        border: 1px solid #7bd4a4;

        .dot {
            background-color: #24b768;
        }

        .text {
            color: #1a284d;
        }
    }

    &.unavailable {
        background: #fff4e2;
        border: 1px solid #fdb436;

        .dot {
            background-color: #fdb436;
        }

        .text {
            color: #1a284d;
        }
    }
}
