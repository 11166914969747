.language-preference {
    .add-new {
        cursor: pointer;
        box-shadow: 0px 1px 2px 0px rgba(26, 40, 77, 0.08);
        border-radius: 5px;
        padding: 10px 14px;
        display: flex;
        align-items: center;
        border: 1px dashed #e1e3eb;

        > span {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px;
            border-radius: 5px;
            background: #d5e8f4;
            width: 30px;
            height: 30px;
            margin-right: 12px;
        }
    }
}
