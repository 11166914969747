input[disabled],
button[disabled] {
    pointer-events: none;
    cursor: not-allowed;
}

.btn-action {
    margin-right: 8px;
    font-size: 14px;
    height: fit-content;
    box-shadow: 0px 1px 2px #1a284d14;
    text-align: center;
    justify-content: center;
    transition: background-color;
    &.btn-text {
        padding: 4px 15px;
        color: $btnColorGray;
        text-align: center;
        font-weight: $font-weight-medium;
        font-family: $font-family-content;
        line-height: 29px;
        -webkit-font-smoothing: antialiased;
    }

    &.btn-green {
        background-color: $green;
        color: white;
        border: 1px solid $green;
        color: #ffffff !important;
        transition: background-color 300ms ease-out;
        &:hover {
            background-color: $darkGreen !important;
            border: 1px solid $darkGreen !important;
            color: #ffffff;
            text-decoration: none;
        }

        &.btn-manage-candidates {
            &:hover {
                color: $btnColorGray;
                background-color: transparent;
                border: 1px solid $regularGrey;
            }
        }
    }

    &.btn-blue {
        background-color: $oceanBlue;
        color: #ffffff !important;
        border: 1px solid $oceanBlue;
        padding: 9px 15px;
        line-height: 1.6;
        font-weight: 600;
        transition: background-color 300ms ease-out;
        &:hover {
            background-color: $darkOceanBlue !important;
            border: 1px solid $darkOceanBlue !important;
            color: #ffffff;
            text-decoration: none;
        }
    }

    &.btn-danger {
        color: white !important;
        background-color: $required !important;
        border-color: $required !important;
        transition: background-color 300ms ease-out;
        &:hover {
            background-color: $requiredLight !important;
            border: 1px solid $requiredLight !important;
            color: #ffffff !important;
            text-decoration: none;
        }
    }

    &.btn-transparent {
        color: $btnColorGray;
        background-color: transparent;
        border: 1px solid $borderGray;
        transition: background-color 300ms ease-out;
        &.btn-sm {
            line-height: 20px;
            width: auto;
            min-width: 0;
            padding: 5px 10px;
            font-size: 10px;
        }

        &:hover {
            background-color: #f5f6f8;
        }
    }

    &.btn-icon {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        line-height: 18px;
        font-weight: $font-weight-medium;
        color: #343f5c;
        transition: background-color 300ms ease-out;

        i {
            float: left;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            background-size: cover;
            filter: none;
        }
        &:hover {
            background-color: $lightGrey;
        }
    }
}

.header-actions {
    button {
        border: 1px solid $regularGrey;
        background-color: white;

        &.btn-left {
            border-radius: 3px 0 0 3px;
            padding: 8.5px;
        }

        &.btn-right {
            border-radius: 0 3px 3px 0;
            border-left: none;
            padding: 8.5px;
        }

        &.btn-today-date {
            display: inline;
            border-radius: 0;
            border-left: none;
            font-family: $font-family-head;
            font-size: 12px;
            color: $btnColorGray;
            font-weight: $font-weight-medium;
            padding: 0 5px;
            max-width: 68px;
            min-width: initial;
            width: 68px;
            height: 39px;
            margin: 0;
            line-height: 0;
            text-transform: capitalize;
        }

        &.btn-calendar {
            padding: 8.5px;
        }

        &:hover {
            background-color: $oceanBlueOpacity;
        }

        i {
            width: 20px;
            height: 20px;
        }
    }
}

.btn-right-abs {
    position: absolute;
    top: 12px;
    right: 12px;
}

.social-buttons {
    i {
        background-size: cover;
        width: 20px;
        height: 20px;
        display: block;
        float: left;
        margin-right: 8px;
    }

    a {
        width: 100%;
        height: 42px;
        position: relative;
        border: 1px solid #e1e3eb;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px 0;
        font-weight: $font-weight-medium;
        font-size: 14px;
        text-transform: capitalize;
        color: #1a284d;
        text-decoration: none !important;

        &:hover {
            background-color: #f9fafc;
            border: 1px solid #adb4c7;
            color: #1a284d;
        }

        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
}

.btn-white-blue {
    border: 1px solid #e1e3eb;
    color: #1a284d;
    font-size: 14px;
    width: 20%;
    text-transform: capitalize !important;

    &:hover {
        border: 1px solid #198cca;
        background-color: #198cca0d;
        color: #1a284d;
    }

    &.btn-white-hover {
        color: #546285;

        &:hover {
            background-color: #ffffff;
            border: 1px solid #e1e3eb;
            color: #1a284d;
        }
    }
}

.btn-upload {
    position: relative;
    min-width: 120px;
    align-items: center;
    display: flex;
    padding-left: 30px;
    color: #546285;
    font-size: 12px;

    &.upload-photo {
        &:before {
            background-image: url(../../assets/img/assets/upload_cloud.svg);
        }

        &:hover {
            &:before {
                background-image: url(../../assets/img/assets/dark_upload_cloud.svg);
            }
        }
    }

    &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(../../assets/img/assets/file_pdf.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        top: 30%;
        left: 8%;
    }

    &:hover {
        border: 1px solid #e1e3eb;
        background-color: #f9fafc;
        color: #1a284d;

        &:before {
            background-image: url(../../assets/img/assets/dark_file_pdf.svg);
        }
    }
}

.hr-line-separator {
    h2 {
        width: 100%;
        color: #546285;
        font-size: 12px;
        text-align: center;
        border-bottom: 1px solid #e1e3eb;
        line-height: 0.1em;
        margin: 30px 0;
    }
    span {
        background: #fff;
        padding: 0 19px;
        font-weight: 400;
    }
}
.re-apply-container {
    .btn-reapply {
        padding: 14px 20px;
        border-radius: 3px;
        margin: 0;
    }
}
