@import "./base.scss";
@import "./platform_react_style.scss";
@import "./login";

.center {
    margin: auto;
    text-align: center;
}
.single-form {
    form [class^="col-md"] {
        margin: auto;
        .left {
            float: left;
            margin: unset;
        }
        .right {
            float: right;
            margin: unset;
        }
    }
    .btn {
        width: 100%;
        font-size: 0.9rem;
    }
    .btn-link {
        padding: 0;
        font-size: 0.9rem;
    }
    font-size: 0.9rem;
}
.rdrDefinedRangesWrapper {
    width: 150px !important;
}
.rdrInputRanges {
    display: none !important;
}
.MuiInputBase-root {
    height: 32px;
}
.info-icon {
    background: #198cca 0% 0% no-repeat padding-box;
    width: 17px;
    height: 16px;
    border-radius: 50%;
    padding-left: 7px;
    padding-top: 3px;
    color: white;
    font-size: 10px !important;
}
.p-viewer {
    float: right;
    bottom: 70px;
    left: 15px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.user-menu-item {
    & button {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
    }
    & :hover {
        color: #198cca !important;
    }
}
.btn-inside-filter {
    min-width: 50px !important;
    line-height: 15px !important;
    border-color: $regularGrey !important;
    margin-left: 5px;
    margin-bottom: 3px;
}
.display-space-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
