.iframe-wrapper {
    &.dashboard-container {
        height: 100vh !important;
        background: white !important;
    }
}

.modal-backdrop.show {
    opacity: 0.5 !important;
}

.position-action-modal {
    margin: 0 !important;
    padding: 0 !important;

    .modal-dialog {
        width: 100vw;
        height: 100vh;
        margin: 0 !important;
        max-width: unset;
    }
}
