@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

#region-select {
    .dropdown {
        width: 100%;

        .dropdown-trigger {
            width: 100%;
            display: flex;
            color: inherit;
            text-decoration: none;
            border-radius: 5px;
            border-color: hsl(0, 0%, 80%);
            background-color: white;

            .search {
                border-radius: 0;
                padding: 0 2px;
                border: none;
                height: 25px;
                width: 100%;

                &::placeholder {
                    color: #777;
                }
            }

            .tag-list {
                width: calc(100% - 24px - 24px);
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                flex: 1;

                &:before {
                    display: none !important;
                }

                .tag-item {
                    .tag {
                        font-size: 12px;
                        color: #333;
                        font-family: inherit;
                        background-color: hsl(0, 0%, 90%);
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        padding: 3px 24px 3px 6px;
                        border-radius: 0;
                        border: 0;
                        outline: 0;
                        position: relative;

                        .tag-remove {
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            width: 22px;
                            height: 100%;
                            display: block;
                            text-align: center;
                            color: #546285;
                            font-size: 12px;
                            cursor: pointer;
                            border: none;
                            outline: none;
                            padding: 0;
                            border-radius: 0;
                            font-weight: bold;

                            &:hover {
                                background-color: #ffbdad;
                                color: #de350b;
                            }
                        }
                    }
                }

                .placeholder {
                    cursor: pointer;
                    color: inherit;
                    background-color: inherit;
                }
            }

            &::after {
                align-self: center;
                cursor: pointer;
                content: "\E5CF";
                vertical-align: middle;
                font: normal normal normal 24px/1 "Material Icons";
                color: hsl(0, 0%, 80%);
            }

            &:hover {
                &::after {
                    color: hsl(0, 0%, 60%);
                }
            }
        }

        .dropdown-content {
            width: 100%;
            max-height: 300px;
            overflow-y: auto;

            ul {
                .infinite-scroll-component {
                    overflow: visible !important;

                    li {
                        cursor: default;
                        display: block;
                        font-size: inherit;
                        width: 100%;
                        user-select: none;
                        background-color: transparent;
                        color: #546285;
                        box-sizing: border-box;
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        padding: 0 !important;

                        .toggle {
                            display: none;
                        }

                        label {
                            flex: 1;
                            display: flex;
                            padding: 8px 4px;

                            span {
                                flex: 1;
                                color: #546285;
                                white-space: pre-wrap;
                            }
                        }

                        &:hover {
                            background-color: #198cca0d;
                        }

                        &.checked label span {
                            font-weight: 500;
                        }

                        .toggle {
                            display: none;

                            // font: normal normal normal 18px/1 "Material Icons";
                            // color: #546285;
                            // white-space: pre;
                            // margin: 0 4px;
                            // display: flex;
                            // align-items: center;
                            // height: 27px;

                            // :hover {
                            //   background-color: #ccc;
                            // }
                        }

                        // .toggle.collapsed::after {
                        //   cursor: pointer;
                        //   content: "\E5CF";
                        //   vertical-align: middle;
                        // }

                        // .toggle.expanded::after {
                        //   cursor: pointer;
                        //   content: "\E5CE";
                        //   vertical-align: middle;
                        // }

                        /* checkbox styles */
                        .checkbox-item {
                            position: relative;
                            width: 1rem;
                            height: 1rem;
                            margin-right: 0.75rem;
                            cursor: pointer;
                            appearance: none;
                            outline: 0;
                            vertical-align: middle;
                            display: inline-block;
                            border: none;
                        }

                        .checkbox-item:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            width: 100%;
                            height: 100%;
                            border: 2px solid #ddd;
                            transition: all 0.1s ease-in-out;
                        }

                        .checkbox-item:checked:before {
                            height: 50%;
                            -webkit-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            border-top-style: none;
                            border-right-style: none;
                            border-color: #2196f3;
                        }
                    }
                }
            }
        }
    }
}
