.note-record {
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    .header {
        display: flex;
        margin-bottom: 1rem;
        justify-content: space-between;

        .details {
            .type {
                font-size: 0.9rem;
            }

            .author {
                color: #546285;
                font-size: 12px;
            }
        }

        .timestamp {
            color: #546285;
            font-size: 12px;
            line-height: 1;
        }
    }

    .content {
    }
}
