.empty-state-wrapper {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .icon {
        width: 200px;
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .message {
        text-align: center;
    }
}
