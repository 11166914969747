.recent-activity-card {
    margin-top: 30px;
    border: 1px solid #e1e3eb;
    padding: 20px;
    border-radius: 10px;
    background: white;

    .title {
        font-weight: 600;
        color: #546285;
        margin-bottom: 15px;
    }

    .activity-list {
        list-style: none;
        padding-left: 0;

        li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
    }
}
