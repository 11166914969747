.datagrid {
    position: relative;

    .pagination-disabler {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.01);
        width: 100%;
        height: 52px;
        pointer-events: all;
        cursor: not-allowed;
    }
}
