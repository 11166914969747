@import "./variables";

$modalSelector: ".modal" !default;
$modalBodyPadding: 24px !default;

@mixin header {
    border: 0;
    padding: 16px 24px;
    border-radius: 10px 10px 0 0;
    background: var(--gray-100, #f9fafc);
}

@mixin title {
    color: #1a284d;
    font-family: $font-family-head;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
}

@mixin closeBtn {
    width: 24px;
    height: 24px;
    padding: 0;
    flex-shrink: 0;
    color: #adb4c7;
}

@mixin body {
    padding: $modalBodyPadding;
}

@mixin footer {
    padding: 16px 24px;
    border-top-color: #e1e3eb;

    button:disabled {
        cursor: wait;
        opacity: 0.6;
    }
}

@mixin modal {
    #{ $modalSelector } {
        .modal-content {
            .modal-header {
                @include header;

                .modal-title {
                    @include title;
                }

                .btn-close {
                    @include closeBtn;
                }
            }

            .modal-body {
                @include body;
            }

            .modal-footer {
                @include footer;
            }
        }
    }
}
