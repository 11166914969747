@import "@app/styles/sass/elements/variables";

.page-wrapper {
    max-width: 1168px !important;
    padding-top: 30px;

    .page-header {
        margin-bottom: 36px;

        .page-title {
            font-family: $font-family-head;
            font-size: 24px;
            text-align: left;
            line-height: 28.8px;
            font-weight: 500;
            margin-bottom: 8px;
            letter-spacing: -0.01em;
        }

        .page-description {
            font-family: $font-family-content;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }
    }
}
