.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.form-check-input {
    clear: left;
    border: 1;
    background-color: #ffffff !important;
}

.form-check-input:checked {
    clear: left;
    background-color: #24b768 !important;
    //background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="500" height="100" viewBox="0 0 4442 720"><path d="M36,297.64c317.62,0,428,134.58,696,136.74S1160,364,1436,389s431.72-102.09,618-91.36,505.93,73.37,715,72.29,339,72,674,64.45,712.27,157.83,920,174l46,111.14H36Z" ></path></svg>');
}

.form-switch.form-switch-sm {
    margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
}

.form-switch.form-switch-md {
    margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.form-switch.form-switch-lg {
    margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
}

.form-switch.form-switch-xl {
    margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
    height: 2.5rem;
    width: calc(4rem + 0.75rem);
    border-radius: 5rem;
}
