.calendar-wrapper {
    width: 100vw;
    height: 100vh;
    pointer-events: all;
    padding: 1rem;
    font-family: "Poppins";
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    &-inner {
        width: 800px;
        height: 794px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        overflow: hidden;
        border-radius: 10px;

        .meetings-header {
            width: 100%;
            z-index: 99;
            position: relative;
            height: 112px;
            padding: 2rem;
            color: #1a284d;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: #f9fafc 0% 0% no-repeat padding-box;
            box-shadow: 0px 8px 20px #1a284d14;

            .heading {
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
                margin-bottom: 4px;
            }

            .description {
                font-size: 14px;
                line-height: 21px;
                text-align: center;
            }
        }

        .meetings-container {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            background-color: #f5f8fa;
            height: 100%;
            overflow: hidden;
            // overflow: auto;
            // margin-top: -18px;

            .meetings-iframe-container {
                flex: 1;
                width: 100%;
                overflow: hidden;
                height: 100%;

                iframe {
                    padding-top: 0.75rem;
                    height: 100% !important;
                }
            }
        }
    }
}
