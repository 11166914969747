.wrapper {
    justify-content: center !important;
}

.email-content {
    p {
        margin-bottom: 1rem;
    }

    table {
        border-collapse: initial !important;
    }
}
