@import "@app/styles/sass/elements/_variables.scss";

.contact-preference {
    .flag-container:hover .selected-flag {
        background-color: white !important;
    }

    .empty-country-dropdown {
        width: 55px;
        height: 42px;
        border: 1px solid #e1e3eb;
        background-color: #f2f2f2;
        border-radius: 5px;
        margin-right: 10px;
    }

    .intl-tel-input {
        &.invalid-number {
            input {
                transition: all 0.35s;
            }

            > input[type="tel"] {
                border-color: $required;
                background-color: rgba(241, 85, 63, 0.05);
            }
        }

        .flag-container,
        .selected-flag {
            height: 42px;
            border-color: #e1e3eb;
            background-color: white;

            &:focus {
                opacity: 1;
                transition: box-shadow 0.2s !important;
                box-shadow: 0 2px 10px rgba(26, 40, 77, 0.08);

                .arrow {
                    color: #adb4c8;
                }
            }

            .arrow {
                color: #ccc;
            }
        }
    }
}
