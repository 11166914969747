@import "@app/styles/sass/elements/variables";

.token {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    line-height: 17px;
    color: $btnColorGray;

    .label {
        color: $dark-gray;
        font-weight: 600;
        padding-right: 0.5rem;
    }

    .separator {
        margin-left: 1rem;
        margin-right: 0.5rem;
    }

    .edit {
        padding: 8px 10px;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 4px;

        &:hover {
            border: 1px solid $regularGrey;
            background-color: $lightGrey;
        }
    }
}
