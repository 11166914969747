.candidate-suitability {
    width: 100%;

    &-empty {
        padding: 1rem;
        color: #888;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.3px;
    }

    thead {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);

        th {
            color: #546285;
            font-weight: 600;
            text-transform: uppercase;
            font-family: "Poppins";
            letter-spacing: 0.3px;
            font-size: 12px;
            padding: 0.75rem 0.5rem 14px 0.5rem;
            width: calc(100% / 3);
        }
    }

    tbody {
        tr {
            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }

            td {
                font-size: 13px;
                font-family: "Inter";
                padding: 1rem 0.5rem;
                vertical-align: baseline;

                &:empty::before {
                    content: "-";
                }
            }
        }
    }
}
