$breakingPoint: 767.98px;

.position-card {
    cursor: pointer;
    border: 1px solid #e1e3eb;
    padding: 0;
    display: flex;
    box-shadow: 0 1px 2px 0 #1a284d14;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    text-decoration: none;
    background-color: #fff;

    &:hover {
        box-shadow: 0 3px 5px 0 #1a284d14;
        background-color: #fbfbfb;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    @media screen and (max-width: $breakingPoint) {
        flex-direction: column;
    }

    .description {
        flex: 1;
        display: flex;
        padding: 2rem;
        row-gap: 1.5rem;
        border-right: 1px solid #e1e3eb;
        flex-direction: column;

        @media screen and (max-width: $breakingPoint) {
            border-right: none;
            border-bottom: 1px solid #e1e3eb;
        }

        .header {
            gap: 1rem;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @media screen and (max-width: $breakingPoint) {
                flex-direction: column;
            }

            .date {
                color: #1a284d;
                display: flex;
                font-size: 12px;
                white-space: nowrap;
                font-weight: 500;
                line-height: 20px;
                align-items: center;

                .icon {
                    margin-right: 0.5rem;
                }
            }
        }

        .body {
            flex: 1;
            color: #343f5c;
        }

        .skills {
            label {
                color: #9aa2b9;
                font-size: 10px;
                line-height: 20px;
                margin-bottom: 0.25rem;
                text-transform: uppercase;
            }

            ul {
                gap: 0.5rem;
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                list-style: none;

                li {
                    color: #1a284d59;
                    border: 1px solid #1a284d1a;
                    padding: 0 0.5rem;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 20px;
                    border-radius: 4px;
                    background-color: white;

                    &.active {
                        color: #1a284d;

                        &::before {
                            color: #24b768;
                            content: "\2713";
                            font-size: 0.6rem;
                            font-weight: 600;
                            margin-right: 0.25rem;
                        }
                    }
                }
            }
        }
    }

    .details {
        flex: 0 0 260px;
        padding: 1.5rem 2rem;
        min-width: 260px;
        max-width: 100%;
        border-radius: 0 9px 9px 0;
        background-color: #f9fafc;

        @media screen and (max-width: 400px) {
            flex: 1;
            min-width: unset;
            max-width: 100%;
        }

        @media screen and (max-width: $breakingPoint) {
            border-radius: 0 0 9px 9px;
        }

        .status {
            .name {
                color: #198cca;
                font-size: 1rem;
                font-weight: 600;
                line-height: 20px;
            }

            .desc {
                color: #1a284d;
                font-size: 12px;
                line-height: 15px;
            }

            &.hiring {
                .name {
                    color: #24b768;
                }
            }
        }

        .terms {
            gap: 1rem;
            display: flex;
            margin-top: 1.5rem;
            flex-direction: column;
            justify-content: center;

            .term {
                color: #1a284d;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                display: flex;
                align-items: center;
                gap: 0.75rem;

                .icon {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                }
            }
        }

        .actions {
            gap: 10px;
            display: flex;
            margin-top: 1rem;
            align-items: center;
            pointer-events: all;
            flex-direction: column;
            justify-content: center;

            > * {
                width: 100%;
                text-align: center;
            }

            .referral-link {
                color: #198cca;
                font-weight: 500;
                line-height: 15px;
                pointer-events: all;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
