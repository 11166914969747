/*
 * Configure the z-index of the editor UI, so when inside a Bootstrap
 * modal, it will be rendered over the modal.
 */
:root {
    --ck-z-default: 1000 !important;
    --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
}

.ck.ck-content :is(p, h1, h2, h3, h4, h5, h6, small, span) {
    font-size: revert;
    font-weight: revert;
    font-family: revert;
    margin-bottom: revert;
}

.ck-editor__editable_inline {
    min-height: 200px;
}

// Set the default font size
.ck.ck-content.ck-editor__editable p {
    font-size: 14px;
}

.ck-content .mention,
.mention {
    display: inline;
    border: 1px solid transparent;
    background: rgba(9, 30, 66, 0.08) !important;
    color: #42526e !important;
    border-radius: 20px;
    cursor: pointer;
    padding: 0.25em 0.5em;
    line-height: 1.714;
    font-size: 1em;
    font-weight: normal;
    word-break: break-word;

    &:hover {
        background: #a6c5e229;
    }
}

.ck.ck-balloon-panel.ck-balloon-panel_caret_se.ck-balloon-panel_visible {
    box-shadow: none;
    border: 1px solid #eee;
    outline: none;
    border-radius: 5px;
}

.ck-list__item {
    width: 320px !important;
    min-width: 320px !important;
    max-width: 320px !important;

    &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0 !important;
    }

    .mention-list-item {
        display: flex !important;
        align-items: center !important;
        padding: 0.5rem 1rem !important;

        &.ck-on {
            background-color: #eee !important;
        }

        // img {
        //   width: 32px;
        //   height: 32px;
        //   border-radius: 50%;
        //   margin-right: 14px;
        // }

        .empty-list {
            width: 100%;
            color: #666;
            padding: 0.75rem;
            text-align: center;
        }

        .text-content {
            width: calc(100% - 46px);
            display: flex;
            flex-direction: column;

            p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #555 !important;
                font-weight: bold !important;
                font-size: 12px !important;
                margin-bottom: 4px !important;
                line-height: 14px !important;
            }

            small {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 11px !important;
                line-height: 11px !important;
                color: #aaa !important;
            }
        }
    }
}

.advanced-editor-empty {
    height: 240px;
    border: 1px solid #e1e3eb;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        margin-top: 0 !important;
    }
}
