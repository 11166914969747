.card-with-toggle-content {
    display: flex;
    flex-direction: column;
    border: 1px solid #e1e3eb;
    border-radius: 10px;
    padding: 24px;
    background: #ffffff;
    margin-bottom: 24px;

    .card-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 576px) {
            flex-direction: column;
            align-items: start;
        }

        .title {
            font-size: 18px;
            font-weight: 600;

            @media (max-width: 576px) {
                margin-bottom: 10px;
            }
        }

        .title-description {
            font-size: 14px;
            color: #546285;

            @media (max-width: 576px) {
                margin-bottom: 10px;
            }
        }

        .icon {
            background-size: cover;
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        .referrals-btn {
            padding: 5px 14px;
            margin-left: 18px;
            font-size: 12px;
            font-weight: 500;
            height: 38px;
            max-width: 155px;
            line-height: 14px;
        }
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        color: #343f5c;
    }

    .toggle-wrapper {
        margin: 15px 0;

        @media (max-width: 576px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .toggle-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            font-size: 14px;
            font-weight: 600;
            color: #198cca;

            .icon-arrow {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transform: rotate(270deg);

                @media (max-width: 576px) {
                    width: 23px;
                }
            }

            &:hover {
                cursor: pointer;
                color: #096ab0;
            }
        }
    }

    .additional-content {
        .description div p {
            margin-bottom: 20px;
            color: #343f5c;
            line-height: 24px;
            font-size: 16px;
        }

        .additional-info {
            padding-top: 20px;
            border-top: 1px solid #e1e3eb;

            .title {
                font-weight: 600;
            }

            .description {
                font-size: 16px;
                margin-bottom: 0;

                a {
                    text-decoration: underline;

                    &:hover {
                        color: #096ab0;
                    }
                }
            }
        }
    }
}
