$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
@import "./elements/variables";
@import "./elements/switches";
@import "./elements/icons";
@import "./elements/buttons";
@import "./elements/forms";
@import "./elements/notifications";
@import "./navigation";

.navbar-company-settings {
    top: 100px;
    left: 310px;
    width: 1540px;
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 0;
}

.center {
    display: block;
    margin: auto;
}

.right {
    float: right;
}

.margin-0 {
    margin: 0;
}

#logo {
    display: inline-block;
    border-right: 1px solid $regularGrey;
    height: 100%;
    vertical-align: top;
    padding-right: 35px;
    margin-right: 20px;
}

#logo img {
    height: 30px;
    width: auto;
    top: 50%;
    position: relative;
    transform: translate3d(0, -50%, 0);
}

#header {
    position: relative;
    z-index: 999;
    padding: 0;
    font-size: 14px;
    height: 60px;
    background-color: #fff;
    // border-bottom: 1px solid $regularGrey;
}

#header .container,
#header .right-side,
#header .left-side {
    height: 100%;
}

header.fullwidth .container {
    max-width: 100%;
    margin: 0;
    padding: 0 35px;
}

#header .container {
    position: relative;
}

#header .left-side {
    float: left;
    width: 100%;
    display: inline-block;
    position: relative;
    flex: 1;
}

#header .right-side {
    float: right;
    text-align: right;
    width: auto;
    display: inline-block;
    position: absolute;
    right: 25px;
    background-color: #fff;
    flex: 1;
    top: 0;
}

.full-width #header .left-side {
    float: left;
    width: 100%;
    display: inline-block;
    position: relative;
}

.full-width #header .right-side {
    float: right;
    text-align: right;
    width: auto;
    display: inline-block;
    position: absolute;
    right: 25px;
    background-color: #fff;
}

.header-widget {
    height: 100%;
    padding: 0 30px;
    text-align: left;
    float: left;
    display: flex;
    align-items: center;

    #give-feedback {
        padding-right: 35px;
        font-size: 14px;
        color: $btnColorGray;
        display: flex;
        align-items: center;

        .icon-feedback {
            background: url(../assets/svg/icons/icon-feedback.svg) no-repeat;
            font-size: 18px;
            display: inline-block;
            background-size: cover;
            width: 20px;
            height: 20px;
            margin-right: 5px;

            &:hover {
                background: url(../assets/svg/icons/blue_feedback.svg) no-repeat #e8f3fa;
                width: 20px;
                height: 20px;
                display: block;
                background-size: cover;
                box-shadow: 0 0 0 6px #e8f3fa;
                border-radius: 50%;
            }
        }
    }

    #help-icon {
        margin-right: 20px;

        .icon-faq {
            width: 20px;
            height: 20px;
            display: block;

            &:hover {
                background: url(../assets/svg/icons/blue_support_help.svg) no-repeat #e8f3fa;
                width: 20px;
                height: 20px;
                display: block;
                background-size: cover;
                box-shadow: 0 0 0 6px #e8f3fa;
                border-radius: 50%;
            }
        }
    }
}

.fullwidth .header-widget:last-of-type {
    padding-right: 5px;
    float: right;
    border-right: none;
}

.header-widget:last-of-type {
    padding-right: 0;
    margin-right: -5px;
}

.fullwidth .header-widget:last-of-type {
    padding-right: 5px;
    margin-right: 0;
}

.header-notifications-content .notification-avatar {
    height: 42px;
    width: 42px;
    max-width: 42px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
}

.header-widget .log-in-button {
    padding: 10px 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    color: #666;
    display: block;
    margin: 0 -3px;
}

.header-widget .log-in-button i {
    font-size: 18px;
    line-height: 0;
    position: relative;
    top: 2px;
    margin-right: 4px;
    margin-left: -1px;
    color: #777;
    transition: 0.3s;
}
.header-widget .log-in-button:hover,
.header-widget .log-in-button:hover i {
    color: $oceanBlue;
}

@media (max-width: 768px) {
    .header-widget .log-in-button span {
        display: none;
    }
    .header-widget .log-in-button i {
        font-size: 20px;
        top: 3px;
    }
    .header-widget .log-in-button i,
    .header-widget .log-in-button i:hover {
        color: #555;
    }
    .header-widget .log-in-button {
        background-color: #f0f0f0;
        border-radius: 4px;
        height: 46px;
        width: 46px;
        text-align: center;
    }
}

.user-menu {
    padding: 0;
}

.user-menu .header-notifications-trigger,
.user-menu .header-notifications-trigger a {
    top: 50%;
}

.user-menu .header-notifications-dropdown {
    top: calc(100% - 8px);
    width: 290px;
    right: -10px;
}

.user-menu .header-notifications-dropdown:before {
    right: 23px;
}

.notification-avatar {
    position: relative;
}

.status-icon,
.notification-avatar:after {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    background-color: silver;
    bottom: 0;
    right: 0;
    display: block;
    border: 2px solid #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

.notification-avatar:after {
    width: 11px;
    height: 11px;
}

.status-icon.status-online,
.status-online:after {
    background-color: #38b653;
}

.status-icon.status-offline,
.status-offline:after {
    background-color: silver;
}

.user-avatar {
    display: inline-block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
}

.user-avatar img {
    width: 100%;
    display: inline-block;
    border-radius: 50%;
}

ul.user-menu-small-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 20px 25px;
    :last-child {
        button {
            margin: 0px;
        }
    }
}

ul.user-menu-small-nav li {
    margin: 0;
    padding: 0;
}

ul.user-menu-small-nav li a {
    display: block;
    padding: 2px 0;
    font-size: 16px;
    color: #666;
    transition: 0.3s;
}

ul.user-menu-small-nav li a i {
    font-size: 16px;
    position: relative;
    top: 1px;
    margin-right: 2px;
    color: #666;
    -webkit-font-smoothing: antialiased;
    transition: 0.3s;
}

ul.user-menu-small-nav li a:hover,
ul.user-menu-small-nav li a:hover i {
    color: $oceanBlue;
}

.user-status {
    padding: 25px;
    border-bottom: 1px solid #e6e6e6;
}

.user-details {
    display: flex;
}

.user-name {
    font-weight: $font-weight-normal;
    color: #333;
    line-height: 20px;
    padding: 2px 0 0 15px;
}

.user-details span {
    display: block;
    font-size: 14.7px;
    color: #888;
    font-weight: $font-weight-normal;
}

#header-container {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
}

#header {
    transition: background 0.3s;
}

#wrapper {
    padding-top: 60px;
}

@media (max-width: 1024px) {
    #wrapper {
        padding-top: 0 !important;
    }
    #header-container {
        position: relative !important;
    }
}

.mm-hidden {
    display: none !important;
}

.mm-wrapper {
    overflow-x: hidden;
    position: relative;
}

.mm-menu {
    box-sizing: border-box;
    background: inherit;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.mm-panels,
.mm-panels > .mm-panel {
    background: inherit;
    border-color: inherit;
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.mm-panels {
    overflow: hidden;
}

.mm-panel {
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform 0.4s ease;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition:
        transform 0.4s ease,
        -webkit-transform 0.4s ease;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}

.mm-panel.mm-opened {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
    -webkit-transform: translate(-30%, 0);
    -ms-transform: translate(-30%, 0);
    transform: translate(-30%, 0);
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
}

.mm-panel.mm-highest {
    z-index: 1;
}

.mm-panel.mm-noanimation {
    -webkit-transition: none !important;
    transition: none !important;
}

.mm-panel.mm-noanimation.mm-subopened {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.mm-panels > .mm-panel {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 20px;
}

.mm-panels > .mm-panel.mm-hasnavbar {
    padding-top: 40px;
}

.mm-panels > .mm-panel:not(.mm-hidden) {
    display: block;
}

.mm-panels > .mm-panel:after,
.mm-panels > .mm-panel:before {
    content: "";
    display: block;
    height: 20px;
}

.mm-vertical .mm-panel {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
    display: none;
    padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
    border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
    display: block;
}

.mm-listview > li.mm-vertical > .mm-next,
.mm-vertical .mm-listview > li > .mm-next {
    box-sizing: border-box;
    height: 40px;
    bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after,
.mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    right: 19px;
}

.mm-btn {
    box-sizing: border-box;
    width: 40px;
    height: 50px;
    position: absolute;
    top: 0;
    z-index: 1;
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
    content: "";
    border: 2px solid transparent;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.mm-clear:before,
.mm-close:before {
    border-right: none;
    border-bottom: none;
    right: 18px;
}

.mm-clear:after,
.mm-close:after {
    border-left: none;
    border-top: none;
    right: 25px;
}

.mm-next:after,
.mm-prev:before {
    content: "";
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
}

.mm-prev:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 23px;
    right: auto;
}

.mm-next:after {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    right: 23px;
    left: auto;
}

.mm-navbar {
    border-bottom: 1px solid;
    border-color: inherit;
    text-align: center;
    line-height: 30px;
    height: 50px;
    padding: 0 40px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.mm-navbar > * {
    display: block;
    padding: 10px 0;
}

.mm-navbar a,
.mm-navbar a:hover {
    text-decoration: none;
}

.mm-navbar .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mm-navbar .mm-btn:first-child {
    left: 0;
}

.mm-navbar .mm-btn:last-child {
    text-align: right;
    right: 0;
}

.mm-panel .mm-navbar {
    display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
    display: block;
    background-color: #262626;
}

.mm-listview,
.mm-listview > li {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
}

.mm-listview {
    font: inherit;
    font-size: 14px;
    line-height: 20px;
}

.mm-listview a,
.mm-listview a:hover {
    text-decoration: none;
}

.mm-listview > li {
    position: relative;
}

.mm-listview > li,
.mm-listview > li .mm-next,
.mm-listview > li .mm-next:before,
.mm-listview > li:after {
    border-color: inherit;
}

.mm-listview > li {
    border-bottom: 1px solid #404040;
}

.mm-listview > li > a,
.mm-listview > li > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: inherit;
    display: block;
    padding: 14px 10px 14px 20px;
    margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
    content: "";
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

.mm-listview > li:not(.mm-divider):after {
    left: 20px;
}

.mm-listview .mm-next {
    background: transparent;
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}

.mm-listview .mm-next:before {
    content: "";
    border-left-width: 1px;
    border-left-style: solid;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.mm-listview .mm-next + a,
.mm-listview .mm-next + span {
    margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
    width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
    border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a,
.mm-listview .mm-next.mm-fullsubopen + span {
    padding-right: 50px;
    margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
    margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child,
.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
    margin-top: -10px;
}

.mm-menu {
    background: #2a2a2a;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
    color: #fff;
    font-weight: $font-weight-bold;
    font-size: 16px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
    border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu .mm-listview {
    border-color: #2a2a2a;
}

.mm-menu .mm-listview > li .mm-next:after {
    border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
    tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
    background: rgba(255, 255, 255, 0.05);
}

.mm-menu .mm-listview > li {
    transition: 0.2s;
}

.mm-menu .mm-listview > li:hover {
    background: rgba(255, 255, 255, 0.03);
}

.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel,
.mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel,
.mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
    background: rgba(0, 0, 0, 0.05);
}

.mm-menu .mm-divider {
    background: rgba(0, 0, 0, 0.05);
}

.mm-page {
    box-sizing: border-box;
    position: relative;
}

.mm-slideout {
    -webkit-transition: -webkit-transform 0.4s ease;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition:
        transform 0.4s ease,
        -webkit-transform 0.4s ease;
    z-index: 1;
}

html.mm-opened {
    overflow-x: hidden;
    position: relative;
}

html.mm-blocking {
    overflow: hidden;
}

html.mm-blocking body {
    overflow: hidden;
}

html.mm-background .mm-page {
    background: inherit;
}

#mm-blocker {
    background: transparent;
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

html.mm-blocking #mm-blocker {
    display: block;
}

.mm-menu.mm-offcanvas {
    z-index: 0;
    display: none;
    position: fixed;
}

.mm-menu.mm-offcanvas.mm-opened {
    display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
    z-index: 10;
}

.mm-menu.mm-offcanvas {
    width: 80%;
    min-width: 140px;
    max-width: 440px;
}

html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(80%, 0);
    -ms-transform: translate(80%, 0);
    transform: translate(80%, 0);
    -webkit-transform: translate3d(80%, 0, 0);
    transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
    html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
        -webkit-transform: translate(140px, 0);
        -ms-transform: translate(140px, 0);
        transform: translate(140px, 0);
        -webkit-transform: translate3d(140px, 0, 0);
        transform: translate3d(140px, 0, 0);
    }
}

@media all and (min-width: 550px) {
    html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
        -webkit-transform: translate(440px, 0);
        -ms-transform: translate(440px, 0);
        transform: translate(440px, 0);
        -webkit-transform: translate3d(440px, 0, 0);
        transform: translate3d(440px, 0, 0);
    }
}

.mm-sronly {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
}

em.mm-counter {
    font: inherit;
    font-size: 14px;
    font-style: normal;
    text-indent: 0;
    line-height: 20px;
    display: block;
    margin-top: -10px;
    position: absolute;
    right: 45px;
    top: 50%;
}

em.mm-counter + a.mm-next {
    width: 90px;
}

em.mm-counter + a.mm-next + a,
em.mm-counter + a.mm-next + span {
    margin-right: 90px;
}

em.mm-counter + a.mm-fullsubopen {
    padding-left: 0;
}

em.mm-counter + a.mm-fullsubopen + a,
em.mm-counter + a.mm-fullsubopen + span {
    padding-right: 90px;
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a,
.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span {
    padding-right: 90px;
}

.mm-vertical > .mm-counter {
    top: 12px;
    margin-top: 0;
}

.mm-vertical.mm-spacer > .mm-counter {
    margin-top: 40px;
}

.mm-nosubresults > .mm-counter {
    display: none;
}

.mm-menu em.mm-counter {
    background-color: rgba(0, 0, 0, 0.3);
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    line-height: 21px;
    font-size: 10px;
    font-weight: $font-weight-bold;
}

.mm-menu em.mm-counter {
    background-color: $oceanBlue;
}

.mmenu-trigger {
    height: 46px;
    width: 46px;
    display: none;
    position: relative;
    margin: 0;
    background-color: #eee;
    border-radius: 4px;
    cursor: pointer;
}

.hamburger {
    padding: 0;
    top: 16px;
    left: 12px;
    transform: scale(0.67);
    -moz-transform: scale(0.7) translateY(4px);
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

.hamburger-box {
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 33px;
    height: 4px;
    background-color: #555;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition:
        top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
    transition:
        top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition:
        top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition:
        top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.mmenu-trigger {
    display: none;
}

@media (max-width: 1024px) {
    #responsive {
        display: none;
    }
    .mmenu-trigger {
        display: inline-block !important;
    }
    #header {
        height: 77px;
        background-color: $lightGrey;
    }
    #logo {
        border: none;
        background-color: $lightGrey !important;
    }
    #logo img {
        border: none;
        max-width: 100px;
        height: auto;
    }
    header.fullwidth .container {
        padding: 0 20px;
    }
    .header-widget {
        float: left;
    }
    .fullwidth .header-widget:last-of-type,
    .header-widget:last-of-type {
        padding-right: 25px;
        float: left;
    }
    #header .right-side {
        position: absolute;
        width: auto;
        background-color: $lightGrey;
        text-align: left;
    }
    .mmenu-trigger {
        margin: 0 -5px 0 23px;
        top: 15px;
        float: right;
    }
    #header .right-side .header-widget {
        border-bottom: none;
        padding-right: 0;
    }
    .header-widget {
        padding: 0 25px;
    }
    .header-notifications:first-child {
        margin-right: -5px;
    }
    #header-container.cloned {
        display: none;
    }
}

@media (max-width: 768px) {
    .header-notifications {
        position: initial;
    }
    .header-notifications-dropdown:before {
        display: none;
    }
    .user-menu .header-notifications-dropdown,
    .header-notifications-dropdown {
        width: calc(100vw - 45px);
        right: -10px;
        top: calc(100% + 15px);
    }
}

@media (max-width: 480px) {
    .hide-on-mobile {
        display: none;
    }
}

#footer {
    background-color: #303030;
    color: silver;
}

#footer p {
    line-height: 26px;
}

.footer-top-section {
    border-bottom: 1px solid #484848;
}

.footer-top-section .footer-logo img {
    max-height: 46px;
}

.footer-rows-container {
    display: block;
    height: 96px;
    position: relative;
    z-index: 100;
}

.footer-row {
    display: inline-block;
    padding: 0 40px;
    border-right: 1px solid #484848;
    border-left: 1px solid #484848;
    float: left;
    height: 100%;
}

.footer-row-inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.footer-row-inner.footer-logo {
    position: relative;
    top: auto;
    transform: translateY(0);
    top: 50%;
    position: relative;
    height: 46px;
}

.footer-row-inner.footer-logo img {
    position: relative;
    top: 50%;
}

.footer-row:first-child {
    padding-left: 0;
    border-left: none;
}

.footer-row:last-child {
    padding-right: 0;
    border: none;
}

.footer-rows-right .footer-row:first-child {
    padding-left: 40px;
    border-left: 1px solid #484848;
}

.footer-rows-right {
    float: right;
    height: 100%;
}

.language-switcher.bootstrap-select.btn-group .dropdown-menu.inner {
    width: 100%;
}

.language-switcher.bootstrap-select:before {
    display: none;
}

.language-switcher.bootstrap-select .dropdown-menu {
    padding-top: 15px;
    top: calc(100% + 15px);
    width: 160px;
    left: auto;
    right: 0;
}

.language-switcher.bootstrap-select .dropdown-menu:before {
    position: absolute;
    top: -5px;
    content: "";
    right: 20px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
}

.language-switcher.bootstrap-select.btn-group button {
    height: 44px;
    padding-right: 35px;
    padding-left: 18px;
    color: #fff;
    background-color: #444;
    transition: 0.3s;
    box-shadow: none;
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    height: 44px;
    line-height: 44px;
}

.language-switcher.bootstrap-select.btn-group button:hover {
    background-color: #fff;
    color: #333;
}

.language-switcher.bootstrap-select.btn-group.open button,
.language-switcher.bootstrap-select.btn-group button:hover {
    background-color: $oceanBlue;
    color: #fff;
}

.language-switcher.bootstrap-select.btn-group button .caret {
    transition: 0.3s;
}

.language-switcher.bootstrap-select.btn-group.open button .caret,
.language-switcher.bootstrap-select.btn-group button:hover .caret {
    border-top-color: #fff;
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .caret {
    right: 18px;
}

.footer-social-links {
    list-style: none;
    margin: 0 -10px;
    margin-top: 3px;
    padding: 0;
}

.footer-social-links li {
    display: inline-block;
    padding: 0;
    margin: 0;
    float: left;
}

.footer-social-links li a {
    font-size: 20px;
    padding: 0 10px;
    color: #fff;
    display: inline-block;
    transition: 0.3s;
    opacity: 0.5;
    transform: translate3d(0, -0.5px, 0);
}

.footer-social-links li a:hover {
    opacity: 1;
}

.footer-middle-section {
    padding: 60px 0;
    position: relative;
    z-index: 10;
}

#footer h3,
#footer h2,
.footer-links h3 {
    font-size: 18px;
    color: #fff;
    margin: 0 0 15px;
    padding: 0;
    display: block;
}

#footer h3 i {
    position: relative;
    margin-right: 5px;
    top: 2px;
    font-size: 20px;
    font-weight: $font-weight-normal !important;
}

.footer-links ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 0;
    display: block;
}

.footer-links ul li:first-child a {
    padding-top: 0;
}

.footer-links ul li:last-child a {
    padding-bottom: 0;
}

.footer-links ul li a {
    color: silver;
    display: block;
    line-height: 25px;
    position: relative;
    z-index: 100;
    padding: 4px 0;
}

.footer-links ul li a span {
    display: inline-block;
    position: relative;
}

.footer-links ul li a:hover {
    color: #fff;
}

.footer-links ul li a span:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $oceanBlue;
    height: 2px;
    display: block;
    transition:
        all 0.25s,
        opacity 0.2s;
    z-index: -1;
    border-radius: 3px;
    opacity: 0.5;
    width: 0;
}

.footer-links ul li a:hover span:before {
    width: 100%;
    opacity: 1;
}

.newsletter {
    display: flex;
    margin-top: 20px;
}

.newsletter input,
.newsletter button {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.newsletter input {
    background: #262626;
    box-shadow: none;
    margin-bottom: 0;
}

.newsletter input:focus {
    box-shadow: none;
}

.newsletter button {
    background-color: $oceanBlue;
    border-radius: 4px;
    height: 48px;
    min-width: 48px;
    max-width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    color: #fff;
    margin-left: 10px;
    transition: 0.3s;
}

.newsletter button:hover {
    background-color: #fff;
    color: #333;
}

.newsletter button i {
    width: 100%;
    line-height: 48px;
}

.footer-bottom-section {
    padding: 25px 0;
    border-top: 1px solid #484848;
    text-align: center;
}

.footer-bottom-section strong {
    color: #fff;
    font-weight: $font-weight-normal;
}

@media (max-width: 992px) {
    #footer h3 {
        margin-top: 45px;
    }
    .footer-middle-section {
        padding-top: 0;
        padding-bottom: 45px;
    }
}

@media (max-width: 768px) {
    .content-left-offset {
        padding-left: 15px;
    }
    .content-right-offset {
        padding-left: 15px;
    }
    .footer-rows-container {
        height: auto;
        padding: 20px 0 40px;
    }
    .footer-rows-left,
    .footer-rows-right {
        float: none;
        height: auto;
    }
    .footer-row-inner.footer-logo img {
        top: 0;
    }
    .footer-row-inner {
        position: relative;
        top: 0;
        transform: translateY(0);
    }
    .footer-row-inner.footer-logo {
        top: 0;
        position: relative;
        height: 46px;
    }
    #footer .footer-row {
        padding: 0;
        float: none;
        padding: 20px 0 0;
        display: block;
        width: 100%;
        border: none;
    }
    .footer-rows-container .footer-rows-left,
    .footer-rows-container .footer-rows-right {
        display: block;
    }
}

.pagination {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media screen and (max-width: 576px) {
        justify-content: center;
    }

    .page-item {
        font-family: $font-family-content;
        font-size: 14px;

        &.active {
            .page-link {
                color: $darkBlue;
                background-color: $oceanBlueOpacity;
                border-bottom: 1px solid $oceanBlue;
            }
        }
        .page-link {
            border: 1px solid $regularGrey;
            color: $btnColorGray;
            min-width: 40px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.page-prev,
        &.page-next {
            a {
                color: $darkBlue;
            }
        }

        &:first-child .page-link {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-child .page-link {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
}

.pagination-next-prev {
    position: relative;
    top: -66px;
}

.pagination ul {
    margin: 0;
    padding: 0;
}

.pagination ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.pagination ul li a,
.pagination-next-prev ul li a {
    padding: 10px 0;
    border-bottom: none;
    display: inline-block;
    color: #333;
    background-color: transparent;
    font-weight: $font-weight-bold;
    margin: 0;
    line-height: 22px;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    font-size: 14px;
    float: left;
}

.pagination ul li a {
    border-radius: 4px;
    width: 44px;
    height: 44px;
    padding: 0;
    line-height: 44px;
}

.pagination ul li a i {
    line-height: 44px;
    font-size: 24px;
}

.pagination ul li.blank {
    color: #a0a0a0;
    padding: 0 6px;
}

.section.gray .pagination .pagination-arrow a:hover,
.pagination ul li a.current-page,
.pagination ul li a:hover {
    background-color: #333;
    color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.pagination ul li a.current-page {
    background-color: $oceanBlue;
    color: #fff;
    box-shadow: 0 2px 8px rgba(25, 140, 202, 0.25);
}

.pagination .pagination-arrow a {
    background-color: #f0f0f0;
}

.section.gray .pagination .pagination-arrow a {
    background-color: #eaeaea;
}

#backtotop {
    position: fixed;
    right: 0;
    opacity: 0;
    visibility: hidden;
    bottom: 25px;
    margin: 0 25px 0 0;
    z-index: 999;
    transition: 0.35s;
    transform: translateY(10px);
}

#backtotop.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

#backtotop a {
    text-decoration: none;
    border: 0;
    display: block;
    width: 46px;
    height: 46px;
    background-color: $oceanBlue;
    opacity: 1;
    transition: all 0.3s;
    border-radius: 4px;
    text-align: center;
    font-size: 26px;
}

body #backtotop a {
    color: #fff;
}

#backtotop a:after {
    content: "";
    font-family: feather-icons;
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    #backtotop {
        display: none;
    }
}

.sidebar-container {
    margin-bottom: 40px;
}

.full-page-container .sidebar-container {
    margin-bottom: 0;
}

.sidebar-widget input {
    margin-bottom: 0;
}

.sidebar-widget {
    margin-bottom: 50px;
    display: block;
}

.sidebar-widget h3 {
    font-size: 20px;
    margin-bottom: 20px;
}

.notify-box {
    display: block;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 15px 25px;
    position: relative;
    line-height: 28px;
    min-height: 59px;
}

.notify-box label {
    margin-bottom: 0;
    cursor: pointer;
}

.notify-box .switch-button {
    margin-right: 10px;
}

.sort-by .bootstrap-select {
    position: relative;
    right: 0;
    top: 0;
}

.sort-by .dropdown-menu {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
}

.sort-by .bootstrap-select.btn-group button {
    flex: 1;
    position: relative;
    right: 0;
    top: 4px;
    font-weight: $font-weight-bold;
    margin-left: 10px;
    width: auto;
    padding: 0;
    padding-right: 12px;
    background-color: transparent;
    box-shadow: none;
    height: 20px;
    color: #333;
}

.sort-by .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: auto;
}

.sort-by .bootstrap-select.btn-group .dropdown-toggle .caret {
    right: 0;
}

.sort-by .bootstrap-select .dropdown-menu {
    padding-top: 15px;
    top: 40px;
    right: 0;
    left: auto;
    position: absolute;
    min-width: 160px !important;
    flex: 1;
}

.sort-by .bootstrap-select.open:before {
    display: none;
}

.sort-by {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    float: right;
}

@media (max-width: 768px) {
    .notify-box {
        margin-bottom: 90px;
    }
    .notify-box .sort-by {
        position: absolute;
        margin-top: 35px;
        left: 0;
    }
    .notify-box .sort-by .bootstrap-select .dropdown-menu {
        left: 0;
        right: auto;
    }
}

#titlebar {
    background-color: #f8f8f8;
    position: relative;
    padding: 70px 0;
    margin-bottom: 65px;
}

#titlebar.gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0));
    padding-bottom: 0;
}

#titlebar.white {
    background: #fff;
    padding-bottom: 0;
}

#titlebar.transparent {
    background: 0 0;
}

#titlebar span a,
#titlebar span {
    font-size: 20px;
    color: #888;
    margin-bottom: 3px;
    margin-top: 3px;
    display: block;
}

#titlebar #breadcrumbs span {
    font-size: 14px;
}

#titlebar h1,
#titlebar h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 1px 0 3px;
}

#breadcrumbs {
    position: absolute;
    right: 15px;
    display: inline-block;
    font-size: 14.7px;
    top: 50%;
    transform: translateY(-51%);
    border-radius: 4px;
    font-weight: $font-weight-bold;
    color: #333;
    background-color: #f0f0f0;
}

#breadcrumbs ul {
    margin: 0;
    padding: 12px 22px;
    line-height: 23px;
}

#breadcrumbs ul li a {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #666;
    font-weight: $font-weight-normal;
}

#breadcrumbs ul li a:hover {
    color: $oceanBlue;
}

#breadcrumbs ul li {
    display: inline-block;
    list-style: none;
    margin: 0 0 0 7px;
}

#breadcrumbs ul li:first-child:before {
    display: none;
}

#breadcrumbs ul li:first-child {
    margin-left: 0;
}

#breadcrumbs ul li:before {
    content: "";
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #aaa;
    font-size: 14px;
    margin-right: 12px;
}

#breadcrumbs ul li.home-icon a {
    opacity: 1;
    font-size: 24px;
    top: 4px;
    position: relative;
    line-height: 0;
    padding: 0;
    display: inline-block;
}

#breadcrumbs ul li.home-icon i {
    line-height: 0;
}

#breadcrumbs.dark {
    color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    background-color: #333;
}

#breadcrumbs.dark ul li a,
#breadcrumbs.dark ul li a:hover {
    color: #fff;
}

#breadcrumbs.dark ul li:before {
    border-left: 4px solid rgba(255, 255, 255, 0.3);
}

#breadcrumbs.white {
    color: #333;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

#breadcrumbs.white ul li a {
    color: #666;
}

#breadcrumbs.white ul li a:hover {
    color: $oceanBlue;
}

#breadcrumbs.white ul li:before {
    border-left: 4px solid rgba(0, 0, 0, 0.3);
}

.single-page-header #breadcrumbs {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-left: auto;
}

@media (max-width: 992px) {
    .single-page-header #breadcrumbs {
        margin: 30px 0 20px;
    }
}

.single-page-header {
    margin-bottom: 65px;
    padding: 60px 0;
    position: relative;
}

.single-page-header .container {
    z-index: 100;
    position: relative;
}

.single-page-header:after,
.single-page-header:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: 15;
    background: linear-gradient(
        to right,
        rgba(247, 247, 247, 1) 50%,
        rgba(247, 247, 247, 0.8) 70%,
        rgba(247, 247, 247, 0.8) 90%
    );
}

.single-page-header:after {
    z-index: 5;
    background-color: #f7f7f7;
}

.single-page-header .background-image-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    left: 50%;
    z-index: 10;
}

.single-page-header .single-page-header-inner {
    display: flex;
}

.single-page-header .left-side,
.single-page-header .right-side {
    flex: 1;
    display: flex;
    align-items: center;
}

.single-page-header .left-side {
    padding-right: 30px;
}

.single-page-header .right-side {
    max-width: 35%;
}

@media (min-width: 1365px) {
    .single-page-header .right-side {
        max-width: 30%;
    }
}

.single-page-header .header-image a {
    display: flex;
    height: 100%;
}

.single-page-header .header-image {
    height: 140px;
    flex: 0 0 140px;
    margin-right: 35px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 0 20px;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .single-page-header .header-image {
        max-width: 140px;
    }
}

.single-page-header.freelancer-header .header-image {
    background: #fff;
    border-radius: 50%;
    box-shadow: none;
    display: flex;
    padding: 0;
    overflow: hidden;
    height: 130px;
    flex: 0 0 130px;
    width: 130px;
}

.single-page-header .header-image img {
    align-self: center;
    transform: translate3d(0, 0, 0);
}

@media all and (-ms-high-contrast: none) {
    .single-page-header .header-image img {
        width: 100%;
    }
}

.single-page-header .header-details {
    flex: 1;
}

.single-page-header .header-details h3 {
    color: #333;
    font-size: 26px;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
    margin-top: 3px;
    line-height: 36px;
    position: relative;
    padding-right: 0;
    display: inline-block;
}

.single-page-header .header-details h3 span {
    color: gray;
    display: block;
    font-size: 20px;
    font-weight: $font-weight-normal;
    margin-top: -3px;
}

.single-page-header .header-details h5 {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #333;
}

.single-page-header .header-details h5 {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #333;
}

.single-page-header .header-details ul {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 24px;
    margin-bottom: -7px;
}

.single-page-header .header-details li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 12px;
}

.single-page-header .header-details li:last-child {
    margin-right: 0;
}

.single-page-header .header-details li a {
    color: #666;
}

.single-page-header .header-details li a:hover {
    color: $oceanBlue;
}

.single-page-header .header-details li i {
    font-size: 20px;
    position: relative;
    top: 2px;
    margin-right: 2px;
}

.single-page-header .header-details li .verified-badge {
    transform: scale(0.85);
    position: relative;
    top: 2px;
}

.single-page-header .header-details li .verified-txt {
    color: #299840;
}

.single-page-header .header-details li .star-rating {
    position: relative;
    top: 6px;
}

.single-page-header .header-details ul li img.flag {
    height: 16px;
    border-radius: 3px;
    position: relative;
    top: -1px;
    display: inline-block;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    margin-right: 5px;
    cursor: default;
}

.single-page-header .salary-box {
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    display: inline-block;
    padding: 20px 25px;
    margin-left: auto;
}

.salary-box .salary-type {
    color: #888;
    line-height: 20px;
    margin: 3px 0 8px;
}

.salary-box .salary-amount {
    color: #333;
    font-size: 28px;
    line-height: 36px;
    font-weight: $font-weight-normal;
}

@media (max-width: 1366px) {
    .single-page-header.freelancer-header .header-image {
        height: 130px;
        flex: 0 0 130px;
        width: 130px;
    }
}

@media (max-width: 1240px) {
    .single-page-header .header-details h3 {
        font-size: 26px;
    }
    .single-page-header {
        padding: 55px 0;
    }
    .salary-box .salary-amount {
        font-size: 26px;
        line-height: 32px;
    }
    .single-page-header .header-image {
        height: 120px;
        flex: 0 0 120px;
    }
    .single-page-header.freelancer-header .header-image {
        height: 120px;
        flex: 0 0 120px;
        width: 120px;
    }
}

@media (max-width: 992px) {
    .single-page-header .single-page-header-inner {
        display: flex;
        flex-direction: column;
    }
    .single-page-header .left-side {
        padding-right: 0;
    }
    .single-page-header .header-details h3 {
        font-size: 24px;
    }
    .single-page-header {
        padding: 35px 0;
    }
    .single-page-header .salary-amount {
        font-size: 22px;
        line-height: 24px;
    }
    .single-page-header .salary-box {
        padding: 0;
        margin-top: 15px;
        margin-bottom: 20px;
        background-color: transparent;
        box-shadow: none;
    }
    .salary-box .salary-type {
        font-weight: $font-weight-bold;
        color: #333;
    }
    .single-page-header .left-side,
    .single-page-header .right-side,
    .single-page-header-inner {
        display: block;
        max-width: 100%;
    }
    .single-page-header .header-image {
        height: 70px;
        width: auto;
        padding: 15px 20px;
        margin: 15px 0;
        display: inline-block;
    }
    .single-page-header .header-image img {
        height: 100%;
    }
    .single-page-header .header-details h5 {
        border-top: 1px solid $regularGrey;
        padding-top: 25px;
    }
    .single-page-header.freelancer-header .header-image {
        height: 70px;
        flex: 0 0 70px;
        width: 70px;
        margin-bottom: -70px;
    }
    .single-page-header.freelancer-header .header-details h3 {
        margin-left: 90px;
    }
}

@media (max-width: 768px) {
    .single-page-header:after,
    .single-page-header:before {
        background: #f7f7f7;
    }
    .single-page-header.freelancer-header .header-image {
        height: 70px;
        flex: 0 0 70px;
        width: 70px;
        margin-bottom: -70px;
    }
    .single-page-header.freelancer-header .header-details h3 {
        margin-left: 90px;
    }
}

.blog-post {
    display: flex;
    background: #fff;
    margin-bottom: 35px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    transition: 0.3s;
}

.blog-post:hover {
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
}

.blog-post-thumbnail {
    flex: 0 0 auto;
    max-width: none;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    width: 260px;
}

.blog-post-thumbnail-inner {
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
}

.blog-post-thumbnail-inner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.blog-post-thumbnail-inner .blog-item-tag {
    bottom: 20px;
    left: 20px;
    top: auto;
    margin: 0;
}

.blog-post-content {
    flex: 1;
    padding: 35px;
}

.blog-post-content h3,
.blog-post-content h3 a {
    font-size: 22px;
    line-height: 33px;
    color: #333;
    margin-bottom: 15px;
    display: block;
}

.blog-post-content h3 a:hover {
    color: $oceanBlue;
}

a.blog-post-info,
.blog-post-info,
.blog-post-date {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
    display: inline-block;
    background-color: #f0f0f0;
    border-radius: 4px;
    line-height: 20px;
    padding: 4px 10px;
    margin-right: 3px;
    transition: 0.3s;
}

a.blog-post-info:hover {
    color: #fff;
    background-color: $oceanBlue;
}

.blog-post-content p {
    padding: 0;
    margin: 0;
    display: block;
    color: gray;
}

.blog-post-content a.button i.icon-feather-arrow-right {
    font-size: 20px;
    top: 4px;
    left: -1px;
    position: relative;
    margin: 0 -5px;
}

.blog-post-info-list {
    display: block;
}

blockquote {
    padding: 10px 20px;
    margin: 0 20px;
    font-size: 17px;
    line-height: 30px;
    color: #888;
    border-left: 5px solid #eee;
}

.blog-post.single-post {
    flex-direction: column;
}

.blog-post.single-post:hover {
    transform: none;
}

.blog-post.single-post .blog-post-thumbnail {
    width: 100%;
    height: 380px;
}

.blog-post.single-post p {
    margin-bottom: 20px;
}

#posts-nav {
    padding: 0;
    list-style: none;
    width: 100%;
    position: relative;
    margin: 0;
    display: inline-block;
    min-height: 60px;
}

#posts-nav li span {
    display: block;
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
    display: inline-block;
    background-color: #f0f0f0;
    border-radius: 4px;
    line-height: 20px;
    padding: 4px 10px;
    margin-right: 3px;
    transition: 0.3s;
}

#posts-nav li a:hover span {
    background-color: $oceanBlue;
    color: #fff;
}

#posts-nav li {
    position: absolute;
    font-size: 18px;
    font-weight: $font-weight-normal;
    width: 50%;
}

#posts-nav li strong {
    display: block;
    font-weight: $font-weight-normal;
}

#posts-nav li a {
    transition:
        transform 0.3s,
        color 0.3s 0s;
    color: #333;
    display: inline-block;
    line-height: 28px;
}

#posts-nav li a:hover {
    color: $oceanBlue;
}

#posts-nav li.next-post {
    right: 0;
    text-align: right;
}

#posts-nav li.prev-post {
    left: 0;
}

#posts-nav li.next-post a {
    padding-right: 50px;
}

#posts-nav li.prev-post a {
    padding-left: 50px;
}

#posts-nav li.prev-post a:before,
#posts-nav li.next-post a:before {
    font-family: feather-icons;
    font-size: 30px;
    position: absolute;
    color: inherit;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    transition: transform 0.3s;
}

#posts-nav li.next-post a:before {
    right: 0;
    content: "\e930";
}

#posts-nav li.prev-post a:before {
    left: 0;
    content: "\e92f";
}

#posts-nav li.next-post a:hover:before {
    transform: translate3d(5px, -50%, 0);
}

#posts-nav li.prev-post a:hover:before {
    transform: translate3d(-5px, -50%, 0);
}

.comments {
    margin: -10px 0 0;
}

.comments h4 {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: $font-weight-normal;
    color: #333;
}

.comments h4 span {
    display: inline-block;
    font-size: inherit;
}

.comment {
    font-size: 20px;
}

.comments .button {
    margin: 0 0 10px;
    padding: 7px 15px;
}

.comments.reviews .button {
    margin: 0;
}

.comments ul {
    padding-left: 0;
}

.comments ul li {
    display: block;
}

.comments ul li,
.comments ul li ul li,
.comments ul li ul li ul li,
.comments ul li ul li ul li {
    margin: 50px 0 0 0;
}

.comments ul li ul {
    margin: 0 0 0 100px;
}

.comment-content p {
    margin: 3px 0 0;
    line-height: 26px;
}

.comment-content {
    color: #666;
    padding: 0 0 0 100px;
}

.comments ul li ul {
    border-left: 1px solid $regularGrey;
    padding-left: 35px;
}

.comments ul li ul li:before {
    content: "";
    width: 15px;
    height: 1px;
    background-color: $regularGrey;
    display: inline-block;
    position: absolute;
    top: 30px;
    left: -35px;
}

.comments ul li {
    position: relative;
}

.comment-content strong {
    padding-right: 5px;
    color: #666;
}

.comment-content span {
    color: #888;
}

body .comment-content p {
    padding: 5px 0;
}

.comments-amount {
    color: #888;
    font-weight: $font-weight-normal;
}

.avatar {
    display: inline-block;
    float: left;
    padding: 0 17px 0 0;
    position: absolute;
    left: 0;
    top: -5px;
}

.avatar img {
    max-width: 70px;
    border-radius: 50%;
}

.comment-by {
    width: 100%;
    padding-bottom: 8px;
    padding-top: 5px;
    font-weight: $font-weight-normal;
    color: #333;
}

.comment-by h5 {
    margin: 0 0 7px;
}

.comment-by a.reply {
    transition: all 0.3s;
}

.comment-by a.reply {
    display: inline;
    background-color: #f0f0f0;
    padding: 5px 16px;
    line-height: 22px;
    font-size: 14px;
    color: #666;
    font-weight: $font-weight-normal;
    position: absolute;
    right: 0;
    top: 15px;
    border-radius: 4px;
}

.comment-by a.reply i {
    font-size: 12px;
    margin-right: 1px;
}

.comment-by a.reply:hover {
    background-color: $oceanBlue;
    color: #fff;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.comment-by span.date {
    color: #888;
    font-weight: $font-weight-normal;
    margin: 0 0 0;
    float: none;
    display: block;
    text-align: left;
}

@media (max-width: 768px) {
    .comments ul li ul {
        margin: 0;
    }
    .avatar {
        top: 3px;
    }
    .comment-content {
        color: #666;
        padding: 0 0 0 70px;
    }
    .avatar img {
        max-width: 50px;
        border-radius: 50%;
        top: 5px;
    }
    .comment-by a.reply {
        position: relative;
        margin: 10px 0 0;
        top: 0;
        display: inline-block;
    }
    .blog-post {
        flex-direction: column;
    }
    .blog-post-thumbnail {
        width: 100%;
        height: 220px;
    }
    #posts-nav li {
        position: relative;
        width: 100%;
    }
    #posts-nav li a {
        padding: 0 !important;
    }
    #posts-nav li a::before {
        display: none;
    }
    #posts-nav li.next-post {
        text-align: left;
        margin-bottom: 30px;
    }
}

.widget-tabs {
    padding: 0;
    list-style: none;
    margin-top: 25px;
    display: block;
}

.widget-content {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.widget-content:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    opacity: 1;
    transition: 0.35s ease-in-out;
}

.widget-content img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 1;
    transition: 0.35s;
    border-radius: 5.5px;
}

.widget-text h5 {
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 24px;
    margin: 0 0 3px;
    color: #333;
    transition: 0.35s;
}

.widget-text span {
    font-size: 14px;
    color: #888;
    display: inline-block;
    background-color: #f0f0f0;
    border-radius: 4px;
    line-height: 20px;
    padding: 4px 10px;
    margin-top: 3px;
    transition: 0.35s;
}

.widget-text {
    display: block;
    padding: 0;
    position: relative;
    z-index: 20;
    padding: 30px;
}

.widget-tabs .widget-content {
    display: table;
}

.widget-tabs .widget-content .widget-text {
    display: table-cell;
    vertical-align: middle;
}

.widget-tabs li {
    margin-top: 20px;
}

.widget-content:hover:before,
.widget-content.active:before {
    opacity: 0.6;
    background: #333;
}

.widget-content:hover img,
.widget-content.active img {
    opacity: 1;
}

.widget-content:hover h5,
.widget-content.active h5 {
    color: #fff;
}

.widget-content:hover span,
.widget-content.active span {
    background: rgba(255, 255, 255, 0.25);
    color: #fff;
}

.blog-compact-item-container {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: 0 0;
    transform: translate3d(0, 0, 0);
    transition: transform 0.4s;
    margin-bottom: 30px;
}

.blog-compact-item-container:hover {
    transform: translateY(-10px);
}

.blog-compact-item {
    background: #ccc;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 100%;
    display: block;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 460px;
    z-index: 100;
    cursor: pointer;
    transition: 0.4s;
}

@media (max-width: 1366px) {
    .blog-compact-item {
        height: 420px;
    }
}

.blog-compact-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 4px;
}

.blog-compact-item:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    border-radius: 4px;
    background: linear-gradient(
        to top,
        rgba(51, 51, 51, 0.8) 0%,
        rgba(51, 51, 51, 0.7) 35%,
        rgba(51, 51, 51, 0.3) 60%,
        rgba(51, 51, 51, 0.3) 100%
    );
    transition: 0.4s;
}

.blog-compact-item-content {
    position: absolute;
    bottom: 32px;
    left: 0;
    padding: 0 34px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
}

.blog-compact-item-content h3 {
    color: #fff;
    font-size: 20px;
    padding: 5px 0;
    font-weight: $font-weight-normal;
    margin: 2px 0 0;
    line-height: 30px;
}

.blog-compact-item-content span {
    font-size: 16px;
    font-weight: $font-weight-normal;
    display: inline-block;
    color: rgba(255, 255, 255, 0.7);
}

.blog-compact-item-content p {
    font-size: 16px;
    font-weight: $font-weight-normal;
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    margin: 7px 0 0;
}

span.blog-item-tag {
    font-size: 14px;
    font-weight: $font-weight-bold;
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 12px;
    line-height: 20px;
    font-weight: $font-weight-normal;
    margin-bottom: 9px;
    position: absolute;
    top: 30px;
    left: 32px;
    z-index: 110;
    transition: 0.4s;
}

.blog-post-tags {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
    font-weight: $font-weight-normal;
    opacity: 0.9;
}

.blog-post-tags li {
    display: inline-block;
    margin-right: 5px;
}

.blog-post-tags li i {
    margin-right: 3px;
    font-weight: $font-weight-normal;
    display: inline-block;
}

.contact-location-info {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.03);
}

.contact-location-info .contact-address,
.contact-location-info #single-job-map-container {
    flex: 1;
}

.contact-location-info #single-job-map-container #singleListingMap {
    border-radius: 0 4px 4px 0;
}

.contact-location-info .contact-address {
    border: 1px solid $regularGrey;
    border-right: 0;
    border-radius: 4px 0 0 4px;
}

.contact-location-info .contact-address {
    display: flex;
}

.contact-location-info .contact-address > ul {
    align-self: center;
    padding: 40px;
    margin: 0;
    list-style: none;
    font-size: 18px;
    color: gray;
}

.contact-location-info .contact-address ul li {
    padding: 2px 0;
}

.contact-location-info .freelancer-socials {
    margin-top: 10px;
    margin-left: -8px;
    transform: scale(0.9);
}

.contact-address-headline {
    color: #333;
    font-weight: $font-weight-bold;
}

.contact-address-headline:after {
    content: "";
    height: 2px;
    width: 25px;
    background-color: $oceanBlue;
    position: relative;
    display: block;
    margin: 15px 0;
    border-radius: 3px;
}

@media (max-width: 768px) {
    .contact-location-info {
        flex-direction: column;
    }
    .contact-location-info .contact-address {
        border: 1px solid $regularGrey;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
    }
    .contact-location-info #single-job-map-container #singleListingMap {
        border-radius: 0 0 4px 4px;
    }
}

#contact div input {
    margin-bottom: 22px;
}

.single-page-section {
    margin-bottom: 55px;
    display: block;
}

.single-page-section p:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
}

.single-page-section h3 {
    margin-bottom: 20px;
    font-size: 20px;
}

.single-page-section .job-listing h3.job-listing-title {
    margin-bottom: 0;
}

.full-page-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.full-page-sidebar {
    flex: 0 0 360px;
    background-color: $lightGrey;
    position: relative;
}

.full-page-sidebar .full-page-sidebar-inner {
    overflow: auto;
    height: 100%;
}

.full-page-sidebar .sidebar-container {
    padding: 40px;
}

.full-page-sidebar .simplebar-scrollbar {
    width: 5px;
}

.full-page-sidebar .sidebar-widget:last-of-type {
    margin-bottom: 80px;
}

.full-page-content-container {
    flex: 1;
    background-color: #fff;
    padding: 0;
    overflow: auto;
}

.full-page-content-inner {
    padding: 40px;
    padding-bottom: 0;
}

.full-page-content-inner .grid-layout {
    width: calc(100% + 30px);
}

.full-page-content-inner .grid-layout .job-listing {
    margin: 0 30px 30px 0;
    width: calc(100% * (1 / 3) - 30px);
}

.sidebar-search-button-container {
    position: absolute;
    width: calc(100% - 15px);
    bottom: 0;
    padding: 40px;
    padding-top: 10px;
    z-index: 100;
    background-color: $lightGrey;
}

.sidebar-search-button-container button {
    width: calc(100% + 15px);
    line-height: 46px;
    height: 46px;
    transition: 0.3s;
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.1);
}

.sidebar-search-button-container button:hover {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.25);
    transform: translateY(-2px);
}

.small-footer {
    border-top: 1px solid $regularGrey;
    padding-top: 0;
    width: calc(100% + 80px);
    left: -40px;
    position: relative;
    padding: 25px 40px;
    display: flex;
}

.small-footer strong {
    color: #333;
}

.small-footer-copyrights {
    flex: 2;
}

.small-footer .footer-social-links {
    transform: none;
    flex: 1;
    text-align: right;
    float: right;
    display: inline-block;
    margin: 0 -5px 0 0;
    position: relative;
    top: 2px;
}

.small-footer .footer-social-links li {
    color: #333;
    transform: none;
    float: none;
    margin: 0 -3px;
}

.small-footer .footer-social-links li a {
    color: #333;
    transform: none;
}

.full-page-map-container {
    flex: auto;
    position: relative;
    background-color: #e5e3df;
}

.full-page-map-container #map {
    position: relative;
    z-index: 30;
}

.full-page-container.with-map .full-page-content-container {
    flex: 0 0 50vw;
}

.full-page-sidebar.hidden-sidebar {
    visibility: hidden;
    transition: 0.4s;
    position: relative;
    opacity: 0;
    max-width: 0;
}

.full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
    width: 360px;
}

.full-page-sidebar.hidden-sidebar.enabled-sidebar {
    visibility: visible;
    opacity: 1;
    max-width: 360px;
}

.enable-filters-button {
    position: relative;
    left: 48px;
    top: 0;
    z-index: 100;
    background-color: #fff;
    color: #333;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    height: 48px;
    line-height: 48px;
    padding: 0;
    transition: 0.3s;
    min-width: 126px;
    text-align: center;
}

.enable-filters-button span {
    position: relative;
    padding: 0 15px;
    background-color: $oceanBlue;
    color: #fff;
    width: 100%;
    z-index: 100;
    border-radius: 0 4px 4px 0;
    transition: 0.3s;
}

.filter-button-container {
    position: absolute;
    left: 40px;
    top: 20px;
    width: calc(100% - 40px);
    overflow-x: hidden;
    padding: 20px 0;
}

.filter-button-tooltip {
    position: absolute;
    width: auto;
    left: 15px;
    top: 24px;
    z-index: 100;
    border-radius: 4px;
    width: auto;
    animation: tooltip-bounce 2s infinite;
    margin-left: 15px;
    white-space: nowrap;
    opacity: 0;
    overflow: visible;
    transition: opacity 0.3s;
    pointer-events: none;
    padding: 0 20px;
    background-color: #fff;
    color: #555;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-size: 15.7px;
    line-height: 48px;
    top: 20px;
}

.filter-button-tooltip.tooltip-visible {
    opacity: 1;
}

.filter-button-tooltip:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: calc(50% - 3px);
    content: "";
    left: -3px;
    box-sizing: border-box;
    border: 5px solid #000;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(45deg) translateY(-50%);
    box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.04);
}

@keyframes tooltip-bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(14px);
    }
    60% {
        transform: translateX(7px);
    }
}

.location-field-on-map {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 280px;
    max-width: 40%;
    z-index: 100;
}

.location-field-on-map input {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

@media (max-width: 3860px) {
    .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% * (1 / 4) - 30px);
    }
    .full-page-container.with-map .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% * (1 / 3) - 30px);
    }
}

@media (max-width: 2560px) {
    .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% * (1 / 4) - 30px);
    }
    .full-page-container.with-map .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% * (1 / 2) - 30px);
    }
}

@media (max-width: 1920px) {
    .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% * (1 / 3) - 30px);
    }
    .full-page-container.with-map .full-page-sidebar {
        flex: 0 0 340px;
    }
    .full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
        width: 340px;
    }
    .full-page-container.with-map .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% * (1 / 2) - 30px);
    }
}

@media (max-width: 1600px) {
    .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% * (1 / 2) - 30px);
    }
    .full-page-sidebar {
        flex: 0 0 340px;
    }
    .full-page-container.with-map .full-page-sidebar {
        flex: 0 0 320px;
    }
    .full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
        width: 320px;
    }
    .full-page-container.with-map .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% - 30px);
    }
    .full-page-container.with-map .full-page-content-container {
        flex: 0 0 55vw;
    }
}

@media (max-width: 1365px) {
    .location-field-on-map {
        left: 0;
        right: auto;
        width: calc(100% - 80px);
        max-width: calc(100% - 80px);
        margin: 0 40px;
    }
    .full-page-container .notify-box {
        margin-bottom: 80px;
    }
    .full-page-container .notify-box .sort-by {
        position: absolute;
        margin-top: 40px;
        left: 0;
    }
    .full-page-container .notify-box .sort-by .bootstrap-select .dropdown-menu {
        left: 0;
        right: auto;
    }
}

@media (max-width: 1099px) {
    .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% - 30px);
    }
    .full-page-sidebar {
        flex: 0 0 340px;
    }
    .full-page-map-container {
        order: -1;
        width: 100%;
        height: 400px;
        position: absolute;
        top: 0;
    }
    .full-page-container.with-map {
        padding-top: 400px;
        height: auto !important;
        width: 100%;
        position: relative;
    }
    .full-page-container.with-map .full-page-sidebar {
        max-width: 100%;
        visibility: visible;
        opacity: 1;
    }
    .filter-button-container {
        display: none;
    }
    .full-page-container.with-map .full-page-sidebar .full-page-sidebar-inner {
        position: relative;
        width: 100%;
    }
    .full-page-container.with-map .full-page-content-container {
        flex: 1;
    }
    .full-page-container,
    .full-page-sidebar-inner,
    .full-page-content-container,
    .full-page-container .full-page-sidebar {
        height: auto !important;
    }
    .full-page-container .simplebar-track {
        display: none;
    }
}

@media (max-width: 992px) {
    .location-field-on-map {
        width: calc(100% - 50px);
        max-width: calc(100% - 50px);
        margin: 0 25px;
        top: 25px;
    }
    .sidebar-search-button-container,
    .full-page-sidebar .sidebar-container,
    .full-page-content-inner {
        padding: 35px;
    }
    .full-page-content-inner {
        padding-bottom: 0;
    }
    .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% - 30px);
    }
    .full-page-sidebar {
        flex: 0 0 320px;
    }
    .full-page-sidebar-inner,
    .full-page-content-container,
    .full-page-container .full-page-sidebar {
        height: auto !important;
    }
}

@media (max-width: 768px) {
    .location-field-on-map {
        width: calc(100% - 30px);
        max-width: calc(100% - 30px);
        margin: 0 15px;
    }
    .sidebar-search-button-container,
    .full-page-sidebar .sidebar-container,
    .full-page-content-inner {
        padding: 40px 15px;
    }
    .full-page-content-inner {
        padding-bottom: 0;
    }
    .full-page-content-inner .grid-layout .job-listing {
        width: calc(100% - 30px);
    }
    .full-page-sidebar {
        flex: 1;
        width: 100%;
    }
    .full-page-container {
        display: block;
        width: 100%;
        flex-wrap: wrap;
        height: auto;
    }
    .small-footer {
        display: block;
        text-align: center;
    }
    .small-footer .footer-social-links {
        float: none;
        left: -10px;
        margin-top: 10px;
    }
}

.list-apply-button {
    position: absolute;
    right: 0;
    padding: 10px 20px;
    line-height: 24px;
    position: relative;
    font-size: 16px;
    font-weight: $font-weight-normal;
    display: inline-block;
    transition: all 0.3s;
    border-radius: 4px;
    background: #f0f0f0;
    color: #666;
    text-align: center;
}

@media (max-width: 768px) {
    .list-apply-button {
        flex: auto;
        width: 100%;
        margin-top: 15px;
    }
}

.grid-layout {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
}

.freelancers-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
}

.freelancer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 30px 30px 0;
    width: calc(100% * (1 / 2) - 30px);
    border-radius: 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    transition: 0.3s;
    position: relative;
    cursor: default;
}

.freelancer:hover {
    box-shadow: 0 2px 18px rgba(0, 0, 0, 0.14);
    transform: translateY(-4px);
}

.freelancer .bookmark-icon {
    cursor: pointer;
}

.freelancer-overview {
    text-align: center;
    padding: 38px 20px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.freelancer-overview-inner {
    flex: 1;
}

.freelancer-overview .freelancer-avatar {
    width: 110px;
    margin: 0 auto;
    position: relative;
}

.freelancer-overview .freelancer-avatar img {
    width: 100%;
    border-radius: 50%;
}

.freelancer-overview .freelancer-avatar .verified-badge {
    position: absolute;
    bottom: 0;
    right: 0;
}

.freelancer-name {
    margin-top: 19px;
}

.freelancer-rating {
    margin-top: 5px;
    margin-bottom: -10px;
}

.freelancer-name h4,
.freelancer-name h4 a {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: #333;
}

.freelancer-name img.flag {
    height: 15px;
    border-radius: 3px;
    position: relative;
    top: -1px;
    display: inline-block;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    margin-left: 4px;
    cursor: default;
}

.freelancer-name span {
    color: #888;
}

.freelancer-details {
    padding: 35px;
    background-color: $lightGrey;
    flex-grow: 0;
}

.freelancers-grid-layout .freelancer-details {
    border-radius: 0 0 4px 4px;
}

.freelancers-grid-layout .freelancer-details a.button {
    display: block;
    text-align: center;
    width: 100% !important;
    transition: 0.3s;
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.1);
}

.freelancer-details a.button:hover {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.2);
}

.freelancer-details-list ul {
    list-style: none;
    padding: 0;
    margin: 0 0 5px;
    font-size: 14.7px;
}

.freelancer-details-list ul li {
    display: inline-block;
    margin-right: 25px;
    line-height: 23px;
    color: gray;
    margin-bottom: 15px;
}

.freelancer-details-list ul li:last-child {
    margin-right: 0;
}

.freelancer-details-list ul li strong {
    display: block;
    color: #333;
}

.freelancer-details-list ul li strong i {
    position: relative;
    top: 1px;
    margin-right: -1px;
    margin-left: -2px;
}

.freelancer-detail-item {
    display: inline-block;
    margin: 2px 10px 5px 0;
}

.freelancer-detail-item a,
.freelancer-detail-item {
    color: #888;
}

.freelancer-detail-item a:hover {
    color: $oceanBlue;
}

.freelancer-detail-item i {
    position: relative;
    top: 2px;
    margin-right: 3px;
}

@media (max-width: 1366px) {
    .freelances-grid-layout .freelancer-overview .freelancer-avatar {
        width: 100px;
    }
}

@media (max-width: 768px) {
    .freelances-grid-layout.freelancers-container {
        width: 100%;
    }
    .freelances-grid-layout .freelancer {
        margin: 0 0 30px;
        width: 100%;
    }
    .freelancers-container.freelancers-grid-layout {
        width: 100%;
    }
    .freelancers-container.freelancers-grid-layout .freelancer {
        width: 100%;
        margin-right: 0;
    }
}

@media (max-width: 2560px) {
    .full-page-container .freelancer {
        width: calc(100% * (1 / 4) - 30px);
    }
}

@media (max-width: 1920px) {
    .full-page-container .freelancer {
        width: calc(100% * (1 / 3) - 30px);
    }
}

@media (max-width: 1366px) {
    .full-page-container .freelancer {
        width: calc(100% * (1 / 2) - 30px);
    }
}

@media (max-width: 1099px) {
    .full-page-container .freelancers-container {
        width: 100%;
    }
    .full-page-container .freelancer {
        width: 100%;
        margin-right: 0;
    }
}

.freelancers-container.freelancers-list-layout {
    width: 100%;
}

.freelancers-list-layout .freelancer {
    margin: 0 0 30px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.freelancers-list-layout .freelancer-overview {
    text-align: left;
    padding: 45px 40px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.freelancers-list-layout .freelancer-overview-inner {
    flex: 1;
    display: flex;
    align-items: center;
}

.freelancers-list-layout .freelancer-details {
    padding: 45px 40px 45px 0;
    background-color: transparent;
    flex: 0 0 360px;
}

.freelancers-list-layout .freelancer-overview .freelancer-avatar {
    width: 100px;
    margin: 0;
}

.freelancers-list-layout .freelancer-overview .freelancer-name {
    text-align: left;
    margin: 0 0 0 30px;
}

.freelancers-list-layout .freelancer-details a.button {
    display: block;
    text-align: center;
    min-width: 50%;
    max-width: 100%;
    width: auto !important;
    transition: 0.3s;
    float: right;
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.15);
}

.freelancers-list-layout .freelancer-details a.button:hover {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.25);
}

.freelancers-list-layout .bookmark-icon {
    display: none;
}

.freelancers-list-layout .freelancer-details-list ul {
    float: right;
    display: block;
}

.freelancers-list-layout .freelancer-details-list ul li {
    margin-left: 25px;
    margin-right: 0;
    float: right;
}

.compact-list.freelancers-list-layout .freelancer {
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: none;
}

.compact-list.freelancers-list-layout .freelancer:hover {
    transform: translateY(0);
}

.compact-list.freelancers-list-layout {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
}

.compact-list.freelancers-list-layout .freelancer:nth-child(2n) {
    background-color: $lightGrey;
}

.compact-list.freelancers-list-layout .freelancer:hover:before {
    opacity: 1;
}

.compact-list.freelancers-list-layout .freelancer:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    display: block;
    background: $oceanBlue;
    transition: 0.3s;
    opacity: 0;
}

.compact-list.freelancers-list-layout .freelancer:last-of-type {
    border-radius: 0 0 3px 3px;
    overflow: hidden;
}

.compact-list.freelancers-list-layout .freelancer:first-of-type {
    border-radius: 3px 3px 0 0;
    overflow: hidden;
}

@media (max-width: 1366px) {
    .freelancers-list-layout .freelancer-overview .freelancer-avatar {
        width: 100px;
    }
}

@media (max-width: 1240px) {
    .freelancers-list-layout .freelancer {
        flex-direction: column;
    }
    .freelancers-list-layout .freelancer-details {
        flex: 1;
        padding: 35px 40px;
        background-color: $lightGrey;
    }
    .freelancers-list-layout .freelancer-details-list ul {
        float: left;
    }
    .freelancers-list-layout .freelancer-details-list ul li {
        margin-left: 0;
        margin-right: 25px;
        float: left;
    }
    .freelancers-list-layout .freelancer-details a.button {
        float: none;
        width: 100% !important;
    }
    .freelancers-list-layout .freelancer-overview .freelancer-avatar {
        width: 90px;
    }
    .compact-list.freelancers-list-layout .freelancer-details {
        background-color: transparent;
        padding-top: 0;
    }
}

@media (max-width: 768px) {
    .freelancers-list-layout .freelancer-overview,
    .freelancers-list-layout .freelancer-details {
        padding: 30px;
    }
    .freelancers-list-layout .freelancer-overview .freelancer-avatar {
        width: 80px;
    }
    .freelancers-list-layout .star-rating:before {
        display: block;
        float: none;
        text-align: center;
        max-width: 40px;
        margin-bottom: 4px;
    }
}

.letters-list {
    width: 100%;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 4px;
    padding: 20px;
}

.letters-list a {
    display: inline-block;
    font-size: 18px;
    color: #333;
    height: 40px;
    width: 40px;
    line-height: 40px;
    background-color: transparent;
    border-radius: 4px;
    transition: 0.3s;
    margin: 0 -2px;
}

.letters-list a.current {
    margin-right: 0;
    color: #fff;
    font-weight: $font-weight-bold;
    background-color: $oceanBlue;
    box-shadow: 0 2px 8px rgba(25, 140, 202, 0.25);
}

.letters-list a:hover {
    color: #fff;
    background-color: #333;
    font-weight: $font-weight-bold;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.companies-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    width: calc(100% + 30px);
}

.companies-list .company {
    width: calc(33.3% - 30px);
    margin: 0 30px 30px 0;
    flex-grow: 1;
    text-align: center;
    padding: 50px 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.companies-list .company:hover {
    transform: translateY(-3px);
}

@media (min-width: 993px) {
    .companies-list .company:nth-child(3n) {
        border-right: none;
    }
    .companies-list .company:nth-last-child(-n + 3) {
        border-bottom: none;
    }
}

.companies-list .company img {
    max-width: 120px;
    max-height: 60px;
    display: inline-block;
    margin: 0;
    transform: translate3d(0, 0, 0);
}

.companies-list .company .company-logo {
    height: 60px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.companies-list .company h4 {
    color: #333;
    font-size: 18px;
    font-weight: $font-weight-bold;
    transition: 0.3s;
    display: block;
}

.companies-list .company span {
    color: #888;
}

.companies-list .company .star-rating {
    margin-top: 5px;
}

.not-rated,
.company-not-rated {
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #888;
    font-size: 14px;
    line-height: 15px;
    font-weight: $font-weight-bold;
    padding: 7px 9px;
    display: inline-block;
    margin-top: 8px;
    text-align: center;
}

@media (max-width: 1366px) {
    .letters-list a {
        font-size: 16px;
        height: 36px;
        width: 36px;
        line-height: 36px;
        margin: 0 -1px;
    }
}

@media (max-width: 992px) {
    .companies-list .company {
        width: calc(50% - 30px);
    }
}

@media (max-width: 768px) {
    .companies-list .company {
        width: 100%;
    }
}

button {
    vertical-align: middle;
}

span.button,
button.button,
input[type="button"],
input[type="submit"],
a.button {
    background-color: $green;
    padding: 16px 20px;
    line-height: 24px;
    color: #fff;
    position: relative;
    font-size: 14px;
    font-weight: $font-weight-bold;
    font-family: $font-family-head;
    text-transform: uppercase;
    letter-spacing: 0.35px;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    border: none;
    border-radius: 3px;
    min-width: 195px;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    &:hover {
        background-color: $oceanBlue;
    }

    .btn-blue {
        background-color: $oceanBlue;

        &:hover {
            background-color: $green;
        }
    }

    .btn-transparent {
        background-color: transparent;
        border: 1px solid $darkBlue;
        color: $darkBlue;
        &:hover {
            color: white;
            background-color: $darkBlue;
        }
    }
}

.button i[class^="icon-material"],
button.button i[class^="icon-material"],
input[type="button"] i[class^="icon-material"],
input[type="submit"] i[class^="icon-material"],
a.button i[class^="icon-material"] {
    font-size: 18px;
    height: 0;
    width: 18px;
    line-height: 0;
    top: 2px;
    position: relative;
    display: inline-block;
    margin-left: 3px;
}

.button i[class^="icon-feather"],
button.button i[class^="icon-feather"],
input[type="button"] i[class^="icon-feather"],
input[type="submit"] i[class^="icon-feather"],
a.button i[class^="icon-feather"] {
    font-size: 16px;
    height: 0;
    width: 16px;
    line-height: 0;
    top: 2px;
    position: relative;
    display: inline-block;
    margin-left: 3px;
}

button.button.dark,
input[type="button"].dark,
input[type="submit"].dark,
a.button.dark {
    background-color: #333;
    color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

button.button.gray,
input[type="button"].gray,
input[type="submit"].gray,
a.button.gray {
    background-color: #eaeaea;
    color: #666;
    box-shadow: none;
}

button.button.gray:hover,
input[type="button"].gray:hover,
input[type="submit"].gray:hover,
a.button.gray:hover {
    background-color: $regularGrey;
}

body .button i.icon-material-outline-arrow-right-alt {
    font-size: 22px;
    top: 4px;
}

.button.full-width {
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: block;
}

.button.big {
    font-size: 18px;
    padding: 13px 22px;
}

body .button.big i {
    font-size: 22px;
    top: 3px;
    position: relative;
}

.button.button-slide-out span {
    transform: translateY(0px);
    opacity: 1;
    transition: 0.3s;
}

.button.button-slide-out:hover span {
    transform: translateY(-10px);
    opacity: 0;
    display: inline-block;
    transition: 0.3s;
}

.button.button-slide-out i {
    transform: translateY(calc(-50% + 10px));
    opacity: 0;
    display: inline-block;
    transition: 0.3s;
    position: absolute;
    left: 0;
    right: 0;
    font-size: 18px;
    top: 50%;
    height: 20px;
}

.button.button-slide-out:hover i {
    transform: translateY(calc(-50% + 0px));
    opacity: 1;
}

.move-on-hover {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.1);
    transform: translateY(0);
    transition: 0.3s;
}

.move-on-hover:hover {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.2);
    transform: translateY(-2px);
}

.centered-button {
    text-align: center;
    display: block;
}

.centered-button .button {
    margin: 0 auto;
}

span.bookmark-icon {
    font-size: 20px;
    position: absolute;
    z-index: 101;
    right: 35px;
    top: 35px;
    cursor: pointer;
    background-color: #eee;
    color: silver;
    display: block;
    height: 39px;
    width: 39px;
    border-radius: 50%;
    transition: all 0.4s;
    line-height: 27px;
}

.bookmark-icon.bookmarked {
    animation: bookmarked-shadow 0.3s;
}

@keyframes bookmarked-shadow {
    0% {
        box-shadow: 0 0 0 0 rgba(254, 179, 66, 0.8);
    }
    100% {
        box-shadow: 0 0 0 10px transparent;
    }
}

.bookmark-icon:before {
    transition: transform 0.4s cubic-bezier(0.8, -0.41, 0.19, 2.5);
    font-family: material-icons;
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    top: 6px;
    content: "\e988";
}

.bookmark-icon:hover {
    background-color: #333;
    color: #fff;
}

.bookmark-icon.bookmarked {
    background-color: #feb342;
    color: #fff;
}

.bookmark-icon.bookmarked:before {
    animation: bookmark-icon 0.5s;
}

@keyframes bookmark-icon {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.bookmark-button {
    background-color: #404040;
    display: inline-block;
    padding: 0;
    position: relative;
    height: 44px;
    line-height: 44px;
    color: #fff;
    padding: 0 15px;
    padding-left: 59px;
    border-radius: 4px;
    transition: 0.4s;
    cursor: pointer;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
}

.bookmark-button .bookmark-icon,
.bookmark-button:hover .bookmark-icon {
    background-color: rgba(255, 255, 255, 0.05);
}

.bookmark-button .bookmark-icon {
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: auto;
    top: auto;
    height: 44px;
    width: 44px;
    border-radius: 4px 0 0 4px;
    pointer-events: none;
    transition: 0.4s;
    animation: none;
}

.bookmark-button .bookmark-icon:before {
    top: 8px;
    color: #fff;
    transition: 0.4s;
    font-size: 18px;
}

.bookmark-button.bookmarked {
    background-color: #feb342;
    color: #fff;
    box-shadow: 0 3px 12px rgba(254, 179, 66, 0.2);
}

.bookmark-button.bookmarked .bookmark-icon:before,
.bookmark-button:hover .bookmark-icon:before {
    color: #fff;
}

.bookmark-button.bookmarked .bookmark-icon,
.bookmark-button.bookmarked:hover .bookmark-icon {
    background-color: rgba(255, 255, 255, 0.16);
}

.bookmark-button.bookmarked .bookmarked-text,
.bookmark-button .bookmark-text {
    display: block;
}

.bookmark-button.bookmarked .bookmark-text,
.bookmark-button .bookmarked-text {
    display: none;
}

.button-sliding-icon {
    text-align: center;
}

.button-sliding-icon i {
    opacity: 0;
    max-width: 0;
    transition: 0.25s;
    display: inline-block;
    transform: translateX(-20px);
}

.button-sliding-icon:hover i {
    max-width: 20px;
    opacity: 1;
    transform: translateX(0);
}

.button.button-sliding-icon {
    padding-left: 20px;
    padding-right: 10px;
}

.button.button-sliding-icon:hover {
    padding-right: 20px;
}

.button.button-sliding-icon i {
    padding-right: 5px;
}

.button.button-sliding-icon:hover i {
    max-width: 20px;
    opacity: 1;
    transform: translateX(0);
}

.list-3,
.list-2,
.list-1 {
    padding: 3px 0 0;
    font-size: 16px;
}

.list-3 li,
.list-2 li,
.list-1 li {
    list-style: none;
    margin: 10px 0;
    line-height: 27px;
    margin-left: 20px;
    position: relative;
}

.list-3 li:first-child,
.list-2 li:first-child,
.list-1 li:first-child {
    margin-top: 0;
}

.list-4 li:before,
.list-3 li:before,
.list-2 li:before,
.list-1 li:before {
    margin: 0;
    position: relative;
    color: $oceanBlue;
    float: left;
    margin-left: -20px;
    display: block;
}

.list-1.gray li:before {
    color: #777;
}

.list-2 li,
.list-2 li {
    margin-left: 28px;
}

.list-3 li:before,
.list-2 li:before {
    font-family: material-icons;
    content: "\e928";
    font-size: 18px;
    font-weight: $font-weight-bold;
    position: relative;
    top: -2px;
    margin-left: -28px;
}

.list-3 li:before {
    content: "\e912";
    font-family: feather-icons;
    font-weight: $font-weight-normal;
    font-size: 18px;
}

.list-1 li:before {
    content: "";
    height: 6px;
    width: 6px;
    background-color: $oceanBlue;
    border-radius: 2px;
    position: relative;
    top: 9px;
}

.numbered ol {
    counter-reset: li;
    list-style: none;
    padding: 0;
    margin-left: 18px;
    display: inline-block;
    font-size: 16px;
}

.numbered ol li {
    display: inline-block;
    padding: 6px 0;
    width: 100%;
}

.numbered ol > li::before {
    content: counter(li);
    counter-increment: li;
    font-size: 16px;
    line-height: 35px;
    width: 36px;
    height: 36px;
    display: inline-block;
    border: 1px solid #333;
    border-radius: 50%;
    text-align: center;
    margin: 0;
    margin-left: 0;
    font-weight: $font-weight-normal;
    color: #333;
    position: relative;
    float: left;
    left: -18px;
    font-size: 15px;
    transform: translateY(-10%);
}

.numbered.color ol > li::before {
    border: 1px solid $oceanBlue;
    color: $oceanBlue;
}

.numbered.color.filled ol > li::before {
    border: 1px solid $oceanBlue;
    color: #fff;
    background-color: $oceanBlue;
}

mark {
    background-color: #fcf8e3;
    color: #8a803e;
    padding: 1px 5px;
}

mark.color {
    background-color: $oceanBlue;
    border-radius: 4px;
    color: #fff;
}

#result .success,
.notification {
    line-height: 24px;
    margin-bottom: 15px;
    position: relative;
    padding: 20px 26px;
    padding-right: 50px;
    border-radius: 3px;
}

.notification p {
    margin: 0;
    font-size: 15px;
}

#result .success,
.notification.success {
    background-color: #ebf6e0;
}

#result .success,
.notification.success,
.notification.success a,
.notification.success strong {
    color: #5f9025;
}

.notification.error {
    background-color: #ffe9e9;
}

.notification.error,
.notification.error a,
.notification.error strong {
    color: #de5959;
}

.notification.warning {
    background-color: #fbfadd;
}

.notification.warning,
.notification.warning a,
.notification.warning strong {
    color: #8f872e;
}

.notification.notice h4 {
    font-size: 19px;
    margin: 3px 0 15px;
}

.notification.notice h4,
.notification.notice,
.notification.notice a,
.notification.notice strong {
    color: #3184ae;
}

.notification.notice {
    background-color: #e9f7fe;
}

.notification.notice.large {
    padding: 32px 36px;
}

.notification strong {
    font-weight: $font-weight-bold;
}

.notification a {
    text-decoration: underline;
}

body .notification strong {
    border: none;
}

.notification.success .close,
.notification.error .close,
.notification.warning .close,
.notification.notice .close {
    padding: 0 9px;
    position: absolute;
    right: 0;
    top: 22px;
    display: block;
    height: 8px;
    width: 8px;
    cursor: pointer;
}

.notification.notice p span i {
    font-weight: $font-weight-normal;
}

.notification a.button {
    float: right;
    color: #fff;
    margin-top: 3px;
}

.notification.notice a.button {
    background-color: #388fc5;
}

.notification.warning a.button {
    background-color: #dfbe51;
}

.notification.error a.button {
    background-color: #d34c4c;
}

.notification.success a.button {
    background-color: #79ba38;
}

.notification.closeable a.close:before {
    content: "\ea02";
    font-family: feather-icons;
    position: absolute;
    right: 25px;
    top: 0;
    cursor: pointer;
    font-weight: $font-weight-bold;
}

.copy-url {
    display: flex;
}

.copy-url input {
    border-radius: 4px 0 0 4px;
    border-right: 0;
}

.copy-url .copy-url-button {
    min-width: 48px;
    background-color: $oceanBlue;
    color: #fff;
    border-radius: 0 4px 4px 0;
}

.share-buttons {
    display: block;
}

.share-buttons-trigger {
    display: inline-block;
    height: 44px;
    width: 44px;
    line-height: 44px;
    text-align: center;
    color: #a0a0a0;
    font-size: 18px;
    background-color: #f0f0f0;
    border-radius: 4px;
    transition: 0.4s;
    cursor: default;
}

.share-buttons-trigger i {
    position: relative;
    top: 1px;
}

.share-buttons-content {
    display: inline-block;
    position: relative;
    width: calc(100% - 60px);
}

.share-buttons-content span {
    margin-left: 10px;
    color: #888;
    cursor: default;
    padding: 10px 0;
    display: inline-block;
    transition: 0.4s;
}

.share-buttons-content span strong {
    color: $oceanBlue;
    font-weight: $font-weight-bold;
}

.share-buttons:hover .share-buttons-content span {
    opacity: 0;
    transition: 0.2s;
}

.share-buttons-icons {
    position: absolute;
    left: 5px;
    top: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.4s cubic-bezier(0.5, -0.41, 0.19, 2);
}

.share-buttons:hover .share-buttons-icons {
    opacity: 1;
    transform: translate(0);
}

.share-buttons-icons li {
    display: inline-block;
    float: left;
}

.share-buttons-icons li:first-child a {
    border-radius: 4px 0 0 4px;
}

.share-buttons-icons li:last-child a {
    border-radius: 0 4px 4px 0;
}

.share-buttons-icons li a {
    height: 44px;
    width: 44px;
    display: inline-block;
    line-height: 44px;
    text-align: center;
    background-color: #333;
    color: #fff;
    font-size: 14px;
}

.share-buttons-icons li a i {
    position: relative;
    top: 1px;
}

.job-listing-company .verified-badge,
.job-listing .job-listing-footer ul li .verified-badge {
    position: relative;
    display: inline-block;
    transform: scale(0.7);
    top: 2px;
    margin-left: -2px;
}

.verified-badge {
    position: relative;
    height: 25px;
    width: 25px;
    display: inline-block;
    background-color: #38b653;
    border-radius: 50%;
    text-align: center;
    z-index: 10;
    font-weight: $font-weight-normal;
}

.verified-badge:before {
    content: "\e92b";
    font-family: feather-icons;
    font-size: 16px;
    color: #fff;
    position: relative;
    top: 0;
    line-height: 27px;
}

.job-listing .job-listing-company-logo .verified-badge {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(7px, 7px);
}

.verified-badge-with-title {
    position: relative;
    height: 26px;
    display: flex;
    top: -1px;
    color: #fff;
    font-weight: $font-weight-normal;
    font-size: 14px;
    background-color: #30ab4a;
    text-align: center;
    z-index: 10;
    font-weight: $font-weight-normal;
    border-radius: 4px;
    padding: 0 8px 0 0;
    margin: 0;
    overflow: hidden;
    padding-left: 34px;
    line-height: 27px;
}

.verified-badge-with-title:before {
    content: "\e92b";
    font-family: feather-icons;
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 26px;
    height: 26px;
    width: 26px;
    display: inline-block;
    background-color: #38b653;
}

.star-rating {
    display: inline-block;
}

.star-rating .star {
    display: inline-block;
    margin: 0;
    padding: 0;
    float: left;
    margin-right: 0;
    position: relative;
}

.star-rating .star.half:after,
.star-rating .star:before {
    font-family: material-icons;
    content: "\e988";
    display: block;
    color: #febe42;
    font-size: 22px;
}

.star-rating .star.empty:before {
    color: #ddd;
}

.rating:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.star-rating .star.half:before {
    color: #ddd;
}

.star-rating .star.half:after {
    color: #febe42;
    position: absolute;
    top: 0;
    width: 50%;
    display: block;
    height: 100%;
    overflow: hidden;
}

.star-rating:before {
    content: attr(data-rating);
    float: left;
    border-radius: 4px;
    background-color: #febe42;
    color: #fff;
    font-size: 14px;
    line-height: 15px;
    font-weight: $font-weight-bold;
    padding: 5px 7px;
    position: relative;
    top: 1px;
    margin-right: 10px;
}

.star-rating.no-stars .star {
    display: none;
}

.star-rating.no-stars:before {
    margin-right: 0;
}

.leave-rating {
    height: 24px;
    float: left;
    position: relative;
}

.leave-rating:hover input[type="radio"]:checked ~ label {
    color: #dadada;
}

.leave-rating input[type="radio"] {
    display: inline-block;
    width: 1px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    margin-left: 50%;
    left: 0;
    top: 0;
    transform: translateX(-50%);
}

.leave-rating input[type="radio"]:checked ~ label {
    color: #ffc600;
}

.leave-rating label {
    font-size: 24px;
    float: right;
    letter-spacing: 0;
    color: #dadada;
    cursor: pointer;
    transition: 0.3s;
}

.leave-rating label:hover,
.leave-rating label:hover ~ label {
    color: #ffc600 !important;
}

.leave-rating-title {
    display: block;
    margin: 0 0 4px;
}

.welcome-text .leave-rating-container {
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    left: 50%;
    width: 100%;
    display: block;
}

.welcome-text .leave-rating {
    position: relative;
    left: 50%;
    transform: translateX(-50%) scale(1.2);
}

.ripple-effect-dark,
.ripple-effect {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.ripple-effect span.ripple-overlay,
.ripple-effect-dark span.ripple-overlay {
    animation: ripple 0.9s;
    border-radius: 100%;
    background: #fff;
    height: 12px;
    position: absolute;
    width: 12px;
    line-height: 12px;
    opacity: 0.1;
    pointer-events: none;
}

.ripple-effect-dark span.ripple-overlay {
    background: #000;
    opacity: 0.07;
}

@keyframes ripple {
    0% {
        -webkit-transform: scale(4);
        transform: scale(4);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(40);
        transform: scale(40);
    }
}

.input-with-icon {
    display: block;
    position: relative;
}

.input-with-icon input {
    padding-right: 45px;
}

.input-with-icon i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    font-size: 20px;
    color: #a0a0a0;
    font-style: normal;
    cursor: normal;
    pointer-events: none;
}

.input-with-icon i.currency {
    font-size: 14px;
}

.input-with-icon-left {
    position: relative;
}

.input-with-icon-left input {
    padding-left: 65px;
}

.input-with-icon-left i {
    position: absolute;
    top: 0;
    color: #a0a0a0;
    text-align: center;
    line-height: 48px;
    width: 48px;
    height: 48px;
    font-size: 19px;
    background-color: #f8f8f8;
    border: 1px solid $regularGrey;
    box-sizing: border-box;
    display: block;
    border-radius: 4px 0 0 4px;
}

.input-with-icon-left.no-border i {
    border: none;
}

.keywords-container {
    margin-bottom: -15px;
}

.keywords-list {
    display: block;
    float: left;
    height: 0;
}

.keyword {
    background-color: rgba(25, 140, 202, 0.07);
    color: $oceanBlue;
    display: inline-block;
    float: left;
    padding: 0;
    border-radius: 4px;
    cursor: default;
    margin: 0 7px 7px 0;
    height: 35px;
    line-height: 35px;
    box-sizing: border-box;
    animation: KeywordIn 0.3s ease-in-out;
    animation-fill-mode: both;
    transition: 0.4s;
    overflow: hidden;
    max-width: 100%;
    font-size: 14.7px;
}

@keyframes KeywordIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.keyword.keyword-removed {
    max-width: 0;
    margin: 0;
    overflow: hidden;
    opacity: 0;
    animation: KeywordOut 0.4s ease-in-out;
    animation-fill-mode: both;
    pointer-events: none;
}

@keyframes KeywordOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        margin-top: -35px;
    }
}

.keyword-text {
    padding-right: 12px;
}

.keyword .keyword-remove:before {
    display: inline-block;
    cursor: pointer;
    font-family: feather-icons;
    content: "\ea02";
    line-height: 20px;
    position: relative;
    top: 2px;
    margin-right: 4px;
    margin-left: 10px;
    font-size: 15px;
}

.keyword-input-container {
    position: relative;
}

.keyword-input-container .keyword-input-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 36px;
    width: 36px;
    padding: 0;
    color: #fff;
    background-color: $oceanBlue;
    border-radius: 4px;
    margin: 6px;
    font-size: 19px;
    text-align: center;
    line-height: 36px;
}

.keyword-input-container .keyword-input-button i {
    color: #fff;
    position: relative;
    top: 2px;
}

input.keyword-input {
    margin: 0 0 15px;
}

.tags-container {
    display: block;
}

.tags-container input[type="checkbox"] {
    display: none;
}

.tags-container input[type="checkbox"] + label {
    transition: 0.3s;
    font-size: 14.7px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #eee;
    color: #777;
    display: inline-block;
    padding: 6px 15px;
    margin: 0;
    line-height: 24px;
}

.tags-container input[type="checkbox"] + label:hover {
    background-color: #e8e8e8;
}

.tags-container input[type="checkbox"]:checked + label {
    background-color: $oceanBlue;
    color: #fff;
    transition: 0.3s;
}

.tags-container input[type="checkbox"] + label:before {
    font-family: feather-icons;
    content: "\e92b";
    font-size: 18px;
    transition:
        all 0.35s cubic-bezier(0.5, -0.41, 0.19, 2),
        max-width 0.3s,
        margin 0.3s;
    max-width: 0;
    display: inline-block;
    position: relative;
    top: 3px;
    margin: 0;
    opacity: 0;
    color: #fff;
    line-height: 0;
    transform: scale(0.5);
}

.tags-container input[type="checkbox"]:checked + label:before {
    max-width: 30px;
    opacity: 1;
    transform: scale(1);
    margin: 0 5px 0 -2px;
}

.tags-container .tag {
    display: inline-block;
    float: left;
    margin: 0 7px 7px 0;
    overflow: hidden;
}

.switches-list .switch-container {
    width: 100%;
}

.switches-list .switch-container:last-child label {
    margin-bottom: 0;
}

.switch-container {
    display: inline-block;
    cursor: pointer;
}

label.switch {
    position: relative;
}

label {
    font-size: 16px;
    color: $darkBlue;
    font-family: $font-family-head;
}

.switches-list .switch-container label {
    cursor: pointer;
    position: relative;
    padding-left: 45px;
    line-height: 26px;
}

.switch-button {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 50px;
    transition: 0.4s;
    margin: 0 7px 0 0;
    top: 4px;
    position: absolute;
    top: 2px;
    left: 0;
}

.notify-box .switch-button {
    top: 0;
}

.notify-box .switch-container {
    top: 1px;
    position: relative;
}

.switch span.switch-text {
    display: block;
    padding-left: 48px;
    font-size: 16px;
    line-height: 22px;
    color: #666;
}

.switch input {
    display: none;
}

.switch.interactive-effect input:checked + .switch-button:before {
    animation: switch-shadow-color 0.4s;
}

.switch.interactive-effect .switch-button:before {
    animation: switch-shadow 0.4s;
}

@keyframes switch-shadow {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    }
    100% {
        box-shadow: 0 0 0 10px transparent;
    }
}

@keyframes switch-shadow-color {
    0% {
        box-shadow: 0 0 0 0 rgba(25, 140, 202, 0.6);
    }
    100% {
        box-shadow: 0 0 0 10px transparent;
    }
}

.switch-button:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    bottom: 2px;
    left: 2px;
    background-color: #fff;
    transition:
        0.4s,
        box-shadow 0.3s;
    border-radius: 50px;
}

input:checked + .switch-button {
    background-color: $oceanBlue;
}

input:checked + .switch-button:before {
    transform: translateX(14px);
}

.radio {
    display: inline-block;
    vertical-align: top;
}

.radio label {
    margin: 3px 0;
    cursor: pointer;
    position: relative;
    padding-left: 29px;
    line-height: 25px;
}

.radio input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.radio input[type="radio"] + label .radio-label {
    content: "";
    background: #fff;
    border-radius: 100%;
    border: 2px solid #b4b4b4;
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 5px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    background-color: #fff;
    box-shadow: inset 0 0 0 8px #fff;
    z-index: 100;
    position: absolute;
    top: 2px;
    left: 0;
}

.radio input[type="radio"] + label .radio-label:after {
    background-color: $oceanBlue;
    content: "";
    top: 3px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    opacity: 1;
    transition: 0.3s;
    border-radius: 50%;
    transform: scale(0);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.radio input[type="radio"]:checked + label .radio-label {
    background-color: $oceanBlue;
    border-color: $oceanBlue;
    box-shadow: inset 0 0 0 3px #fff;
}

.radio-label:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: transparent;
    display: block;
}

.interactive-effect label .radio-label:before {
    animation: radius-shadow 0.4s;
}

@keyframes radius-shadow {
    0% {
        box-shadow: 0 0 0 0 rgba(25, 140, 202, 0.4);
    }
    100% {
        box-shadow: 0 0 0 10px transparent;
    }
}

.small-label {
    border-radius: 4px;
    display: inline-block;
    background: #e0f5d7;
    color: #449626;
    padding: 0 8px;
    line-height: 27px;
    height: 26px;
    margin-left: 5px;
    position: relative;
    top: -1px;
}

.checkbox {
    display: inline-block;
    margin-bottom: 0;
}

.checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    line-height: 25px;
}

.checkbox label span.checkbox-icon {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #b4b4b4;
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 4px;
    transition: 0.3s;
    position: absolute;
    left: 0;
    top: 1px;
}

.checkbox input + label span.checkbox-icon:after {
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    content: "";
    display: block;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: scale(0.5) rotate(45deg);
    transition:
        all 0.35s cubic-bezier(0.3, -0.41, 0.19, 2),
        opacity 0.3s;
}

.checkbox input:checked + label span.checkbox-icon {
    border-color: $oceanBlue;
    background-color: $oceanBlue;
}

.checkbox input:checked + label span.checkbox-icon:after {
    transform: scale(1) rotate(45deg);
    opacity: 1;
}

.uploadButton {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;
    font-style: normal;
    font-size: 14px;
}

.uploadButton .uploadButton-input {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    z-index: -1;
    pointer-events: none;
}

.uploadButton .uploadButton-button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 44px;
    padding: 10px 18px;
    cursor: pointer;
    border-radius: 4px;
    color: $oceanBlue;
    background-color: transparent;
    border: 1px solid $oceanBlue;
    flex-direction: row;
    transition: 0.3s;
    margin: 0;
    outline: none;
    box-shadow: 0 3px 10px rgba(25, 140, 202, 0.1);
}

.uploadButton .uploadButton-button:hover {
    background-color: $oceanBlue;
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.15);
    color: #fff;
}

.uploadButton .uploadButton-file-name {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    padding: 0 10px;
    padding-left: 18px;
    min-height: 42px;
    top: 1px;
    position: relative;
    color: #888;
    background-color: transparent;
    overflow: hidden;
    line-height: 22px;
}

.intro-banner {
    padding: 105px 0;
    position: relative;
}

.intro-banner.big-padding {
    padding: 130px 0;
}

.intro-banner .container {
    z-index: 100;
    position: relative;
}

.intro-banner:after,
.intro-banner:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: 15;
    background: linear-gradient(
        to right,
        rgba(250, 250, 250, 1) 20%,
        rgba(250, 250, 250, 0.95) 50%,
        rgba(250, 250, 250, 0.1) 80%,
        rgba(250, 250, 250, 0) 100%
    );
}

.intro-banner:after {
    z-index: 5;
    background-color: $lightGrey;
}

.intro-banner .background-image-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 60%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 90%;
    left: 10%;
    z-index: 10;
    overflow: hidden;
}

.intro-banner.disable-gradient:before {
    background: #f2f2f2;
    opacity: 0.85;
}

.intro-banner.disable-gradient .background-image-container {
    left: 0;
    width: 100%;
}

.banner-headline {
    display: block;
    max-width: 55%;
}

.banner-headline h1,
.banner-headline h2,
.banner-headline h3,
.banner-headline h4 {
    font-size: 28px;
    font-weight: $font-weight-normal;
    color: #777;
    line-height: 42px;
}

.banner-headline strong {
    font-weight: $font-weight-bold;
    color: #333;
}

.banner-headline strong.color {
    color: $oceanBlue;
}

.banner-headline-alt {
    margin: -10px 0 0;
    display: block;
}

.banner-headline-alt h3 {
    font-size: 40px;
    font-weight: $font-weight-bold;
    color: #333;
    line-height: 50px;
}

.banner-headline-alt span {
    font-size: 24px;
    color: #888;
    font-weight: $font-weight-normal;
    line-height: 34px;
    margin-top: 5px;
    display: inline-block;
}

.dark-overlay .banner-headline-alt h3 {
    color: #fff;
}

.dark-overlay .banner-headline-alt span {
    color: rgba(255, 255, 255, 0.7);
}

.intro-banner-search-form {
    display: flex;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.09);
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    background-size: cover;
    background-position: 50%;
}

.video-container video {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -10;
    background-size: cover;
    transition: 1s opacity;
    position: absolute;
    z-index: 9;
}

.intro-search-field {
    padding: 10px 12px;
    border-right: 1px solid #e4e4e4;
    flex: 1;
    align-items: center;
    display: flex;
    position: relative;
}

.intro-search-field .field-title {
    content: attr(data-field-tiele);
    position: absolute;
    top: 0;
    left: 0;
    background: $oceanBlue;
    color: #fff;
    border-radius: 4px;
    padding: 5px 10px;
    line-height: 22px;
    transform: translateY(calc(-100% - 15px));
    cursor: default;
}

.intro-banner-search-form .intro-search-field:nth-last-child(2) {
    border-right: none;
}

.intro-search-button {
    margin: 8px;
    margin-left: 0;
    flex: auto;
    flex-grow: 0;
    display: flex;
    align-items: center;
}

.intro-search-button .button {
    height: 52px;
    line-height: 52px;
    padding: 0 30px;
    font-size: 18px;
}

.intro-banner-search-form input:focus {
    box-shadow: none;
}

.intro-banner-search-form input {
    box-shadow: none;
    font-size: 18px;
    margin: 0;
}

.intro-banner-search-form input::placeholder {
    color: #888;
}

.intro-banner-search-form .input-with-icon {
    align-self: center;
    flex: 1;
}

.intro-banner-search-form input {
    padding: 10px 15px;
}

.intro-banner-search-form .input-with-icon input {
    padding: 10px 15px;
    padding-left: 43px;
}

.intro-banner-search-form .input-with-icon i {
    position: absolute;
    right: auto;
    left: 10px;
    top: calc(50% - 1px);
    font-size: 24px;
    color: #b0b0b0;
}

.intro-search-field.with-autocomplete .pac-container {
    border: none;
    padding-top: 0;
    z-index: 9;
    left: 0 !important;
    top: 100% !important;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    width: 100% !important;
    margin-top: 0;
    overflow: visible;
}

.intro-search-field.with-autocomplete .pac-container:before {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 5px;
    display: block;
    top: -5px;
    left: 0;
}

.intro-search-field .bootstrap-select.btn-group button {
    padding: 0 15px;
    box-shadow: none;
    font-size: 18px;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .intro-search-field .field-title {
        transform: translateY(-100%);
        margin-top: -15px;
    }
    .intro-banner-search-form input {
        line-height: 20px;
    }
    .intro-search-field .bootstrap-select.open .dropdown-menu {
        top: 55px;
    }
}

.intro-stats {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.intro-stats li {
    flex: auto;
    flex-grow: 0;
    margin-right: 35px;
    padding-right: 35px;
    border-right: 1px solid $regularGrey;
}

.intro-stats li:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

.intro-stats li span,
.intro-stats li strong {
    display: block;
    margin: 0;
    padding: 0;
}

.intro-stats li strong {
    font-weight: $font-weight-bold;
    color: #333;
    font-size: 28px;
    margin-bottom: 2px;
}

.intro-stats li span {
    font-weight: $font-weight-normal;
    color: #888;
    font-size: 18px;
}

.intro-banner.dark-overlay:before {
    background: #333;
    opacity: 0.75;
}

.intro-banner.dark-overlay .background-image-container {
    left: 0;
    width: 100%;
    background-position: 100%;
}

.intro-banner.dark-overlay .intro-stats li span,
.intro-banner.dark-overlay .banner-headline strong,
.intro-banner.dark-overlay .banner-headline h1,
.intro-banner.dark-overlay .banner-headline h2,
.intro-banner.dark-overlay .banner-headline h3,
.intro-banner.dark-overlay .banner-headline h4 {
    color: rgba(255, 255, 255, 0.7);
}

.intro-banner.dark-overlay .intro-stats li strong,
.intro-banner.dark-overlay .banner-headline strong {
    color: #fff;
}

.intro-banner.dark-overlay .intro-banner-search-form {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
}

.intro-banner.dark-overlay .intro-stats li {
    border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.intro-banner.dark-overlay .intro-stats li:last-child {
    border-right: none;
}

@media (max-width: 1366px) {
    .banner-headline h1,
    .banner-headline h2,
    .banner-headline h3,
    .banner-headline h4 {
        font-size: 26px;
        line-height: 42px;
    }
}

@media (max-width: 1240px) {
    .banner-headline {
        max-width: 65%;
    }
}

@media (max-width: 992px) {
    .intro-search-field .bootstrap-select.btn-group .dropdown-toggle .filter-option {
        height: auto;
        line-height: 20px;
    }
    .intro-banner {
        padding: 70px 0;
    }
    .banner-headline {
        max-width: 100%;
    }
    .banner-headline h1,
    .banner-headline h2,
    .banner-headline h3,
    .banner-headline h4 {
        font-size: 24px;
        line-height: 38px;
    }
    .intro-banner.dark-overlay .intro-banner-search-form {
        box-shadow: none;
    }
    .intro-banner.big-padding {
        padding: 70px 0;
    }
    .banner-headline-alt {
        margin-bottom: 0;
    }
    .banner-headline-alt h3 {
        font-size: 36px;
        line-height: 42px;
    }
    .banner-headline-alt span {
        font-size: 22px;
        line-height: 32px;
    }
    .intro-banner:after,
    .intro-banner:before {
        background: rgba(250, 250, 250, 0.9);
    }
    .intro-banner .background-image-container {
        background-size: cover;
        height: 100%;
        width: 100%;
        left: 0;
    }
    .intro-banner-search-form .intro-search-field .bootstrap-select.btn-group button,
    .intro-banner-search-form input {
        height: 56px;
        padding: 15px 22px;
    }
    .intro-banner-search-form .input-with-icon i {
        left: 17px;
    }
    .intro-banner-search-form .input-with-icon input {
        padding-left: 50px;
    }
    .intro-banner-search-form.margin-top-100,
    .intro-banner-search-form.margin-top-95,
    .intro-banner-search-form.margin-top-90,
    .intro-banner-search-form.margin-top-85 {
        margin-top: 20px !important;
    }
    .intro-banner-search-form {
        flex-direction: column;
        background: 0 0;
        box-shadow: none;
        margin-top: 20px;
    }
    .intro-banner-search-form .intro-search-field {
        padding: 0;
        border-radius: 4px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);
        margin-top: 20px;
    }
    .intro-banner-search-form .intro-search-field.with-label {
        margin-top: 80px;
    }
    .intro-banner-search-form .intro-search-button {
        margin-top: 30px;
    }
    .intro-stats {
        flex-direction: column;
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        justify-content: center;
    }
    .intro-stats li {
        display: flex;
        flex-direction: column;
        margin: 0 0 10px;
        padding: 0 0 10px;
        border: none;
        border-bottom: 1px solid #eee;
        width: 100%;
        align-self: center;
        text-align: center;
    }
    .intro-stats li:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
    }
}

.categories-container {
    display: flex;
    flex-wrap: wrap;
}

.category-box {
    flex: 0 0 25%;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: center;
    padding: 25px;
    border-radius: 4px;
    transition: 0.35s;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .category-box {
        max-width: 25%;
        text-align: center;
    }
    .category-box-counter {
        width: 50px !important;
    }
}

.category-box-icon {
    font-size: 42px;
    line-height: 32px;
    color: $oceanBlue;
    position: relative;
}

.category-box-icon i {
    height: 42px;
    display: block;
    transition: 0.35s;
    margin-bottom: 12px;
}

.category-box-counter {
    width: auto;
    height: 24px;
    font-size: 14px;
    line-height: 25px;
    border-radius: 4px;
    padding: 0 8px;
    color: #909090;
    background: rgba(0, 0, 0, 0.06);
    font-weight: $font-weight-bold;
    display: inline-block;
    margin: 0 auto;
    transition: 0.35s;
    margin-bottom: 18px;
}

.category-box-content h3 {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #333;
    margin: 0;
    padding: 0;
    transition: 0.35s;
}

.category-box-content p {
    color: #888;
    margin: 0;
    padding: 0;
    line-height: 24px;
    margin-top: 5px;
    transition: 0.35s;
}

.category-box:hover {
    background: $oceanBlue;
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.2);
    color: #fff;
}

.category-box:hover .category-box-content h3,
.category-box:hover .category-box-icon {
    color: #fff;
}

.category-box:hover .category-box-content p {
    color: rgba(255, 255, 255, 0.7);
}

.category-box:hover .category-box-counter {
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
}

@media (max-width: 1240px) {
    .category-box {
        flex: 0 0 33.3%;
    }
}

@media (max-width: 768px) {
    .category-box {
        flex: 0 0 50%;
    }
}

@media (max-width: 480px) {
    .category-box {
        flex: 0 0 100%;
        margin-right: 0;
    }
}

.photo-box {
    border-radius: 4px;
    height: 360px;
    display: block;
    position: relative;
    background-size: cover;
    background-position: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
    margin: 0 0 25px;
}

.photo-box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #333;
    opacity: 0.5;
    border-radius: 4px;
    transition: 0.4s;
}

.photo-box-content {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.photo-box-content h3 {
    color: #fff;
    font-size: 20px;
    line-height: 28px;
}

.photo-box-content span {
    color: #333;
    background: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: $font-weight-normal;
    padding: 4px 10px;
    line-height: 20px;
    margin-top: 8px;
    display: inline-block;
}

.photo-box:hover {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.25);
    transform: translateY(-10px);
}

.photo-box:hover:before {
    background: $oceanBlue;
    opacity: 0.95;
}

.photo-box.small {
    height: 160px;
    margin-bottom: 30px;
}

.photo-box.small:hover {
    transform: translateY(-5px);
}

.photo-box.small .photo-box-content {
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    bottom: auto;
    top: 50%;
    transform: translateY(-49%);
}

.photo-box.small .photo-box-content h3 {
    font-size: 16px;
    font-weight: $font-weight-bold;
}

.photo-box.small .photo-box-content span {
    color: #fff;
    background: $oceanBlue;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    transition: 0.3s;
}

.photo-box.small:hover .photo-box-content span {
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
}

.headline-border-top {
    border-top: 1px solid $regularGrey;
}

.boxed-widget {
    background-color: #f9f9f9;
    padding: 0;
    transform: translate3d(0, 0, 0);
    z-index: 90;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.boxed-widget-headline {
    color: #333;
    font-size: 20px;
    padding: 20px 30px;
    background-color: #f0f0f0;
    color: #333;
    position: relative;
    border-radius: 4px 4px 0 0;
}

.boxed-widget-headline h3 {
    font-size: 20px;
    padding: 0;
    margin: 0;
}

.boxed-widget-inner {
    padding: 30px;
}

.boxed-widget ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.boxed-widget ul li span {
    float: right;
    color: #333;
    font-weight: $font-weight-bold;
}

.boxed-widget ul li {
    color: #666;
    padding-bottom: 1px;
}

.boxed-widget.summary li.total-costs {
    font-size: 18px;
    border-top: 1px solid #e4e4e4;
    padding-top: 18px;
    margin-top: 18px;
}

.boxed-widget-footer {
    border-top: 1px solid #e4e4e4;
    width: 100%;
    padding: 20px 30px;
}

.boxed-widget-footer .checkbox label {
    margin-bottom: 0;
}

.boxed-widget.summary li.total-costs span {
    font-weight: $font-weight-bold;
    color: $oceanBlue;
}

.listing-item-container.compact.order-summary-widget .listing-item {
    border-radius: 4px 4px 0 0;
    cursor: default;
    height: 240px;
}

.listing-item-container.compact.order-summary-widget {
    margin-bottom: 0;
}

.listing-item-container.compact.order-summary-widget:hover {
    transform: none;
}

.billing-cycle {
    display: flex;
}

.billing-cycle .radio {
    flex: 1;
    margin: 5px 20px 5px 0;
}

.billing-cycle .radio label {
    border-radius: 4px;
    border: 2px solid #eee;
    padding: 25px;
    height: 100%;
    align-self: center;
}

.billing-cycle .radio:last-of-type {
    margin-right: 0;
}

.billing-cycle .radio input[type="radio"] + label .radio-label {
    position: relative;
    top: 2px;
    margin-right: 7px;
}

.billing-cycle-details {
    display: block;
    padding-left: 30px;
}

.discounted-price-tag,
.regular-price-tag {
    font-size: 14px;
    background: #e0f5d7;
    color: #449626;
    border-radius: 4px;
    line-height: 20px;
    padding: 4px 10px;
    flex-grow: 0;
    flex: auto;
    width: auto;
    transition: 0.3s;
    margin-top: 6px;
    margin-right: 5px;
    display: inline-block;
}

.line-through {
    text-decoration: line-through;
    background-color: #fbf6dd;
    color: #a18d29;
}

@media (max-width: 768px) {
    .billing-cycle {
        display: flex;
        flex-direction: column;
    }
    .billing-cycle .radio {
        margin-right: 0;
    }
}

.payment-logo {
    height: 24px;
    position: absolute;
    right: 19px;
    top: 18px;
    image-rendering: -webkit-optimize-contrast;
}

.payment-logo.paypal {
    height: 16px;
    top: 22px;
}

.payment-tab-trigger > input {
    position: absolute;
    visibility: hidden;
}

.payment-tab-trigger {
    padding: 0 20px;
}

.payment-tab-trigger > label {
    cursor: pointer;
    display: block;
    position: relative;
    top: 0;
    padding: 14px 0;
    top: 3px;
    margin-bottom: 0;
    font-weight: $font-weight-normal;
}

.payment-tab-trigger > label::before {
    transition: 0.2s;
    box-sizing: border-box;
    background-color: #fff;
    border: 2px solid #d0d0d0;
    border-radius: 100%;
    content: "";
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 3px;
    width: 18px;
    height: 18px;
}

.payment-tab-trigger > label::after {
    background: #fff;
    border-radius: 100%;
    content: "";
    display: inline-block;
    position: absolute;
    left: 5px;
    top: 22px;
    width: 8px;
    height: 8px;
    transition: 0.2s;
    transform: scale(0);
}

.payment-tab-trigger > input:checked ~ label::before {
    border-color: $oceanBlue;
}

.payment-tab-trigger > input:checked ~ label::after {
    background-color: $oceanBlue;
    transform: scale(1);
}

.payment {
    border: 2px solid #f2f2f2;
    border-radius: 4px;
    overflow: hidden;
}

.payment-tab {
    background: #f2f2f2;
    box-sizing: border-box;
    max-height: 60px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s 0s ease-in-out;
    width: 100%;
}

.payment-tab:first-of-type {
    border-radius: 4px 4px 0 0;
}

.payment-tab:last-of-type {
    border-radius: 0 0 4px 4px;
}

.payment-tab.payment-tab-active {
    max-height: 400px;
    background: #fff;
}

@media (max-width: 991px) {
    .payment-tab.payment-tab-active {
        max-height: 800px;
    }
}

.payment-tab-content {
    padding: 0 20px 20px;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out;
}

.payment-form-row {
    margin-top: -10px;
    margin-bottom: 5px;
}

.payment-tab.payment-tab-active .payment-tab-content {
    visibility: visible;
    opacity: 1;
}

.card-label > input {
    box-sizing: border-box;
    display: block;
    height: 51px;
    margin: 14px auto 5px;
    padding: 5px 15px 0;
    transition: border-color 0.3s;
    width: 100%;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    box-shadow: none;
    padding: 15px 0;
    border: none;
    border-bottom: 1px solid $regularGrey;
    border-radius: 0;
}

.card-label > input:focus {
    box-shadow: none;
    border-bottom: 1px solid $regularGrey;
    padding: 15px 0;
}

.card-label > input::placeholder {
    transition: 0.2s ease-in-out;
    -webkit-transform-origin: left;
}

.card-label > input:focus::placeholder {
    transform: translateY(-18px);
}

.card-label > label {
    background-color: #fff;
    display: inline-block;
    margin: 6px auto auto 8px;
    font-size: 13px;
    padding: 0 7px;
    font-weight: $font-weight-normal;
    color: #909090;
    display: none;
}

.payment-tab p {
    margin: 10px 0;
}

.order-confirmation-page {
    text-align: center;
    padding-bottom: 120px;
    padding-top: 0;
}

.order-confirmation-page h2 {
    font-size: 48px;
    font-weight: $font-weight-bold;
    letter-spacing: -0.5px;
    margin-bottom: 25px;
    display: block;
}

.order-confirmation-page p {
    font-size: 20px;
    color: gray;
    font-weight: $font-weight-normal;
    display: block;
    line-height: 32px;
}

.order-confirmation-page a.button:before {
    display: none;
}

.order-confirmation-page a.button {
    background-color: #eee;
    color: #606060;
    font-weight: $font-weight-bold;
    font-size: 18px;
    box-shadow: none;
    padding-top: 14px;
    padding-bottom: 14px;
}

.order-confirmation-page a.button i {
    font-size: 24px;
}

.book-now.button {
    padding: 9px;
    margin-bottom: -4px;
}

.breathing-icon {
    background-color: $oceanBlue;
    width: 120px;
    height: 120px;
    line-height: 0;
    font-size: 18px;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
    z-index: 100;
    transition: 0.4s;
    color: #fff;
    font-weight: $font-weight-normal;
    animation: bicokAnim 3s infinite;
    box-shadow: 0 0 0 15px rgba(25, 140, 202, 0.07);
    margin-bottom: 70px;
    font-size: 90px;
}

.breathing-icon i {
    color: #fff;
    position: absolute;
    top: 53%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

@keyframes bicokAnim {
    0%,
    100% {
        box-shadow: 0 0 0 15px rgba(25, 140, 202, 0.07);
    }
    50% {
        box-shadow: 0 0 0 28px rgba(25, 140, 202, 0.12);
    }
}

@media (max-width: 991px) {
    .order-confirmation-page i {
        font-size: 100px;
    }
    .order-confirmation-page p {
        font-size: 18px;
    }
    .order-confirmation-page h2 {
        font-size: 30px;
    }
}

.icon-box {
    text-align: center;
    margin-top: 45px;
    position: relative;
}

.icon-box-circle {
    background-color: #fff;
    width: 50%;
    height: 60px;
    z-index: 100;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    margin-bottom: 35px;
}

.icon-box-circle-inner {
    font-size: 72px;
    color: #ccc;
    position: relative;
    width: 60px;
    margin: 0 auto;
    display: inline-block;
}

.icon-box-check {
    background-color: $oceanBlue;
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 18px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    z-index: 100;
    animation: circle-box-anim 2s infinite;
    transition: 0.4s;
    color: #fff;
    font-weight: $font-weight-normal;
    position: absolute;
    bottom: -3px;
    right: -10px;
    animation: checkAnim 2s infinite;
    box-shadow: 0 0 0 5px rgba(25, 140, 202, 0.1);
}

@keyframes checkAnim {
    0%,
    100% {
        box-shadow: 0 0 0 5px rgba(25, 140, 202, 0.15);
    }
    50% {
        box-shadow: 0 0 0 8px rgba(25, 140, 202, 0.15);
    }
}

.icon-box span {
    color: #666;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: $font-weight-normal;
}

.icon-box h4 {
    margin: 4px 0;
    line-height: 26px;
}

.icon-box h3 {
    margin: 4px 0;
    line-height: 26px;
    font-size: 22px;
    font-weight: $font-weight-normal;
}

.icon-box p {
    margin: 0;
    margin-top: 25px;
    font-style: 16px;
    padding: 0 10px;
    color: gray;
}

.icon-box.with-line:before {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 40px;
    background: #e9e9e9;
    display: block;
    z-index: 9;
}

@media (max-width: 992px) {
    .icon-box.with-line:before {
        display: none;
    }
}

.testimonial-box {
    background: 0 0;
    border-radius: 6px;
    padding: 45px;
    padding-top: 35px;
    color: #888;
    position: relative;
    margin: 70px 0 0;
    box-shadow: none;
    transition: 0.4s;
    cursor: grab;
    text-align: center;
    transform: scale(0.8);
}

.testimonial-box:before {
    content: "\e948";
    font-family: material-icons;
    position: absolute;
    top: -35px;
    left: 0;
    font-size: 80px;
    transform: translateY(-100%);
    color: $oceanBlue;
    opacity: 0;
    transition: 0.3s;
}

.testimonial-carousel .slick-slide {
    margin: 0 20px 30px;
    opacity: 0;
    transition: 0.4s;
    pointer-events: all;
}

.testimonial-carousel .slick-slide.slick-active {
    opacity: 1;
    filter: none;
    pointer-events: all;
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box {
    transform: scale(1);
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box:before {
    opacity: 1;
}

.testimonial-carousel .slick-slide.slick-active .testimonial-box {
    background: #fff;
    color: #666;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

.testimonial,
.testimonial p {
    font-size: 18px;
    font-weight: $font-weight-normal;
    line-height: 30px;
    color: gray;
    line-height: 32px;
}

.testimonial-author {
    display: block;
    width: 100%;
    padding: 20px 0 15px;
    text-align: center;
    margin: 0 auto;
}

.testimonial-avatar {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
}

.testimonial-avatar img {
    width: 70px;
    display: inline-block;
    text-align: center;
    height: auto;
    border-radius: 50%;
    margin: 0 auto;
}

.testimonial-author h4 {
    display: block;
    width: 100%;
    font-size: 18px;
    padding-top: 0;
    color: #333;
    font-weight: $font-weight-bold;
}

.testimonial-author span {
    display: block;
    padding-top: 0;
    display: inline-block;
    border-radius: 4px;
    font-size: 16px;
    font-weight: $font-weight-normal;
    line-height: 23px;
    padding: 3px 12px;
    margin: 5px 0 10px;
    background-color: $oceanBlue;
    color: #fff;
    box-shadow: 0 2px 10px rgba(25, 140, 202, 0.25);
}

.testimonial-box .star-rating {
    height: 30px;
    display: inline-block;
    margin-bottom: -10px;
    margin-top: 15px;
}

.testimonial-carousel .slick-next {
    right: 25%;
}

.testimonial-carousel .slick-prev {
    left: 25%;
}

.testimonial-carousel .slick-next,
.testimonial-carousel .slick-prev {
    margin-top: 35px;
}

@media (max-width: 1600px) {
    .testimonial-carousel .slick-next {
        right: 15%;
    }
    .testimonial-carousel .slick-prev {
        left: 15%;
    }
}

@media (max-width: 1240px) {
    .testimonial-carousel .slick-next {
        right: 13%;
    }
    .testimonial-carousel .slick-prev {
        left: 13%;
    }
}

@media (max-width: 992px) {
    .testimonial-carousel .slick-next {
        right: 8%;
    }
    .testimonial-carousel .slick-prev {
        left: 8%;
    }
    .testimonial-box {
        padding: 35px;
    }
    .testimonial,
    .testimonial p {
        font-size: 16px;
        line-height: 28px;
    }
}

@media (max-width: 768px) {
    .testimonial-carousel .slick-next {
        right: 20px;
    }
    .testimonial-carousel .slick-prev {
        left: 20px;
    }
    .testimonial-carousel .slick-next,
    .testimonial-carousel .slick-prev {
        transform: scale(0.8) translateY(-50%);
    }
}

.logo-carousel .carousel-item {
    display: inline-block;
    height: 120px;
    text-align: center;
    position: relative;
}

.logo-carousel .carousel-item a {
    display: inline-block;
}

.logo-carousel .carousel-item img {
    max-height: 60%;
    max-width: 60%;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.logo-carousel .slick-prev,
.logo-carousel .slick-next {
    top: 50%;
}

.blog-carousel {
    width: calc(100% + 30px);
    left: -15px;
}

.blog-carousel .blog-compact-item-container {
    margin: 15px;
    display: inline-block;
    width: 30%;
}

.blog-carousel.slick-initialized .blog-compact-item-container {
    width: auto;
}

.full-width-carousel-fix .default-slick-carousel.slick-initialized .freelancer,
.full-width-carousel-fix .blog-carousel.slick-initialized .blog-compact-item-container {
    margin: 15px;
    transform: scale(0.85);
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
}

.full-width-carousel-fix .default-slick-carousel.slick-initialized .slick-slide.slick-active.freelancer,
.full-width-carousel-fix .blog-carousel.slick-initialized .slick-slide.slick-active.blog-compact-item-container {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
}

.full-width-carousel-fix .blog-carousel.slick-initialized .slick-slide.slick-active.blog-compact-item-container:hover {
    transform: scale(1) translateY(-10px);
}

.photo-section {
    background-size: cover;
    background-position: 50%;
    display: block;
    position: relative;
}

.photo-section:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #333;
    opacity: 0.65;
    display: block;
    position: absolute;
    top: 0;
}

.text-content {
    background: 0 0;
    padding: 110px 0;
    font-weight: $font-weight-normal;
    z-index: 99;
    position: relative;
}

.text-content.white-font,
.text-content h1,
.text-content h2,
.text-content h3,
.text-content h4,
.text-content h5,
.text-content.white-font h1,
.text-content.white-font h2,
.text-content.white-font h3,
.text-content.white-font h4,
.text-content.white-font h5 {
    color: #fff;
}

.text-content a.button {
    font-size: 16px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.text-content p {
    font-size: 18px;
    font-weight: $font-weight-normal;
    line-height: 31px;
    opacity: 0.85;
}

.text-content h2 {
    margin-bottom: 23px;
    line-height: 46px;
    font-size: 34px;
    font-weight: $font-weight-normal;
}

.top-1 {
    position: relative;
    top: -1px;
}

.top-0 {
    position: relative;
}

@media (max-width: 992px) {
    .text-content {
        padding: 80px 0;
    }
    .text-content h2 {
        margin-bottom: 20px;
        line-height: 40px;
        font-size: 30px;
        font-weight: $font-weight-normal;
    }
}

.counters-container {
    display: flex;
    flex-wrap: wrap;
}

.single-counter {
    flex: 1;
    text-align: center;
    position: relative;
    border-right: 1px solid $regularGrey;
    margin-top: 80px;
}

.single-counter:last-child {
    border-right: 0;
}

.single-counter i {
    font-size: 64px;
    color: #ccc;
    position: absolute;
    top: -20px;
    transform: translateY(-100%);
    left: 0;
    right: 0;
}

.single-counter h3 {
    font-size: 36px;
    font-weight: $font-weight-bold;
    color: $oceanBlue;
    margin-bottom: 7px;
}

.single-counter .counter-title {
    color: #888;
    padding-top: 0;
    display: inline-block;
    border-radius: 4px;
    font-size: 18px;
}

@media (max-width: 992px) {
    .single-counter {
        flex: 1 1 50%;
        margin-bottom: 30px;
    }
    .single-counter:nth-child(2) {
        border: none;
    }
    .counters-container {
        margin-bottom: -30px;
    }
}

@media (max-width: 768px) {
    .single-counter {
        flex: 1 1 100%;
        border: none;
        border-bottom: 1px solid $regularGrey;
        padding-bottom: 30px;
    }
    .single-counter:nth-child(2) {
        border-bottom: 1px solid $regularGrey;
    }
    .single-counter:last-child {
        margin-bottom: -15px;
        border-bottom: none;
    }
    .counters-container {
        margin-bottom: -30px;
    }
}

.countdown {
    border-radius: 4px;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    padding: 14px;
}

.countdown.green {
    background-color: #e7f8ec;
    color: #289c41;
}

.countdown.yellow {
    background-color: #fbf6dd;
    color: #a18d29;
}

.bidding-widget {
    border-radius: 4px;
    background-color: #f9f9f9;
}

.dashboard-box .bidding-widget,
.popup-tab-content .bidding-widget {
    border-radius: 0;
    background-color: transparent;
}

.dashboard-box .slider-handle.custom,
.popup-tab-content .slider-handle.custom {
    border: 1px solid $regularGrey;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
}

.bidding-widget .bidding-headline {
    background-color: #f0f0f0;
    color: #333;
    position: relative;
    border-radius: 4px 4px 0 0;
}

.bidding-widget .bidding-inner {
    padding: 30px 35px;
}

.bidding-widget .bidding-headline {
    color: #333;
    font-size: 20px;
    padding: 20px 35px;
}

.bidding-widget .bidding-headline h3 {
    color: #333;
    font-size: 20px;
    padding: 0;
    margin: 0;
}

.bidding-widget .bidding-signup {
    color: #666;
    font-size: 14.7px;
    padding: 16px 35px;
    text-align: center;
    border-top: 1px solid $regularGrey;
}

.bidding-widget .bidding-signup a {
    font-weight: $font-weight-bold;
}

.bidding-widget button.button {
    margin-bottom: 3px;
}

.bidding-widget .bidding-detail {
    color: #888;
    display: block;
}

.bidding-widget .bidding-detail strong {
    color: #333;
    font-weight: $font-weight-bold;
}

.dashboard-box .bidding-detail strong {
    font-weight: $font-weight-bold;
}

.bidding-widget .bidding-value {
    font-size: 26px;
    font-weight: $font-weight-normal;
    color: #333;
    margin: 5px 0 20px;
}

.bidding-widget .bidding-value {
    font-size: 26px;
    font-weight: $font-weight-normal;
    color: #333;
}

.bidding-widget .button {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.15);
    transition: 0.3s;
    height: 46px;
}

.bidding-widget .button:hover {
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.25);
    transform: translateY(-2px);
}

.bidding-widget .bidding-fields {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 12px;
    width: calc(100% + 20px);
}

.bidding-widget .bidding-fields .bidding-field {
    flex: 1;
    margin-right: 20px;
}

.qtyButtons {
    display: flex;
    margin: 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    height: 48px;
    padding: 6px;
}

.qtyButtons.with-border {
    border: 1px solid $regularGrey;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
}

.qtyButtons input {
    outline: 0;
    font-size: 16px;
    font-weight: $font-weight-bold;
    text-align: center;
    width: 50px;
    height: 36px;
    color: gray;
    line-height: 36px;
    margin: 0 !important;
    padding: 0 5px;
    border: none;
    box-shadow: none;
    display: inline-block;
    flex-grow: 1;
    box-shadow: none !important;
}

.qtyTitle {
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 36px;
    padding-right: 15px;
    display: block;
    flex: 1;
}

.qtyInc,
.qtyDec {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    background-color: #f0f0f0;
    color: gray;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    font-family: feather-icons;
    flex: 1;
    min-width: 36px;
    max-width: 36px;
    transition: 0.3s;
}

.qtyInc:before {
    content: "\e9b1";
}

.qtyDec:before {
    content: "\e996";
}

.qtyInc:hover,
.qtyDec:hover {
    background-color: $oceanBlue;
    color: #fff;
}

.qtyTotal {
    background-color: $oceanBlue;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: $font-weight-bold;
    font-family:
        open sans,
        sans-serif;
    line-height: 18px;
    text-align: center;
    position: relative;
    top: -2px;
    left: 2px;
    height: 18px;
    width: 18px;
}

@media (max-width: 1366px) {
    .qtyInc,
    .qtyDec {
        min-width: 34px;
        max-width: 34px;
    }
    .bidding-widget .bidding-inner {
        padding: 27px 32px;
    }
    .bidding-widget .bidding-headline {
        padding: 20px 32px;
    }
}

@media (max-width: 1240px) {
    .qtyInc,
    .qtyDec {
        min-width: 36px;
        max-width: 36px;
    }
    .bidding-widget .bidding-fields .bidding-field {
        flex: auto;
        margin-right: 0;
        width: 100%;
    }
    .bidding-widget .bidding-fields .bidding-field:first-child {
        margin-bottom: 20px;
    }
    .bidding-widget .bidding-fields {
        width: 100%;
    }
}

#not-found {
    margin: 40px 0 80px;
}

#not-found.center {
    text-align: center;
}

#not-found h2 {
    text-align: center;
    font-size: 210px;
    line-height: 210px;
    font-weight: $font-weight-normal;
    letter-spacing: -8px;
}

#not-found p {
    text-align: center;
    font-size: 28px;
    line-height: 42px;
    display: inline-block;
    margin-top: 0;
    color: #999;
}

#not-found i {
    color: $regularGrey;
    font-size: 210px;
    top: 0;
    margin-left: -40px;
    position: relative;
}

.not-found-search.intro-banner-search-form .intro-search-field,
.not-found-search.intro-banner-search-form input,
.not-found-search.intro-banner-search-form {
    background: #f7f7f7;
    box-shadow: none;
}

@media (max-width: 992px) {
    .not-found-search.intro-banner-search-form {
        background: #fff;
    }
}

@media (max-width: 767px) {
    #not-found h2 {
        font-size: 110px;
        line-height: 110px;
    }
    #not-found p {
        font-size: 20px;
        line-height: 32px;
    }
    #not-found i {
        font-size: 120px;
        top: 5px;
        margin-left: 0;
    }
    .not-found-search.intro-banner-search-form {
        margin-top: -30px;
    }
    .login-register-page .account-type {
        width: 100%;
    }
    .login-register-page .account-type div {
        margin-right: 0;
    }
    .login-register-page .social-login-buttons button {
        margin-right: 0;
        width: 100%;
        display: block;
        max-width: 100%;
    }
}

.login-register-page .welcome-text h3 {
    font-size: 26px;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #333;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    display: block;
    top: calc(50% - 15px);
    transform: translateY(-50%);
    transition: 0.3s;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    z-index: 10;
}

.slick-prev:hover,
.slick-next:hover {
    color: transparent;
    outline: none;
    background: $oceanBlue;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    opacity: 1;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    pointer-events: none;
    cursor: default;
    background-color: #e8e8e8;
    box-shadow: none;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    color: gray;
}

.slick-prev:before,
.slick-next:before {
    font-family: feather-icons;
    font-size: 26px;
    line-height: 1;
    opacity: 1;
    transition: 0.3s;
    position: relative;
    color: #fff;
}

.slick-prev {
    left: -85px;
}

.slick-prev:before {
    content: "\e92f";
    left: -1px;
}

.slick-next {
    right: -85px;
}

.slick-next:before {
    content: "\e930";
    right: -1px;
}

.slick-dots {
    margin: 0 auto;
    padding: 0;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 3px;
    cursor: pointer;
}

.slick-dots li {
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    position: relative;
    outline: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 3px;
    opacity: 1;
    border-radius: 50%;
    line-height: 0;
    box-shadow: inset 0 0 0 2px silver;
    transition: all 0.3s;
}

.slick-dots li.slick-active {
    box-shadow: inset 0 0 0 6px silver;
    transition: all 0.3s;
}

.slick-dots li:after {
    display: block;
    height: 13px;
    width: 13px;
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.3s;
    opacity: 0;
    background-color: silver;
}

.slick-dots li.slick-active:after {
    opacity: 1;
}

.slick-slide {
    display: none;
    outline: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.default-slick-carousel .slick-carousel-item {
    margin: 15px;
}

.default-slick-carousel {
    height: auto;
    width: calc(100% + 30px);
    left: -15px;
}

.default-slick-carousel,
.testimonial-carousel,
.logo-carousel,
.blog-carousel {
    display: none;
}

.default-slick-carousel.slick-initialized,
.testimonial-carousel.slick-initialized,
.logo-carousel.slick-initialized,
.blog-carousel.slick-initialized {
    display: block;
}

.default-slick-carousel .freelancer {
    margin: 15px;
    width: 30%;
}

.default-slick-carousel.slick-initialized .freelancer {
    width: auto;
}

.default-slick-carousel .freelancer-details-list ul li {
    margin-right: 22px;
}

.default-slick-carousel .freelancer-details-list ul li:last-child {
    margin-right: 0;
}

.default-slick-carousel .freelancer:hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
    transform: translateY(0px);
}

@media (min-width: 1367px) and (max-width: 1440px) {
    .slick-prev {
        left: -65px;
    }
    .slick-next {
        right: -65px;
    }
}

@media (max-width: 1290px) {
    .slick-prev {
        left: -10px;
    }
    .slick-next {
        right: -10px;
    }
}

@media (max-width: 1090px) {
    .slick-prev {
        left: 40px;
    }
    .slick-next {
        right: 40px;
    }
}

.full-width-carousel-fix {
    overflow: hidden;
}

.full-width-carousel-fix .slick-list {
    overflow: visible;
}

[data-simplebar] {
    position: relative;
    z-index: 0;
    overflow: hidden !important;
    max-height: inherit;
    -webkit-overflow-scrolling: touch;
    min-width: 240px;
}

[data-simplebar="init"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.simplebar-scroll-content {
    overflow-x: hidden !important;
    overflow-y: scroll;
    min-width: 100% !important;
    max-height: inherit !important;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
}

.simplebar-content {
    overflow-y: hidden !important;
    overflow-x: scroll;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    min-height: 100% !important;
}

.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 11px;
    height: calc(100% - 6px);
    margin-top: 6px;
}

.simplebar-scrollbar {
    position: absolute;
    right: 6px;
    width: 5px;
    min-height: 10px;
}

.simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #000;
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    transition:
        opacity 0.2s,
        background-color 0.3s;
    background-color: #d8d8d8;
}

.simplebar-scrollbar:hover:before {
    background-color: #c8c8c8;
}

.simplebar-track:hover .simplebar-scrollbar:before,
.simplebar-track .simplebar-scrollbar.visible:before {
    opacity: 1;
    -webkit-transition: opacity 0 linear;
    transition: opacity 0 linear;
}

.simplebar-track.vertical {
    top: 0;
}

.simplebar-track.vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
}

.simplebar-track.horizontal {
    left: 0;
    width: auto;
    height: 11px;
    display: none;
}

.simplebar-track.horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px;
}

.horizontal.simplebar-track .simplebar-scrollbar {
    right: auto;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto;
}

body.gray .dashboard-content-container .simplebar-scrollbar:before {
    background-color: #c9c9c9;
}

body.gray .dashboard-content-container .simplebar-scrollbar:hover:before {
    background-color: #b9b9b9;
}

.dashboard-box .simplebar-scrollbar:before {
    background-color: #d8d8d8;
}

.dashboard-box .simplebar-scrollbarLhover:before {
    background-color: #c8c8c8;
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    overflow: hidden;
    position: fixed;
    background: #111;
}

.mfp-wrap {
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2001;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    height: 100%;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 18px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mfp-container:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 505;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #aaa;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 504;
}

.mfp-preloader a {
    color: #ccc;
}

.mfp-preloader a:hover {
    color: #fff;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

.mfp-s-loading .mfp-arrow {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    display: block;
    padding: 0;
    z-index: 506;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    right: 0;
    top: -40px;
}

.og-close,
button.mfp-close {
    position: absolute;
    width: 45px;
    height: 45px;
    top: -20px;
    display: block;
    right: -12px;
    cursor: pointer !important;
    z-index: 9999;
    color: #fff;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-radius: 50%;
    margin: 0;
    background-color: transparent;
    outline: none;
    transform: translate3d(0, 0, 0);
}

.mfp-iframe-holder .mfp-close {
    top: -50px;
    right: -12px;
    transform: translate3d(0, 0, 0);
}

.mfp-gallery .mfp-close {
    top: 20px;
    right: 10px;
}

.mfp-gallery .mfp-content .mfp-close {
    display: none;
}

.mfp-close::after,
.mfp-close::before {
    top: 0;
}

.og-close {
    margin: 17px;
    right: 10px;
    background: 0 0;
    color: #222;
}

.og-close:before,
.mfp-close:before {
    font-family: feather-icons;
    font-size: 20px;
    top: 2px;
    left: 0;
    position: relative;
    height: 10px;
}

.og-close:before,
.mfp-close:before {
    content: "\ea02";
}

#small-dialog .mfp-close,
#small-dialog-1 .mfp-close,
#small-dialog-2 .mfp-close,
#small-dialog-3 .mfp-close,
#small-dialog-4 .mfp-close,
#sign-in-dialog,
.mfp-close,
.mfp-close:hover {
    color: #fff;
}

#sign-in-dialog .mfp-close,
#small-dialog-1 .mfp-close,
#small-dialog-2 .mfp-close,
#small-dialog-3 .mfp-close,
#small-dialog-4 .mfp-close,
#small-dialog .mfp-close {
    color: #666;
    background-color: #e4e4e4;
    border-radius: 4px;
    top: 28px;
    right: 40px;
    width: 40px;
    height: 40px;
}

#sign-in-dialog .mfp-close:hover,
#small-dialog-1 .mfp-close:hover,
#small-dialog-2 .mfp-close:hover,
#small-dialog-3 .mfp-close:hover,
#small-dialog-4 .mfp-close:hover,
#small-dialog .mfp-close:hover {
    color: #fff;
    background-color: $oceanBlue;
}

.small-dialog-content input[type="submit"] {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 10px;
}

.small-dialog-content .divider {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    padding-bottom: 0;
}

.small-dialog-header {
    font-size: 22px;
    width: calc(100% + 80px);
    position: relative;
    left: -40px;
    top: 0;
    border-radius: 4px 4px 0 0;
    display: inline-block;
    background-color: #f6f6f6;
    padding: 35px 40px;
    margin-bottom: 40px;
}

.small-dialog-header h3 {
    padding: 0;
    margin: 0;
    font-size: 23px;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #aaa;
    font-size: 13px;
    line-height: 18px;
}

.mfp-arrow {
    opacity: 1;
    margin: 0 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    color: #fff;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: absolute;
    display: block;
    z-index: 100;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    outline: none;
}

.mfp-arrow:hover {
    background: $oceanBlue;
    color: #fff;
}

.mfp-arrow.mfp-arrow-left,
.mfp-arrow.mfp-arrow-right {
    font-family: simple-line-icons;
    font-size: 20px;
}

.mfp-arrow.mfp-arrow-right {
    right: 15px;
}

.mfp-arrow.mfp-arrow-left:before {
    content: "\e605";
    left: -1px;
    top: 0;
    position: relative;
}

.mfp-arrow.mfp-arrow-right:before {
    content: "\e606";
    left: 1px;
    top: 0;
    position: relative;
}

.mfp-content:hover .mfp-arrow {
    opacity: 1;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 1180px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 54%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

#sign-in-dialog,
#small-dialog-1,
#small-dialog-2,
#small-dialog-3,
#small-dialog-4,
#small-dialog {
    background: #fff;
    padding: 40px;
    padding-top: 0;
    text-align: left;
    max-width: 610px;
    margin: 40px auto;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 550px;
}

#sign-in-dialog-1 span.line,
#sign-in-dialog-2 span.line,
#sign-in-dialog-3 span.line,
#sign-in-dialog-4 span.line,
#sign-in-dialog span.line,
#small-dialog span.line {
    padding-top: 0;
}

.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
}

.my-mfp-zoom-in.mfp-bg {
    opacity: 0.001;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.85;
}

.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}

.dupa {
    display: block;
    height: 30px;
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 40px auto;
    border-radius: 4px;
}

.mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 18px rgba(11, 11, 11, 0.6);
}

.mfp-figure {
    line-height: 0;
}

.mfp-bottom-bar {
    margin-top: -30px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-break: break-word;
    padding-right: 36px;
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }
}

.mfp-ie7 .mfp-img {
    padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
    padding: 0;
}

.mfp-ie7 .mfp-content {
    padding-top: 44px;
}

.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0;
}

.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.92;
    background-color: #111;
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.feedback-yes-no strong {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #333;
    display: block;
}

.feedback-yes-no .radio {
    margin-right: 15px;
}

.feedback-yes-no {
    margin-bottom: 15px;
}

.feedback-yes-no .leave-rating {
    transform: scale(1.1);
    margin: 3px 0 10px 3px;
}

.popup-tabs-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
    height: 62px;
    background: #f8f8f8;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid $regularGrey;
}

.popup-tabs-nav li {
    float: left;
    line-height: 62px;
    height: 62px;
    padding: 0;
    margin-right: 0;
    position: relative;
}

.popup-tabs-nav li:first-child a {
    border-radius: 4px 0 0 0;
}

.popup-tabs-nav li a,
.popup-tab-reviews {
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.popup-tabs-nav li a {
    border: none;
    color: #777;
    display: inline-block;
    outline: none;
    padding: 0;
    text-decoration: none;
    font-size: 16px;
    z-index: 10;
    position: relative;
    height: 100%;
    padding: 0 30px;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    transition:
        all 0.3s,
        font-weight 0s;
    border-right: 1px solid $regularGrey;
}

.popup-tabs-nav li.active {
    background-color: #fff;
    position: relative;
}

.popup-tabs-nav li a:hover {
    background-color: #f3f3f3;
    height: calc(100% - 1px);
    color: #333;
}

.popup-tabs-nav li:last-child a:hover {
    border-right: 1px solid $regularGrey;
}

.popup-tabs-nav li.active a:hover,
.popup-tabs-nav li.active {
    background-color: #fff;
}

.popup-tabs-nav li.active a {
    color: $oceanBlue;
    font-weight: $font-weight-bold;
}

.popup-tabs-nav li.active:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    z-index: 111;
}

.popup-tabs-nav li:first-child.active {
    border-left: none;
    border-radius: 4px 0 0 0;
}

.popup-tab-content {
    padding: 50px 35px;
    position: relative;
    z-index: 10;
    display: inline-block;
    width: 100%;
}

.popup-tabs-container {
    overflow: hidden;
    width: 100%;
}

.popup-tab-content p:last-child {
    margin-bottom: 0;
}

.dialog-with-tabs#sign-in-dialog,
.dialog-with-tabs#small-dialog-1,
.dialog-with-tabs#small-dialog-2,
.dialog-with-tabs#small-dialog-3,
.dialog-with-tabs#small-dialog-4,
.dialog-with-tabs#small-dialog {
    padding: 0;
    color: #666;
    max-width: 540px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}

#sign-in-dialog.dialog-with-tabs .mfp-close,
#small-dialog.dialog-with-tabs .mfp-close,
#small-dialog-1.dialog-with-tabs .mfp-close,
#small-dialog-2.dialog-with-tabs .mfp-close,
#small-dialog-3.dialog-with-tabs .mfp-close,
#small-dialog-4.dialog-with-tabs .mfp-close {
    color: #888;
    background-color: #f8f8f8;
    border-left: 1px solid $regularGrey;
    border-radius: 0 4px 0 0;
    top: 0;
    right: 0;
    width: 62px;
    height: 61px;
}

#sign-in-dialog.dialog-with-tabs .mfp-close:hover,
#small-dialog-1.dialog-with-tabs .mfp-close:hover,
#small-dialog-2.dialog-with-tabs .mfp-close:hover,
#small-dialog-3.dialog-with-tabs .mfp-close:hover,
#small-dialog-4.dialog-with-tabs .mfp-close:hover,
#small-dialog.dialog-with-tabs .mfp-close:hover {
    color: #333;
    background-color: #f2f2f2;
}

.dialog-with-tabs .mfp-close:before,
.dialog-with-tabs .mfp-close:before {
    font-size: 24px;
}

.dialog-with-tabs input:-webkit-autofill,
.dialog-with-tabs input:focus:-webkit-autofill {
    border: 1px solid $regularGrey;
    -webkit-box-shadow:
        inset 0 0 0 9999px #fff,
        0 1px 4px rgba(0, 0, 0, 0.07);
}

.dialog-with-tabs input {
    margin-bottom: 22px;
}

.dialog-with-tabs .forgot-password {
    font-size: 14px;
    color: #888;
    margin-top: -12px;
    display: block;
}

.dialog-with-tabs .forgot-password:hover {
    color: $oceanBlue;
}

.dialog-with-tabs .button {
    height: 48px;
    width: 100% !important;
    margin-top: 25px;
    box-shadow: 0 4px 12px rgba(25, 140, 202, 0.15);
}

.welcome-text {
    display: block;
    text-align: center;
    color: gray;
    margin-bottom: 33px;
}

.welcome-text h3 {
    display: block;
    font-weight: $font-weight-bold;
    color: #333;
    font-size: 24px;
}

.welcome-text span a {
    font-weight: $font-weight-normal;
}

.welcome-text span {
    display: block;
    margin-top: 4px;
}

.social-login-separator {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    width: 100%;
    margin: 38px 0 15px;
    height: 1px;
}

.social-login-separator:before {
    width: 100%;
    content: "";
    height: 1px;
    display: block;
    background-color: $regularGrey;
    position: absolute;
    top: 0;
}

.social-login-separator span {
    background-color: #fff;
    top: 0;
    position: absolute;
    line-height: 10px;
    top: -5px;
    margin-left: -15px;
    padding: 0 10px;
    color: gray;
}

.social-login-buttons {
    display: flex;
    width: calc(100% + 20px);
}

.social-login-buttons button {
    flex: 1;
    text-align: center;
    border: 1px solid #333;
    color: #333;
    border-radius: 4px;
    margin-top: 10px;
    margin-right: 20px;
    font-size: 14px;
    padding: 9px 5px;
    max-width: 50%;
    transition: 0.3s;
}

.social-login-buttons button:hover {
    background-color: #333;
    color: #fff;
}

.social-login-buttons button i {
    position: relative;
    top: 1px;
    margin-right: 7px;
    font-size: 15px;
}

.social-login-buttons .facebook-login {
    border-color: #3b5998;
    color: #3b5998;
}

.social-login-buttons .facebook-login:hover {
    background-color: #3b5998;
    color: #fff;
}

.social-login-buttons .google-login {
    border-color: #dd4b39;
    color: #dd4b39;
}

.social-login-buttons .google-login:hover {
    background-color: #dd4b39;
    color: #fff;
}

@media (max-width: 768px) {
    .social-login-separator {
        margin-bottom: 25px;
    }
    .social-login-buttons,
    .social-login-buttons a {
        width: 100%;
        display: block;
        max-width: 100%;
    }
}

.account-type {
    display: flex;
    width: calc(100% + 20px);
    margin: 0 0 20px;
}

.account-type div {
    flex: 1;
    margin-right: 20px;
}

.account-type label {
    border-radius: 3px;
    border: none;
    background-color: #f2f2f2;
    width: 100%;
}

.account-type input.account-type-radio:empty {
    display: none;
}

.account-type label i {
    position: relative;
    font-size: 18px;
    top: 2px;
    margin-right: 2px;
}

.account-type input.account-type-radio:empty ~ label {
    position: relative;
    float: left;
    padding: 10px;
    text-align: center;
    padding-bottom: 11px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    color: #888;
    transition: 0.4s;
    height: 48px;
    line-height: 28px;
    overflow: hidden;
}

.account-type input.account-type-radio ~ label:hover {
    color: #289c41;
    background-color: #e4f6e9;
}

.account-type input.account-type-radio:checked ~ label {
    color: #fff;
    background-color: #47bb67;
}

.account-type input.account-type-radio:empty ~ label:after,
.account-type input.account-type-radio:empty ~ label:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 48px;
    border-radius: 4px;
    font-size: 22px;
    background: 0 0;
    z-index: 100;
    opacity: 0;
}

.account-type .ripple-effect-dark span.ripple-overlay {
    animation: ripple 1.2s;
    opacity: 0.03;
}

@keyframes typeCheck {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    50% {
        opacity: 1;
        transform: translateY(0px);
    }
    100% {
        opacity: 0;
        transform: translateY(-10px);
    }
}

@keyframes typeBG {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 480px) {
    .popup-tabs-nav {
        padding-top: 50px;
        height: 50px;
    }
    .mfp-container {
        padding: 0 10px;
    }
    .popup-tabs-nav li:first-child {
        border-top: 1px solid $regularGrey;
    }
    .popup-tabs-nav li {
        border-bottom: 1px solid $regularGrey;
    }
    .popup-tabs-nav li,
    .popup-tabs-nav li a {
        width: 100%;
        text-align: center;
        height: 50px;
        line-height: 50px;
    }
    .popup-tabs-nav li a:hover {
        background-color: transparent;
    }
    #sign-in-dialog.dialog-with-tabs .mfp-close,
    #small-dialog.dialog-with-tabs .mfp-close,
    #small-dialog-1.dialog-with-tabs .mfp-close,
    #small-dialog-2.dialog-with-tabs .mfp-close,
    #small-dialog-3.dialog-with-tabs .mfp-close,
    #small-dialog-4.dialog-with-tabs .mfp-close {
        height: 50px;
        width: 50px;
    }
    .account-type,
    .social-login-buttons {
        flex-direction: column;
    }
    .social-login-buttons button {
        max-width: 100%;
        width: 100%;
        flex: auto;
    }
    .uploadButton {
        flex-direction: column;
    }
    .uploadButton .uploadButton-file-name {
        padding: 0;
        margin: 15px 0 0;
    }
}

input.range-slider,
input.default-slider {
    display: none;
}

.slider {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.slider.slider-horizontal {
    width: calc(100% - 20px);
    margin-left: 11px;
    height: 20px;
}

.slider.slider-horizontal .slider-track {
    height: 3px;
    width: calc(100% + 18px);
    margin-top: -2px;
    top: 50%;
    left: -9px;
}

.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
    height: 100%;
    top: 0;
    bottom: 0;
}

.slider.slider-horizontal .slider-handle {
    margin-left: -11px;
}

.slider.slider-horizontal .slider-tick-label-container {
    white-space: nowrap;
    margin-top: 20px;
}

.slider.slider-horizontal .tooltip {
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
}

.slider input {
    display: none;
}

.slider .tooltip.top {
    margin-top: -40px;
}

.slider .tooltip-inner {
    white-space: nowrap;
    max-width: none;
    background-color: #333;
    padding: 4px 12px;
    line-height: 21px;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
}

.slider .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
}

.tooltip.tooltip-min,
.tooltip.tooltip-max {
    display: none;
}

.slider .hide {
    display: none;
}

.slider-track {
    position: absolute;
    cursor: pointer;
    border-radius: 4px;
    background-color: $regularGrey;
}

.slider-selection {
    position: absolute;
    background-color: $oceanBlue;
    box-sizing: border-box;
    border-radius: 4px;
}

.slider-track-low,
.slider-track-high {
    position: absolute;
    background: 0 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
}

.slider-handle {
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid $oceanBlue;
    cursor: pointer;
    z-index: 20;
    border-radius: 50%;
}

.slider-handle:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 50%;
    z-index: 30;
    display: block;
    box-shadow: 0 0 0 6px rgba(25, 140, 202, 0.12);
    transition: 0.2s;
}

.slider-handle:hover:after {
    box-shadow: 0 0 0 8px rgba(25, 140, 202, 0.12);
}

.slider-handle.custom {
    position: absolute;
    top: -5px;
    width: 40px;
    height: 29px;
    border: none;
    cursor: pointer;
    z-index: 20;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    text-align: center;
}

.slider-handle.custom:after {
    display: none;
}

.slider-handle.custom:before {
    content: "\e958 \e959";
    font-family: material-icons;
    letter-spacing: -10px;
    font-size: 21px;
    color: #a0a0a0;
    margin-left: -10px;
    transition: 0.3s;
}

.slider-handle.custom:hover:before {
    color: #333;
}

.slider.slider-horizontal .slider-handle.custom {
    margin-left: -20px;
}

select.bs-select-hidden,
select.selectpicker {
    display: none !important;
}

.bootstrap-select .btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bootstrap-select > .dropdown-toggle {
    width: 100%;
    padding-right: 25px;
    z-index: 1;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: gray;
}

.bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
}

.bootstrap-select > select.mobile-device {
    top: 0;
    left: 0;
    display: block !important;
    width: 100% !important;
    z-index: 2;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
    border-color: #b94a48;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
}

.bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
    z-index: auto;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
    float: none;
    display: flex;
    margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
    float: right;
}

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.checkbox .bootstrap-select.btn-group {
    margin-bottom: 0;
}

.checkbox-lg .bootstrap-select.btn-group.form-control,
.checkbox-sm .bootstrap-select.btn-group.form-control {
    padding: 0;
}

.checkbox-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.checkbox-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}

.form-inline .bootstrap-select.btn-group .form-control {
    width: 100%;
}

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
    cursor: not-allowed;
}

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
    outline: none !important;
}

.bootstrap-select.btn-group.bs-container {
    position: absolute;
    height: 0 !important;
    padding: 0 !important;
}

.bootstrap-select.btn-group.bs-container .dropdown-menu {
    z-index: 1060;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    overflow: hidden;
    width: 100%;
    line-height: 48px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    float: left;
    height: 48px;
    display: block;
}

.bootstrap-select.btn-group .dropdown-menu li.hidden {
    display: none;
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .filter-option,
.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    max-width: 100%;
    float: left;
}

.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    line-height: 20px;
    height: auto;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -2px;
    vertical-align: middle;
    transition: 0.35s;
}

.bootstrap-select.open .dropdown-toggle .caret {
    transform: rotate(180deg);
}

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
    width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
    min-width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0 5px;
    border-radius: 0;
    box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
    position: relative;
    margin-bottom: 1px;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled {
    opacity: 0.75;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
    color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
    cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
    display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left: 7px;
    opacity: 0.8;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: calc(100% - 20px);
    margin: 0;
    min-height: 26px;
    padding: 3px 5px;
    pointer-events: none;
    padding: 5px 10px;
    background: #333;
    color: #fff;
    margin: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 14px;
    border: none;
}

.bootstrap-select.btn-group .no-results {
    padding: 5px 10px;
    background: #f6f6f6;
    margin: 5px 0;
    margin-bottom: 0;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 14px;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
    position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
    position: static;
    top: auto;
    margin-top: -1px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    white-space: normal;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
    z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
    content: "";
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(204, 204, 204, 0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
    bottom: auto;
    top: -3px;
    border-top: 7px solid rgba(204, 204, 204, 0.2);
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
    bottom: auto;
    top: -3px;
    border-top: 6px solid #fff;
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
    right: 12px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
    display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
    padding: 0 5px;
}

.bs-actionsbox {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 5px;
    margin-bottom: 10px;
    margin-top: -5px;
}

.bs-actionsbox div {
    display: flex;
}

.bootstrap-select.btn-group .bs-actionsbox div button {
    flex: 1;
    background: #f6f6f6;
    box-shadow: none;
    padding: 5px;
    height: 36px;
    font-size: 14px;
    color: #777;
    transition: 0.3s;
}

.bootstrap-select.btn-group .bs-actionsbox div button:first-child {
    border-radius: 4px 0 0 4px;
    background-color: #f0f0f0;
}

.bootstrap-select.btn-group .bs-actionsbox div button:last-child {
    border-radius: 0 4px 4px 0;
}

.bootstrap-select.btn-group .bs-actionsbox div button:hover {
    background: $oceanBlue;
    color: #fff;
}

.bs-actionsbox .btn-group button {
    width: 50%;
}

.bs-donebutton {
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bs-donebutton .btn-group button {
    width: 100%;
}

.bs-searchbox + .bs-actionsbox {
    padding: 0;
}

.bs-searchbox .form-control:focus,
.bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
    box-shadow: none;
    background: #f4f4f4;
    margin: -5px 0 10px;
    height: 38px;
    line-height: 38px;
    padding: 10px;
}

.bs-searchbox {
    position: relative;
}

.bs-searchbox:after {
    font-family: material-icons;
    content: "\e982";
    font-size: 20px;
    width: 20px;
    height: 20px;
    line-height: 0;
    display: inline-block;
    position: absolute;
    margin: 0;
    color: #a0a0a0;
    right: 15px;
    top: 18px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid #aaa;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup,
.dropdown {
    position: relative;
}

.dropdown-toggle:focus {
    outline: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    padding: 10px 5px;
    margin: -4px 0 0;
    font-size: 15px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.dropdown-menu .divider {
    display: none;
}

.dropdown-menu > li > a {
    display: block;
    padding: 7px 10px;
    clear: both;
    font-weight: $font-weight-normal;
    line-height: 21px;
    color: gray;
    border-radius: 4px;
}

.bootstrap-select:before {
    content: "";
    position: absolute;
    z-index: 2100;
    bottom: 0;
    left: 0;
    height: 8px;
    width: 100%;
    background-color: #fff;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
}

.bootstrap-select.open:before {
    opacity: 1;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover {
    color: $oceanBlue;
    text-decoration: none;
    background-color: rgba(25, 140, 202, 0.07);
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover {
    color: #777;
}

.dropdown-menu > .disabled > a:hover {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
}

.dropdown-menu.inner::-webkit-scrollbar {
    width: 5px;
}

.dropdown-menu.inner::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 12px;
}

.dropdown-menu.inner::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #d8d8d8;
}

.dropdown-menu.inner::-webkit-scrollbar-thumb:hover {
    border-radius: 12px;
    background-color: #c8c8c8;
}

.bootstrap-select .dropdown-menu {
    opacity: 0;
    transition: 0.4s;
    display: block;
    pointer-events: none;
    visibility: hidden;
    cursor: default;
}

.bootstrap-select.open .dropdown-menu {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
}

.open > a {
    outline: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-header {
    display: block;
    padding: 7px 10px;
    line-height: 20px;
    white-space: nowrap;
    background: #f6f6f6;
    color: #777;
    border-radius: 4px;
    margin-bottom: 1px;
}

.dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
}

.pull-right > .dropdown-menu {
    right: 0;
    left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: 100%;
    bottom: auto;
    margin-bottom: 2px;
}

@media (min-width: 767px) {
    .navbar-right .dropdown-menu {
        right: 0;
        left: auto;
    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
}

.bootstrap-select.btn-group {
    position: relative;
}

.bootstrap-select.btn-group button {
    position: relative;
    display: inline-block;
    height: 48px;
    line-height: 0;
    padding: 0 20px;
    margin: 0;
    outline: none;
    font-size: 16px;
    color: gray;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    font-weight: $font-weight-normal;
    opacity: 1;
    border-radius: 4px;
    border: none;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
}

.hide-tick .check-mark {
    display: none !important;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
    position: absolute;
    display: inline-block;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    line-height: 0;
    width: 20px;
    height: 20px;
    text-align: center;
}

.bootstrap-select.btn-group .dropdown-menu li span.check-mark:before {
    font-family: feather-icons;
    content: "\e92b";
    font-size: 18px;
    transition: all 0.3s;
    display: inline-block;
    top: 50%;
    right: 0;
    position: absolute;
    margin: 0;
    opacity: 0;
    transform: scale(0.5);
}

.bootstrap-select.btn-group .dropdown-menu li.selected span.check-mark:before {
    opacity: 1;
    transform: scale(1);
    transition:
        all 0.35s cubic-bezier(0.3, -0.41, 0.19, 2),
        opacity 0.3s;
}

.bootstrap-select .dropdown-menu li.selected a span.check-mark:before,
.bootstrap-select .dropdown-menu li.selected:hover a span.check-mark:before {
    color: $oceanBlue;
}

.bootstrap-select .glyphicon:not(.check-mark) {
    font-size: 17px;
    line-height: 0;
    position: relative;
    top: 1px;
    margin-right: 3px;
}

.bootstrap-select .filter-option .glyphicon {
    position: relative;
    top: 1px;
}

.with-border .dropdown-menu,
.with-border.bootstrap-select.btn-group button {
    border: 1px solid $regularGrey;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
}

.with-border.bootstrap-select:before {
    width: calc(100% - 2px);
    left: 1px;
}

.snackbar-container {
    transition: all 500ms ease;
    transition-property: top, right, bottom, left, opacity;
    font-size: 16px;
    min-height: 14px;
    background-color: #e9f8f0;
    border-radius: 3px;
    border: 1px solid $green;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    line-height: 22px;
    padding: 18px 24px;
    padding-left: 45px;
    bottom: -100px;
    top: -100px;
    opacity: 0;
    z-index: 9999;
    cursor: default;
    font-weight: $font-weight-normal;

    &.alert-fail {
        border: 1px solid $required;
        padding-left: 15px;

        p {
            &:before {
                content: none;
            }
        }
    }

    p {
        color: $darkBlue !important;
        font-family: $font-family-content;
        -webkit-font-smoothing: antialiased;

        strong {
            font-weight: $font-weight-bold !important;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 18px;
            height: 18px;
            top: 16px;
            left: 15px;
            background-image: url(../assets/img/assets/green-white-checkmark.svg);
            background-size: cover;
        }
    }
    @media (min-width: 640px) {
        max-width: 750px;
    }
}

.snackbar-container .action {
    background: inherit;
    display: inline-block;
    border: none;
    font-size: inherit;
    text-transform: uppercase;
    color: #aaa;
    margin: 0 0 0 24px;
    padding: 0;
    min-width: min-content;
    cursor: pointer;
    font-weight: $font-weight-normal;
    font-size: 14px;
}

@media (min-width: 640px) {
    .snackbar-container {
        display: inline-flex;
        margin: 24px;
    }
}

.snackbar-pos.bottom-center {
    top: auto !important;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.snackbar-pos.bottom-left {
    top: auto !important;
    bottom: 0;
    left: 0;
}

.snackbar-pos.bottom-right {
    top: auto !important;
    bottom: 0;
    right: 0;
}

.snackbar-pos.top-left {
    bottom: auto !important;
    top: 0;
    left: 0;
}

.snackbar-pos.top-center {
    bottom: auto !important;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.snackbar-pos.top-right {
    bottom: auto !important;
    top: 0;
    right: 0;
}

@media (max-width: 640px) {
    .snackbar-container {
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0;
    }
    .snackbar-pos.bottom-center,
    .snackbar-pos.top-center {
        left: 0;
        transform: none;
    }
}

body .pac-item {
    font-size: 12px;
    color: #999;
    cursor: pointer;
    transition: 0.2s;
    padding: 5px 15px;
}

body .pac-container {
    border-radius: 0 0 4px 4px;
    border: none;
    box-sizing: content-box;
    margin-top: -2px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 100;
}

.infoBox {
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    line-height: 24px;
}

.infoBox .job-listing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    box-shadow: none;
    width: 100%;
}

.infoBox .job-listing .job-listing-description {
    padding-top: 0;
}

.infoBox .job-listing:hover {
    transform: none;
}

.infoBox .job-listing-details {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 25px;
}

.infoBox .job-listing h4.job-listing-company {
    font-size: 14px;
    position: relative;
    top: 0;
}

.infoBox .job-listing .verified-badge {
    transform: scale(0.85);
    right: -7px;
    bottom: -7px;
}

.infoBox .job-listing .not-verified-badge {
    display: none;
}

.infoBox .job-listing-footer {
    flex-grow: 0;
    padding: 15px 25px;
    font-size: 16px;
    display: none;
}

.infoBox .job-listing h3.job-listing-title {
    font-size: 16px;
    line-height: 24px;
}

.infoBox .job-listing .job-listing-company-logo {
    max-width: 46px;
    margin: 5px 18px 5px 0;
    top: 0;
    flex: 0 0 46px;
}

.infoBox .job-listing .job-listing-company-logo img {
    max-width: 46px;
}

.map-box {
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    position: relative;
    width: 100%;
}

.map-box .listing-img-container img {
    width: 100%;
    border-radius: 4px 4px 0 0;
}

.map-box h4 {
    margin: 0;
    padding: 0;
}

.map-box h4 a {
    padding: 0 0 2px;
    font-size: 17px;
    line-height: 25px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: inline-block;
}

.listing-img-container {
    position: relative;
    height: 100%;
    display: block;
}

.map-box h4 a:hover {
    color: $oceanBlue;
}

.map-box p {
    padding: 0;
    line-height: 25px;
    margin: 2px 0 0;
    font-size: 14px;
}

.map-box ul.listing-details {
    padding: 0;
    font-size: 14px;
    display: none;
}

.map-box .listing-title {
    padding: 16px 22px;
}

.map-box .listing-img-content {
    padding: 18px 22px;
}

.map-box .listing-img-content span {
    font-size: 21px;
}

.map-box .listing-img-content .listing-price i {
    font-size: 15px;
    margin: -7px 0 0;
}

.infoBox > img {
    position: absolute !important;
    right: 0;
    top: 0;
}

.map-box .listing-img-container::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: linear-gradient(
        to top,
        rgba(35, 35, 37, 0.85) 0%,
        rgba(35, 35, 37, 0.4) 35%,
        rgba(22, 22, 23, 0) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    background-color: rgba(35, 35, 37, 0.2);
}

.map-box .listing-item-content {
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 0 24px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
}

.map-box .listing-item-content h3 {
    color: #fff;
    font-size: 18px;
    padding: 0 0 2px;
    font-weight: $font-weight-normal;
    margin: 0;
    line-height: 27px;
}

.map-box .listing-item-content span {
    font-size: 15px;
    font-weight: $font-weight-normal;
    display: inline-block;
    color: rgba(255, 255, 255, 0.7);
}

.infoBox-close {
    position: absolute;
    top: 12px;
    right: 12px;
    display: inline-block;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: $font-weight-normal;
    height: 27px;
    width: 27px;
    line-height: 27px;
    background-color: #f0f0f0;
    color: #666;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
}

.infoBox-close i {
    position: relative;
    top: 2px;
    left: -0.5px;
}

.infoBox-close:hover {
    color: #fff;
    background-color: $oceanBlue;
}

.map-box-icon {
    height: 38px;
    width: 38px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #3685cf;
    display: block;
    color: #fff;
    font-size: 12px;
    z-index: 101;
    text-align: center;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
    visibility: hidden;
}

.map-box-icon:before {
    content: "\f064";
    font-family: fontawesome;
    position: relative;
    top: 7px;
    display: block;
}

.map-box-image:hover .map-box-icon {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateX(0);
}

.map-box-image {
    position: relative;
    overflow: hidden;
    display: block;
}

.infoBox {
    animation: infoBoxAnimation 0.5s;
}

@keyframes infoBoxAnimation {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.infoBox .star-rating span {
    margin-right: 3px;
}

.infoBox .rating-counter {
    position: relative;
    top: -1px;
}

.infoBox:after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #fff;
}

#map {
    height: 100%;
    width: 100%;
    margin: 0;
    z-index: 990;
}

#map-container {
    position: relative;
    top: 0;
    left: 0;
    height: 520px;
    width: 100%;
    z-index: 990;
    margin-bottom: 60px;
}

.gmnoprint {
    margin: 10px;
}

.homepage-map {
    height: 560px;
    margin-bottom: 0;
    position: relative;
}

.search-bar-on-map {
    position: absolute;
    bottom: 50px;
    z-index: 1000;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.cluster img {
    display: none;
}

.cluster-visible {
    text-align: center;
    font-size: 14px !important;
    color: #fff !important;
    font-weight: $font-weight-bold;
    border-radius: 50%;
    width: 33px !important;
    height: 33px !important;
    line-height: 33px !important;
    background-color: $oceanBlue;
    animation: clusterAnimation 2.5s infinite;
}

@keyframes clusterAnimation {
    0%,
    100% {
        box-shadow: 0 0 0 6px rgba(25, 140, 202, 0.15);
    }
    50% {
        box-shadow: 0 0 0 10px rgba(25, 140, 202, 0.15);
    }
}

.map-marker-container {
    position: absolute;
}

.marker-container {
    position: relative;
    margin: -9px 0 0 -9px;
    width: 18px;
    height: 18px;
    z-index: 1;
    border-radius: 50%;
    cursor: pointer;
    top: 0;
    background-color: $oceanBlue;
    animation: markerAnimation 2.5s infinite;
    transition: 0.4s;
}

@keyframes markerAnimation {
    0%,
    100% {
        box-shadow: 0 0 0 6px rgba(25, 140, 202, 0.15);
    }
    50% {
        box-shadow: 0 0 0 8px rgba(25, 140, 202, 0.15);
    }
}

.map-marker-container.clicked .marker-container {
    transform: scale(1.3);
}

.marker-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: transparent;
    border-radius: 50%;
    transform: scale(2);
    opacity: 0;
}

.custom-zoom-in,
.custom-zoom-out {
    background-color: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px 15px;
    transition: 0.3s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 18px;
    height: 34px;
    width: 34px;
}

.custom-zoom-in:hover,
.custom-zoom-out:hover {
    background-color: $oceanBlue;
    color: #fff;
}

.custom-zoom-in:before,
.custom-zoom-out:before {
    font-family: feather-icons;
    width: 100%;
    line-height: 35px;
}

.zoomControlWrapper {
    position: absolute;
    left: initial;
    right: 0;
    width: 70px;
    margin-right: 20px;
}

.custom-zoom-in:before {
    content: "\e9b1";
}

.custom-zoom-out:before {
    content: "\e996";
}

#streetView,
#geoLocation,
#scrollEnabling {
    background-color: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px 15px;
    transition: 0.3s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 99;
    font-size: 14px;
    line-height: 21px;
    padding: 8px 14px;
    font-weight: $font-weight-normal;
}

#geoLocation:hover,
#streetView:hover,
#scrollEnabling:hover,
#scrollEnabling.enabled {
    background-color: $oceanBlue;
    color: #fff;
}

#streetView:before {
    content: "\e974";
    font-family: material-icons;
    font-size: 18px;
    top: 3px;
    position: relative;
    margin-right: 7px;
    margin-left: -1px;
    line-height: 0;
}

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
    position: absolute;
    left: 0;
    top: 0;
}

.leaflet-container {
    overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}

.leaflet-safari .leaflet-tile {
    image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
    width: 1600px;
    height: 1600px;
    -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
    display: block;
}

.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
    max-width: none !important;
    max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
    -ms-touch-action: pan-x pan-y;
    touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
    -ms-touch-action: pinch-zoom;
    touch-action: none;
    touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    -ms-touch-action: none;
    touch-action: none;
}

.leaflet-tile {
    filter: inherit;
    visibility: hidden;
}

.leaflet-tile-loaded {
    visibility: inherit;
}

.leaflet-zoom-box {
    width: 0;
    height: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 800;
}

.leaflet-overlay-pane svg {
    -moz-user-select: none;
}

.leaflet-pane {
    z-index: 400;
}

.leaflet-tile-pane {
    z-index: 200;
}

.leaflet-overlay-pane {
    z-index: 400;
}

.leaflet-shadow-pane {
    z-index: 500;
}

.leaflet-marker-pane {
    z-index: 600;
}

.leaflet-tooltip-pane {
    z-index: 650;
}

.leaflet-popup-pane {
    z-index: 700;
}

.leaflet-map-pane canvas {
    z-index: 100;
}

.leaflet-map-pane svg {
    z-index: 200;
}

.leaflet-vml-shape {
    width: 1px;
    height: 1px;
}

.lvml {
    behavior: url(#default#VML);
    display: inline-block;
    position: absolute;
}

.leaflet-control {
    position: relative;
    z-index: 800;
    pointer-events: visiblePainted;
    pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
    position: absolute;
    z-index: 1000;
    pointer-events: none;
}

.leaflet-top {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}

.leaflet-right {
    right: 0;
}

.leaflet-bottom {
    bottom: 0;
}

.leaflet-left {
    left: initial;
    right: 15px;
}

.leaflet-control {
    float: left;
    clear: both;
}

.leaflet-right .leaflet-control {
    float: right;
}

.leaflet-top .leaflet-control {
    margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
    margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
    margin-left: 10px;
}

.leaflet-right .leaflet-control {
    margin-right: 10px;
}

.leaflet-fade-anim .leaflet-tile {
    will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    opacity: 1;
}

.leaflet-zoom-animated {
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
    will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
    visibility: hidden;
}

.leaflet-interactive {
    cursor: pointer;
}

.leaflet-grab {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
    cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
    cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
    pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
    pointer-events: visiblePainted;
    pointer-events: auto;
}

.leaflet-bar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
}

.leaflet-bar a.leaflet-disabled {
    cursor: default;
    pointer-events: none;
}

.leaflet-touch .leaflet-bar a {
    width: 34px;
    height: 34px;
    line-height: 34px;
}

.leaflet-touch .leaflet-bar a i {
    line-height: 34px;
    left: -1px;
    position: relative;
}

body #submit_map {
    outline: none;
    margin-bottom: 30px;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font:
        700 18px lucida console,
        Monaco,
        monospace;
    text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
    font-size: 15px;
    text-align: center;
}

.leaflet-control-layers {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: #fff;
    border-radius: 5px;
}

.leaflet-control-layers-toggle {
    // background-image: url(../assets/images/layers.png);
    width: 36px;
    height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
    // background-image: url(../assets/images/layers-2x.png);
    background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
    display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
    display: block;
    position: relative;
}

.leaflet-control-layers-expanded {
    padding: 6px 10px 6px 6px;
    color: #333;
    background: #fff;
}

.leaflet-control-layers-scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}

.leaflet-control-layers-selector {
    margin-top: 2px;
    position: relative;
    top: 1px;
}

.leaflet-control-layers label {
    display: block;
}

.leaflet-control-layers-separator {
    height: 0;
    border-top: 1px solid #ddd;
    margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
    // background-image: url(../assets/images/marker-icon.png)
}

.leaflet-container .leaflet-control-attribution {
    background: #fff;
    margin: 10px;
    border-radius: 3px;
    padding: 4px 8px;
    color: #777;
    font-weight: $font-weight-normal;
    line-height: 15px;
}

.leaflet-container .leaflet-control-attribution a {
    color: #333;
    text-decoration: none !important;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
    padding: 0 5px;
    color: #333;
}

.leaflet-control-attribution a {
    text-decoration: none;
}

.leaflet-control-attribution a:hover {
    text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
    font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
    margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
    margin-bottom: 5px;
}

.leaflet-control-scale-line {
    border: 2px solid #777;
    border-top: none;
    line-height: 1.1;
    padding: 2px 5px 1px;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
    border-top: 2px solid #777;
    border-bottom: none;
    margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
    border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    background-clip: padding-box;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    background-color: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px 15px;
    transition: 0.3s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 18px;
    height: 34px;
    width: 34px;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
    background-color: $oceanBlue;
    color: #fff;
}

.leaflet-control-zoom-in:before,
.leaflet-control-zoom-out:before {
    font-family: feather-icons;
    width: 100%;
    line-height: 35px;
}

.leaflet-control-zoom-in:before {
    content: "\e9b1";
}

.leaflet-control-zoom-out:before {
    content: "\e996";
}

.marker-container:hover .marker-card,
.clicked .marker-container .marker-card,
.leaflet-marker-icon.clicked .marker-container {
    transform: scale(1.3);
}

.leaflet-popup {
    position: absolute;
    text-align: center;
    margin-bottom: 25px;
}

.leaflet-popup-content-wrapper {
    padding: 0;
    text-align: left;
    border-radius: 4px;
}

.leaflet-popup-content {
    width: 270px;
}

.leaflet-popup-content p {
    margin: 0;
}

.leaflet-listing-img-container img {
    border-radius: 4px 4px 0 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.leaflet-listing-img-container {
    position: relative;
    height: 210px;
    display: block;
    border-radius: 4px;
    overflow: hidden;
}

.leaflet-listing-img-container:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: linear-gradient(
        to top,
        rgba(35, 35, 37, 0.85) 0%,
        rgba(35, 35, 37, 0.4) 35%,
        rgba(22, 22, 23, 0) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    background-color: rgba(35, 35, 37, 0.2);
}

.leaflet-listing-item-content {
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 0 24px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
}

.leaflet-listing-item-content h3 {
    color: #fff;
    font-size: 18px;
    padding: 0 0 2px;
    font-weight: $font-weight-normal;
    margin: 0;
    line-height: 25px;
}

.leaflet-listing-content {
    padding: 16px 22px;
    width: 100%;
    z-index: 50;
    box-sizing: border-box;
    position: relative;
}

.leaflet-listing-content .not-rated {
    color: #888;
}

.leaflet-listing-content .star-rating {
    font-size: 16px;
    margin-top: 1px;
    display: block;
    line-height: 18px;
}

.leaflet-listing-content .numerical-rating {
    margin: 0;
}

.leaflet-listing-item-content span {
    font-size: 15px;
    font-weight: $font-weight-normal;
    display: inline-block;
    color: rgba(255, 255, 255, 0.7);
    line-height: 24px;
    display: block;
}

.leaflet-popup-tip-container {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    overflow: hidden;
    pointer-events: none;
    background: 0 0;
}

.leaflet-popup-tip {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #fff;
}

.leaflet-popup-content-wrapper {
    background: #fff;
    color: #333;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    display: inline-block;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: $font-weight-normal;
    height: 27px;
    width: 27px;
    line-height: 27px;
    background-color: #f0f0f0;
    color: transparent;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
}

.leaflet-container a.leaflet-popup-close-button:before {
    position: absolute;
    font-family: feather-icons;
    content: "\ea02";
    color: #666;
    transition: all 0.2s ease-in-out;
    left: 0;
    right: 0;
    top: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover {
    background-color: $oceanBlue;
}

.leaflet-container a.leaflet-popup-close-button:hover:before {
    color: #fff;
}

.leaflet-popup-scrolled {
    overflow: auto;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
    zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
    width: 24px;
    margin: 0 auto;
    // -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
    // filter: progid: DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)
}

.leaflet-oldie .leaflet-popup-tip-container {
    margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
    border: 1px solid #999;
}

.leaflet-div-icon {
    background: #fff;
    border: 1px solid #666;
}

.leaflet-popup-content-wrapper .job-listing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    box-shadow: none;
    width: 100%;
}

.leaflet-popup-content-wrapper .job-listing .job-listing-description {
    padding-top: 0;
}

.leaflet-popup-content-wrapper .job-listing:hover {
    transform: none;
}

.leaflet-popup-content-wrapper .job-listing-details {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 25px;
}

.leaflet-popup-content-wrapper .job-listing h4.job-listing-company {
    font-size: 14px;
    position: relative;
    top: 0;
}

.leaflet-popup-content-wrapper .job-listing .verified-badge {
    transform: scale(0.85);
    right: -7px;
    bottom: -7px;
}

.leaflet-popup-content-wrapper .job-listing .not-verified-badge {
    display: none;
}

.leaflet-popup-content-wrapper .job-listing-footer {
    flex-grow: 0;
    padding: 15px 25px;
    font-size: 16px;
    display: none;
}

.leaflet-popup-content-wrapper .job-listing h3.job-listing-title {
    font-size: 16px;
    line-height: 24px;
}

.leaflet-popup-content-wrapper .job-listing .job-listing-company-logo {
    max-width: 46px;
    margin: 5px 18px 5px 0;
    top: 0;
    flex: 0 0 46px;
}

.leaflet-popup-content-wrapper .job-listing .job-listing-company-logo img {
    max-width: 46px;
}

.leaflet-tooltip {
    position: absolute;
    padding: 6px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    color: #222;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
    cursor: pointer;
    pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: 0 0;
    content: "";
}

.leaflet-tooltip-bottom {
    margin-top: 6px;
}

.leaflet-tooltip-top {
    margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
    left: 50%;
    margin-left: -6px;
}

.leaflet-tooltip-top:before {
    bottom: 0;
    margin-bottom: -12px;
    border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
    top: 0;
    margin-top: -12px;
    margin-left: -6px;
    border-bottom-color: #fff;
}

.leaflet-tooltip-left {
    margin-left: -6px;
}

.leaflet-tooltip-right {
    margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    top: 50%;
    margin-top: -6px;
}

.leaflet-tooltip-left:before {
    right: 0;
    margin-right: -12px;
    border-left-color: #fff;
}

.leaflet-tooltip-right:before {
    left: 0;
    margin-left: -12px;
    border-right-color: #fff;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition:
        -webkit-transform 0.3s ease-out,
        opacity 0.3s ease-in;
    -moz-transition:
        -moz-transform 0.3s ease-out,
        opacity 0.3s ease-in;
    -o-transition:
        -o-transform 0.3s ease-out,
        opacity 0.3s ease-in;
    transition:
        transform 0.3s ease-out,
        opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
    -webkit-transition:
        -webkit-stroke-dashoffset 0.3s ease-out,
        -webkit-stroke-opacity 0.3s ease-in;
    -moz-transition:
        -moz-stroke-dashoffset 0.3s ease-out,
        -moz-stroke-opacity 0.3s ease-in;
    -o-transition:
        -o-stroke-dashoffset 0.3s ease-out,
        -o-stroke-opacity 0.3s ease-in;
    transition:
        stroke-dashoffset 0.3s ease-out,
        stroke-opacity 0.3s ease-in;
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
    background-color: transparent;
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-cluster div {
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    font-weight: $font-weight-bold;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: $oceanBlue;
    position: relative;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
    background-color: $oceanBlue;
}

.marker-cluster div:before {
    border: 7px solid $oceanBlue;
    opacity: 0.2;
    box-shadow: inset 0 0 0 4px $oceanBlue;
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {
    0%,
    100% {
        transform: scale(1.3) rotate(0deg);
    }
    50% {
        transform: scale(1.5) rotate(90deg);
    }
}

.marker-cluster span {
    font-family: Arial, sans-serif;
    line-height: 36px;
}

.leaflet-control-geocoder {
    border-radius: 4px;
    background: #fff;
    min-width: 26px;
    min-height: 26px;
}

.leaflet-touch .leaflet-control-geocoder {
    min-width: 30px;
    min-height: 30px;
}

.leaflet-control-geocoder a,
.leaflet-control-geocoder .leaflet-control-geocoder-icon {
    border-bottom: none;
    display: inline-block;
}

.leaflet-control-geocoder .leaflet-control-geocoder-alternatives a {
    width: inherit;
    height: inherit;
    line-height: inherit;
}

.leaflet-control-geocoder a:hover,
.leaflet-control-geocoder .leaflet-control-geocoder-icon:hover {
    border-bottom: none;
    display: inline-block;
}

.leaflet-control-geocoder-form {
    display: none;
    vertical-align: middle;
}

.leaflet-control-geocoder-expanded .leaflet-control-geocoder-form {
    display: inline-block;
}

.leaflet-control-geocoder-form input {
    font-size: 120%;
    border: 0;
    background-color: transparent;
    width: 246px;
}

.leaflet-control-geocoder-icon {
    border-radius: 4px;
    width: 26px;
    height: 26px;
    border: none;
    background-color: #fff;
    // background-image: url(../assets/images/geocoder.png);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.leaflet-touch .leaflet-control-geocoder-icon {
    width: 30px;
    height: 30px;
}

.leaflet-control-geocoder-throbber .leaflet-control-geocoder-icon {
    // background-image: url(../assets/images/throbber.gif)
}

.leaflet-control-geocoder-form-no-error {
    display: none;
}

.leaflet-control-geocoder-form input:focus {
    outline: none;
}

.leaflet-control-geocoder-form button {
    display: none;
}

.leaflet-control-geocoder-error {
    margin-top: 8px;
    margin-left: 8px;
    display: block;
    color: #444;
}

.leaflet-control-geocoder-alternatives {
    display: block;
    width: 272px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.leaflet-control-geocoder-alternatives-minimized {
    display: none;
    height: 0;
}

.leaflet-control-geocoder-alternatives li {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    padding: 5px 8px;
    text-overflow: ellipsis;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.leaflet-control-geocoder-alternatives li a,
.leaflet-control-geocoder-alternatives li a:hover {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: inherit;
    border-radius: inherit;
    text-align: left;
}

.leaflet-control-geocoder-alternatives li:last-child {
    border-bottom: none;
}

.leaflet-control-geocoder-alternatives li:hover,
.leaflet-control-geocoder-selected {
    background-color: #f5f5f5;
}

.leaflet-control-geocoder-address-detail {
}

.leaflet-control-geocoder-address-context {
    color: #666;
}

@-webkit-keyframes leaflet-gestures-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes leaflet-gestures-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.leaflet-container:after {
    -webkit-animation: leaflet-gestures-fadein 0.8s backwards;
    animation: leaflet-gestures-fadein 0.8s backwards;
    color: #fff;
    font-family: roboto, Arial, sans-serif;
    font-size: 22px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 461;
    pointer-events: none;
}

.leaflet-gesture-handling-touch-warning:after,
.leaflet-gesture-handling-scroll-warning:after {
    -webkit-animation: leaflet-gestures-fadein 0.8s forwards;
    animation: leaflet-gestures-fadein 0.8s forwards;
}

.leaflet-gesture-handling-touch-warning:after {
    content: attr(data-gesture-handling-touch-content);
}

.leaflet-gesture-handling-scroll-warning:after {
    content: attr(data-gesture-handling-scroll-content);
}

#leaflet-geocode-cont {
    position: absolute;
    z-index: 110;
    background: #fff;
    border: none;
    border-radius: 0 0 4px 4px;
    top: 46px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    display: none;
    width: 100%;
    border-top: 1px solid #e8e8e8;
}

#leaflet-geocode-cont:before {
    content: "";
    width: 100%;
    height: 4px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: -5px;
}

#leaflet-geocode-cont.active {
    display: block;
}

#leaflet-geocode-cont ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#leaflet-geocode-cont ul li {
    color: #888;
    font-size: 13px;
    font-weight: $font-weight-normal;
    line-height: 20px;
    padding: 11px 15px;
    padding-left: 38px;
    margin: 0;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
}

#leaflet-geocode-cont ul li:before {
    content: "\f041";
    font-family: fontawesome;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 20px;
    margin-right: 0;
    color: #aaa;
    transition: 0.3s;
}

#leaflet-geocode-cont ul li:hover:before {
    color: #a0a0a0;
}

#leaflet-geocode-cont ul li:last-child {
    border: none;
    border-radius: 0 0 4px 4px;
}

#leaflet-geocode-cont ul li.selected,
#leaflet-geocode-cont ul li:hover {
    background: $lightGrey;
}

#leaflet-geocode-cont span.highlight {
    color: #222;
    font-weight: $font-weight-bold;
}

.type-and-hit-enter {
    display: block;
    position: absolute;
    right: 16px;
    top: 13px;
    z-index: 120;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-size: 13px;
    font-weight: $font-weight-normal;
    color: #888;
    line-height: 18px;
    padding: 3px 7px;
    pointer-events: none;
    opacity: 0;
    transition: 0.4s;
    transform: translateX(4px);
}

@media (min-width: 992px) {
    .intro-banner-search-form .type-and-hit-enter {
        top: 23px;
        right: 18px;
    }
    .intro-banner-search-form #leaflet-geocode-cont {
        width: calc(100% + 24px);
        left: -12px;
        top: calc(100% + 10px);
    }
}

@media (max-width: 992px) {
    .intro-banner-search-form #leaflet-geocode-cont {
        top: calc(100% + 0px);
    }
    .intro-banner-search-form .type-and-hit-enter {
        top: 17px;
        right: 14px;
    }
}

.tip-visible-focusin.type-and-hit-enter,
.tip-visible.type-and-hit-enter {
    opacity: 1;
    transform: translateX(0);
}

.main-search-container.alt-search-box #leaflet-geocode-cont {
    top: 51px;
}

.main-search-input.gray-style .type-and-hit-enter,
.main-search-inner .type-and-hit-enter {
    top: 11px;
    right: 25px;
}

.main-search-container.alt-search-box .type-and-hit-enter {
    right: 19px;
    top: 14px;
}

@media (min-width: 992px) {
    .main-search-input .osm-dropdown-active #leaflet-geocode-cont,
    .main-search-container:not(.alt-search-box) .osm-dropdown-active #leaflet-geocode-cont {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
        border: none;
        padding-top: 45px;
        top: 0;
        z-index: 10;
        border-radius: 4px;
    }
    .main-search-input.gray-style .osm-dropdown-active #leaflet-geocode-cont {
        box-shadow: none;
        border: 1px solid $regularGrey;
    }
    .main-search-input .osm-dropdown-active #leaflet-geocode-cont ul li:first-child,
    .main-search-container:not(.alt-search-box) .osm-dropdown-active #leaflet-geocode-cont ul li:first-child {
        border-top: 1px solid #e8e8e8;
    }
}

@media (max-width: 992px) {
    .main-search-container:not(.alt-search-box) #leaflet-geocode-cont {
        top: 52px;
    }
    .main-search-input.gray-style .type-and-hit-enter,
    .main-search-container:not(.alt-search-box) .type-and-hit-enter {
        top: 16px;
        right: 20px;
    }
}

#leaflet-geocode-cont ul li.powered-by-osm {
    display: block;
    padding: 9px 0;
    padding-right: 20px;
    font-size: 12px;
    font-weight: $font-weight-normal;
    color: #999;
    pointer-events: none;
    text-align: right;
}

#leaflet-geocode-cont ul li.powered-by-osm strong {
    color: #333;
    position: relative;
    padding-left: 26px;
}

#leaflet-geocode-cont ul li.powered-by-osm strong:before {
    background-image: url(https://i.imgur.com/YaiaYGw.png);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 17px;
    width: 17px;
    display: inline-block;
    position: absolute;
    left: 5px;
    top: -2px;
}

#leaflet-geocode-cont ul li.powered-by-osm:before {
    content: "";
}

.leaflet-control-geocoder {
    border-radius: 4px;
    background: #fff;
    min-width: 26px;
    min-height: 26px;
}

.leaflet-touch .leaflet-control-geocoder {
    min-width: 30px;
    min-height: 30px;
}

.leaflet-control-geocoder a,
.leaflet-control-geocoder .leaflet-control-geocoder-icon {
    border-bottom: none;
    display: inline-block;
}

.leaflet-control-geocoder .leaflet-control-geocoder-alternatives a {
    width: inherit;
    height: inherit;
    line-height: inherit;
}

.leaflet-control-geocoder a:hover,
.leaflet-control-geocoder .leaflet-control-geocoder-icon:hover {
    border-bottom: none;
    display: inline-block;
}

.leaflet-control-geocoder-form {
    display: none;
    vertical-align: middle;
}

.leaflet-control-geocoder-expanded .leaflet-control-geocoder-form {
    display: inline-block;
}

.leaflet-control-geocoder-form input {
    font-size: 120%;
    border: 0;
    background-color: transparent;
    width: 246px;
}

.leaflet-control-geocoder-icon {
    border-radius: 4px;
    width: 26px;
    height: 26px;
    border: none;
    background-color: #fff;
    // background-image: url(../assets/images/geocoder.png);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.leaflet-touch .leaflet-control-geocoder-icon {
    width: 30px;
    height: 30px;
}

.leaflet-control-geocoder-throbber .leaflet-control-geocoder-icon {
    // background-image: url(../assets/images/throbber.gif)
}

.leaflet-control-geocoder-form-no-error {
    display: none;
}

.leaflet-control-geocoder-form input:focus {
    outline: none;
}

.leaflet-control-geocoder-form button {
    display: none;
}

.leaflet-control-geocoder-error {
    margin-top: 8px;
    margin-left: 8px;
    display: block;
    color: #444;
}

.leaflet-control-geocoder-alternatives {
    display: block;
    width: 272px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.leaflet-control-geocoder-alternatives-minimized {
    display: none;
    height: 0;
}

.leaflet-control-geocoder-alternatives li {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    padding: 5px 8px;
    text-overflow: ellipsis;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.leaflet-control-geocoder-alternatives li a,
.leaflet-control-geocoder-alternatives li a:hover {
    width: inherit;
    height: inherit;
    line-height: inherit;
    background: inherit;
    border-radius: inherit;
    text-align: left;
}

.leaflet-control-geocoder-alternatives li:last-child {
    border-bottom: none;
}

.leaflet-control-geocoder-alternatives li:hover,
.leaflet-control-geocoder-selected {
    background-color: #f5f5f5;
}

.leaflet-control-geocoder-address-detail {
}

.leaflet-control-geocoder-address-context {
    color: #666;
}

#streetView {
    z-index: 999;
}

#map,
#singleListingMap {
    outline: none;
}

.boxed-list {
    margin-top: 10px;
    display: block;
}

.boxed-list-headline {
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 20px 35px;
}

.boxed-list-headline h3 {
    font-size: 18px;
}

.boxed-list-headline h3 span {
    color: #888;
}

.boxed-list-headline h3 i {
    font-size: 18px;
    color: $oceanBlue;
    font-size: 24px;
    position: relative;
    top: 3px;
    line-height: 0;
    display: inline-block;
    margin-right: 5px;
}

.boxed-list ul.boxed-list-ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.boxed-list ul.boxed-list-ul > li {
    display: block;
    background-color: transparent;
    padding: 35px;
    border-radius: 4px;
}

.boxed-list ul.boxed-list-ul > li:nth-child(2n) {
    background-color: $lightGrey;
}

.fields-ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.fields-ul > li {
    display: block;
    background-color: transparent;
    padding: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
}

.boxed-list-item {
    display: flex;
}

.boxed-list-item .item-image {
    flex: 0 0 60px;
    height: 60px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-right: 25px;
    position: relative;
    top: -2px;
    display: flex;
    background-color: #fff;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .boxed-list-item .item-image {
        max-width: 60px;
    }
    .boxed-list-item .item-image img {
        width: 100%;
    }
}

.boxed-list-item .item-image img {
    height: auto;
    align-self: center;
}

.boxed-list-item .item-content h4 a,
.boxed-list-item .item-content h4 {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: #333;
}

.boxed-list-item .item-content h4 span {
    font-size: 16px;
    color: gray;
    display: block;
    font-weight: $font-weight-normal;
}

.boxed-list-item .item-details {
    display: flex;
    margin-top: 5px;
}

.boxed-list-item .item-details .star-rating {
    top: -2px;
    position: relative;
    margin-right: 15px;
}

.boxed-list-item .item-details .detail-item {
    margin-right: 15px;
    color: gray;
}

.boxed-list-item .item-details .detail-item a {
    color: gray;
}

.boxed-list-item .item-details .detail-item a:hover {
    color: $oceanBlue;
}

.boxed-list-item .item-details .detail-item i {
    margin-right: 3px;
    position: relative;
    top: 0;
}

.boxed-list-item .item-description p {
    margin-bottom: 0;
    padding-bottom: 0;
}

.boxed-list-item .item-description {
    margin-top: 15px;
}

.bid {
    display: flex;
    width: 100%;
}

.bid .bids-content {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.bid .bids-bid {
    align-items: center;
    display: flex;
}

.bid .bids-avatar {
    align-self: center;
}

.bid .freelancer-name {
    margin-top: 0;
}

.bid .freelancer-avatar {
    width: 80px;
    margin: 0 auto;
    position: relative;
    margin-right: 30px;
}

.bid .star-rating {
    margin-top: 3px;
    display: block;
}

.bid .freelancer-avatar img {
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
}

.bid .freelancer-avatar .verified-badge {
    position: absolute;
    bottom: 0;
    right: 0;
}

.bid-rate {
    text-align: center;
    font-size: 14px;
    background: #f4f4f4;
    color: #888;
    border-radius: 4px;
    padding: 8px 12px;
}

.boxed-list ul.boxed-list-ul > li:nth-child(2n) .bid-rate {
    background: #f2f2f2;
}

.bid-rate .rate {
    color: #333;
    font-weight: $font-weight-bold;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
    padding: 5px 10px;
    padding-bottom: 0;
    font-size: 16px;
}

.bid-rate span {
    display: block;
    margin-top: -2px;
}

@media (max-width: 768px) {
    .bid .freelancer-avatar {
        width: 70px;
        margin: 0 0 15px;
    }
    .bid {
        display: block;
    }
    .bids-bid {
        margin-top: 15px;
    }
    .bid-rate {
        width: 100%;
    }
}

.job-overview {
    border-radius: 4px;
    background-color: #f9f9f9;
}

.job-overview .job-overview-headline {
    color: #333;
    font-size: 20px;
    padding: 20px 35px;
    background-color: #f0f0f0;
    color: #333;
    position: relative;
    border-radius: 4px 4px 0 0;
}

.job-overview .job-overview-inner {
    padding: 35px;
}

.job-overview .job-overview-inner ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.job-overview .job-overview-inner ul li {
    position: relative;
    display: block;
    font-size: 16px;
    padding-left: 38px;
    margin-bottom: 25px;
}

.job-overview .job-overview-inner ul li:last-child {
    margin-bottom: 0;
}

.job-overview .job-overview-inner ul li span {
    font-weight: $font-weight-bold;
    color: #333;
    margin: 0;
    padding: 0;
    display: block;
}

.job-overview .job-overview-inner ul li h5 {
    font-weight: $font-weight-normal;
    color: #666;
    font-size: 16px;
    margin: 0;
    padding: 0;
    line-height: 20px;
}

.job-overview .job-overview-inner ul li i {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 24px;
    color: $oceanBlue;
}

a.apply-now-button {
    background-color: $oceanBlue;
    color: #fff;
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
    padding: 14px 20px;
    margin-bottom: 35px;
    box-shadow: 0 2px 8px rgba(25, 140, 202, 0.15);
    transition: 0.3s;
    display: block;
}

a.apply-now-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 2px 8px rgba(25, 140, 202, 0.35);
}

a.apply-now-button i {
    font-size: 22px;
    position: relative;
    line-height: 0;
    top: 3px;
    margin-left: 5px;
}

#single-job-map-container {
    position: relative;
}

#single-job-map-container #singleListingMap {
    position: relative;
    height: 340px;
    border-radius: 4px;
}

.freelancer-socials ul {
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: default;
    margin: 0 0 -5px -8px;
}

.freelancer-socials ul li {
    display: inline-block;
    font-size: 24px;
}

.freelancer-socials ul li a {
    color: #b9b9b9;
    padding: 0 8px;
}

.freelancer-socials ul li a:hover {
    color: #333;
}

.attachments-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% + 20px);
}

.attachment-box {
    display: flex;
    margin: 0 20px 20px 0;
    background-color: #ffffff;
    border-radius: 4px 0 4px 4px;
    font-weight: $font-weight-bold;
    padding: 15px 20px;
    padding-bottom: 45px;
    padding-right: 25px;
    line-height: 24px;
    flex-direction: column;
    color: #666;
    position: relative;
    transition: 0.3s;
    cursor: default;
    position: relative;
    width: 100%;
}

.single-page-section .attachment-box {
    flex: 0 1 calc(33% - 20px);
}

.single-page-section .attachments-container {
    margin-bottom: -20px;
}

a.attachment-box {
    cursor: pointer;
}

.attachment-box:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.15) $lightGrey;
    transition: 0.3s;
    border-radius: 0 0 0 4px;
}

a.attachment-box:hover {
    background-color: $oceanBlue;
    color: #fff;

    span {
        color: white;
    }

    &:before {
        border-color: rgba(0, 0, 0, 0.15) $lightGrey;
    }
}

.attachment-box span {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    flex: auto;
    color: $darkBlue;
    font-weight: $font-weight-bold;
}

.attachment-box i {
    display: block;
    font-style: normal;
    font-size: 14px;
    color: $borderGray;
    font-weight: $font-weight-normal;
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    transition: 0.3s;
}

a.attachment-box:hover i {
    color: rgba(255, 255, 255, 0.7);
}

.attachment-box .remove-attachment {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #fff;
    background-color: #dc3139;
    box-shadow: 0 3px 8px rgba(234, 65, 81, 0.15);
    height: 28px;
    width: 28px;
    line-height: 28px;
    border-radius: 3px;
    font-weight: $font-weight-normal;
    font-size: 14px;
    transition: 0.3s;
    opacity: 0;
    transform: translateY(3px);
}

.attachment-box .remove-attachment:before {
    content: "\e9e4";
    font-family: feather-icons;
}

.attachment-box:hover .remove-attachment {
    opacity: 1;
    transform: translateY(0);
}

.profile-overview {
    display: flex;
    line-height: 22px;
    align-items: center;
    margin-bottom: 25px;
    width: calc(100% + 25px);
}

.profile-overview .overview-item {
    flex: 1;
    border-right: 1px solid $regularGrey;
    margin-right: 20px;
    padding-right: 20px;
    flex-grow: 1;
}

.profile-overview .overview-item:last-child {
    border-right: 0;
    padding-right: 0;
}

.profile-overview .overview-item strong {
    display: block;
    font-weight: $font-weight-bold;
    color: #333;
    font-size: 18px;
    margin-bottom: 5px;
}

.profile-overview .overview-item span {
    color: gray;
}

.freelancer-indicators {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    line-height: 24px;
    margin-bottom: -20px;
}

.freelancer-indicators .indicator {
    width: calc(50% - 31px);
    margin-right: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.freelancer-indicators .indicator strong {
    color: #333;
    font-weight: $font-weight-bold;
}

.freelancer-indicators .indicator-bar span,
.freelancer-indicators .indicator-bar {
    width: 100%;
    height: 4px;
    background-color: $regularGrey;
    border-radius: 10px;
    position: relative;
    margin: 10px 0;
    transition: 1s;
}

.freelancer-indicators .indicator-bar span {
    width: 0;
    background-color: $oceanBlue;
    position: absolute;
    top: 0;
    margin: 0;
}

@media (max-width: 1240px) {
    .profile-overview,
    .profile-overview .overview-item {
        display: block;
        width: 100%;
    }
    .profile-overview .overview-item {
        border-right: 0;
        border-bottom: 1px solid $regularGrey;
        padding: 15px 0;
        margin: 0;
    }
    .profile-overview .overview-item:last-child {
        border-right: none;
        border-bottom: none;
    }
}

@media (max-width: 768px) {
    .boxed-list-item .item-details,
    .attachments-container,
    .attachments-container .attachment-box,
    .freelancer-indicators,
    .freelancer-indicators .indicator {
        display: block;
        width: 100%;
    }
    .boxed-list-item .item-image {
        display: none;
    }
}

.full-page-container,
.dashboard-container {
    height: calc(100vh - 70px);
}

@media (max-width: 1099px) {
    .full-page-container,
    .dashboard-container {
        height: calc(100vh - 76px);
    }
}

.dashboard-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.dashboard-sidebar {
    flex: 0 0 240px;
    background-color: $lightGrey;
    position: relative;
    border-right: 1px solid $regularGrey;
    z-index: 100;
}

.dashboard-sidebar .dashboard-sidebar-inner {
    // overflow: auto;
    width: 240px;
    height: 100%;
    overflow-y: auto !important;
    // height: 100vh;
    position: fixed;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media (max-width: 640px) {
        position: relative;
        width: 100%;
        overflow: hidden !important;
    }
    @media (max-width: 1024px) {
        position: relative;
        width: 100%;
        overflow: hidden !important;
    }
}

.dashboard-sidebar-inner::-webkit-scrollbar {
    display: none;
}

.dashboard-content-container {
    flex: 1;
    background-color: $lightGrey;
    padding: 0;
    overflow: auto;
    position: relative;
    z-index: 99;
    margin-top: 60px;
    height: 100%;
    @media (max-width: 1024px) {
        margin-top: 0px;
    }
    &.gray {
        background-color: #f9fafc;
    }
}

.dashboard-content-container-alt {
    flex: 1;
    background-color: #fff;
    padding: 0;
    overflow: auto;
    position: relative;
    z-index: 99;
    height: 100%;
    @media (max-width: 1024px) {
        margin-top: 0px;
    }
    &.gray {
        background-color: #f9fafc;
    }
}

.dashboard-content-inner {
    padding: 0px 40px;
    padding-bottom: 0;
    position: relative;
    // padding-top: 40px;

    .hide {
        display: none;
    }

    .bottom-fixed-buttons {
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        padding: 0px 50px;
        border-top: 1px solid #e1e3eb;
        z-index: 2;

        a:hover {
            background-color: #24b768;
            color: white;
            border: 1px solid #24b768;
        }

        @media (max-width: 768px) {
            padding: 0px 30px;
        }
    }

    .quickstart {
        position: fixed;
        bottom: 100px;
        right: 65px;
        align-items: center;

        .quickstart-btn {
            position: relative;
            background-color: #fff;
            color: #546285;
            font-size: 12px;
            box-shadow: 0px 8px 16px #1a284d1f;
            border-radius: 24px;
            overflow: hidden;
            display: inline-flex;
            padding: 10px 30px 10px 40px;
            font-weight: $font-weight-bold;

            &:hover {
                background-color: #f9fafc;
            }

            &:before {
                content: "";
                display: block;
                width: 17px;
                height: 17px;
                background-image: url(../assets/img/assets/bulb.svg);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: 14px;
                left: 17px;
            }
        }
    }
}

@media (min-width: 1024px) {
    #header-container.dashboard-header .container {
        padding-right: 35px;
        padding-left: 0;
    }
    #header-container.dashboard-header #logo {
        width: 240px;
        padding-left: 23px;
    }
}

.dashboard-nav {
    display: block;
    z-index: 11;
    padding-right: 7px;
    padding-left: 7px;
    height: 100%;
    margin-top: 75px;
}

button.dashboard-responsive-nav-trigger {
    display: none;
}

// .dashboard-nav ul:first-child {
//     margin: 30px 0 0;
// }
// .dashboard-nav ul:last-child {
//     @media (min-width: 1024px) and (min-height:880px) {
//         bottom: 0px;
//         position: fixed;
//     }
// }

.dashboard-nav ul {
    list-style: none;
    padding: 0;
    margin: 0 0 25px;
    position: relative;

    &:before {
        font-family: $font-family-content;
        content: attr(data-submenu-title);
        padding: 0 15px;
        margin-bottom: 5px;
        display: block;
        color: $borderGray;
        font-weight: $font-weight-bold !important;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }
}

.dashboard-nav ul.locked {
    li {
        pointer-events: none;

        a {
            color: $borderGray;
        }
    }
    &:after {
        background-image: url(../assets/svg/lock.svg);
        content: " ";
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        right: 20px;
        top: 5px;
        height: 20px;
        width: 20px;
    }
}

.dashboard-nav ul li {
    // display: block;
    // border-left: 3px solid transparent;
    line-height: 17px;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;

    ul {
        li {
            font-size: 13px;
        }
    }

    .sidebar-item-link.has-children {
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            background-image: url("../assets/img/assets/icon-dropdown-arrow.svg");
            background-size: cover;
            background-repeat: no-repeat;
            right: 20px;
        }
    }
}

.dashboard-nav ul li .sidebar-item-link {
    color: $darkBlue;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    position: relative;
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    width: 100%;
    height: 48px;
    font-weight: $font-weight-medium;
}

.dashboard-nav ul li .sidebar-item-link i {
    margin-right: 8px;
    font-size: 20px;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: cover !important;
}

.dashboard-nav ul li ul li:hover .sidebar-item-link {
    padding-left: 4px;
}

.dashboard-nav ul li.active-submenu .sidebar-item-link i,
.dashboard-nav ul li.active .sidebar-item-link i,
.dashboard-nav ul li:hover .sidebar-item-link i {
    color: $oceanBlue;
}

.dashboard-nav ul li.active-submenu,
.dashboard-nav ul li.active > .sidebar-item-link,
.dashboard-nav ul li:hover > .sidebar-item-link {
    background-color: rgb(225 227 235 / 40%);
    height: 48px;
    width: 100%;
    border-radius: 5px;
    text-decoration: none;
}

// .dashboard-nav ul li.active {
//     // border-color: $oceanBlue;
// }

.dashboard-nav ul li.active-submenu .sidebar-item-link,
.dashboard-nav ul li.active .sidebar-item-link {
    color: $oceanBlue !important;
    background-color: rgb(25 140 202 / 5%);
    font-weight: $font-weight-bold;
    height: 48px;
    border-radius: 5px;
    width: 100%;
    white-space: nowrap;
}

.dashboard-nav ul li span.nav-tag {
    display: inline-block;
    font-weight: $font-weight-bold;
    height: 19px;
    width: 19px;
    line-height: 19px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    background-color: $oceanBlue;
    border-radius: 50%;
    position: relative;
    margin: 0 0 0 4px;
    top: -2px;
}

.dashboard-nav ul li ul {
    padding: 0;
    margin: 0;
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    line-height: 0;
    transition: 0.3s;
    pointer-events: none;
}

.dashboard-nav ul li.active-submenu ul,
.dashboard-nav ul li.active ul {
    visibility: visible;
    padding: 0 0 18px;
    line-height: 24px;
    opacity: 1;
    max-height: 200px;
    pointer-events: all;
}

.dashboard-nav ul li.active-submenu ul.submenu {
    padding-bottom: 0;
}

.dashboard-nav ul li ul li a {
    background-color: transparent;
    border: none;
    padding-left: 65px !important;
    position: relative;
    margin: 0;
}

.dashboard-nav ul li ul li a {
    padding: 5px 0;
}

.dashboard-nav ul li ul li:hover {
    border: none;
    background-color: transparent;
}

.dashboard-nav ul li ul li.active {
    border-color: transparent;
}

.dashboard-nav ul li a:after {
    display: none;
    font-family: material-icons;
    content: "\e914";
    opacity: 1;
    font-size: 16px;
    transition: 0.3s;
    opacity: 1;
    line-height: 17px;
    width: 17px;
    height: 17px;
    background-color: #f0f0f0;
    color: #a8a8a8;
    // display: inline-block;
    border-radius: 3px;
    margin-left: 7px;
    text-indent: 0;
    top: 1px;
    position: absolute;
    right: 35px;
    top: 15px;
}

.dashboard-nav ul li.active-submenu a:after,
.dashboard-nav ul li.active a:after,
.dashboard-nav ul li:hover a:after {
    color: $oceanBlue;
    background: rgba(25, 140, 202, 0.13);
    opacity: 0.8;
}

.dashboard-nav ul li ul:before,
.dashboard-nav ul li a:only-child:after {
    content: "";
    display: none;
}

.dashboard-headline {
    display: block;
    position: relative;
    margin-bottom: 40px;

    @media (max-width: 1024px) {
        margin-bottom: 20px;
    }
}

.dashboard-headline h3 {
    display: block;
    font-size: 26px;
    color: #333;
}

.dashboard-headline span {
    display: block;
    font-size: 18px;
    color: #888;
    line-height: 30px;
    margin-top: 5px;
}

.dashboard-headline #breadcrumbs {
    right: 0;
}

.fun-facts-container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin-bottom: -30px;
}

.fun-fact {
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 25px;
    margin-right: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 1 calc(25% - 30px);
    height: auto;
    cursor: default;
    transition: 0.3s;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .fun-fact {
        flex: 1;
    }
}

.fun-fact-icon {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.04);
    flex: 0 0 100px;
    height: 100px;
    display: flex;
    text-align: center;
}

.fun-fact-icon i {
    font-size: 48px;
    align-self: center;
    margin: 0 auto;
    color: #666;
}

.fun-fact-icon i.icon-material-outline-gavel {
    transform: scale(0.91);
}

.fun-fact-text {
    flex: 1;
    margin-right: 20px;
}

.fun-fact span {
    font-size: 18px;
    color: #888;
    line-height: 22px;
}

.fun-fact h4 {
    font-size: 38px;
    color: #333;
    line-height: 38px;
    margin-top: 5px;
}

.generic-table {
    h3.title {
        font-size: 20px;
        font-weight: $font-weight-normal;
        line-height: 1.5;
        margin-bottom: 8px;
        display: inline-block;
        color: $darkBlue;
    }

    h4.subtitle {
        font-size: 14px;
        font-weight: $font-weight-normal;
        line-height: 1.5;

        strong {
            font-weight: $font-weight-bold;
        }
    }

    .generic-table-inner {
        .open-position-details {
            background-color: $lightGrey;
            padding: 100px 15px 80px 15px;

            @media (max-width: 768px) {
                padding: 80px 15px 40px 15px;
            }

            .about-position {
                .now-hiring {
                    color: #24b768;
                    font-weight: $font-weight-bold;
                    font-size: 12px;
                    margin-bottom: 0;
                    text-transform: uppercase;
                }

                .position-title {
                    margin-top: 0;
                    font-size: 55px;
                    margin-bottom: 40px;
                    text-transform: capitalize;
                }

                .position-attributes-wrap {
                    display: flex;

                    @media (max-width: 768px) {
                        display: block;
                    }

                    .position-attributes {
                        display: flex;
                        padding: 0;
                        margin-bottom: 15px;
                        line-height: 1;

                        i {
                            display: block;
                            background-size: cover;
                            height: 20px;
                            width: 20px;
                            float: left;
                            margin-right: 10px;
                            margin-top: 5px;
                        }

                        p {
                            margin: 0;
                            color: $darkBlue;
                            font-size: 14px;
                            font-weight: $font-weight-bold;
                            display: flex;
                            align-items: center;
                        }

                        span {
                            color: $btnColorGray;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .position-details-wrap {
            padding: 50px 15px;

            .position-details {
                border-right: 1px solid #e1e3eb;
                padding-right: 60px;

                @media (max-width: 768px) {
                    border-right: none;
                    padding-right: 0;
                }

                .position-content {
                    margin-bottom: 40px;

                    .position-subtitle {
                        font-weight: $font-weight-bold;
                        margin-bottom: 20px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }

            .apply-section {
                padding-left: 50px;

                h5 {
                    margin-bottom: 20px;
                }

                @media (max-width: 768px) {
                    padding-left: 15px;
                }

                .position-skills {
                    margin-bottom: 40px;
                }

                .dev-skills li {
                    margin: 8px 8px 8px 0px;
                }

                .additional-skills {
                    li {
                        color: #546285;

                        span {
                            border: 1px solid rgb(84, 98, 133, 0.1);
                            color: #546285 !important;
                            background: none !important;
                        }
                    }
                }

                .dev-skills li span:hover {
                    background: #e6eff7 0% 0% no-repeat padding-box;
                    color: #198cca;
                }

                .raise-interest {
                    padding-bottom: 40px;
                    border-bottom: 1px solid #e1e3eb;
                }

                .invite-section {
                    margin-top: 40px;

                    h5 {
                        color: #546285;
                    }

                    h3 {
                        font-size: 24px;
                        line-height: 1.5;
                    }
                }
            }
        }

        @media (max-width: 1024px) {
            padding: 0;
        }

        &.col-xl-9 {
            @media (min-width: 1240px) and (max-width: 1440px) {
                max-width: 70%;
            }
        }

        &.col-xl-3 {
            @media (min-width: 1240px) and (max-width: 1440px) {
                max-width: 30%;
            }
        }

        &.author-box {
            .title-wrapper {
                display: flex;
                align-items: center;
                padding-top: 15px;

                .img-wrapper {
                    img {
                        max-width: 60px;
                        border-radius: 100%;
                        margin-right: 25px;
                    }
                }

                .name-position {
                    span.name {
                        font-size: 18px;
                        font-weight: $font-weight-bold;
                        color: $darkBlue;
                    }

                    p.position {
                        font-size: 12px;
                        color: $btnColorGray;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .referrals-forms-wrap,
        .referrals-list-wrap {
            .headline {
                h3 {
                    i {
                        padding-right: 24px;
                        background-size: cover;
                        margin-right: 10px;
                    }
                }
            }

            .referrals-forms {
                @media (min-width: 1200px) and (max-width: 1540px) {
                    padding: 15px;

                    p {
                        font-size: 14px;
                        line-height: 1.5;
                        margin-bottom: 20px;

                        &terms {
                            font-size: 11px;
                        }
                    }
                }
            }

            .position-box {
                @media (min-width: 1200px) and (max-width: 1540px) {
                    margin-bottom: 20px;

                    .position-name {
                        .position-title {
                            font-size: 12px;
                            line-height: 1.4;
                            word-break: break-all;
                        }

                        .position-subtitle {
                            font-size: 10px;
                            line-height: 1.5;
                        }
                    }
                    .position-status {
                        .position-status-step {
                            #arrow {
                                height: 36px;
                                width: 110px;
                            }
                        }
                    }
                }
            }
        }

        .referrals-forms-wrap {
            @media (min-width: 1200px) and (max-width: 1600px) {
                max-width: 40%;
                flex: 0 0 40%;
            }

            .select2-container {
                .select2-selection {
                    .select2-selection__rendered {
                        .select2-selection__choice {
                            padding: 4px 10px;
                            margin: 5px;
                        }

                        .select2-search {
                            input {
                                min-height: 42px;
                                padding: 0 0 0 10px !important;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .referrals-list-wrap {
            @media (min-width: 1200px) and (max-width: 1600px) {
                max-width: 60%;
                flex: 0 0 60%;
            }
        }

        .balance-wrap {
            .balance-box {
                padding: 30px;

                .balance-value {
                    font-size: 34px;
                    color: $darkBlue;
                    font-family: $font-family-head;
                    -webkit-font-smoothing: antialiased;
                    line-height: 1.5;
                }
            }

            &.disabled {
                .row,
                .balance-box {
                    .btn-action {
                        pointer-events: none;
                        background-color: $lightGrey;
                        color: $borderGray;
                        border: none;
                    }
                }
                .balance-value {
                    color: $borderGray;
                }
            }
        }

        .comission-balance {
            border: 1px solid #e1e3eb;
            margin: 40px 0;
            padding: 20px;
            border-radius: 5px;

            .balance-value {
                color: #adb4c7;
                font-size: 40px;
                font-family: $font-family-head;
                display: block;
                padding: 20px 0;
                padding-bottom: 30px;
                border-bottom: 1px solid $regularGrey;
            }

            .key-val-pair {
                p {
                    font-size: 14px;
                    width: 75%;
                    float: left;
                    color: $btnColorGray;
                }

                span {
                    float: right;
                }
            }

            .btn-action {
                min-width: auto;
                width: calc(50% - 5px);

                &.Donate {
                    float: right;
                }
            }

            .notice {
                font-size: 12px;
                line-height: 15px;
                padding-left: 30px;
                position: relative;

                i {
                    background-size: cover;
                    width: 24px;
                    height: 24px;
                    display: block;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .form-filter {
        width: 50%;
        float: right;
        max-width: 450px;

        @media (max-width: 1024px) {
            width: 100%;
            max-width: initial;
            overflow: hidden;
            float: none;

            div.input-control,
            div.input-control.submit {
                width: 100%;
                max-width: initial;
            }
        }

        .input-control {
            width: 49%;
            max-width: 160px;
            margin-right: 10px;
            float: left;
            position: relative;

            i {
                position: absolute;
                width: 24px;
                height: 24px;
                top: 8px;
                right: 8px;
            }

            &.submit {
                width: 110px;
                margin-right: 0;
                float: right;

                input {
                    min-width: initial;
                    text-transform: uppercase;
                    letter-spacing: 0.3px;
                }
            }

            input {
                height: 42px;
                width: 100%;

                &[type="text"] {
                    border-color: $regularGrey;
                }

                &[type="date"]::-webkit-outer-spin-button,
                &[type="date"]::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    display: none;
                }

                &[type="date"] {
                    color: $darkBlue;
                    font-size: 14px;
                }
            }
        }
    }
}

.dashboard-headline {
    .title {
        display: inline-block;
    }
}

.dashboard-box {
    display: block;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 10px #1a284d14;
    margin: 40px 0;
    position: relative;

    @media (max-width: 1024px) {
        margin-top: 20px;
    }
    .content {
        overflow: hidden;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 20px;
        }

        p {
            color: $dark-gray;
            font-size: 18px;
            line-height: 1.7;
        }

        #description-tips {
            font-size: 14px;
        }

        &.empty-content {
            overflow: hidden;

            .btn-action {
                display: block;
                float: none;
                max-width: 150px;
                margin: 25px auto 25px auto;
            }
        }
    }

    h3 {
        font-size: 20px;
    }

    .dashboard-map {
        background-image: url("../assets/img/assets/dashboard_map.svg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;

        p {
            font-size: 16px;
        }
    }

    &.executive-member {
        .client-executive {
            max-width: 170px;

            img {
                border-radius: 100%;
            }
        }

        p {
            margin-bottom: 30px;
        }
    }

    &.open-positions {
        .headline {
            overflow: hidden;

            h3 {
                float: left;
            }

            p {
                width: auto;
                text-align: right;
                float: right;
                font-family: $font-family-head;
                font-size: 12px;
                color: $btnColorGray;
                display: flex;
                align-items: center;

                span {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    background-color: rgb(241 85 63 / 0.1);
                    border-radius: 100%;
                    margin-right: 6px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    float: left;

                    &:before {
                        content: "";
                        display: block;
                        width: 6px;
                        height: 6px;
                        background-color: $required;
                        border-radius: 100%;
                    }
                }
            }
        }

        .position-btn-wrap {
            @media (max-width: 1024px) {
                text-align: left !important;
                padding: 15px;
            }

            @media (max-width: 768px) {
                display: flex;
            }

            .copyAction:hover {
                cursor: pointer;
            }
        }

        .open-position-wrap {
            padding: 30px;

            &:hover {
                background-color: #f9fafc;

                .job-listing-title {
                    color: #198cca;
                }
            }

            .default-cursor {
                cursor: default;
            }

            p {
                font-size: 14px;
                color: $btnColorGray;

                &.job-listing-title {
                    font-size: 16px;
                    color: $darkBlue;
                    font-weight: 600;
                    font-family: $font-family-head;
                    margin-bottom: 0;
                    display: inline-block;

                    span {
                        width: 20px;
                        height: 20px;
                        position: relative;
                        background-color: rgb(241 85 63 / 0.1);
                        border-radius: 100%;
                        margin-left: 10px;
                        margin-top: 3px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        float: right;

                        &:before {
                            content: "";
                            display: block;
                            width: 6px;
                            height: 6px;
                            background-color: $required;
                            border-radius: 100%;
                        }
                    }
                }

                &.pos-description {
                    margin-top: 10px;

                    a {
                        font-weight: $font-weight-bold;
                    }
                }
            }

            span {
                &.status {
                    font-size: 14px;
                    color: $btnColorGray;
                    display: block;
                }
            }

            .dev-skills {
                overflow: initial;

                li {
                    display: none;
                    margin-bottom: 8px;

                    p {
                        text-transform: none;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &.show-all-skills {
                        display: block;
                    }

                    &.show-all-skills {
                        cursor: pointer;
                    }
                }

                &.expanded {
                    li {
                        display: block;

                        &.show-all-skills {
                            display: none;
                        }
                    }
                }
            }

            .commitment-expire-date {
                p {
                    display: flex;
                    float: right;
                    margin: 0 10px;
                    align-items: center;
                    color: #1a284d;

                    @media (max-width: 1024px) {
                        margin: 0;
                        float: none;
                        margin-bottom: 5px;
                    }

                    i {
                        display: block;
                        background-size: cover;
                        height: 16px;
                        width: 16px;
                        float: left;
                        margin-right: 10px;
                    }
                }
                #format-money {
                    font-weight: 600 !important;
                }
            }

            .btn-text {
                text-transform: none;
                transition: 0.2s all ease;

                &:hover {
                    background-color: $green;
                    border-color: $green;
                    color: white;
                }
            }
        }
    }

    .headline {
        h3 {
            font-size: 14px;
            color: $btnColorGray;
            display: flex;
            align-items: center;

            i {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-top: 0;
                top: 0 !important;
                margin-right: 10px;
            }
        }

        .bootstrap-select {
            float: right;

            .dropdown-toggle {
                box-shadow: none;
                font-family: $font-family-head;

                &:after {
                    content: none;
                }

                .filter-option {
                    color: $btnColorGray;
                    font-weight: $font-weight-normal;
                    -webkit-font-smoothing: antialiased;
                }
            }
        }
    }

    .form-filter {
        display: inline-block;
        float: right;
        margin-top: -25px;
        margin-right: 0;
        width: auto;

        .dropdown-menu {
            top: 10px;

            li {
                a {
                    .check-mark {
                        &:before {
                            content: "";
                        }
                    }
                }
            }
        }
    }
    .dashboard-box-list {
        .edit-btn {
            position: relative;
            min-width: 120px;
            padding-left: 25px;

            &:before {
                content: "";
                display: block;
                width: 17px;
                height: 17px;
                background-image: url(../assets/img/assets/default_edit.svg);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: 10px;
                left: 14px;
            }

            &:hover {
                background-color: #f9fafc;
                color: #1a284d;

                &:before {
                    background-image: url(../assets/img/assets/dark_edit.svg);
                    content: "";
                    display: block;
                    width: 17px;
                    height: 17px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    position: absolute;
                    top: 10px;
                    left: 14px;
                }
            }
        }

        li,
        .dashboard-item {
            display: flex;
            flex-wrap: nowrap;

            @media (max-width: 768px) {
                display: block;
            }

            &.table-head {
                background-color: $lightGrey;
                padding: 15px 30px;
                max-height: 50px;

                h6 {
                    text-transform: uppercase;
                    font-weight: $font-weight-bold;
                    color: $btnColorGray;
                    letter-spacing: 1.2px;
                    line-height: 1.5;
                }

                .job-listing {
                    &:not(:first-child) {
                        text-align: center;
                    }
                }
            }

            .job-listing {
                display: block;
                line-height: normal;

                @media (max-width: 1024px) {
                    margin-bottom: 20px;
                }

                .job-listing-title {
                    font-size: 16px;
                    font-family: $font-family-content;
                    font-weight: $font-weight-bold;
                    margin-bottom: 0;
                    line-height: 23px;
                    display: flex;
                    align-items: center;

                    i {
                        width: 24px;
                        font-size: 24px;
                        position: relative;
                        background-size: 100%;
                        display: inline-block;
                        height: 24px;
                        margin-right: 15px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 14px;
                    }
                }

                &.position-title {
                    @media (max-width: 1024px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        div.position-dev-img {
                            margin-right: 0 !important;
                        }
                        .job-listing-title {
                            font-size: 18px;
                            padding-right: 0;
                            margin-bottom: 10px;
                        }
                    }
                }

                &.post-thumb {
                    max-width: 50px;
                    text-align: center;
                    margin-right: 20px;

                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 3px;
                    }
                }

                &.post-title {
                    h5 {
                        line-height: 1.5;
                    }
                }

                &.post-status {
                    max-width: 100px;
                    text-align: center;
                }

                &.post-actions {
                    display: flex;
                    max-width: 50px !important;
                }

                span {
                    font-size: 12px;
                    color: $btnColorGray;
                    padding-left: 40px;

                    &.pending,
                    &.expired,
                    &.in-progress,
                    &.active,
                    &.waiting {
                        font-weight: $font-weight-bold;
                        -webkit-font-smoothing: antialiased;
                    }

                    &.status {
                        padding: 0;
                        font-size: 12px;
                        font-weight: $font-weight-bold;

                        &.status-icon-combo {
                            padding-left: 40px;
                        }

                        @media (max-width: 1024px) {
                            font-size: 14px;
                        }

                        &.pending,
                        &.active,
                        &.publish,
                        &.filled,
                        &.green {
                            color: $green;
                        }

                        &.blue {
                            color: $oceanBlue;
                        }
                        &.yellow {
                            color: $yellow;
                        }

                        &.purple {
                            color: $purple;
                        }

                        &.grey {
                            color: $borderGray;
                        }

                        &.red {
                            color: $required;
                        }
                    }
                }
            }
            .buttons-to-right {
                text-align: right;
                display: flex;
                justify-content: flex-end;
                max-width: 150px;

                @media (max-width: 1024px) {
                    text-align: left;
                    padding: 15px 0 0;
                }

                .btn-action {
                    text-transform: none;
                }

                .btn-manage-candidates {
                    i {
                        filter: brightness(3);
                    }

                    &:hover {
                        i {
                            filter: brightness(0.5);
                        }
                    }
                }

                .post-actions {
                    display: flex;
                }
            }
        }

        &.profile-completion-box {
            li:first-child {
                padding: 30px !important;

                h5 {
                    width: 100% !important;
                    position: relative;

                    span {
                        right: 0;
                        font-size: 14px;
                    }
                }
            }
        }

        &.checklist-completion-box {
            li {
                padding: 20px 30px !important;
                padding-left: 50px !important;
                color: $darkBlue;
                display: flex !important;
                align-items: center;
                line-height: 1.5;

                @media (max-width: 1440px) {
                    font-size: 14px;
                }

                &.cl-completed {
                    color: $borderGray;

                    span.cl-checkbox {
                        background-image: url(../assets/img/assets/checkbox_filled.svg);
                    }
                }

                &.title {
                    padding: 25px !important;
                }

                h5 {
                    margin-bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    i {
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        background-size: cover;
                        margin-left: 6px;
                        float: right;
                    }
                }

                span.cl-checkbox {
                    background-image: url(../assets/img/assets/checkbox_empty.svg);
                    background-size: cover;
                    background-position: center;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    left: 20px;
                    cursor: pointer;
                }

                .cl-item-link {
                    position: absolute;
                    right: 20px;
                    transition: 0.2s all ease;

                    &:hover {
                        right: 15px;
                    }

                    svg {
                        width: 14px;
                    }
                }
            }
        }

        &.timelogs-list {
            li,
            .timelog-item {
                display: block;

                @media (max-width: 1024px) {
                    text-align: left;
                    padding: 15px;
                }

                .job-listing {
                    position: relative;

                    h4 {
                        margin-bottom: 0;
                        color: $darkBlue;
                        line-height: 1.5;

                        @media (min-width: 1200px) {
                            padding-right: 90px;
                        }
                    }

                    span {
                        padding: 0;
                    }

                    p {
                        color: $dark-gray;
                        margin-bottom: 0;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 1.7;

                        &.time-log-val {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            top: -4px;
                            margin-top: 0;
                            font-family: $font-family-head;
                            font-weight: $font-weight-bold;
                            -webkit-font-smoothing: antialiased;

                            i {
                                width: 24px;
                                height: 24px;
                                display: inline-block;
                                background-size: cover;
                                margin-right: 10px;
                            }
                        }
                    }

                    .blue-link {
                        font-size: 14px;
                        font-weight: $font-weight-medium;
                        margin-top: 20px;
                        display: flex;
                        align-items: center;

                        i {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                            background-size: cover;
                        }
                    }
                }
            }
        }

        &.payments-list,
        &.posts-list {
            .table-head {
                .job-listing {
                    text-align: left !important;

                    h6 {
                        margin-bottom: 0;
                    }
                }
            }

            .job-listing {
                p {
                    font-size: 16px;
                    color: $darkBlue;
                    line-height: 1.2;
                    margin-bottom: 0;
                }

                &.date {
                    max-width: 170px;
                }

                &.type {
                    max-width: 130px;
                }

                &.amount,
                &.status,
                &.refid {
                    max-width: 130px;
                }

                &.status-timeoff {
                    max-width: 180px;
                }

                &.amount {
                    p {
                        font-weight: $font-weight-bold;
                    }
                }

                &.status,
                &.post-status {
                    .badge {
                        padding: 7px 14px;
                        color: white;
                        border-radius: 3px;
                        font-size: 13px;
                        -webkit-font-smoothing: antialiased;

                        &.badge-Paid,
                        &.badge-Approved,
                        &.badge-publish {
                            background-color: $org;
                        }

                        &.badge-Pending,
                        &.badge-draft {
                            background-color: $oceanBlue;
                        }

                        &.badge-Declined {
                            background-color: $required;
                        }
                    }
                }

                &.refid {
                    p {
                        color: $btnColorGray;
                    }
                }

                &.w-250 {
                    max-width: 250px;
                }

                &.w-120 {
                    max-width: 120px;
                }

                &.w-150 {
                    max-width: 150px;
                }

                &.w-180 {
                    max-width: 180px;
                }
            }

            .writer-benefits {
                h4 {
                    font-size: 18px;
                    margin-bottom: 30px;
                }

                ul {
                    li {
                        margin-bottom: 30px;
                        position: relative;
                        color: $darkBlue;

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            left: -40px;
                            top: 8px;
                            background-image: url("../assets/img/assets/green-checkmark.svg");
                            background-size: cover;
                            width: 18px;
                            height: 13px;
                        }
                    }
                }
            }
        }

        &.inspiration-list {
            .li-ideas {
                padding-left: 65px;

                li {
                    position: relative;
                    margin-bottom: 30px;
                    font-family: $font-family-head;
                    line-height: 1.5;
                    display: flex;
                    align-items: center;
                    font-weight: $font-weight-bold;
                    color: $darkBlue;
                    min-height: 50px;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: -65px;
                        background-image: url("../assets/img/assets/inspiration-bulb.svg");
                        background-size: cover;
                        width: 50px;
                        height: 50px;
                    }
                }
            }

            .li-books {
                padding-left: 0;

                li {
                    margin-bottom: 30px;

                    a {
                        display: flex;
                        align-items: center;

                        .post-thumb {
                            float: left;
                        }
                    }
                }
            }
        }

        &.time-off-list {
            .job-listing {
                max-width: 180px;
            }
        }

        &.position-status {
            overflow-x: scroll;

            li {
                display: flex !important;

                .job-listing {
                    margin-bottom: 0;

                    &:not(:first-child) {
                        p {
                            justify-content: center;
                        }
                    }

                    &.position-title:first-child {
                        align-items: flex-start;
                    }

                    &.status-item {
                        .job-listing-title {
                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                                right: 0;
                                height: 2px;
                                background-color: $green;
                            }

                            &:before {
                                left: 16px;
                                width: calc(50% - 22px);
                            }

                            &:after {
                                width: 100%;
                            }
                        }

                        &.first {
                            .job-listing-title {
                                &:before {
                                    left: 60px;
                                    width: 35%;
                                }

                                &:after {
                                    right: -30px;
                                }
                            }

                            &.last-item {
                                .job-listing-title {
                                    &:after {
                                        left: 30px !important;
                                        width: 35% !important;
                                    }
                                }
                            }
                        }

                        &.not-started {
                            &.last-item {
                                .job-listing-title {
                                    &:before {
                                        content: none;
                                    }

                                    &:after {
                                        width: 50%;
                                        left: 0;
                                    }
                                }
                            }
                        }

                        &.last-item {
                            .job-listing-title {
                                &:before {
                                    content: none;
                                }

                                &:after {
                                    width: 50%;
                                    left: 0;
                                }
                            }

                            &.active:not(.not-started) {
                                .job-listing-title {
                                    .status-label {
                                        background-size: 8px;
                                        border: 2px solid $org;
                                        position: relative;

                                        &:after {
                                            content: "";
                                            display: block;
                                            position: absolute;
                                            width: 12px;
                                            height: 12px;
                                            background-color: $org;
                                            background-position: center center;
                                            top: 3px;
                                            left: 3px;
                                            border-radius: 100%;
                                        }
                                    }

                                    &:before,
                                    &:after {
                                        background-color: $org !important;
                                    }
                                }
                            }
                        }

                        &.declined {
                            .job-listing-title {
                                &:after {
                                    background-color: $required;
                                }

                                .status-label {
                                    border: 2px solid $required;
                                }
                            }
                        }

                        &.success {
                            .job-listing-title {
                                .status-label {
                                    background-image: url(../assets/img/assets/green-status-checkmark.svg);
                                }
                            }

                            &.expired {
                                .job-listing-title {
                                    .status-label {
                                        background-image: url(../assets/img/assets/grey-status-checkmark.svg);
                                        border: 2px solid $borderGray;
                                    }

                                    &:before,
                                    &:after {
                                        background-color: $borderGray !important;
                                    }
                                }
                            }
                        }

                        &.not-started {
                            .job-listing-title {
                                .status-label {
                                    border: 2px solid $borderGray;
                                    position: relative;

                                    &:after {
                                        content: "";
                                        display: block;
                                        position: absolute;
                                        width: 10px;
                                        height: 2px;
                                        background-color: $borderGray;
                                        top: 8px;
                                        left: 4px;
                                    }
                                }

                                &:before,
                                &:after {
                                    background-color: $borderGray !important;
                                }
                            }
                        }

                        &.no-status {
                            visibility: hidden;
                        }
                    }
                }

                .status-label {
                    display: block;
                    width: 22px;
                    height: 22px;
                    border: 2px solid $green;
                    border-radius: 100%;
                    padding: 0;
                    background-color: white;
                    z-index: 1;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .status-item {
                    &.declined {
                        .job-listing-title {
                            .status-label {
                                background-image: url(../assets/img/assets/icon-x.svg);
                                background-size: 8px;
                            }
                        }
                    }

                    &.in-progress {
                        .job-listing-title {
                            .status-label {
                                background-size: 8px;
                                border: 2px solid $oceanBlue;
                                position: relative;

                                &:after {
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    background-color: $oceanBlue;
                                    background-position: center center;
                                    top: 3px;
                                    left: 3px;
                                    border-radius: 100%;
                                }
                            }

                            &:before,
                            &:after {
                                background-color: $oceanBlue !important;
                            }
                        }
                    }
                }

                &.table-head,
                &.availability-requested {
                    min-width: 1024px;
                }
            }
        }
    }

    .form-wrap {
        width: 100%;
        padding: 50px 35px;

        @media (max-width: 768px) {
            padding: 20px 15px;
        }

        .form-group {
            overflow: hidden;
            //margin-bottom: 30px;

            &.talent-phone-number {
                .input-error {
                    border: 1px solid #f1553f !important;
                    background-color: rgba(241, 85, 63, 0.05) !important;
                }

                #error-msg {
                    font-size: 80%;
                    color: #f1553f;
                }

                .iti__flag-container {
                    width: 20%;
                    border: 1px solid $regularGrey;
                    border-radius: 3px;

                    .iti__selected-flag {
                        justify-content: center;
                        outline: none;
                    }
                }

                input {
                    padding-left: 15px;
                    width: 78%;
                    float: right;
                }
            }

            &.member-profile-img-wrap {
                margin: auto;

                .member-profile-img {
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;
                    margin: auto;
                    text-align: center;
                    width: 150px;
                    border-radius: 100%;

                    @media (max-width: 1440px) {
                        width: 130px;
                        border-radius: 100%;
                    }

                    img {
                        width: 150px;
                        height: 150px;
                        object-fit: cover;
                        border-radius: 100%;

                        @media (max-width: 1440px) {
                            width: 130px;
                            height: 130px;
                        }
                    }

                    .edit-photo-overlay {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: rgba(26, 40, 77, 0.4);
                        background-image: url(../assets/img/assets/icon-edit-photo.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        display: none;
                    }

                    &:hover {
                        .edit-photo-overlay {
                            display: block;
                        }
                    }
                }

                .col-form-label {
                    justify-content: center;
                    padding: 15px 0;
                }

                #avatar-upload {
                    display: none;
                }

                p {
                    color: $btnColorGray;
                    margin-bottom: 15px;
                    line-height: 15px;
                    text-align: center;
                    font-size: 12px;
                }
            }

            &.position-skills {
                .radio {
                    display: block;

                    label {
                        font-size: 14px;
                    }
                }
            }

            &.position-title {
                .keywords-container {
                    .keyword-input-button {
                        background-color: $green;
                        margin: 3px;
                        color: white;
                        cursor: pointer;
                    }

                    .keywords-list {
                        .keyword {
                            border-radius: 100px;

                            .keyword-remove {
                                &:before {
                                    content: "+";
                                    top: 2px;
                                    font-size: 22px;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }
                }
            }

            &.profile-type {
                button {
                    display: flex;
                    text-align: center;
                    padding: 5px 20px;
                    align-items: center;
                    justify-content: center;
                    float: left;
                    text-transform: capitalize;

                    &.active {
                        background-color: $oceanBlueOpacity;
                        border: 1px solid $oceanBlue;
                    }

                    &.btn-public {
                        border-radius: 3px 0 0 3px;
                    }

                    &.btn-private {
                        border-radius: 0 3px 3px 0;
                    }

                    i {
                        float: left;
                        filter: none;
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                        background-size: cover;
                    }
                }
            }

            .select2-container--default {
                .select2-selection--multiple {
                    display: flex;

                    li.select2-selection__choice {
                        background-color: rgba(25, 140, 202, 0.07) !important;
                        border: none;
                        border-radius: 100px;
                        color: $oceanBlue !important;
                        line-height: 30px !important;
                        padding: 0 10px;
                        font-size: 12px !important;
                        margin-left: 5px;

                        .select2-selection__choice__remove {
                            color: $oceanBlue !important;
                            font-weight: normal !important;
                            font-size: 16px !important;
                        }

                        &.invalid-email {
                            color: $required !important;
                            border: 1px solid $required !important;
                            background-color: rgba(241, 85, 63, 0.05) !important;

                            span {
                                color: $required !important;
                            }
                        }
                    }

                    .select2-selection__clear {
                        display: none;
                    }
                    .select2-search--inline {
                        .select2-search__field {
                            padding: 0 10px;
                            border: none;
                            min-height: 42px;
                            margin: 0;
                        }
                    }
                }

                &.select2-container--focus {
                    .select2-selection--multiple {
                        border: solid #ced4da 1px;
                        outline: 0;
                    }
                }

                .select2-selection--multiple {
                    border: solid #ced4da 1px;

                    .select2-search__field {
                        height: 25px;
                    }
                }
            }

            &.skills-fg {
                .select2-container--default {
                    .select2-selection--multiple {
                        display: flex;
                    }
                }
            }

            &.error {
                .message {
                    width: 100%;
                }
            }

            input[type="text"],
            input[type="email"],
            input[type="url"],
            input[type="number"],
            input[type="search"],
            input[type="date"],
            textarea {
                @include placeholder {
                    color: $borderGray;
                }
                border: 1px solid $regularGrey;
            }

            .input-group-prepend {
                i {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    background-size: cover;
                }
            }
        }

        .col {
            @media (max-width: 1024px) {
                flex-basis: auto;
            }
        }
        .radio {
            label {
                margin-right: 80px;
                font-family: $font-family-content;
                font-size: 16px;
                color: $darkBlue;
                font-weight: $font-weight-normal;

                span {
                    display: block;
                    color: $btnColorGray;

                    &:nth-child(2) {
                        margin-top: 5px;
                    }
                }
            }
        }

        .section-headline {
            label {
                font-family: $font-family-content;
                font-weight: $font-weight-bold;
                color: $darkBlue;
                font-size: 14px;
                padding-bottom: 20px;
            }
        }

        input.btn-action {
            line-height: 0;
            text-transform: uppercase;
            font-family: $font-family-head;
            float: right;

            @media (max-width: 768px) {
                display: block;
                width: 100%;
            }
        }

        .position-engagement,
        .position-project-length {
            .radio {
                width: 40%;
                float: left;

                @media (max-width: 768px) {
                    width: 100%;

                    label {
                        margin: 0;
                    }
                }
            }
        }

        .col-form-label {
            font-size: 14px;
            display: flex;
            align-items: center;
            font-family: $font-family-content;
            font-weight: $font-weight-bold;

            i {
                width: 18px;
                height: 18px;
                display: inline-block;
                background-size: cover;
                margin-left: 6px;
            }
        }

        &.step-form {
            padding: 0;

            .detail-box {
                padding: 10px 90px 40px;
                position: relative;
                border-bottom: 1px solid $regularGrey;
                background: white;

                #description-tips {
                    font-size: 14px;
                }

                .checkbox-currently-wrap {
                    display: flex;
                    align-items: center;

                    label {
                        font-size: 14px;
                        font-family: $font-family-content;
                    }

                    .checkbox-currently {
                        font-size: 14px;
                        font-weight: 400 !important;
                    }
                }

                .detail-box-num-wrap {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    top: 0;
                    border-right: 1px solid $regularGrey;
                    width: 60px;
                    text-align: center;
                    cursor: move;

                    .detail-box-num {
                        font-size: 18px;
                        color: $btnColorGray;
                        font-family: $font-family-head;
                        -webkit-font-smoothing: antialiased;
                        padding: 15px;
                        display: block;
                        border-bottom: 1px solid $regularGrey;
                    }
                }

                .btn-delete-wrap {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    border-left: 1px solid $regularGrey;
                    width: 60px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .btn-action {
                        height: 100%;
                        width: 100%;
                        border: none;
                        border-radius: 0;
                        i {
                            margin: auto;
                        }

                        &:hover {
                            background-color: #fcfcfc;
                        }
                    }
                }

                &.detail-box-full {
                    padding: 80px 40px 40px 100px;

                    .detail-box-section-title-wrap,
                    .detail-box-num-wrap {
                        background-color: $lightGrey;
                    }

                    .detail-box-section-title-wrap {
                        padding: 15px 40px;
                        position: absolute;
                        right: 0;
                        left: 60px;
                        top: 0;
                        border-bottom: 1px solid $regularGrey;
                        max-height: 58px;

                        .detail-box-section-title {
                            font-size: 12px;
                            font-family: $font-family-head;
                            text-transform: uppercase;
                            font-weight: $font-weight-bold;
                            letter-spacing: 1.2px;
                            color: $btnColorGray;
                        }
                    }
                }

                .markdown-lbl {
                    font-size: 14px;
                    color: $btnColorGray;

                    a {
                        color: $darkBlue;
                        font-weight: $font-weight-bold;
                    }
                }
            }

            .blue-link {
                padding: 25px;
                font-size: 14px;
                font-weight: $font-weight-medium;
            }
        }
    }

    &.wip-msg {
        .content {
            padding: 80px 40px;

            .icon-wip {
                margin-bottom: 30px;
            }

            p {
                color: $btnColorGray;
            }
        }
    }

    .dashboard-box-list {
        .freelancer-overview {
            .freelancer-overview-inner {
                @media (max-width: 768px) {
                    display: block;
                }

                .freelancer-name {
                    .match-details {
                        display: flex;

                        i {
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            margin-top: 0;
                            margin-right: 8px;
                        }

                        p {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: $dark-gray;
                            line-height: 1.3;
                            font-weight: $font-weight-bold;
                        }
                    }

                    h4 {
                        margin-bottom: 2px;
                        display: inline;

                        .iti__flag {
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }

                    span {
                        font-size: 12px;
                        color: $btnColorGray;
                        line-height: 1.3;
                        display: block;
                    }

                    p {
                        font-size: 16px;
                        color: $dark-gray;
                        line-height: 1.7;
                        margin-top: 10px;
                        margin-bottom: 20px;

                        &.match-status {
                            float: right;
                            font-weight: $font-weight-bold;
                            font-size: 12px;
                            font-family: $font-family-head;
                            margin: 0;
                            display: flex;
                            color: $oceanBlue;

                            span {
                                width: 20px;
                                height: 20px;
                                position: relative;
                                background-color: $oceanBlueOpacity;
                                border-radius: 100%;
                                margin-right: 6px;
                                align-items: center;
                                justify-content: center;
                                display: flex;

                                &:before {
                                    content: "";
                                    display: block;
                                    width: 6px;
                                    height: 6px;
                                    background-color: $oceanBlue;
                                    border-radius: 100%;
                                }
                            }

                            &.active {
                                color: $green;

                                span {
                                    background-color: $greenOpacity;

                                    &:before {
                                        background-color: $green;
                                    }
                                }
                            }

                            &.interview {
                                color: $oceanBlue;

                                span {
                                    background-color: $oceanBlueOpacity;

                                    &:before {
                                        background-color: $oceanBlue;
                                    }
                                }
                            }

                            &.expired {
                                color: $borderGray;

                                span {
                                    background-color: $borderGrayOpacity;

                                    &:before {
                                        background-color: $borderGray;
                                    }
                                }
                            }

                            &.trial {
                                color: $orange;

                                span {
                                    background-color: $orangeOpacity;

                                    &:before {
                                        background-color: $orange;
                                    }
                                }
                            }

                            &.hide {
                                display: none;
                            }
                        }
                    }

                    .dev-skills {
                        margin-bottom: 0;

                        li {
                            display: flex;
                        }

                        .show-all-skills {
                            display: none;
                        }
                    }

                    .btn-action {
                        text-transform: capitalize;
                    }
                }
            }
        }

        &.client-skills {
            li {
                flex-wrap: wrap;

                .job-listing {
                    &.position-title {
                        flex: 0 0 50%;
                        max-width: 50%;
                        width: auto;
                    }
                }

                span.status {
                    color: #576ac7;
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                }

                .skills-wrap {
                    display: flex;
                    flex: 0 0 100%;
                }

                @media (max-width: 600px) {
                    .details {
                        display: block !important;
                        padding: 0;
                        max-width: 100% !important;
                    }

                    .skills-wrap {
                        display: block;
                    }

                    .skills-listing {
                        display: block !important;

                        .buttons-to-right {
                            justify-content: flex-start;
                        }
                    }
                }

                .details {
                    display: flex;
                    flex: 0 0 50%;
                    justify-content: flex-end;
                    align-items: center;
                    max-width: 50%;

                    .weekly-hours {
                        display: block;
                        margin-right: 32px;
                        width: auto;
                    }

                    .position-duration {
                        display: block;
                        width: auto;
                    }

                    i {
                        margin-right: 10px;
                    }
                }

                .skills-listing {
                    flex: 0 0 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;

                    li {
                        line-height: 1.5;
                        font-size: 12px;

                        a {
                            text-transform: capitalize;
                        }
                    }

                    .buttons-to-right {
                        display: flex;
                    }
                }
            }
        }

        &.position-info {
            li {
                align-items: center;
                line-height: 0;

                .job-listing {
                    &.position-title {
                        align-items: flex-start;
                    }
                }
            }
        }

        &.contracts-info {
            li {
                align-items: center;

                .job-listing {
                    overflow: hidden;

                    &.position-title {
                        .position-dev-img {
                            overflow: hidden;
                            width: 80px;
                            height: 80px;
                            border-radius: 100%;
                            float: left;
                            margin-right: 20px;
                        }

                        .job-listing-title {
                            margin-top: 20px;
                        }

                        .name-title {
                            overflow: hidden;
                        }
                    }
                }
            }

            @media (min-width: 1024px) {
                &.minimal {
                    .position-dev-img {
                        width: 50px !important;
                        height: 50px !important;
                    }

                    .name-title {
                        p {
                            margin-top: 5px !important;
                        }
                    }
                }
            }
        }

        &.invoices-info {
            .table-head {
                .job-listing {
                    text-align: left !important;

                    h6 {
                        margin-bottom: 0;
                    }
                }
            }

            li {
                .job-listing {
                    p {
                        font-weight: $font-weight-bold;

                        &.invoice-id {
                            font-weight: $font-weight-bold;
                        }
                    }

                    &.status {
                        span {
                            color: white;
                            padding: 7px 14px;
                        }
                    }

                    .invoice-attachments {
                        display: flex;
                        align-items: center;
                        i {
                            margin-right: 5px;
                            width: 15px;
                            height: 15px;
                        }
                    }

                    &.invoice-total {
                        p {
                            font-weight: $font-weight-bold;
                        }
                    }
                }

                &.invoice-overdue {
                    .invoice-total {
                        p {
                            color: $required;
                        }
                    }
                }

                &.invoice-awaiting-payment {
                    .invoice-total {
                        p {
                            color: $darkBlue;
                        }
                    }
                }

                .buttons-to-right {
                    .btn-action {
                        padding: 5px;

                        .loader {
                            margin: auto;
                            width: 20px;
                            height: 20px;
                            border: 2px solid $regularGrey;
                            border-left: 2px solid $darkBlue;
                        }

                        i {
                            background-size: cover;
                        }
                    }
                }
            }
        }

        .job-skills {
            padding: 18px 10px;
            p {
                font-size: 16px;
            }
        }
    }

    &.form-main-wrap {
        max-width: 1016px;
    }

    &.talent-box {
        .freelancer-avatar {
            position: relative;
            width: 160px !important;

            .edit-profile {
                position: absolute;
                right: -2px;
                bottom: -5px;
                border-radius: 100%;
                border: 5px solid white;
                background-color: white;
                &:hover {
                    a svg {
                        filter: brightness(1.2);
                    }
                }
            }

            @media (min-width: 1240px) and (max-width: 1440px) {
                max-width: 120px !important;
            }

            @media (max-width: 1024px) {
                margin: auto !important;
            }
        }

        .dev-info {
            display: block;
            overflow: hidden;

            .dev-info-box {
                display: inline-block;
                float: left;
                padding: 22px 25px;
                border-right: 1px solid $regularGrey;

                &:last-child {
                    border-right: none;
                }

                p {
                    font-size: 14px;
                    color: $btnColorGray;
                    margin: 0;
                    float: left;

                    &:first-child {
                        margin-right: 40px;
                        font-weight: $font-weight-bold;

                        @media (min-width: 1025px) and (max-width: 1440px) {
                            margin-right: 10px;
                        }
                    }
                }

                &.social-media {
                    @media (min-width: 1025px) and (max-width: 1440px) {
                        p:first-child {
                            display: none;
                        }
                    }

                    .social-icons {
                        display: flex;
                        min-height: 22px;

                        a {
                            color: $btnColorGray;
                            display: flex;
                            align-items: center;
                            i {
                                font-size: 18px;
                                margin: 0 15px;
                                display: inline-block;
                                background-size: cover;
                                width: 18px;
                                height: 18px;

                                @media (min-width: 1025px) and (max-width: 1440px) {
                                    font-size: 14px;
                                    margin: 0 5px;
                                }
                            }

                            &:hover {
                                i {
                                    filter: brightness(0.5);
                                }
                            }
                        }
                    }
                }

                &.availability {
                    padding: 19px 25px;

                    .select2-container {
                        .select2-selection {
                            border: none !important;
                            font-size: 14px;
                            font-weight: $font-weight-bold;

                            #select2--container[title="Part time"] {
                                color: $oceanBlue;
                            }

                            #select2--container[title="Full-time"] {
                                color: $org;
                            }

                            #select2--container[title="Unavailable"] {
                                color: $required;
                            }
                        }

                        .select2-results__options {
                            box-shadow: 0 4px 10px #1a284d14;
                            border-bottom-right-radius: 5px;
                            border-bottom-left-radius: 5px;
                        }
                    }
                    ul {
                        margin: 0;
                        list-style: none;
                        padding: 0;

                        li {
                            &.active a {
                                pointer-events: none;
                            }
                        }

                        &:not(.active-list) {
                            li {
                                line-height: normal;

                                &:not(.active) {
                                    display: none;
                                }
                            }
                        }

                        &.active-list {
                            li {
                                display: block;
                            }
                        }

                        li {
                            &.unavailable a {
                                color: $required;
                            }

                            &.part-time a {
                                color: $oceanBlue;
                            }

                            &.full-time a {
                                color: $org;
                            }
                        }
                    }
                }

                @media (min-width: 1024px) and (max-width: 1440px) {
                    padding: 15px 15px;

                    p {
                        font-size: 13px;
                    }

                    &.availability {
                        padding: 10px 20px;
                    }
                }

                @media (max-width: 1024px) {
                    display: block;
                    width: 100%;
                    border-right: none;
                    border-bottom: 1px solid $regularGrey;
                }
            }
        }
        .dashboard-box-list {
            li {
                .buttons-to-right {
                    max-width: initial;
                }
            }
        }
    }

    &.writter-box {
        a {
            &.article-template {
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;
                -webkit-box-flex: 1;
                flex: 1;
                margin: 35px;
                padding: 4em;
                border-radius: 3px;
                background-color: #fff;
                cursor: pointer;
                border: 1px solid rgba(0, 0, 0, 0.05);
                -webkit-box-align: center;
                align-items: center;
                box-shadow: 0px 4px 10px #1a284d14;

                .article-template-icon {
                    width: 50px;
                    height: 50px;
                    background-size: 150px 100px;
                    background-image: url(../assets/img/assets/sprite-article-template@2x.png);
                    margin-bottom: 30px;

                    &.learned {
                        background-position: -100px -50px;
                    }

                    &.built {
                        background-position: -50px -50px;
                    }

                    &.blank {
                        background-position: -50px 0;
                    }
                }

                &:hover {
                    background: $oceanBlueOpacity;
                    border: 1px solid $oceanBlue;
                    text-decoration: none;
                }
            }
        }
    }

    &.referrals-box {
        .head-wrap {
            overflow: hidden;
            display: flex;
            align-items: self-start;

            img {
                max-width: 60px;
                float: left;
                margin-right: 15px;
            }

            p {
                font-size: 12px;
                line-height: 1.5;
                color: $btnColorGray;
                margin-bottom: 0;
            }
        }

        .invite-form {
            label {
                font-size: 14px;
                font-family: $font-family-head;
                color: $darkBlue;
            }

            .btn-action {
                padding: 10px 30px;
                line-height: 1.6;
                min-width: initial;
            }
        }

        &.referral-stats {
            .position-title {
                width: 80%;

                p {
                    font-weight: $font-weight-bold;
                    margin-bottom: 0;
                    line-height: 23px;
                }

                span {
                    font-size: 14px;
                }
            }

            .weekly-hours {
                text-align: right;
                font-family: $font-family-head;
                width: 20%;

                p {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: $font-weight-normal;
                    -webkit-font-smoothing: antialiased;

                    sup {
                        font-size: 60%;
                    }

                    &.grey {
                        color: $borderGray;
                    }
                }
            }
        }

        &.referral-billing {
            li {
                &.table-head {
                    min-width: auto !important;
                }

                .job-listing {
                    &.date {
                        width: 35%;

                        p {
                            color: $btnColorGray;
                            font-weight: $font-weight-normal;
                        }
                    }

                    &.description {
                        text-align: left;

                        p {
                            color: $darkBlue;
                            font-weight: $font-weight-normal;
                        }
                    }
                    &.amount {
                        width: 25%;
                    }
                }
            }
        }
    }

    .profile-completion {
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media (max-width: 1024px) {
            display: block;
        }

        &.dropdown-opened {
            .profile-completion-box {
                &:not(.percentage-wrap) {
                    display: block;
                }
            }
        }

        .profile-completion-box {
            padding: 20px 15px;
            border-right: 1px solid $regularGrey;
            max-height: 80px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex: 1 1 1px;
            position: relative;

            &.completed {
                p {
                    width: 75%;
                }

                i {
                    display: none;

                    &.completed-step {
                        background-image: url("../assets/img/assets/cm-complete.svg");
                        background-repeat: no-repeat;
                        width: 22px;
                        height: 22px;
                        display: block;
                        position: absolute;
                        right: 15px;
                        top: 20px;
                    }
                }

                span {
                    color: $green;
                    font-weight: $font-weight-bold;
                }
            }

            @media (max-width: 1024px) {
                border-bottom: 1px solid $regularGrey;
                height: 70px;

                &:not(.percentage-wrap) {
                    display: none;
                }
            }

            &:first-child {
                width: 20%;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                padding-left: 15px;

                @media (max-width: 1440px) {
                    flex: none;
                }

                @media (max-width: 1024px) {
                    width: 100%;
                    height: 90px;
                    position: relative;

                    &:after {
                        content: "";
                        background-image: url(../assets/img/assets/tl-arrow.svg);
                        display: block;
                        position: absolute;
                        right: 20px;
                        width: 20px;
                        height: 20px;
                        transform: rotate(270deg);
                        background-size: cover;
                    }

                    &.profile-box-opened {
                        &:after {
                            transform: rotate(90deg);
                        }
                    }
                }

                p {
                    font-weight: $font-weight-bold;
                    &:before {
                        content: none;
                    }
                }

                span {
                    color: $darkBlue;
                }
            }

            &:last-child {
                border-right: none;
            }

            p {
                margin-bottom: 0;
                line-height: 1;
                position: relative;
                width: 100%;

                @media (max-width: 1440px) {
                    font-size: 14px;
                }
            }

            span {
                font-size: 14px;
                color: $borderGray;

                @media (max-width: 1440px) {
                    font-size: 12px;
                }
            }

            .profile-completion-percentage-wrap {
                margin-left: 15px;
            }

            // Here
            .single-chart {
                width: 60px;
                justify-content: space-around;
            }

            .circular-chart {
                display: block;
                max-width: 100%;
            }

            .circle-bg {
                fill: none;
                stroke: #eee;
                stroke-width: 3.8;
            }

            .circle {
                fill: none;
                stroke-width: 2.8;
                stroke-linecap: round;
                animation: progress 1s ease-out forwards;
            }

            @keyframes progress {
                0% {
                    stroke-dasharray: 0 100;
                }
            }

            .circular-chart.orange .circle {
                stroke: #ff9f00;
            }

            .circular-chart.green .circle {
                stroke: $green;
            }

            .circular-chart.blue .circle {
                stroke: #3c9ee5;
            }

            .percentage {
                fill: #666;
                font-family: sans-serif;
                font-size: 0.5em;
                text-anchor: middle;
            }

            &.percentage-wrap {
                @media (min-width: 1140px) {
                    pointer-events: none;
                }
            }
        }
    }
}

.blue-link {
    position: relative;
    color: $oceanBlue;
    font-family: $font-family-content;
    font-weight: $font-weight-medium;
    font-size: 14px;
    margin-bottom: 20px;
    &:hover {
        color: $darkBlue;
    }

    &.arrow {
        &:after {
            content: "";
            display: block;
            position: absolute;
            background-image: url("../assets/img/assets/icon-arrow-right.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 17px;
            height: 14px;
            right: -33px;
            top: 5px;
            transition: 0.2s all ease;
        }

        &:hover {
            &:after {
                right: -37px;
            }
        }

        &.back {
            padding-left: 25px;

            &:after {
                right: initial;
                transform: rotate(180deg);
                left: 0;
                width: 12.5px;
                height: 10px;
                top: 3px;
            }

            &:hover {
                &:after {
                    left: -5px;
                    filter: brightness(0.5);
                }
            }
        }
        @media (max-width: 991px) {
            font-size: 14px;
            &:after {
                width: 13px;
                height: 10px;
                right: -20px;
            }
            &:hover {
                &:after {
                    right: -25px;
                }
            }
        }
    }

    &.attachment-link {
        font-size: 14px;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .icon-attach {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background-size: cover;
        }

        &:hover {
            color: $darkBlue;
            text-decoration: none;
        }
    }
}

.invite-teammate {
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 30px;
    font-weight: $font-weight-bold;
    color: $btnColorGray;

    i {
        height: 20px;
        width: 20px;
        display: inline-block;
        line-height: 0;
        margin-right: 10px;
    }
}

.dashboard-box .headline {
    display: block;
    padding: 20px 30px;
    border-bottom: 1px solid $regularGrey;
    position: relative;
}

.dashboard-box .headline h3 i {
    color: $oceanBlue;
    font-size: 21px;
    line-height: 0;
    position: relative;
    top: 2px;
    margin-right: 8px;
    background-size: cover;
}

.dashboard-box .headline h3 i.icon-material-outline-gavel {
    font-size: 20px;
}

.dashboard-box .headline h3 i.icon-material-outline-supervisor-account {
    font-size: 24px;
    top: 3px;
}

.dashboard-box .headline h3 i.icon-feather-folder-plus {
    font-size: 20px;
    top: 2px;
    margin-right: 5px;
}

.button.big i.icon-feather-plus {
    font-size: 20px;
    top: 2px;
    margin-left: -4px;
    margin-right: 5px;
}

.dashboard-box .content.with-padding {
    padding: 50px 35px;
    padding-bottom: 35px;
}

.dashboard-box .chart {
    padding: 30px 20px 17px 18px;
}

.dashboard-box .headline .sort-by {
    float: none;
    display: inline-block;
    position: absolute;
    top: calc(50% + 3px);
    transform: translateY(-50%);
    right: 30px;
    z-index: 100;
    height: 33px;
}

.dashboard-note {
    border-radius: 4px;
    background-color: #f8f8f8;
    padding: 25px;
    padding-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.dashboard-note:last-child {
    margin-bottom: 65px;
}

.dashboard-note:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.15) #fff;
    transition: 0.3s;
    border-radius: 0 0 0 4px;
}

.dashboard-note p {
    line-height: 24px;
    margin-bottom: 20px;
    color: #777;
    font-size: 14.7px;
}

.note-priority {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    padding: 5px 9px;
    display: inline-block;
    line-height: 20px;
}

.note-priority.high {
    background-color: #dc3139;
}

.note-priority.medium {
    background-color: #f59110;
}

.note-priority.low {
    background-color: #3fb660;
}

.note-footer {
    display: flex;
}

.note-buttons {
    align-self: center;
    margin-left: auto;
}

.note-buttons a {
    font-size: 16px;
    padding-left: 3px;
    margin-top: 2px;
    display: inline-block;
    color: #888;
}

.note-buttons a:hover {
    color: #444;
}

.add-note-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 30px;
    padding-top: 10px;
    background: #fff;
    border-radius: 4px;
    width: calc(100% - 15px);
}

.add-note-button a.button {
    width: calc(100% + 15px) !important;
    max-width: calc(100% + 15px) !important;
}

ul.dashboard-box-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.dashboard-box-list > li {
    border-bottom: 1px solid #e4e4e4;
    padding: 0;
    line-height: 26px;
    padding: 22px 30px;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    flex-flow: row wrap;
    transition: 0.3s;
}

ul.dashboard-box-list > li:last-child {
    //border-bottom: none
}

ul.dashboard-box-list > li:hover {
    //background-color: #fcfcfc;
}

.dashboard-box-list .notification-text {
    font-size: 14.7px;
    flex: 1;
}

.dashboard-box-list .notification-icon {
    flex: 0 0 40px;
}

.dashboard-box-list .notification-text .star-rating {
    position: relative;
    top: -18px;
    line-height: 0;
    height: 0;
    display: inline-block;
    margin: 0 3px;
    transform: scale(0.93);
}

.buttons-to-right,
.dashboard-box-list .button.to-right {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: 0.3s;
    box-shadow: 0 0 10px 15px #fff;
    background-color: #fff;
}

.dashboard-box-list .buttons-to-right.always-visible,
.dashboard-box-list li:hover .buttons-to-right {
    opacity: 1;
}

.dashboard-box-list .buttons-to-right.always-visible:not(.single-right-button) {
    position: relative;
    transform: none;
    top: 0;
    opacity: 1;
    right: auto;
    display: inline-block;
    box-shadow: none;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
}

.dashboard-box-list .button {
    padding: 6px 15px;
    line-height: 20px;
    font-size: 13px;
    font-weight: $font-weight-bold;
    margin: 0;
    display: inline-block;
    box-shadow: 0 3px 8px rgba(25, 140, 202, 0.15);
}

.dashboard-box-list .button i[class*="icon-material"] {
    font-size: 18px;
    top: 3px;
    margin-right: 2px;
    margin-left: -2px;
}

.dashboard-box-list .buttons-to-right .button {
    margin: 0 0 -6px 2px;
}

.dashboard-box-list .buttons-to-right.always-visible .button {
    margin: 0 3px 0 0;
}

.dashboard-box .button.red {
    background-color: #dc3139;
    box-shadow: 0 3px 8px rgba(234, 65, 81, 0.15);
}

.dashboard-box .button.green {
    background-color: #40b660;
    box-shadow: 0 3px 8px rgba(64, 182, 96, 0.15);
}

.dashboard-box .button.dark {
    background-color: #333;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-box .button.gray {
    background-color: #eee;
    color: #666;
    box-shadow: none;
}

.dashboard-box .dashboard-box-list .button:not(.ico):hover,
.dashboard-box .button.red:not(.ico):hover,
.dashboard-box .button.green:not(.ico):hover,
.dashboard-box .button.gray:not(.ico):hover {
    color: #fff;
    background-color: #333;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-box .button.dark:not(.ico):hover {
    background-color: $oceanBlue;
    box-shadow: 0 3px 8px rgba(25, 140, 202, 0.15);
}

.dashboard-box .button.ico {
    width: 32px;
    text-align: center;
}

.dashboard-box .button.ico i {
    font-size: 15px;
    margin-left: -7px;
    top: 2px;
    position: relative;
}

.dashboard-box .button i.icon-material-outline-check {
    margin-left: -5px;
}

.dashboard-box .button.ico i[class*="icon-material"] {
    font-size: 18px;
    margin-left: -8px;
    top: 3px;
    position: relative;
}

.dashboard-box-list {
    box-shadow: none;
}

.dashboard-box-list button i {
    margin: 0;
    padding: 0;
    position: relative;
    top: 1px;
    font-size: 14px;
    line-height: 0;
    margin-left: -2px;
}

.dashboard-box .mark-as-read {
    float: none;
    background-color: #f4f4f4;
    position: absolute;
    right: 30px;
    top: 18px;
}

.button-info {
    background: rgba(255, 255, 255, 0.25);
    color: #fff;
    width: 19px;
    height: 19px;
    display: inline-block;
    text-align: center;
    line-height: 19px;
    border-radius: 50%;
    font-size: 11px;
    font-weight: $font-weight-bold;
    margin-left: 3px;
    margin-right: -2px;
}

.dashboard-status-button {
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 21px;
    padding: 2px 8px;
    display: inline-block;
    position: relative;
    top: -2px;
    margin: 0 0 4px 4px;
}

.dashboard-status-button.green {
    background: #e0f5d7;
    color: #449626;
}

.dashboard-status-button.yellow {
    background-color: #fbf6dd;
    color: #a18d29;
}

.dashboard-status-button.red {
    background-color: #ffe6e6;
    color: #ea5252;
}

.invoice-list-item ul {
    line-height: 22px;
    padding-bottom: 2px;
    color: #909090;
    list-style: none;
    padding: 0;
    margin: 0;
}

.invoice-list-item ul li {
    padding: 0;
    display: inline-block;
    font-size: 14.7px;
    margin-bottom: 5px;
}

.invoice-list-item ul li:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 11px;
    background-color: $regularGrey;
    position: relative;
    margin: 0 10px;
}

.invoice-list-item ul li:last-child:after {
    display: none;
}

.invoice-list-item strong {
    font-weight: $font-weight-bold;
    color: #333;
    margin-bottom: 5px;
    margin-top: -2px;
    display: block;
}

.paid,
.unpaid {
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    line-height: 20px;
    padding: 4px 8px;
    font-size: 13px;
    position: relative;
    top: -1px;
}

.paid {
    background-color: #40b660;
}

.unpaid {
    background-color: #dc3139;
}

.dashboard-box .job-listing-details {
    box-shadow: none;
    padding: 10px 0;
    align-items: center;
    flex: auto;
}

.dashboard-box .job-listing {
    box-shadow: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dashboard-box .job-listing.width-adjustment {
    max-width: 60%;
}

.dashboard-box .job-listing h3 a {
    color: #333;
    font-size: 18px;
}

.dashboard-box .job-listing .job-listing-company-logo {
    max-width: 50px;
}

.dashboard-box .job-listing .job-listing-description {
    padding-top: 0;
}

.dashboard-box .job-listing:hover {
    transform: none;
}

.dashboard-box .job-listing .job-listing-footer {
    background-color: transparent;
    padding: 0;
    border-radius: none;
}

.dashboard-box .freelancer-overview {
    padding: 15px 0;
}

.dashboard-box .freelancer-overview-inner {
    flex: auto;
    display: flex;
    align-items: center;
}

.dashboard-box .freelancer-overview .freelancer-name {
    text-align: left;
    margin: 0 0 0 30px;
    width: 100%;
}

.dashboard-box .freelancer-overview .freelancer-avatar {
    margin: 0;
    width: 90px;
}

.dashboard-box .freelancer-overview.manage-candidates .freelancer-avatar {
    align-self: flex-start;
}

.dashboard-task-info {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #f4f4f4;
    text-align: center;
    border-radius: 4px;
    padding: 14px 25px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-self: flex-end;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    justify-content: center;
}

.dashboard-box li:hover .dashboard-task-info {
    background-color: #f0f0f0;
}

.dashboard-task-info li {
    display: inline-block;
    border-right: 1px solid #d0d0d0;
    padding-right: 22px;
    margin-right: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-task-info li:last-child {
    margin-right: 0;
    padding-right: 0;
    border: none;
}

.dashboard-task-info li strong {
    display: block;
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #333;
    line-height: 22px;
}

.dashboard-task-info li span {
    display: block;
    font-size: 14px;
    color: #888;
    line-height: 20px;
}

.bid-acceptance {
    display: flex;
    color: #289c41;
    background-color: #e4f6e9;
    font-size: 18px;
    font-weight: $font-weight-bold;
    text-align: center;
    border-radius: 4px;
    padding: 12px 18px;
    display: inline-block;
    margin: 0 auto;
    line-height: 20px;
}

.bid-acceptance span {
    font-size: 14px;
    opacity: 0.86;
    line-height: 20px;
}

@media (max-width: 1366px) {
    .dashboard-box .job-listing.width-adjustment {
        max-width: 100%;
    }
    .dashboard-task-info {
        position: relative;
        right: 0;
        top: 0;
        transform: none;
        margin: 10px 0 20px;
    }
    .dashboard-task-info.bid-info {
        margin-bottom: 0;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .dashboard-box .freelancer-overview .freelancer-avatar {
        width: 60px;
    }
    .dashboard-box .freelancer-overview .freelancer-avatar .verified-badge {
        transform: scale(0.8);
        bottom: -5px;
        right: -5px;
    }
    .dashboard-box .freelancer-overview .freelancer-name {
        margin: 25px 0 0 0;
    }
    .dashboard-box .job-listing .job-listing-company-logo {
        display: none;
    }
    .dashboard-task-info.bid-info,
    .dashboard-task-info:not(.bid-info) {
        flex-direction: column;
        width: 100%;
    }
    .dashboard-task-info li {
        border-right: none;
        border-bottom: 1px solid #d0d0d0;
        padding: 0 0 10px;
        margin: 0 0 10px;
        width: 100%;
    }
    .dashboard-task-info li:last-child {
        border-right: none;
        border-bottom: none;
        padding: 0;
        margin: 0;
    }
}

.dashboard-box .boxed-list-item {
    padding: 10px 0;
    width: 100%;
}

.submit-field {
    margin-bottom: 28px;
    display: block;
}

.submit-field .pac-container {
    box-shadow: none;
    border: 1px solid $regularGrey;
    border-top: 1px solid #fff;
    padding-top: 0;
    z-index: 9;
    left: 0 !important;
    top: 47px !important;
    border-radius: 0 0 4px 4px;
}

.submit-field h5 {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #333;
    margin-bottom: 12px;
}

.submit-field h5 span {
    color: #888;
    font-weight: $font-weight-normal;
}

.help-icon {
    color: $oceanBlue;
    display: inline-block;
    font-size: 19px;
    font-weight: $font-weight-normal;
    line-height: 18px;
    height: 16px;
    position: relative;
    top: 3px;
    margin: 0 3px;
    font-style: normal;
    cursor: help;
}

.help-icon:before {
    content: "\e97c";
    font-family: feather-icons;
}

.avatar-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: none;
    margin: 0 10px 30px 0;
    transition: all 0.3s ease;
}

.avatar-wrapper:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.avatar-wrapper .profile-pic {
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
    object-fit: cover;
}

.avatar-wrapper .profile-pic:after {
    font-family: Feather-Icons;
    content: "\e9f1";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 120px;
    position: absolute;
    font-size: 60px;
    background: #f0f0f0;
    color: #aaa;
    text-align: center;
}

.avatar-wrapper .upload-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.avatar-wrapper .file-upload {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.dashboard-content-inner .small-footer {
    width: 100%;
    left: 0;
    padding: 25px 50px;
    position: absolute;
    bottom: 0;
}

.dashboard-footer-spacer {
    padding-top: 125px;
}

.dashboard-nav-inner {
    height: 95%;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1365px) and (max-width: 1600px) {
    .fun-fact:last-child {
        display: none;
    }
}

@media (max-width: 1365px) {
    .fun-fact {
        flex: 1 1 calc(50% - 30px);
        max-width: calc(50% - 30px);
    }
}

@media (max-width: 1024px) {
    .dashboard-content-inner {
        min-height: auto !important;
    }
    .dashboard-sidebar .dashboard-nav-container,
    .dashboard-content-inner {
        padding: 15px;
    }
    .dashboard-sidebar-inner {
        padding-bottom: 30px;
    }
    .dashboard-sidebar .simplebar-content {
        overflow-x: hidden;
        padding-bottom: 0 !important;
    }
    // .dashboard-nav-inner {
    //     padding: 30px 0;
    //     margin-bottom: 0;
    // }

    .dashboard-sidebar {
        flex: 1;
        width: 100%;
        background-color: $lightGrey;
        box-shadow: none;
        height: auto !important;
    }
    .full-page-sidebar .simplebar-track,
    .dashboard-sidebar .simplebar-track {
        display: none;
    }
    .full-page-sidebar-inner,
    .dashboard-sidebar-inner {
        height: auto !important;
    }
    .dashboard-container {
        display: block;
        width: 100%;
        flex-wrap: wrap;
        height: auto !important;
    }
    .dashboard-content-container {
        height: auto !important;
    }
    .dashboard-content-container .simplebar-scrollbar {
        display: none;
    }
    .dashboard-nav {
        width: 100%;
        margin: 0;
        border-radius: 0 0 4px 4px;
        position: relative;
        min-height: auto;
        opacity: 1;
        visibility: hidden;
        max-height: 0;
        padding: 0;
        transition: all 0.3s;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background: #fff;
    }
    .dashboard-nav ul:first-child {
        margin: 0;
    }
    .dashboard-nav ul {
        margin-top: 15px;
    }

    .dashboard-nav.is-active {
        opacity: 1;
        visibility: visible;
        max-height: 100%;
    }
    .dashboard-nav ul:before {
        padding: 0 29px;
    }
    .dashboard-nav ul li a {
        padding: 10px 25px;
    }

    .dashboard-nav.active ul li.active-submenu ul {
        visibility: visible;
        opacity: 1;
        max-height: 300px;
    }
    button.dashboard-responsive-nav-trigger {
        width: 100%;
        margin: 0;
        background-color: #fff;
        border-radius: 5px;
        color: $darkBlue;
        padding: 16px 30px;
        font-weight: 700;
        transition: 0.2s;
        display: block;
        line-height: 26px;
        box-shadow: 0px 4px 10px #1a284d14;
        text-align: start;
        font-size: 16px;
    }
    button.dashboard-responsive-nav-trigger span.trigger-title {
        padding-left: 37px;
    }
    button.dashboard-responsive-nav-trigger .hamburger-inner,
    button.dashboard-responsive-nav-trigger .hamburger-inner::before,
    button.dashboard-responsive-nav-trigger .hamburger-inner::after {
        background-color: $oceanBlue;
    }
    button.dashboard-responsive-nav-trigger .hamburger {
        top: 2px;
        left: 0;
        transform: scale(0.66);
    }
    button.dashboard-responsive-nav-trigger i {
        margin-right: 8px;
        margin-left: -2px;
        font-size: 28px;
        line-height: 0;
        position: relative;
        top: 6px;
    }
    button.dashboard-responsive-nav-trigger.active {
        border-radius: 4px 4px 0 0;
    }
    .buttons-to-right:not(.single-right-button) {
        opacity: 1;
    }
    .fun-facts-container {
        width: calc(100% + 15px);
        margin-bottom: -15px;
    }
    .fun-fact {
        flex: 1 1 calc(50% - 15px);
        max-width: calc(50% - 15px);
        margin: 0 15px 15px 0;
    }
}

@media (max-width: 768px) {
    .fun-fact {
        flex: 1 1 100%;
        max-width: 100%;
    }
    #titlebar #breadcrumbs,
    .dashboard-headline #breadcrumbs {
        right: 0;
        position: relative;
        top: 0;
        transform: none;
        margin-top: 30px;
        max-width: 100%;
    }
    .buttons-to-right:not(.single-right-button) {
        position: relative;
        transform: none;
        top: 0;
        margin: 10px 0 0;
        opacity: 1;
        right: auto;
        display: inline-block;
        box-shadow: none;
        width: 100%;
    }
    .dashboard-box .headline .sort-by {
        float: none;
        display: inline-block;
        position: relative;
        top: 0;
        transform: none;
        right: 0;
        top: 5px;
        left: -8px;
    }
    .dashboard-box .headline .sort-by .bootstrap-select .dropdown-menu {
        left: 0;
        right: auto;
    }
}

body {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #546285 !important;
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
}

body.gray {
    background-color: #fbfbfb;
}

body p {
    font-size: 14px;
    // line-height: 17px;
    color: $darkBlue;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select,
input[type="button"],
input[type="submit"],
button,
body .pac-container {
    font-family:
        Inter,
        helveticaneue,
        helvetica neue,
        Helvetica,
        Arial,
        sans-serif;
    text-transform: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
    font-weight: $font-weight-bold;
    margin: 0;
    padding: 0;
    font-family: $font-family-content;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 12px;
}

h3.page-title {
    font-size: 20px;
    line-height: 32px;
}

.section {
    display: block;
}

.section.white {
    background: #fff;
}

.section.gray {
    background: #f9f9f9;
}

.section.border-top {
    border-top: 1px solid $regularGrey;
}

.section.border-bottom {
    border-bottom: 1px solid $regularGrey;
}

.section-headline {
    display: block;
    position: relative;
    padding-right: 20%;
}

.section-headline.centered {
    text-align: center;
    padding: 0;
}

.section-headline span {
    font-size: 18px;
    line-height: 26px;
    font-weight: $font-weight-normal;
    color: #888;
    margin-top: 0;
    display: block;
}

.section-headline h1,
.section-headline h2,
.section-headline h3,
.section-headline h4 {
    font-size: 26px;
    line-height: 36px;
    font-weight: $font-weight-normal;
    color: #333;
}

.section-headline h4 {
    font-size: 22px;
    line-height: 34px;
}

.section-headline.border-top {
    border-top: 1px solid $regularGrey;
}

h1.in-a-box,
h2.in-a-box,
h2.in-a-box,
h3.in-a-box,
h5.in-a-box {
    display: inline-block;
    font-size: 18px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    padding: 8px 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.section-headline h5 {
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 30px;
}

a.headline-link {
    position: absolute;
    right: 0;
    bottom: 0;
}

a.headline-link.bottom-icon:after {
    content: "\e957";
    top: 3px;
}

a.headline-link:after {
    content: "\e995";
    font-family: material-icons;
    font-size: 21px;
    line-height: 0;
    position: relative;
    top: 4px;
    margin-left: 5px;
}

@media (max-width: 768px) {
    a.headline-link {
        position: relative;
        margin-top: 10px;
        display: inline-block;
    }
}

@media (max-width: 992px) {
    .hide-under-992px {
        display: none;
    }
}

@media (max-width: 768px) {
    .hide-under-768px {
        display: none;
    }
}

@media (max-width: 480px) {
    .hide-under-480px {
        display: none;
    }
}

.icon-set-container {
    display: block;
    width: calc(100% + 30px);
    left: -15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.glyph.fs1 {
    position: relative;
    font-size: 20px;
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    flex: 1 1 calc(25% - 30px);
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 15px;
    transition: 0.3s;
    color: #666;
}

.glyph.fs1:hover {
    transform: translateY(-5px);
    background-color: #333;
    color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.glyph.fs1:hover span:not([class*="mls"]) {
    background-color: #fff;
    color: #333;
}

.glyph.fs1 .fs0,
.glyph.fs1 fieldset {
    display: none;
}

.glyph.fs1 span:not([class*="mls"]) {
    vertical-align: middle;
    top: -4px;
    height: 40px;
    width: 40px;
    background: rgba(0, 0, 0, 0.04);
    display: inline-block;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    font-size: 24px;
    transition: 0.3s;
    position: relative;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.glyph.fs1 .mls:not([class*="icon-"]) {
    max-width: 80%;
    display: inline-block;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    top: 0;
    vertical-align: middle;
    padding-left: 55px;
}

.content-left-offset {
    padding-left: 30px;
}

.content-right-offset {
    padding-right: 30px;
}

@media (max-width: 992px) {
    .content-left-offset {
        padding-left: 15px;
    }
    .content-right-offset {
        padding-right: 15px;
    }
}

.btn-action {
    display: inline-block;
    padding: 9px 5px 4px 8px;
    border: 1px solid $regularGrey;
    border-radius: 5px;

    &.disabled {
        background-color: $regularGrey;
        // color: $borderGray;
    }

    &.disabled {
        pointer-events: none;
        border: 1px solid $regularGrey;
    }

    i {
        width: 24px;
        height: 24px;
        display: block;
        filter: brightness(0.5);
    }
}

.dev-skills {
    display: block;
    margin-bottom: 0;
    padding-left: 0;
    overflow: hidden;

    &.small {
        max-width: 350px;
        li {
            display: none;
            margin-top: 5px;
            p {
                font-size: 11px;
                padding: 2px 7px;
            }
        }
    }

    &.expanded {
        li {
            display: block;

            &.show-all-skills {
                display: none;
            }
        }
    }

    li {
        float: left;
        list-style: none;
        margin-right: 8px;
        margin-top: 8px;
        font-family: $font-family-content;
        font-weight: $font-weight-medium;
        color: $oceanBlue;
        letter-spacing: 0.35px;

        a,
        span,
        p {
            color: $oceanBlue;
            font-size: 12px;
            line-height: 1.3;
            padding: 4px 14px;
            background: #e6eff7 0% 0% no-repeat padding-box;
            border-radius: 155px;
            pointer-events: none;
            // text-transform: uppercase;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            display: block;
        }

        &.show-all-skills {
            cursor: pointer;
            display: block;
        }

        .transparent {
            border: 1px solid #adb4c742 !important;
            background: transparent !important;
            color: $btnColorGray !important;
        }
    }

    @media (max-width: 768px) {
        padding-left: 0;

        li {
            display: flex !important;
        }
    }
}

.portal,
.logs,
.account,
.referral {
    overflow-x: hidden;

    .select2-container {
        width: 100% !important;

        .select2-dropdown {
            border: 1px solid $regularGrey;

            .select2-results__options {
                .select2-results__option {
                    color: $darkBlue;

                    &.select2-results__option--highlighted {
                        color: $oceanBlue;
                        text-decoration: none;
                        background-color: rgba(25, 140, 202, 0.07);
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
    }

    .modal:not(#talentFeedbackModal),
    .modal-mask {
        &.show {
            display: flex !important;
            align-items: center;
        }

        .modal-dialog {
            max-width: 540px;
            width: 100%;

            .modal-content {
                border: none;

                .modal-header {
                    align-items: center;
                    padding-left: 35px;

                    h5 {
                        color: $btnColorGray;
                    }

                    .close {
                        padding: 18px 23px;
                        border-left: 1px solid #dee2e6;
                    }

                    .modal-title {
                        span {
                            font-weight: $font-weight-normal;
                        }
                    }
                }
                .modal-body {
                    max-height: 80vh;
                    overflow-y: auto;
                }

                form {
                    padding: 20px;

                    .modal-body {
                        color: $darkBlue;
                    }

                    .modal-footer {
                        border-top: none;

                        button {
                            background-color: $required;
                            display: block;
                            width: 100%;
                            text-transform: uppercase;
                            font-family: $font-family-head;
                            border: 1px solid $required;
                            letter-spacing: 0.35px;
                            font-weight: $font-weight-bold;
                            -webkit-font-smoothing: antialiased;
                            font-size: 14px;
                            padding: 14px;

                            &:hover {
                                background-color: transparent;
                                color: $btnColorGray;
                                border: 1px solid $btnColorGray;
                            }
                        }
                    }
                }
            }
        }

        .select-button {
            float: left;
            display: flex;
            align-items: center;
        }

        .modal-body {
            padding: 30px 35px;

            .form-group {
                .col-form-label {
                    font-size: 14px;
                    font-family: $font-family-content;
                    color: $darkBlue;
                    font-weight: $font-weight-bold;
                    padding-bottom: 10px;
                    line-height: 18px;
                    display: flex;

                    i {
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        background-size: cover;
                        margin-left: 6px;
                    }
                }

                input[type="text"],
                textarea,
                select,
                input[type="date"],
                input[type="url"],
                input[type="email"] {
                    @include placeholder {
                        color: $borderGray;
                    }
                    border: 1px solid $regularGrey;
                    border-radius: 3px;
                }

                @media (max-width: 1024px) {
                    .time-log-date {
                        text-align: left !important;
                        margin-top: 10px;

                        button {
                            float: left;
                        }
                    }

                    .btn-close {
                    }
                }
            }

            &#attachmentsModalBody {
                .attachments-container {
                    width: 100%;

                    .atachment-link-wrap {
                        width: 100%;
                        margin-bottom: 20px;
                        border: 1px solid $regularGrey;
                        position: relative;
                        border-radius: 4px;

                        &:after {
                            content: "";
                            position: absolute;
                            top: -1px;
                            right: -1px;
                            border-width: 0 20px 20px 0;
                            border-style: solid;
                            border-color: $regularGrey;
                            border-right-color: white;
                            border-left-color: white;
                            transition: 0.3s;
                            border-radius: 0 0 0 4px;
                            z-index: 1;
                        }
                        .attachment-box {
                            margin: 0;
                            border-radius: none;
                            &:after,
                            &:before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            .col-md-12 {
                padding: 0;

                button {
                    min-width: initial;

                    &.col-md-9 {
                        max-width: 73%;
                    }

                    @media (max-width: 1024px) {
                        &.col-md-9,
                        &.col-md-3 {
                            max-width: 100%;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    .modal-mask {
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-footer {
            border: none;
            padding: 30px 35px;
        }

        &.deleteLogModal {
            .modal-footer {
                padding-top: 0;

                button {
                    background-color: $required;
                    display: block;
                    width: 100%;
                    text-transform: uppercase;
                    font-family: Poppins, sans-serif;
                    border: 1px solid $required;
                    letter-spacing: 0.35px;
                    font-weight: $font-weight-bold;
                    -webkit-font-smoothing: antialiased;
                    font-size: 14px;
                    padding: 14px;

                    &:hover {
                        background-color: transparent;
                        color: $btnColorGray;
                        border: 1px solid $btnColorGray;
                    }
                }
            }
        }
    }

    #wrapper {
        z-index: unset;
    }
}

.modal:not(#talentFeedbackModal) {
    .modal-header {
        .modal-title {
            color: $btnColorGray;
            font-size: 15px;
            font-weight: $font-weight-bold;
        }
    }
    .modal-body {
        // font-family: Inter, sans-serif;
        label {
            font-size: 14px;
            font-family: $font-family-content;
            font-weight: $font-weight-normal;
        }
        textarea {
            font-size: 14px;
            border: 1px solid #e1e3eb;
            color: #1a284d;
        }
        input {
            font-size: 14px;
            color: #1a284d;
        }
    }
}

.details-wrap {
    float: left;
    margin-top: 20px;

    @media (max-width: 1199px) {
        margin-top: 0;
    }

    .details {
        padding: 15px;

        @media (max-width: 1024px) {
            padding: 0;
        }

        h3 {
            font-size: 14px;
            color: $btnColorGray;
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-bottom: 40px;
        }

        .detail-box {
            padding-bottom: 20px;

            p {
                font-size: 16px;
                font-family: $font-family-content;
                font-weight: $font-weight-bold;
                margin-bottom: 5px;
                line-height: 23px;
                display: -webkit-box;
                display: flex;
                -webkit-box-align: center;
                align-items: center;

                i {
                    width: 24px;
                    height: 24px;
                    margin-right: 15px;
                    background-size: cover;
                }
            }
            span {
                font-size: 12px;
                color: $btnColorGray;
                padding-left: 40px;
            }
        }
    }

    &.contract-details {
        @media (max-width: 1024px) {
            margin-top: 0;
        }

        .btn-action {
            float: right;

            @media (max-width: 1024px) {
                float: left;
            }
        }
    }
}

.talent-right-sidebar {
    .dashboard-box {
        &.dev-invite-box {
            .dashboard-box-list {
                li {
                    display: block;
                    padding: 30px 40px;
                    position: relative;

                    h5 {
                        color: $btnColorGray;
                        margin-bottom: 10px;
                    }

                    h3 {
                        font-size: 24px;
                        line-height: 35px;
                        margin-bottom: 20px;

                        @media (min-width: 1240px) and (max-width: 1440px) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 10px;
                        font-weight: $font-weight-bold;
                        line-height: 18px;
                    }

                    &.center {
                        text-align: center;
                        padding: 25px 40px;
                        a {
                            font-size: 14px;
                            font-weight: $font-weight-bold;
                            line-height: 1.5;
                        }
                    }

                    img.arrow {
                        position: absolute;
                        bottom: 30px;
                        right: 40px;
                        width: 20px;
                        transition: 0.2s all ease;
                    }

                    &:hover {
                        img.arrow {
                            right: 20px;
                        }
                    }
                }
            }
        }

        .dashboard-box-list {
            li {
                h5 {
                    width: 60%;
                    line-height: 1.6;
                }

                span {
                    right: 30px;
                    position: absolute;
                    font-size: 28px;
                    font-weight: $font-weight-bold;
                    color: $darkBlue;
                    font-family: $font-family-head;
                }
            }
        }

        &.dev-stats {
            li {
                display: flex;
            }
        }
    }

    @media (max-width: 1024px) {
        padding-top: 0 !important;
    }
}

#snackbar {
    position: fixed;
    bottom: 10px;
    z-index: 123;
    left: 0;
    margin: auto;
    width: 100%;
    transition: 0.3s all ease;

    .snackbarContent {
        width: 320px;
        text-align: center;
        margin: auto;
        display: block;
        background-color: $oceanBlue;
        color: white;
        border-radius: 3px;
        cursor: default;
        padding: 10px;
    }
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.position-box .position-status .position-status-step {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: -17px;
    color: #737373;
    width: auto;

    #arrow {
        height: 62px;
        width: 155px;
    }

    .position-status-name {
        position: absolute;
        width: 70%;
        height: 100%;
        text-align: center;
        font-size: 12px;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        line-height: 14px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: #737373;

        div {
            min-width: 100%;
        }
    }

    .arrow-path {
        fill: #e8e8e8;
    }

    &.success {
        color: white !important;
        .arrow-path {
            fill: $org;
        }

        .position-status-name {
            color: white !important;
        }
    }

    &.in-progress {
        color: white !important;
        .arrow-path {
            fill: $blue;
        }

        .position-status-name {
            color: white !important;
        }
    }

    &.declined {
        color: white;
        .arrow-path {
            fill: $red;
        }

        .position-status-name {
            color: white !important;
        }
    }
}

.position-box {
    .position-name .position-title {
        text-align: left;
        font-size: 14px;
        color: #33475b;
    }
    .position-name .position-subtitle {
        text-align: left;
        font-size: 12px;
        color: #737373;
    }
}

.logs {
    .modal-body {
        max-height: 75vh;
        overflow-y: scroll;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 1024px) {
            max-height: 55vh;
        }
    }

    &.timelog-modal-opened {
        overflow: hidden;
    }
}

.time-log-calendar {
    @media (max-width: 1024px) {
        .formatted-date {
            &.title {
                display: block;
                text-align: center !important;
            }
        }

        .header-actions {
            text-align: center !important;
        }
    }
}

.timelog-datepicker-wrapper {
    width: 100%;

    .vdpComponent {
        top: 0 !important;
    }
}

.dev-invite-link {
    position: relative;

    .copy-clipboard {
        background-image: url(../assets/img/assets/icon-clipboard.svg);
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        right: 1px !important;
        bottom: 1px;
        z-index: 12;
        background-color: white;
        padding: 20px;
        background-repeat: no-repeat;
        background-position: center;
        border-left: 1px solid $regularGrey;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
    }

    input {
        border: 1px solid $regularGrey;
    }
}

.headline {
    .select2-container {
        min-width: 140px !important;
        left: initial;
        right: 0;
    }
}

.applicant {
    #tippy-1.tippy-popper {
        right: -100px !important;
        left: 320px !important;
        width: 330px;
        border-radius: 5px;
        border-radius: 5px !important;
        color: white;
        font-size: 14px;
        padding: 25px !important;
        transform: translate(-55px, 100%) !important;
    }
    #tippy-1.tippy-popper .tippy-tooltip.dark-theme {
        padding: 25px 10px !important;
        background-color: #1a284d !important;
        border-radius: 5px !important;
    }
    #tippy-1.tippy-popper .tippy-arrow {
        left: -13px !important;
        transform: rotate(270deg);
        top: 38px !important;
        border-bottom-color: #1a284d !important;
    }
}

.hide-wrap {
    display: none !important;
}

.select2-container {
    .form-filter-container {
        &.select2-selection {
            border: none !important;
            font-size: 14px;
            font-weight: $font-weight-normal;
            font-family: $font-family-head;

            .select2-selection__rendered {
                color: $btnColorGray;
                text-align: right;
            }
        }
    }
}

.status {
    .badge {
        padding: 7px 14px;
        color: white;
        border-radius: 3px;
        font-size: 13px;
        -webkit-font-smoothing: antialiased;

        &.badge-sm {
            font-size: 0.75rem;
            padding: 4px 8px;
        }

        &.badge-success,
        &.badge-paid {
            background-color: $org;
        }

        &.badge-warning {
            color: #333;
            background-color: var(--bs-warning);
        }

        &.badge-secondary {
            background-color: var(--bs-secondary);
        }

        &.badge-AUTHORISED,
        &.badge-awaiting-payment,
        &.badge-pending {
            background-color: $oceanBlue;
        }

        &.badge-VOIDED,
        &.badge-overdue {
            background-color: $required;
        }
    }
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.loader {
    margin: 30px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.5);
    border-right: 1.1em solid rgba(255, 255, 255, 0.5);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.5);
    border-left: 1.1em solid $darkBlue;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.avoid-clicks {
    pointer-events: none;
}

.switch-container {
    label {
        padding-left: 45px;
        top: 8px;
    }
    span {
        top: 0px;
    }
}

input:checked + .switch-button {
    background-color: #24b768;
}

.alert-custom {
    display: block;
    color: $darkBlue;
    font-family: $font-family-content;
    padding: 14px;

    p {
        margin-bottom: 0;
        padding-left: 35px;

        &:before {
            content: "";
            background-size: cover;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            left: 20px;
            top: 18px;
        }
    }

    a {
        color: $darkBlue;
        font-weight: $font-weight-bold;
        -webkit-font-smoothing: antialiased;

        &:hover {
            color: $oceanBlue;
        }
    }

    &.alert-warning {
        border: 1px solid $yellow;
        background-color: $yellowOpacity;

        p {
            &:before {
                background-image: url(../assets/img/assets/icon-warning-yellow.svg);
            }
        }
    }

    &.alert-danger {
        border: 1px solid $required;
        background-color: $requiredLight;

        p {
            &:before {
                background-image: url(../assets/img/assets/icon-warning.svg);
            }
        }
    }
}

.positions {
    #confirmationModal {
        .modal-dialog {
            max-width: 550px;

            .modal-content {
                .modal-body {
                    color: $darkBlue;
                }

                .modal-footer {
                    border-top: none;

                    button {
                        width: 30%;
                    }

                    a {
                        width: 65%;
                    }

                    @media (max-width: 1024px) {
                        flex-wrap: initial;

                        button,
                        a {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

.adv-progress-bar {
    width: 100%;
    height: 6px;
    right: 0;
    border-radius: 10px;
    background-color: $borderGrayOpacity30;
    position: relative;

    span {
        background-color: $green;
        height: 6px;
        border-radius: 10px;
        left: 0;
    }
}

.w-250 {
    max-width: 250px;
}

.w-120 {
    max-width: 120px;
}

.w-150 {
    max-width: 150px;
}

.w-180 {
    max-width: 180px;
}

.accordion-quickstart {
    .accordion {
        height: 100%;
        width: 320px;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: #f9fafc;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 70px;
        box-shadow: -8px 0px 20px #1a284d14;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card {
            border: none;
            padding: 0 20px;
            background-color: #f9fafc;

            .getting-started {
                padding: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #f9fafc;
                border-bottom: 1px solid #e1e3eb;

                .closebtn {
                    content: "";
                    right: 7%;
                    position: absolute;
                    background-image: url(../assets/svg/icons/light_close.svg);
                    width: 25px;
                    height: 25px;
                    background-size: cover;
                }
            }

            .card-header {
                border-bottom: none;
                background-color: #f9fafc;
                padding: 20px 0 0 0;

                .card-title {
                    background-color: #fff;
                    box-shadow: 0px 2px 4px #1a284d14;
                    border-radius: 5px;

                    .card-btn:not(.collapsed) {
                        background-color: #e8eaf2;
                    }
                    .card-btn.collapsed {
                        background-color: white;
                    }

                    .card-btn {
                        font-size: 14px;
                        color: #1a284d;
                        text-decoration: none;
                        width: 100%;
                        text-align: left;
                        display: flex;
                        align-items: center;

                        &:focus {
                            box-shadow: none;
                        }

                        &:after {
                            content: "";
                            right: 7%;
                            position: absolute;
                            background-image: url(../assets/svg/icons/light_chevron_right.svg);
                            width: 30px;
                            height: 30px;
                            background-size: cover;
                        }
                    }
                    .card-btn[aria-expanded="true"]::after {
                        content: "";
                        right: 7%;
                        position: absolute;
                        background-image: url(../assets/svg/icons/light_chevron_right.svg);
                        width: 30px;
                        height: 30px;
                        transform: rotate(90deg);
                    }

                    .sample-profile-btn {
                        font-size: 14px;
                        color: #1a284d;
                        text-decoration: none;
                        width: 87%;
                        text-align: left;
                        padding-left: 35px;

                        &:before {
                            content: "";
                            display: block;
                            width: 17px;
                            height: 17px;
                            background-image: url(../assets/img/assets/sample_profile.svg);
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                            position: absolute;
                            top: 27px;
                            left: 30px;
                        }
                    }
                }
            }

            .card-body {
                background-color: #fff;

                p {
                    font-size: 14px;
                    color: #666;
                }

                .accordion-list {
                    padding: 0;

                    ul {
                        list-style: none;
                        counter-reset: li;
                    }

                    li {
                        padding: 10px 0;
                        display: flex;
                        border-bottom: 1px solid #e1e3eb;
                        font-size: 14px;
                        counter-increment: li;

                        &:last-child {
                            border: none;
                        }

                        &::before {
                            content: counter(li);
                            color: #343f5c;
                            display: inline-table;
                            width: 20px;
                            height: 20px;
                            font-size: 9px;
                            margin-right: 12px;
                            text-align: center;
                            border-radius: 50%;
                            background-color: #e1e3eb;
                            line-height: 20px;
                            margin-top: 3px;
                        }
                    }
                }
            }
        }

        .sample-profile-card {
            width: 100%;
            padding-bottom: 60px;
        }
    }
}

.tabs-filter {
    .dashboard-box-list {
        display: block;
        width: 100%;
        margin-top: 10px;

        li {
            padding: 0 !important;

            p {
                margin-bottom: 0;
                font-size: 14px;
                padding: 16px 0px;
                margin-right: 25px;

                button {
                    color: $btnColorGray;
                }

                &.active {
                    color: $oceanBlue;
                    border-bottom: 2px solid $oceanBlue;
                    font-weight: $font-weight-medium;

                    button {
                        color: $oceanBlue;
                    }
                }
            }
        }

        &.tab-contents {
            .tab-content-wrap {
                display: none;

                &.notifications,
                &.online-presence,
                &.account-deactivation {
                    .form-wrap {
                        label {
                            font-family: $font-family-content;
                            font-weight: $font-weight-bold;

                            &.notification-category-lbl {
                                font-size: 16px;
                            }
                        }

                        .form-group {
                            position: relative;

                            .form-check-input {
                                display: none;
                            }

                            label {
                                font-family: $font-family-content;
                                font-weight: $font-weight-bold;
                                font-size: 14px;

                                span {
                                    display: block;
                                    color: $btnColorGray;
                                    padding-top: 5px;
                                }

                                &:before {
                                    content: "";
                                    background-image: none;
                                    background-color: white;
                                    width: 19px;
                                    height: 19px;
                                    border: 1px solid $oceanBlue;
                                    border-radius: 3px;
                                    left: 0;
                                    top: 2px;
                                    position: absolute;
                                }
                            }

                            .form-check-input:checked + label:before {
                                content: "";
                                background-image: url(../assets/img/assets/round-checkbox.svg);
                                width: 24px;
                                height: 24px;
                                background-size: cover;
                                color: black;
                                position: absolute;
                                border: none;
                                top: 0;
                                left: -3px;
                            }

                            .blue-link {
                                position: absolute;
                                top: 20px;
                                right: 20px;
                            }
                        }

                        .note {
                            color: $btnColorGray;
                        }
                    }

                    .btn-delete {
                        min-width: 220px;
                        line-height: 36px;
                        font-size: 14px;
                    }
                }

                &.online-presence {
                    p {
                        padding: 0 !important;
                    }
                }

                .payment-settings-wrap {
                    .category-lbl {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
