@import "@app/styles/sass/elements/_variables.scss";

.email-preview-wrapper {
    white-space: pre-line;
    background-color: $lightGrey;
    padding: 45px;
    border-radius: 10px;

    .logo {
        width: 110px;
        height: 35px;
        margin-bottom: 30px;
    }

    .title {
        color: #1a284d;
        font-family: $font-family-head;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
    }

    .email-body {
        font-size: 16px;
        color: #343f5c;
    }

    .join-button-wrapper {
        display: flex;
        justify-content: center;
        margin: 34px 0;

        .join-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            width: 300px;
            height: 38px;
            background-color: $green;
            font-weight: 700;
            color: white;
            border: 1px solid $green;
            padding: 4px 15px;
        }
    }

    .greetings-info p {
        font-size: 16px;
        color: $dark-gray;
        line-height: 24px;
    }
}
