@import "@app/styles/sass/elements/variables";

.helper-text {
    margin-top: 0.25rem;

    .message {
        font-size: 80%;

        &.success-message {
            color: $darkGreen;
        }

        &.error-message {
            color: $required;
        }

        &.info-message {
            color: $darkOceanBlue;
        }
    }
}
