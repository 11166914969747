.tab-navbar {
    li {
        margin-right: 24px;

        @media (max-width: 532px) {
            margin-right: 20px;
        }
    }

    .link {
        color: #546285;

        &:hover {
            color: #198cca;
        }

        &::after {
            margin-top: 8px;
        }

        &.active {
            color: #198cca;
        }

        &.active::after {
            width: 100%;
            opacity: 1;
            transition:
                width 0.3s,
                opacity 0.6s;
        }
    }
}
