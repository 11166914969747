.position-snippet {
    padding: 10 0;
    min-width: 200;

    &-empty {
        width: 200;
        height: 100;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-wrap {
        ul {
            margin: 0;
            padding: 0;

            li {
                font-size: 12px;
                color: #546285;
                display: inline;
                margin-right: 3px;

                &.dark-gray {
                    color: #343f5c;
                    font-weight: 500;
                }
            }
        }
    }
}
