@use "@app/styles/sass/elements/modals" with (
    $modalSelector: ".modal #AvailabilityModal"
);
@use "@app/styles/sass/elements/preferences";

@include preferences.preference;

@include modals.modal;

#AvailabilityModal {
    max-width: 680px;
}
