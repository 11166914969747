.parent-item {
    position: relative;

    .dropdown-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 15px;
        right: 10px;
        transform: rotate(90deg);
        padding: 0;
        text-align: center;
        font-size: 12px;
    }

    &.open {
        ul {
            visibility: visible !important;
            max-height: unset !important;
            opacity: 1 !important;
            pointer-events: all !important;
            padding-top: 2px !important;

            li {
                margin-bottom: 2px !important;
            }

            li button {
                height: 35px !important;
                padding-left: 35px !important;
            }

            li:hover button {
                padding-left: 33px !important;
            }
        }

        .dropdown-icon {
            transform: rotate(270deg);
            width: 18px;
            height: 18px;
            position: absolute;
            top: 15px;
            right: 10px;
            padding: 0;
            text-align: center;
            font-size: 12px;
        }
    }
}
