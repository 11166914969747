#DirectIntroductionModal {
    max-width: 613px;

    .modal-content {
        padding: 35px;
    }

    .modal-header {
        padding: 0 0 16px 0;
    }

    .modal-body {
        padding: 16px 0;
    }

    .border-error {
        border: 1px solid #f1553f !important;
        background-color: rgba(241, 85, 63, 0.05) !important;
    }
}
