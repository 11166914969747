@import "@app/styles/sass/elements/variables";

.interview-note-wrapper {
    position: relative;
    padding: 2rem 0;

    .char-counter {
        position: absolute;
        right: 15px;
        bottom: calc(2rem + 15px);
        color: $borderGray;
    }
}
