@import "@app/styles/sass/elements/variables";

$disabledTileColor: rgba(0, 0, 0, 0.38);

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}

.react-calendar {
    width: 320px;
    max-width: 100%;
    background: white;
    font-family: $font-family-content;
    line-height: 1.125em;
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
        rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
        rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;

    button {
        margin: 0;
        outline: none;

        &:enabled:hover {
            cursor: pointer;
        }
    }

    &__tile {
        font: inherit;
        padding: 8px;
        max-width: 100%;
        font-size: 13px;
        background: none;
        text-align: center;
        line-height: 16px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &--active,
        &--hasActive {
            color: white;
            background: rgb(25, 118, 210);

            &:enabled:hover,
            &:enabled:focus {
                will-change: background;
                background: rgb(21, 101, 192);
            }
        }

        &--now:not(&--active):not(&--hasActive) {
            border: 1px solid #ccc;
        }

        &:not(&--active):not(&--hasActive):disabled {
            color: $disabledTileColor;
        }
        &--active:disabled,
        &--hasActive:disabled {
            color: #ccc;
        }

        &:not(&--active):not(&--hasActive):enabled:hover,
        &:not(&--active):not(&--hasActive):enabled:focus {
            background: rgba(25, 118, 210, 0.04);
        }
    }

    &__navigation {
        display: flex;
        height: 44px;
        margin-bottom: 0;
        background-color: #fafafa;

        button {
            min-width: 44px;
            background: none;

            &:disabled {
                background-color: #f0f0f0;
            }

            &:enabled:hover,
            &:enabled:focus {
                background-color: #e6e6e6;
            }
        }

        &__label {
            font-weight: bold;
        }
    }

    &__viewContainer {
        padding: 8px;
    }

    &__month-view__weekdays {
        font: inherit;
        cursor: default;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.03333em;
        font-size: 0.75rem;
        text-align: center;
        font-weight: 400;
        line-height: 1.66;
        height: 40px;
        text-transform: uppercase;
        display: grid !important;
        grid-gap: 2px;
        grid-template-columns: repeat(7, 1fr);

        &__weekday {
            padding: 8px;

            abbr[title],
            abbr[data-bs-original-title] {
                cursor: default;
                text-decoration: none;
            }
        }
    }

    &__month-view__days {
        display: grid !important;
        grid-gap: 2px;
        grid-template-columns: repeat(7, 1fr);

        &__day {
            height: auto;
            min-height: 32px;
            aspect-ratio: 1;
            border-radius: 50%;
        }
    }
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    &:not(.react-calendar__tile--active, .react-calendar__tile--hasActive) {
        color: rgba(0, 0, 0, 0.6);

        &:disabled {
            color: $disabledTileColor;
        }
    }
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1.5em 0.5em;
}
