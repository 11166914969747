@import "./mixins";
@import "./elements/forms";

.login,
.password,
.reset,
.signup {
    background-image: url("../assets/img/assets/adeva_login_bg.svg");
    background-size: cover;
    background-position: center 10%;
    background-color: $lightGrey;

    .login-form {
        font-family: $font-family-head;

        .login-logo {
            img {
                max-width: 175px;
            }
        }
        .card {
            @include box-shadow();
            margin-top: 35px;
            padding: 90px 100px;
            padding-bottom: 0;
            padding-top: 50px;
            border-radius: 10px;

            @media (max-width: 991px) {
                padding: 30px;
            }

            .card-body {
                padding: 0;

                form {
                    .form-group {
                        @include darkLabelInput();

                        input {
                            &[type="password"],
                            &[type="email"] {
                                padding: 20px 10px;
                            }
                        }

                        .forgot-remember {
                            margin-bottom: 20px;

                            .pass-remember {
                                padding-left: 0;

                                @media (max-width: 768px) {
                                    width: 50%;
                                    float: left;
                                }
                                .form-check-label {
                                    padding-left: 8px;
                                    font-weight: 400;

                                    @include checkbox();
                                }

                                input {
                                    @include checkboxInput();
                                }
                            }

                            .pass-forgot {
                                padding-right: 0;
                                @media (max-width: 768px) {
                                    width: 50%;
                                }
                                a {
                                    @include oceanBlueLink();
                                    text-align: right;
                                    font-size: 12px;
                                }
                            }
                        }

                        button {
                            @include green-button();
                            padding: 15px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            footer {
                text-align: center;
                padding: 40px 0;
                box-shadow: none;

                label {
                    color: $btnColorGray;
                    a {
                        @include oceanBlueLink();
                    }
                }
            }
        }

        &.customer-set-password {
            .card {
                max-width: 590px;
                margin: auto;
                padding: 60px 80px;

                .startup-enroll-form-wrap {
                    .enroll-set-password {
                        h3 {
                            font-size: 18px;
                        }

                        .startup-enroll-form {
                            margin-top: 40px;

                            .hide-show {
                                .visibility {
                                    top: 42px;
                                }
                            }
                            input[type="submit"] {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
