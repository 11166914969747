// ***********************************
// MIXINS
// ***********************************

@mixin transition-all($time: 1s) {
    -webkit-transition: all $time ease-in-out;
    -moz-transition: all $time ease-in-out;
    -ms-transition: all $time ease-in-out;
    -o-transition: all $time ease-in-out;
    transition: all $time ease-in-out;
}
@mixin fade($time: 0.5s) {
    -webkit-transition: background-color $time ease-in-out;
    -moz-transition: background-color $time ease-in-out;
    -o-transition: background-color $time ease-in-out;
    -ms-transition: background-color $time ease-in-out;
    transition: background-color $time ease-in-out;
}
@mixin heading1 {
    @extend %heading;
    line-height: 58px;
}
@mixin heading2 {
    @extend %heading;
    font-size: 34px;
    font-weight: $font-weight-bold;
}
@mixin subheading2 {
    font-family: $font-family-content;
    color: $black;
    font-size: 18px;
    line-height: 35px;
    margin: auto;
    font-weight: $font-weight-normal;
}
@mixin content {
    @extend %content;
    @extend %p;
}
@mixin green-button {
    font-weight: $font-weight-bold;
    padding-top: 10px;
    background-color: $green;
    border-color: $green;
    color: #ffffff;
    transition: 0.3s;

    &:hover {
        background-color: $oceanBlue;
        border-color: $oceanBlue;
    }
}
@mixin guides-headings-main {
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %guides-heading;

        @media (max-width: 768px) {
            line-height: 30px;
        }
    }
}
@mixin guide-heading2 {
    h2 {
        font-size: 22px;
    }
}
@mixin guide-heading3 {
    h3 {
        font-size: 20px;
    }
}
@mixin guide-heading4 {
    h4 {
        font-size: 18px;
    }
}
@mixin guide-heading5 {
    h5 {
        font-size: 16px;
    }
}
@mixin guide-heading6 {
    h6 {
        font-size: 15px;
    }
}

@mixin guide-box-hover {
    &:hover {
        transform: perspective(1000px) rotateY(-10deg) translate3d(-5px, 0, 0);
    }
}

@mixin box-shadow {
    box-shadow: 0px 4px 10px rgba(26, 40, 77, 0.0784313725490196);
}

@mixin checkbox {
    &:before {
        content: "";
        background-image: none;
        background-color: white;
        width: 19px;
        height: 19px;
        border: 1px solid $oceanBlue;
        border-radius: 3px;
        left: 0;
        top: 2px;
        position: absolute;
    }
}

@mixin checkboxInput {
    display: none;
    &:checked + label::before {
        content: "";
        background-image: url("../assets/img/assets/round-checkbox.svg");
        width: 24px;
        height: 24px;
        background-size: cover;
        color: black;
        position: absolute;
        border: none;
        top: 0;
        left: -3px;
    }
}

@mixin darkLabelInput {
    label {
        color: $black;
        font-weight: $font-weight-bold;
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 12px;
    }
}

@mixin oceanBlueLink {
    text-decoration: none;
    color: $oceanBlue;
    font-weight: $font-weight-bold;

    &:hover {
        color: $black;
    }
}

@mixin listStyle {
    &:before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        left: -15px;
        top: 13px;
        background: #546285 0% 0% no-repeat;
        opacity: 0.3;
        border-radius: 100%;
    }
}

@mixin stepNumLine {
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    height: 1px;
    background-color: $oceanBlue;
    opacity: 0.2;

    @media (max-width: 768px) {
        content: none;
    }
}

@mixin slidingArrow($right, $top) {
    svg {
        position: absolute;
        right: $right;
        top: $top;
        width: 20px;
        height: 16px;
        visibility: hidden;
        transition: 0.3s all ease;
    }
}

@mixin lineLimit($limit) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $limit;
}

@mixin heroBgColor($color) {
    .hero-wrap {
        background-color: $color;
    }
}

@mixin yellowBtn() {
    background-color: $yellow;
    border: $yellow;
    color: $darkBlue;

    &:hover {
        background-color: white;
        color: $darkBlue;
    }
}
