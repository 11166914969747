.card-row {
    border-radius: 0 !important;

    &:first-of-type {
        border-radius: 10px 10px 0 0 !important;
    }

    &:last-of-type {
        border-radius: 0 0 10px 10px !important;
    }

    &.muted {
        background-color: #f9fafc;
    }

    .card-wrapper {
        padding: 1.5rem;

        @media (max-width: 768px) {
            padding: 1.5rem 0.5rem;
        }

        @media (max-width: 500px) {
            padding: 1rem 0;
        }

        .text-left {
            .heading-label {
                opacity: 1;
                color: #1a284d;
                text-align: left;
                letter-spacing: 0px;
                margin-bottom: 0.35rem;
                font: normal normal 600 16px/20px Inter;
            }

            .subheading-label {
                opacity: 1;
                color: #546285;
                text-align: left;
                letter-spacing: 0px;
                font: normal normal normal 14px/20px Inter;
            }
        }
    }
}
