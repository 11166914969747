.perks-index-page {
    .link {
        color: #546285;
    }
    .link.active {
        color: #198cca;
    }

    .nav {
        padding-bottom: 15px;
    }

    .nav-item a:after {
        margin-top: 14px;
    }

    .community-header {
        color: var(--Gray-700, #1a284d);
        font-family: Poppins, serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .community-subtext {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #546285;
    }

    .community-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        margin-bottom: 20px;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        border-radius: 10px;
        border: 1px solid var(--Gray-50, #e1e3eb);
        background: var(--Gray-White, #fff);

        /* Box Shadow */
        box-shadow: 0px 1px 2px 0px rgba(26, 40, 77, 0.08);
    }

    .community-panel-image {
        height: 60px;
        display: flex;
        align-items: center;
    }

    .community-panel-header {
        color: var(--Gray-700, #1a284d);
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }

    .community-panel-subtext {
        color: var(--Gray-600, #343f5c);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
    }

    .community-panel-cta {
        color: var(--Curious-Blue-100, #198cca);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .panels-container {
        @media (max-width: 768px) {
            flex-direction: column;
        }

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
    }
}
