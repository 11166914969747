@use "@app/styles/sass/elements/modals" with (
    $modalSelector: ".modal #WorkPreferencesModal",
    $modalBodyPadding: 0 24px
);
@use "@app/styles/sass/elements/preferences";
@import "@app/styles/sass/elements/variables";

@include modals.modal;

#WorkPreferencesModal {
    max-width: 680px;

    .accordion-wrapper {
        &:not(:first-of-type) {
            border-top: 1px solid #e1e3eb;
        }

        &::before {
            display: none;
        }

        .accordion-summary {
            padding: 8px 0;
            color: #1a284d;
            font-family: $font-family-content;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            justify-content: space-between;
        }

        .accordion-details {
            padding: 0 0 24px 0;
        }
    }
}
