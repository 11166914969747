@import "@app/styles/sass/elements/variables";

.adeva-radio-input {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border: 1px solid $regularGrey;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;

    &.inline {
        width: auto;
    }

    &:not(:last-of-type) {
        margin-right: 16px;
    }

    &:hover {
        background-color: #f9fafc;
    }

    &.disabled {
        cursor: default;
        background-color: #e9ecef;

        .adeva-radio-input-wrapper {
            .adeva-radio-input-text {
                .adeva-radio-input-title,
                .adeva-radio-input-description {
                    color: #999;
                }
            }
        }
    }

    &.light-blue {
        background-color: #198cca14;
        border-color: #198cca80;
        pointer-events: none;

        &.disabled {
            border-color: #aaa;
            background-color: #e9ecef;

            .adeva-radio-input-button {
                filter: grayscale(1);
            }

            .adeva-radio-input-wrapper {
                .adeva-radio-input-text {
                    .adeva-radio-input-title {
                        color: #7f7f7f;
                    }

                    .adeva-radio-input-description {
                        color: #7f7f7f;
                    }
                }
            }
        }

        .adeva-radio-input-wrapper {
            .adeva-radio-input-text {
                .adeva-radio-input-title {
                    color: #0e448c;
                }

                .adeva-radio-input-description {
                    color: #096ab0;
                }
            }
        }
    }

    .adeva-radio-input-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 14px;
        padding: 12px 14px;
        transition: all 0.5s;

        .adeva-radio-input-button-wrapper {
            padding-right: 8px;

            .adeva-radio-input-button {
                width: 15px;
                height: 15px;
                flex: 0 0 15px;
                margin-bottom: 0;
                margin-top: 2px;
            }
        }

        .adeva-radio-input-text {
            .adeva-radio-input-title {
                color: #1a284d;
                line-height: 17px;
                font-weight: $font-weight-medium;
                margin-bottom: 0;
            }

            .adeva-radio-input-description {
                color: #546285;
                text-align: left;
                font: normal normal normal 14px/20px Inter;
                letter-spacing: 0;
                opacity: 1;
                margin-top: 3px;
            }
        }
    }
}
