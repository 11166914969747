input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="url"],
input[type="search"],
select,
textarea {
    height: 42px;
    outline: none;
    margin: 0 0 2px;
    box-sizing: border-box;
    display: block;
    border: 1px solid #e1e3eb;
    border-radius: 5px;
    color: #1a284d;
    transition: all 0.4s ease-out;
    outline: none !important;
    font-size: 14px !important;
    &:focus {
        transition: box-shadow 0.2s !important;
        opacity: 1;
        border-color: $borderGray;
        box-shadow: 0 2px 10px rgba(26, 40, 77, 0.08);
    }
}

.form-control[readonly] {
    color: #6a6a6ae8;
    background-color: #ebecf257;
    border-color: #e1e3eb;
    opacity: 1;
}

.dropdown-form {
    cursor: pointer;
    -webkit-appearance: none;
    background: #fff url(../../assets/img/light_chevron_down.svg) no-repeat calc(100% - 8px) !important;
    border-color: #e1e3eb;
    font-size: 14px !important;
    height: 42px;
    padding-right: 35px;
    &:disabled {
        background-color: #ebecf2 !important;
        color: #0000006b !important;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    color: #999;
}

.form-control-read-only {
    margin-bottom: 1rem;
    label {
        color: $btnColorGray;
    }
    a,
    p {
        font-weight: $font-weight-medium;
        height: 42px;
        padding: 0.375rem 0rem;
        align-items: center;
        display: inline-flex;
    }
}
.icon-dropdown-form {
    cursor: pointer;
    width: 95%;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid #e1e3eb;
    border-radius: 5px;
    color: #343f5c;
    font-weight: $font-weight-medium;
    display: flex;
    align-items: center;
    padding: 10px 13px;
    &:hover {
        background: #fff !important;
    }
    i {
        height: 18px;
        min-width: 18px;
        background-size: 18px !important;
    }
    p {
        margin-left: 5px;
    }
    @media (max-width: 1200px) {
        margin-top: 10px;
    }
}

input {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
input:-webkit-autofill:focus,
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: #1a284d !important;
}

input[type="submit"] {
    border: none;
    padding: 11px 18px;
    width: auto;
}

input[type="checkbox"] {
    display: inline;
}

input[type="radio"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    box-shadow: none;
}

form {
    label,
    legend {
        line-height: 17px;
        padding-bottom: 8px;
        display: block;
        font-weight: $font-weight-medium;
        font-size: 14px;
        margin-bottom: 0px;
    }
}

.error {
    .form-control {
        border: 1px solid $required !important;
        background-color: rgba(241, 85, 63, 0.05) !important;
    }
    .message {
        margin-top: 0.25rem;
        font-size: 80%;
        // float: left;
        color: $required;
    }
}

.required {
    color: $required !important;
    display: inline !important;
}

.visibility-hiden {
    background-image: url(../../assets/img/assets/hide.svg);
    position: absolute;
    width: 20px;
    height: 20px;
    right: 30px;
    top: 37px;
}

.visibility-show {
    background-image: url(../../assets/img/assets/show.svg);
    position: absolute;
    width: 20px;
    height: 20px;
    right: 30px;
    top: 37px;
}

::placeholder {
    color: $borderGray;
    opacity: 1;
}

textarea {
    height: auto;
    line-height: 27px;
    padding: 20px;
    min-height: 130px;
    transition: none !important;
    min-width: 100%;
}

label span,
legend span {
    font-weight: 400;
    font-size: 14px;
    color: $black;
}

fieldset {
    padding: 0;
    border: none;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: 0 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    cursor: pointer;
}

a {
    color: $oceanBlue;
    transition: 0.3s;
    text-decoration: none;
}

a,
button {
    outline: none !important;
}

a:focus,
a:hover {
    text-decoration: underline;
    color: $oceanBlue;
}

img {
    max-width: 100%;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    .form-control {
        position: relative;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        min-width: 0;
        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .input-group-append,
    .input-group-prepend {
        display: -ms-flexbox;
        display: flex;
    }
    .input-group-prepend {
        margin-right: -1px;
        .input-group-text {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .input-group-append {
        margin-left: -1px;
        .input-group-text {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .input-group-text {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        white-space: nowrap;
        border: 1px solid $regularGrey;
        border-radius: 0.25rem;
        background-color: transparent;
        color: initial;
    }
}

.helper-text {
    text-align: left;
    list-style: none;
    padding-left: 0px;
    li {
        position: relative;
        padding-left: 30px;
        font-size: 12px;
        line-height: 30px;
        color: $btnColorGray;
        &.valid {
            color: #33475b;
            span {
                &:before {
                    content: url(../../assets/svg/icons/icon-check.svg);
                    position: absolute;
                    color: white;
                    left: 0px;
                    top: 0px;
                }
            }
        }
    }
    span {
        &:before {
            content: url(../../assets/svg/icons/icon-minus.svg);
            display: inline-flex;
            height: 2em;
            position: relative;
            position: absolute;
            left: 0px;
            top: -3px;
        }
    }
}

small {
    font-size: 14px;
    color: $btnColorGray;
    line-height: 1.6px;
}

.adeva-form {
    .form-group {
        margin-bottom: 30px;
    }

    input[type="submit"] {
        margin-top: 20px;
        margin-bottom: 40px;
        padding: 0px;
    }
}

/* Custom Checkbox */
/* ALERT: Duplicate code, exists in style.scss */

.custom-chk-label {
    display: inline-block;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked {
            & ~ .checkmark {
                background-color: $darkBlue;
                border-color: $darkBlue;
                &:after {
                    display: block;
                }
            }
        }
    }
    .k {
        background-color: white;
        border: 1px solid #ced4da;
        border-radius: 2px;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 17px;
        &:after {
            content: "";
            position: absolute;
            display: none;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border-width: 0 2px 2px 0;
            top: 1px;
            left: 9px;
        }
    }
}
.error {
    .custom-chk-label {
        .checkmark {
            border: 1px solid $required;
            background-color: rgba(241, 85, 63, 0.05);
        }
    }
}

/* Select2 customization */

.select2-selection__choice {
    color: #555 !important;
    background-color: #fff !important;
    font-size: 14px !important;
}

.select2-container--default {
    .select2-selection--multiple {
        border: 1px solid $regularGrey !important;
    }
    .select2-results__options {
        font-size: 14px;
        .select2-results__option {
            font-size: 14px !important;
            line-height: 24px !important;
            &[aria-selected="true"] {
                background-color: $lightGrey;
            }
            &.select2-results__option--highlighted {
                color: $oceanBlue;
                text-decoration: none;
                background-color: rgba(25, 140, 202, 0.07);
                font-weight: $font-weight-bold;
            }
        }
    }
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-selection.select2-selection--multiple {
    border-radius: 4px !important;
    border: 1px solid $regularGrey;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    font-size: 14px !important;
    line-height: 24px !important;
    input {
        height: auto !important;
    }
}

.select2-search__field {
    &:focus {
        border-color: transparent !important;
        box-shadow: none !important;
    }
}

.error {
    .select2-selection {
        border: 1px solid $required !important;
        background-color: rgba(241, 85, 63, 0.05) !important;
    }
}

.input-icon-start {
    background: white;
    padding: 4px 12px;
    height: 42px;
    display: flex;
    border: 1px solid #e1e3eb;
    border-radius: 5px 0 0 5px;
    align-items: center;
    justify-content: center;
    margin-right: -5px;
    z-index: 1;
}
