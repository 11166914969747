@charset "UTF-8";
// @import url('//fonts.googleapis.com/css?family=Poppins:400,600|Inter:wght@400;600&display=swap');
@import url("//fonts.googleapis.com/css?family=Poppins:400,600&display=swap");
@import url("//fonts.googleapis.com/css?family=Inter:wght@400;600&display=swap");
@import url("https://rsms.me/inter/inter.css");

$font-family-head: "Poppins", sans-serif;
$font-family-content: "Inter", sans-serif;
$font-weight-section-title: 900;
$font-weight-emphasise: 500;
$font-weight-title: 400;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$red: #e2685a;
$green: #24b768;
$text_main: #33475b;
$text_sec: #737373;
$adeva: #ff7a59;
$adeva_hover: #ff8f73;
$orange: #ff7a59;

$regular-font-size: 18px;
$regular-line-height: 30px;

// COLORS
$green: #24b768;
$darkGreen: #18a14d;
$greenOpacity: rgba(36, 183, 104, 0.1);
$green-hover: #198c4e;
$org: #24b768;
$org-hover: #198c4e;
$grey: #b9bcc0;
$black: #1a284d;
$borderGray: #adb4c7;
$borderGrayOpacity: rgba(173, 180, 197, 0.1);
$borderGrayOpacity30: rgba(173, 180, 199, 0.3);
$btnColorGray: #546285;

$gray-bg: #f8f8f9;
$oceanBlue: #198cca;
$darkOceanBlue: #096ab0;
$oceanBlueOpacity: rgba(25, 140, 202, 0.1);
$oceanBlueOpacity2: rgb(25 140 202 / 2%);
$lightBlue: #e7f3fa;
$lightGrey: #f9fafc;
$regularGrey: #e1e3eb;
$dark-gray: #343f5c;
$required: #f1553f;
$requiredLight: #f37565;

$darkBlue: #1a284d;
$darkBlue2: #111e40;
$darkBlue3: #070f26;
$lightCream: #f5f0ea;

$purple: #576ac7;

$body-color: #33475b;
$yellow: rgb(253, 180, 54);
$yellowOpacity: rgb(253 180 54 / 10%);

$orange: #fdb436;
$orangeOpacity: rgba(253, 180, 54, 0.1);

$talentMapDevs: 20;

%heading {
    font-family: $font-family-head;
    color: $black;
}
%guides-heading {
    font-family: $font-family-content;
    font-weight: $font-weight-bold;
    color: $black;
    padding-bottom: 0;
}
%content {
    font-family: $font-family-content;
}
%p {
    color: $darkBlue;
    line-height: 1.7em;
    margin-top: 1em;
    margin-bottom: 1em;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}
