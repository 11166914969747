@import "@app/styles/sass/elements/variables";

.share-referral-section {
    border: 1px solid $regularGrey;
    padding: 20px;
    border-radius: 10px;
    background: #ffffff;

    .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-weight: 600;
            color: $btnColorGray;
        }
    }

    .share-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        border: 1px solid $regularGrey;
        border-radius: 5px;
        padding: 8px 8px 8px 14px;
        height: 42px;

        .link-field {
            font-size: 14px;
            width: calc(100% - 75px);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .copy-btn {
            position: absolute;
            width: 70px;
            margin-right: 5px;
            right: 0;
            background: $oceanBlue;
            border: 1px solid $oceanBlue;
            color: #ffffff;
            border-radius: 5px;
            padding: 7px 10px;

            &:hover {
                background: $darkOceanBlue;
            }
        }
    }

    .social-buttons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1200px) {
            justify-content: start;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 1px solid $regularGrey;
            border-radius: 5px;
            width: 56px;
            height: 37px;
            margin-right: 8px;

            &:first-child,
            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background: $lightGrey;
                border-color: $borderGray;
            }

            i {
                display: block;
                width: 16px;
                height: 16px;
                background-size: contain;
            }
        }
    }
}
