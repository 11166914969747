@import "@app/styles/sass/elements/variables";

.select-note-type {
    margin-top: 2rem;

    .label {
        margin-bottom: 0.5rem;
        color: $darkBlue;
        line-height: 17px;
    }

    .select-wrapper {
        width: 100%;
        max-width: 430px;

        input,
        input[type="text"] {
            height: auto !important;
        }
    }
}
