@import "./base.scss";
@import "./login";

input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 0.15em solid $borderGray;
    border-radius: 50% !important;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #198cca;
}

input[type="radio"]:checked {
    border: 0.15em solid #198cca !important;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

.vertical-scroll::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    background-color: transparent;
}

.vertical-scroll::-webkit-scrollbar-track {
    border-radius: 100vw;
    background: rgba(233, 233, 233, 0.322);
}

.vertical-scroll::-webkit-scrollbar-thumb {
    background: rgba(206, 205, 205, 0.541);
    border-radius: 100vw;
}

.vertical-scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(100, 100, 100, 0.384);
}

.vertical-scroll {
    overflow-x: auto;
}

label {
    font-size: 14px;
    font-family: $font-family-content;
    font-weight: $font-weight-bold;
}

a {
    margin-bottom: 0px;
    line-height: 20px;

    &.main-text {
        font-weight: $font-weight-bold !important;
        color: $darkBlue;
        word-break: break-all;
        line-height: 14px;
    }
}

p {
    margin-bottom: 0px;
    line-height: 20px;

    &.second-text {
        letter-spacing: 0px;
        color: $btnColorGray;
        word-break: break-all;
        line-height: 16px;
    }

    &.member-name {
        font-size: 14px;
        letter-spacing: 0px;
        color: $darkBlue;
        font-weight: $font-weight-bold;
        word-break: break-all;
        line-height: 14px;
    }

    &.main-text {
        font-weight: $font-weight-bold;
        color: $darkBlue;
        word-break: break-all;
        line-height: 14px;
    }

    &.status-text-green {
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: #24b768;
        word-break: break-all;
        line-height: 14px;
    }

    &.status-text-gray {
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: #adb4c7;
        word-break: break-all;
        line-height: 14px;
    }
}

.gradient-scroll {
    height: 100%;
    --scrollbar-width: 0px;
    --mask-height: 75px;
    overflow-y: auto;
    padding-bottom: var(--mask-height);
    --mask-image-content: linear-gradient(to bottom, black calc(75% - var(--mask-height)), transparent);
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    mask-repeat: no-repeat, no-repeat;
}

.MuiButton-root {
    border: 1px solid $regularGrey !important;
    margin-right: 8px !important;
    color: $dark-gray !important;
    font-size: 14px !important;
    font-weight: bolder !important;
    align-items: center !important;
    padding: 10px 15px !important;
    text-transform: capitalize !important;
}

.MuiDataGrid-root {
    border: none !important;
}

.component-title {
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
    color: #1a284d;
}

.sub-component-title {
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0px;
    color: #1a284d;
}

.tag-text {
    border-radius: 4px;
    padding: 1px 6px;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 0px;
    text-transform: capitalize;

    &.primary {
        color: #0e448c;
        background: #d2e7f4;
    }

    &.active {
        color: #226134;
        background: rgba(206, 244, 224, 1);
    }

    &.archived {
        color: $dark-gray;
        background: #adb4c75d;
    }

    &.declined {
        color: #8c2323 !important;
        background: #fcddd9 0% 0% no-repeat padding-box;
    }

    &.yellow {
        color: #6b4a04;
        background: #ffe7be 0% 0%;
    }

    &.purple {
        color: #660e8c !important;
        background: #a657c747 0% 0% no-repeat padding-box;
    }

    &.pink-ghost {
        color: #660e8c !important;
        border-color: #dbbce9;
        background: #a657c720 0% 0% no-repeat padding-box;
    }

    &.gray {
        background-color: #e3e3e3;
        border-color: #e1e3eb !important;
        color: #546285 !important;
    }

    &.dark-blue {
        background-color: #0e458c36;
        color: #0e448c !important;
    }

    &.pale-blue {
        border-color: #198cca66;
        background-color: #198cca1a;
        color: #0e448c !important;
    }

    &.mint {
        background-color: #24b7b74a;
        color: #226161;
    }

    &.brown {
        background-color: #6b4a0460;
        color: #6b4a04;
    }

    &.fallback {
        color: #1a284d !important;
        border: 1px solid #e1e3eb;
        background-color: #fff;
    }
}

.radio-form {
    .radio-button-form {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid $regularGrey;
        border-top: 0px;
        opacity: 1;
        position: relative;
        border-radius: 0;

        label {
            font-weight: $font-weight-bold;
            font-size: 14px;
        }

        p {
            margin: 0px !important;
            padding-bottom: 5px;
        }

        &:hover {
            background-color: #f9fafc;
        }

        &.light-blue {
            background-color: #198cca14;
            border-color: #198cca80 !important;
            pointer-events: none !important;

            p {
                color: #096ab0;
            }

            label {
                color: #0e448c;
            }

            input[type="radio"] {
                border: 0.15em solid #198cca;
            }
        }

        &.disabled {
            p {
                color: #adb4c7 !important;
            }

            label {
                color: #adb4c7 !important;
            }

            input[type="radio"] {
                border: 0.15em solid #adb4c7;
            }

            &:hover {
                background-color: transparent !important;
            }
        }
    }

    :first-child {
        border-radius: 5px 5px 0px 0px;

        &.radio-button-form {
            border-top: 1px solid $regularGrey;
        }
    }

    :last-child {
        border-radius: 0px 0px 5px 5px;
    }
}

.full-click-button {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    cursor: pointer;
    background: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.invite-user {
    p {
        color: $btnColorGray;
    }

    .info-box-invite-user {
        @media (max-width: 1200px) {
            width: 22%;
        }

        @media (max-width: 1024px) {
            width: 28%;
            right: 32px;
        }

        flex: 0 0 auto;
        width: 280px;
        position: fixed;
        right: 45px;
        background: $lightGrey 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 20px;
        margin: 45px 0px;

        h4 {
            font-size: 14px;
            font-weight: $font-weight-bold;
            font-family: $font-family-content;
            color: #1a284d;
            opacity: 1;
            padding-bottom: 5px;
        }

        p {
            color: #1a284d;
            opacity: 1;
            margin-top: 5px !important;
            margin-bottom: 1rem !important;
        }

        label {
            font-size: 12px;
            font-weight: $font-weight-bold;
            color: #1a284d;
        }
    }
}

.btn-filter {
    width: 88px;
    text-align: left;
    color: $dark-gray;
    font-weight: $font-weight-bold;
    height: fit-content;
    box-shadow: 0px 1px 2px #1a284d14;

    &:hover {
        background-color: $lightGrey;
    }
}

.btn-invite-members {
    display: initial;
    width: 175px;
    text-align: left;
    color: $dark-gray;
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
    box-shadow: 0px 1px 2px #1a284d14;

    &:hover {
        background-color: $lightGrey;
    }
}

.btn-create-group {
    display: initial;
    width: 157px;
    height: 40px;
    text-align: left;
    letter-spacing: 0px;
    font-weight: $font-weight-bold;
    margin-bottom: 10px;

    &:hover {
        background-color: #18a14d;
    }
}

.btn-date-range {
    padding: 0 10px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid $regularGrey;
    box-shadow: 0px 1px 2px #1a284d14;
    border-radius: 5px;
    color: $dark-gray;
    width: 317px;

    i {
        color: $dark-gray;
        margin-right: 11px;
        font-size: 15px;
    }

    &:hover {
        background-color: $lightGrey;
    }
}

.mr-0 {
    margin-right: 0px !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-1 {
    margin-left: 5px !important;
}

.input-search {
    padding: 0 10px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid $regularGrey;
    box-shadow: 0px 1px 2px #1a284d14;
    border-radius: 5px;
    display: flex;
    width: 317px;
    margin-right: 10px;

    i {
        margin-top: 9px;
        width: 30px;
    }

    input {
        width: 100%;
        padding-bottom: 6px;
        text-align: left;
        letter-spacing: 0px;
        color: #1a284d;
        opacity: 1;
        border: none !important;
        background: transparent;

        ::placeholder {
            color: $borderGray;
        }
    }
}

.filter-wrapper {
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1200px) {
        display: block;
        justify-content: right;
    }
}

.total-hours {
    padding-top: 4px;
    font-size: 12px;
    color: #546285;
    padding-left: 20px;
    border-left: 1px solid #e1e3eb;

    p {
        font-weight: $font-weight-bold;
    }
}

.sub-title {
    color: $btnColorGray;
    padding-top: 7px;
}

.info-box {
    margin-top: 10px;
    background: #198cca14 0% 0% no-repeat padding-box;
    border: 1px solid #198cca29;
    border-radius: 5px;
    opacity: 1;
    display: flex;

    p {
        padding: 15px 0;
        color: $darkBlue;
        opacity: 1;
    }
}

.sort-icon {
    font-size: 12px;
    padding-left: 7px;
    color: #d2d4db;
}

.collapse-btn {
    text-align: left;
    width: 100%;
    margin: 20px 0;
    color: $btnColorGray;

    i {
        float: right;
        font-size: 20px;
    }
}

.inline-block {
    display: inline-block !important;
    word-break: break-all;
}

.border-right {
    border-right: 1px solid $regularGrey;
}

.border-left {
    border-left: 1px solid $regularGrey;
}

.border-bottom {
    border-bottom: 1px solid $regularGrey;
}

.border-top {
    border-top: 1px solid $regularGrey;
}

.user-profile-box {
    display: flex;
    margin-top: 25px;
    justify-content: left;
    align-items: center;

    .name-position {
        margin-left: 10px;
    }

    .small-text {
        font-size: 12px;
        margin-bottom: 0;
        color: #546285;
    }
}

.contract-info-box {
    margin-top: 20px;
}

.teams-info-box {
    margin-top: 20px;

    .teams-wrapper {
        min-width: 580px;
        text-align: left;
        align-items: center;

        p {
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            font-weight: $font-weight-normal;
        }

        .main-text {
            font-weight: $font-weight-medium;
            display: inline-block;
            min-width: 180px;
            max-width: 250px;
            margin-right: 20px;
        }

        .secondary-text {
            display: inline-block;
            min-width: 180px;
            max-width: 250px;
            line-height: 20px;
            color: #546285;
            margin-right: 20px;
        }

        .main-text {
            display: inline-block;
            min-width: 180px;
            max-width: 250px;
            margin-right: 20px;
        }

        &:hover {
            background: $lightGrey 0% 0% no-repeat padding-box;
            border-radius: 10px;
        }
    }
}

.activity-info-box {
    margin-top: 20px;
}

.activity-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    color: $darkBlue !important;

    p {
        margin-bottom: 0px !important;
    }

    .activity-text {
        margin: 0 15px;
    }

    .secondary-text {
        color: $btnColorGray;
        margin: 0 15px;
        font-size: 12px !important;
        display: flex;
        align-items: center;
        // color: $darkBlue !important;
    }

    .time-text {
        color: $btnColorGray;
        margin-right: 30px;
    }

    &:hover {
        background: $lightGrey 0% 0% no-repeat padding-box;
        border-radius: 10px;
    }
}

.info ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    position: relative;
    font-weight: $font-weight-medium;
    color: $darkBlue;
    text-align: left;

    &:before {
        font-family: $font-family-head;
        content: attr(data-submenu-title);
        margin-bottom: 5px;
        display: block;
        color: $borderGray;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }

    li {
        padding: 10px 0;
        display: flex;
        justify-content: left;

        i {
            height: 30px;
            width: 35px;
            display: inline-block;
            background-size: 22px;
        }

        p {
            line-height: 17px;
            font-size: 14px;
            font-weight: $font-weight-normal;
            color: $btnColorGray;
            margin-bottom: 8px;
        }
    }
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    @media (min-width: 1099px) {
        padding-right: 0px !important;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.section-title-text {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    font-weight: $font-weight-bold;
    letter-spacing: 0px;
    color: $darkBlue;
}

@media (max-width: 1200px) {
    .border-right {
        border: 0px;
    }

    .p-4 {
        padding: 0px !important;
        padding-top: 10px !important;
    }

    .radio-form {
        margin-top: 20px;
    }
}

.card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #1a284d14;
    border: 1px solid #e1e3eb;
    border-radius: 10px;

    .card-header {
        padding: 1rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
        border-color: #e1e3eb;
        color: $btnColorGray;
        // font-family: $font-family-head;
        font-size: 14px;
        font-weight: $font-weight-bold;
    }

    .card-footer {
        // background: $lightGrey 0% 0% no-repeat padding-box;
        color: $oceanBlue;
        font-size: 14px;
        height: 58px;
    }

    .card-icon {
        width: 24px;
        height: 24px;
    }
}

.devider {
    border-bottom: 1px solid rgb(225, 227, 235);
    padding-bottom: 1.5rem;
    margin: 0;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.empty-state-text {
    color: $borderGray !important;
}

.blue-text {
    color: $oceanBlue !important;
    font-weight: $font-weight-bold;
    font-size: 14px;
}

.bold-text {
    font-weight: $font-weight-bold !important;
}

.medium-text {
    font-weight: $font-weight-medium !important;
}

.error-text {
    padding-top: calc(0.375rem + 1px);
    color: rgba(205, 0, 0, 0.75) !important;
    font-size: 12px;
    letter-spacing: 1px;
}

// ===> Dashboard <<===

.custom-orange-alert {
    border: 1px solid #fdb436;
    background: #faf3e8 !important;

    svg {
        color: #c8811a;
    }
}

.initial-flex {
    flex-direction: initial !important;
}

.card-main-text {
    color: $btnColorGray;
    margin: 0px;
    font-weight: $font-weight-medium;
    line-height: 16px;
    margin-bottom: 3px;
}

.card-bold-text {
    margin: 0px;
    font-size: 25px;
    font-weight: $font-weight-bold;
}

.date-wrapper {
    float: left;
    border-right: 1px solid $regularGrey;
    text-align: center;
    display: grid;
    padding: 13px;
    width: 78px;

    p {
        margin: 0px;
    }
}

.dashboard-icon {
    padding: 12px;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
}

#contracts-icon {
    background-color: #198cca14;
}

#upcoming-icon {
    background-color: #54628514;
}

#contracts-icon-green {
    background-color: #24b76814;
}

#engagement-icon {
    background-color: #fdb43614;
}

#renew-icon {
    background-color: #576ac714;
}

#time-icon {
    background-color: #f1553f14;
}

.dashboard-card-widget {
    height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 1rem;

    .dashboard-card-item {
        border-bottom: 1px solid $regularGrey;
        min-width: 335px;

        &:hover {
            background-color: transparent;
            border-radius: 0px;
        }
    }

    .title {
        font-size: 18px;
        font-weight: 500;
        color: #1a284d;
    }

    .content {
        font-size: 12px;
        line-height: 17px;
        color: #546285;
        width: 250px;
        text-align: center;
    }

    // :last-child {
    //     &.dashboard-card-item {
    //         border: none;
    //     }
    // }
}

.green-text {
    color: #24b768;
    position: relative;
    top: 4px;
    left: 4px;
}

.red-text {
    color: #f1553f;
    position: relative;
    top: 4px;
    left: 4px;
}

.gray {
    color: $btnColorGray;
}

.purple {
    color: #576ac7 !important;
}

.intl-tel-input {
    display: flex;
    position: inherit;
}

.phone-number-error {
    input {
        border: 1px solid #f1553f !important;
    }
}

.intl-tel-input .flag-container {
    position: relative;
}

.intl-tel-input.allow-dropdown .flag-container {
    width: auto;
}

.intl-tel-input .selected-flag {
    border: 1px solid $regularGrey;
    border-radius: 5px;
}

.intl-tel-input.allow-dropdown .selected-flag {
    width: 55px;
    margin-right: 10px;
}

.intl-tel-input.allow-dropdown input[type="tel"] {
    padding: 0 12px;
    margin-left: 0;
    width: 100%;
}

.responsive-btn {
    @media (max-width: 1200px) {
        margin-top: 10px;
    }
}

.center-image {
    display: inline-block;
    text-align: center;
}

.member-profile-img-wrap {
    margin: auto;

    .member-profile-img {
        overflow: hidden;
        position: relative;
        margin: auto;
        text-align: center;
        width: 140px;
        border-radius: 100%;

        img {
            width: 140px;
            height: 140px;
            object-fit: cover;
            border-radius: 100%;
        }

        .edit-photo-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(26, 40, 77, 0.4);
            // background-image: url(../img/assets/icon-edit-photo.svg);
            background-image: url(../assets/img/assets/icon-edit-photo.svg);
            background-repeat: no-repeat;
            background-position: center;
            display: none;
        }

        &:hover {
            .edit-photo-overlay {
                display: block;
                cursor: pointer;
            }
        }
    }
}

.align-center {
    align-items: center;
}

.straight-angle-chart {
    position: relative;
    top: 40px !important;
}

.avatar-chart {
    width: 24px !important;
    height: 24px !important;
}

.pie-chart {
    top: 10px !important;
    width: 100% !important;
    height: 280px !important;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.border-none {
    border: none !important;
    box-shadow: none !important;
}

.dnd-headline {
    font-size: 12px;
    font-weight: $font-weight-bold;
    font-family: $font-family-head;
    color: $btnColorGray;
    margin-top: 18px;
    margin-bottom: 18px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}

.dropable-area {
    .dropable-item {
        // border-top: 1px solid $regularGrey;
        border-right: 1px solid $regularGrey;
        min-width: 220px;
        width: 20%;
        height: 75vh;
        // margin-top: 40px;
    }

    .dropable-item-header {
        border-top: 1px solid $regularGrey;
        border-right: 1px solid $regularGrey;
        min-width: 220px;
        width: 20%;
    }

    :last-child {
        &.dropable-item {
            border-right: none;
        }

        &.dropable-item-header {
            border-right: none;
        }
    }
}

.draggable-items {
    .item {
        margin-bottom: 10px;
    }

    // :first-child {
    //     &.item {
    //         margin-top: 30px;
    //     }
    // }
}

.dashboard-empty-state {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    i {
        height: 70px;
        width: 80px;
    }

    p {
        color: #546285;
    }

    @media (max-width: 1200px) {
        padding: 30px !important;
    }
}

.pie-chart-empty-state {
    border: 4px solid #ebecf2;
    opacity: 1;
    border-radius: 50%;
    width: 80px;
    height: 80px;

    p {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 16px;
        color: #adb4c7;
    }
}

.half-pie-chart-empty-state {
    width: 200px;
    height: 100px;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    border: 8px solid #ebecf2;
    border-bottom: 0;

    .text-inside {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
}

.country-flag-fixed {
    position: fixed;
    width: 18px;
    height: 18px;
    // margin-right: 12px;
}

.country-flag {
    width: 18px;
    height: 18px;
}

.vertical-chart-text {
    font-size: 13px;
    fill: "#1A284D";
    line-height: 15px;
    margin-left: 25px;
}

.elipse-point {
    opacity: 1;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 7px;
}

.blue {
    background-color: #198cca !important;
}

.yellow {
    background-color: #fdb436;
}

.red {
    background-color: #f1553f;
}

.alert-success {
    font-size: 14px !important;
    background-color: rgb(237, 247, 237) !important;
    margin-bottom: 10px !important;
}

.alert-error {
    font-size: 14px !important;
    margin-bottom: 10px !important;
}

.light-gray-backgroud {
    background-color: $lightGrey !important;

    &.border-bottom {
        border-bottom: solid 1px #e1e3eb;
    }
}

.fade-in {
    animation: fadeIn 300ms;
    -webkit-animation: fadeIn 300ms;
    -moz-animation: fadeIn 300ms;
    -o-animation: fadeIn 300ms;
    -ms-animation: fadeIn 300ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.edit-icon-btn {
    width: 16px;
    height: 16px;
    background-size: 17px;
    margin-left: 10px;
    display: block;
}

.edit-text-box {
    // width: 116px !important;
    height: 33px !important;
    background-color: #ebecf2;
    border: none !important;
    padding: 10px !important;
    font-weight: $font-weight-bold;
}

.edit-wrapper {
    display: flex;
    align-items: center;

    button {
        display: none;
    }

    &:hover {
        button {
            display: inline;
        }
    }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root + .MuiDivider-root {
    margin: 0px !important;
    display: flex !important;
}

.filter-widget-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .widget {
        align-items: center;
        display: flex;
        position: relative;
        padding: 0px 12px;
        height: 40px;
        border: 1px solid $regularGrey;
        border-right: 0px;
        border-left: 0px;
        border-radius: 0;
        box-shadow: 0px 1px 2px #1a284d14 !important;
        transition: all 0.5s ease-in;

        p {
            justify-content: center;
            align-items: center;
            display: flex;
            margin: 0px !important;
            font-weight: $font-weight-medium;
            color: #546285;
        }

        .tag-number {
            font-size: 12px;
            background-color: #ebecf2;
            padding: 1px 6px;
            margin-left: 5px !important;
            border-radius: 5px;
            align-items: center;
        }

        &.active {
            background-color: #198cca14;
            border-color: #198cca80 !important;
            border: solid 1px;
            border-right: 0px;
            transition: all 0.5s ease-out;

            p {
                color: #0e448c;
            }

            .tag-number {
                background-color: #198cca29;
                transition: all 1s ease-out;
            }
        }
    }

    :first-child {
        &.widget {
            border-radius: 5px 0px 0px 5px;
            border-left: 1px solid $regularGrey;
        }
    }

    :last-child {
        &.widget {
            border-radius: 0px 5px 5px 0px;
            border-right: 1px solid $regularGrey;
        }
    }
}

.dashboard-box-widget {
    border: none;
    box-shadow: none;
    flex-direction: initial;
    padding: 1.4rem;

    @media (max-width: 1200px) {
        padding-left: 0px;
    }
}

.commitment-widget {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid $regularGrey;
    border-radius: 5px;
    width: 48.5%;

    label {
        font-weight: $font-weight-bold;
        font-size: 14px;
        cursor: pointer;
        padding-bottom: 3px;
    }

    &.light-blue {
        background-color: #198cca14;
        border-color: #198cca80 !important;
        pointer-events: none !important;

        p {
            color: #096ab0;
        }

        label {
            color: #0e448c;
        }

        input[type="radio"] {
            border: 0.15em solid #198cca;
        }
    }

    &:hover {
        background-color: #f9fafc;
    }
}

.mr-3 {
    margin-right: 3rem !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.radio-button-input {
    input[type="radio"] {
        margin-right: 10px;
    }

    display: flex;
    margin: 10px 0px;
    width: fit-content;
    cursor: pointer;
    font-weight: $font-weight-normal;

    &.checked {
        font-weight: $font-weight-medium;
        color: #0e448c;
    }
}

.css-cf4mub-control {
    border-color: #e1e3eb !important;

    input[type="text"] {
        height: 30px !important;
    }
}

.css-1om15kg-control {
    border-color: #e1e3eb !important;
    box-shadow: none !important;

    input[type="text"] {
        height: 30px !important;
    }
}

.costume-tags-container {
    margin-top: 5px;
    display: flex;

    ul {
        margin: 0px;
        padding: 0px;

        li {
            background-color: rgba(25, 140, 202, 0.07);
            border-radius: 100px;
            color: #198cca;
            padding: 7px;
            padding-right: 4px;
            padding-left: 10px;
            font-size: 12px;
            margin-top: 5px;
            margin-right: 5px;
            list-style: none;
            float: left;
            position: relative;
        }
    }

    .destroy-tag-btn {
        padding-right: 3px;
        padding-left: 3px;
        padding-bottom: 1px;

        i {
            height: 14px;
            width: 14px;
            background-size: 15px;
            display: flex;
        }
    }
}

.line-button {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e1e3eb;
    line-height: 0.1em;
    margin: 10px 0 20px;

    button {
        position: relative;
        top: 17px;
        padding: 7px 10px;
        border: 1px solid #e1e3eb;
        background-color: #ffffff;
        border-radius: 100px;
        font-weight: $font-weight-medium;
        color: #1a284d;
        box-shadow: 0px 1px 2px #1a284d14;
        justify-content: center;
        align-items: center;
        display: inline-flex;

        &:hover {
            background-color: #f9fafc;
        }

        .icon-minus {
            float: left;
            width: 12px;
            height: 2px;
            margin-right: 5px;
        }

        .icon-plus {
            float: left;
            width: 18px;
            height: 17px;
            margin-right: 5px;
            background-size: cover;
        }
    }
}

.underline-text {
    text-decoration: underline;
    color: #198cca;
    cursor: pointer;
}

.gray-text {
    color: #546285;
    margin-top: 10px;
    line-height: 17px;
}

.datagrid-btn-action {
    display: initial;
    border: none;
    box-shadow: none;
    margin: 0px;
}

.download-btn {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    color: $darkBlue !important;
    border: 1px solid #e1e3eb;
    border-radius: 5px;
    width: 100%;

    p {
        margin-bottom: 0px;
    }

    &:hover {
        background: $lightGrey 0% 0% no-repeat padding-box;
    }
}

.text-right-align {
    text-align: right;

    @media (min-width: 1200px) {
        // text-align: left;
        padding-top: 10px;
        padding-left: 10px;
    }
}

.center-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.width-50 {
    width: 50%;
}

.equipment-dd-icon {
    float: right;
    width: 16px;
    height: 14px;
    background-size: cover;
}

.referral-input {
    width: 100% !important;
    margin-right: 15px !important;
    margin-left: 60px !important;
}

.search-sidebar {
    label {
        font-size: 12px;
    }
}

.search-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 17px;

    @media (max-width: 769px) {
        display: none;
    }
}

.badge-prefix {
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 10px;

    .prefix {
        background: #d3f1e1;
        border: 1px solid #7bd4a4;
        border-radius: 4px 0px 0px 4px;
        color: #226134;
        padding: 0px 5px;

        &.blue {
            background: #d1e8f5 !important;
            border: 1px solid #75badf;
            color: #0e448c;
        }

        &.pink {
            background: #edddf4;
            border: 1px solid #c99add;
            color: #660e8c;
        }

        &.yellow {
            background: #fff0d6;
            border: 1px solid #fed286;
            color: #6b4a04;
        }
    }

    .name {
        color: #1a284d;
        border: 1px solid #e1e3eb;
        border-left: 0px;
        border-radius: 0px 4px 4px 0px;
        background-color: #ffffff;
        padding: 0px 4px;
    }
}

.badge-compact {
    background: #ffffff;
    border: 1px solid #e1e3eb;
    border-radius: 4px;
    color: #1a284d;
    font-size: 10px;
    font-weight: 500;
    padding: 0px 3px;
    display: flex;
    align-items: center;
}

.card-hover {
    width: 220px;
    border: 1px solid #e1e3eb;
    border-radius: 5px;

    .total-points {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background-color: #f9fafc;
        border-bottom: 1px solid #e1e3eb;
        border-radius: 5px 5px 0 0;

        span {
            font-size: 12px;
            color: #1a284d;
        }

        .dev-total-points {
            font-size: 18px;
            margin-right: 2px;
            color: #1a284d;
            font-weight: 600;
        }

        .dev-max-points {
            font-size: 10px;
            color: #adb4c7;
        }
    }

    .card-option {
        padding: 10px;
        background-color: #fff;
        border-radius: 5px 5px 0 0;

        .card-subtitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            span {
                font-size: 12px;
                color: #546285;
            }

            .cultural-fit-title::before {
                content: "";
                width: 6px;
                height: 6px;
                display: block;
                margin: 5px 0px;
                border-radius: 50%;
                background: rgb(253, 180, 54);
            }

            .tech-score-title::before {
                content: "";
                width: 6px;
                height: 6px;
                display: block;
                margin: 5px 0px;
                border-radius: 50%;
                background: rgb(25, 140, 202);
            }

            .dev-partial-points {
                font-size: 14px;
                margin-right: 2px;
                color: #1a284d;
            }

            .dev-max-points {
                font-size: 10px;
                color: #adb4c7;
            }
        }
    }
}

.un-authorized {
    position: relative;
    height: 400px;

    .text-gray-500 {
        --text-opacity: 1;
        color: #a0aec0;
        color: rgba(160, 174, 192, var(--text-opacity));

        &.text-lg {
            font-size: 1.125rem;
            text-transform: uppercase;
        }
    }
}

.vertical-horizontal-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.cell-wrapper {
    flex-direction: column;
    display: flex;
    height: 72px;
    justify-content: center;
}

.link {
    cursor: pointer;
    position: relative;
    color: $oceanBlue;
    font-family: $font-family-content;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    display: block;
    width: fit-content;
    height: 48px;
    padding: 12px 16px;
    text-decoration: none !important;

    .arrow-right-blue {
        background: url(../assets/svg/icons/arrow_right_blue.svg) no-repeat;
        background-size: cover;
        display: -webkit-inline-box;
        width: 13px;
        height: 10px;
        margin-left: 5px;
    }

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: $oceanBlue;
        opacity: 0;
        transition:
            width 0.3s,
            opacity 0.3s;
    }

    &:hover::after {
        width: 100%;
        opacity: 1;
        transition:
            width 0.3s,
            opacity 0.6s;
    }
}

.link.active {
    cursor: pointer;
    position: relative;
    color: $oceanBlue;
    font-family: $font-family-content;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    display: block;
    width: fit-content;
    height: 48px;
    padding: 12px 16px;
    text-decoration: none !important;

    .arrow-right-blue {
        background: url(../assets/svg/icons/arrow_right_blue.svg) no-repeat;
        background-size: cover;
        display: -webkit-inline-box;
        width: 13px;
        height: 10px;
        margin-left: 5px;
    }

    &::after {
        content: "";
        display: block;
        width: 100%; /* Always visible */
        height: 2px;
        background: $oceanBlue;
        opacity: 1; /* Fully visible */
        transition: none; /* No transition */
    }
}
