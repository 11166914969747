@import "./variables";

@mixin preference {
    .preference {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        .preference-label {
            color: #1a284d;
            font-family: $font-family-content;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 12px;
            padding-bottom: 0;
        }

        .option-light {
            color: #546285;
            font-family: $font-family-content;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .option-dark {
            font-weight: 500;
            font-family: $font-family-content;
        }

        div {
            &[aria-disabled="false"] {
                .option-dark {
                    color: #1a284d;
                }
            }
            .option-dark {
                font-family: $font-family-content;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}
