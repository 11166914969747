@import "@app/styles/sass/elements/variables";

.interview-date {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    p {
        margin: 0;
        font-size: 14px;
        white-space: nowrap;
        word-break: keep-all;
    }

    .start-date,
    .end-date {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    .dot {
        vertical-align: bottom;
        font-size: 8px;
        color: $borderGray;
    }

    .time {
        color: $btnColorGray;
    }
}
