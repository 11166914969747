.card-view {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgb(26 40 77 / 8%);
    margin: 0 0 40px 0;
    position: relative;

    .card-view-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e4e4e4;

        @media (max-width: 760px) {
            justify-content: center;
        }

        .content {
            flex: 1;
            padding: 30px;
            display: flex;

            .declined {
                background-image: url("../../styles/assets/img/assets/icon-decline.svg");
                width: 24px;
                height: 24px;
                display: block;
                background-size: cover;
                position: absolute;
                left: 59px;
                margin: auto;
                top: 99px;
                z-index: 1;
            }

            @media (max-width: 760px) {
                flex-direction: column;

                .avatar-wrap {
                    display: flex;
                    justify-content: center;
                    margin-right: 0px !important;
                    margin-bottom: 10px;
                }
            }

            .avatar-wrap {
                margin-right: 20px;
            }

            .title-wrapper {
                margin-bottom: 10px;

                @media (max-width: 760px) {
                    text-align: center;
                }

                .title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;
                    line-height: 1.6;
                }

                span {
                    font-size: 12px;
                    color: #546285;
                    line-height: 1.3;
                    display: block;
                }

                ul {
                    padding: 0px;
                    margin: 2px 0px;

                    li {
                        font-size: 12px;
                        color: #546285;
                        list-style-position: outside;
                        display: inline;
                        margin-right: 3px;

                        &.green {
                            color: rgb(36, 183, 104);
                            background: transparent;
                        }

                        &.blueish {
                            color: rgb(0, 141, 255);
                            background: transparent;
                        }

                        &.red {
                            color: rgb(241, 85, 63);
                            background: transparent;
                        }

                        &:before {
                            content: "·";
                            color: #546285;
                            margin-right: 3px;
                        }

                        &:first-child {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }

                .score-wrap-title {
                    display: flex;
                    justify-content: center;

                    @media (min-width: 760px) {
                        display: none;
                    }
                }
            }

            .hits-wrap {
                &:not(:empty) {
                    margin-top: 30px;
                }

                > * {
                    opacity: 0.6;
                    line-height: 1.7;
                    color: rgb(52, 63, 92);
                }

                .matching-hit {
                    font-style: italic;

                    &:not(:last-child) {
                        margin-bottom: 0.5rem;
                    }
                }

                .see-more {
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .score-wrap {
            @media (max-width: 760px) {
                display: none;
            }
        }

        .score {
            margin: 15px;
            margin-top: 22px;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;

        .dev-info-box {
            display: flex;
            align-items: center;
            padding: 22px 20px;
            max-width: none;
            border-right: 1px solid #e1e3eb;
            max-height: 95px;

            i {
                width: 20px;
                height: 20px;
                display: block;
                float: left;
                margin-right: 5px;
                background-size: cover;
            }
        }

        .actions-wrapper {
            display: flex;
            align-items: center;
            margin: 5px;
        }

        .info-wrapper {
            display: flex;
        }

        @media (max-width: 510px) {
            flex-direction: column;

            .dev-info-box {
                border-bottom: 1px solid #e1e3eb;
                border-right: transparent;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            .info-wrapper {
                flex-direction: column;
                align-items: center;
            }

            .actions-wrapper {
                margin: 10px;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
