.calendar-integration-wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
        margin-top: 10px;
    }

    .integrate-text {
        font-size: 13px;
        line-height: 17px;
        font-weight: 600;
        text-transform: none;
    }

    .icon-faq {
        width: 20px;
        height: 20px;
        display: block;
    }
}
