#navigation {
    position: relative;
    display: inline-block;
    margin-top: 25px;

    h3 {
        font-family: $font-family-head;
        font-size: 16px;
        color: $black;
        font-weight: 400;
    }

    @media (max-width: 1024px) {
        display: none;
    }
}

#navigation ul {
    list-style: none;
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
}

#navigation ul a {
    display: block;
    text-decoration: none;
    padding: 5px 0;
}

#navigation ul li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
    &.active {
        a {
            color: $oceanBlue !important;
        }
    }
}

#navigation ul li ul {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 9px;
    padding: 0;
    z-index: 99999;
}

#navigation ul ul li {
    float: none;
}

#navigation ul ul ul {
    top: 0;
    left: 100%;
    margin: 0 0 0 15px;
}

#navigation ul ul {
    margin: 0;
}

#navigation ul ul ul {
    pointer-events: none;
}

#navigation ul ul li:hover > ul {
    pointer-events: all;
}

#navigation ul ul:before {
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    background: transparent;
    width: 100%;
    height: 10px;
}

#navigation ul ul ul:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 100%;
    left: -15px;
    top: 0;
    opacity: 0;
}

#navigation ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a:after,
#navigation ul ul.dropdown-nav li:hover a:after,
#navigation ul li:hover a:after {
    color: $oceanBlue;
    background: rgba(25, 140, 202, 0.13);
    opacity: 0.8;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li a:after {
    background-color: #f0f0f0;
    color: #a8a8a8;
    opacity: 1;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a:after,
#navigation ul ul.dropdown-nav li:hover a:after {
    color: #fff !important;
    background-color: $oceanBlue;
    opacity: 1;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav li a:after,
#navigation ul ul.dropdown-nav li a:after {
    content: "\e917";
    position: absolute;
    left: auto;
    right: 20px;
    top: 6px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #bbb;
    opacity: 1;
}

#navigation ul li a.current {
    color: $oceanBlue;
}

#navigation ul li a.current:after {
    color: $oceanBlue;
    background: rgba(25, 140, 202, 0.13);
    opacity: 0.8;
}

#navigation ul li a:only-child:after,
#navigation ul ul li a:only-child:after {
    content: "";
    display: none;
}

#navigation ul ul li:hover a:after {
    opacity: 1;
    transform: translateX(0);
}

#navigation ul li {
    transition: all 0.25s;
    margin: 0 15px;
    border-radius: 4px;
}

#navigation ul li a,
#navigation ul ul li a {
    color: #666;
    cursor: pointer;
}

#navigation ul a {
    line-height: 23px;
    padding: 4px 9px;
    border-right: 4px;

    &.has-children {
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            background-image: url("../assets/img/assets/icon-dropdown-arrow.svg");
            background-size: cover;
            background-repeat: no-repeat;
            right: -15px;
            top: 8px;
        }
    }
}

#navigation ul li:hover a {
    color: $oceanBlue;
}

#navigation ul ul {
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

#navigation ul ul li {
    border-radius: 4px;
}

#navigation ul ul li:last-child {
    border-bottom: none;
}

#navigation ul li:hover ul a,
#navigation ul ul a {
    padding: 4px 40px 4px 15px !important;
    line-height: 22px !important;
}

#navigation ul li:hover ul a:only-child,
#navigation ul a:only-child {
    padding: 4px 15px !important;
}

#navigation ul ul:after {
    content: "";
    position: absolute;
    left: 25px;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ffffff;
}

body #navigation ul ul {
    padding: 14px 0;
    box-sizing: border-box;
    margin-top: 12px;
}

body #navigation ul ul ul {
    margin-top: 0;
}

#navigation ul ul:before {
    content: ".";
    position: absolute;
    width: 100%;
    height: 12px;
    top: -12px;
    opacity: 0;
    opacity: 0;
}

#navigation ul ul ul:before {
    width: calc(100% + 15px);
    left: -15px;
}

#navigation ul ul li {
    width: 208px;
}

#navigation ul ul li:hover {
    border-radius: 0;
}

#navigation ul ul li:first-child {
    border-radius: 4px 4px 0 0;
}

#navigation ul ul li:last-child {
    border-radius: 0 0 4px 4px;
}

#navigation ul ul {
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
    transform: translate3d(0, 15px, 0);
}

#navigation ul ul ul {
    transform: translate3d(15px, 0, 0);
}

#navigation ul li ul {
    z-index: 10;
}

#navigation ul li:hover ul {
    z-index: 20;
}

#navigation ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

#navigation ul ul li {
    margin: 0;
    padding: 0 6px;
    border-radius: 0;
    font-size: 15.7px;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav ul.dropdown-nav li a,
#navigation ul ul.dropdown-nav ul.dropdown-nav li a,
#navigation ul ul.dropdown-nav li a {
    transition: 0.3s;
    color: #666;
}

#navigation ul ul.dropdown-nav ul.dropdown-nav ul.dropdown-nav li:hover a,
#navigation ul ul.dropdown-nav ul.dropdown-nav li:hover a,
#navigation ul ul.dropdown-nav li:hover a {
    color: $oceanBlue;
}

.mega-menu {
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s;
    position: absolute;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-size: 15px;
    display: flex;
    top: 100%;
    left: 0;
    padding: 20px 0;
    box-sizing: border-box;
    font-size: 14px;
    margin-top: -10px;
    background-color: #303030;
    width: 200px;
    z-index: 99;
    transform: translate3d(-42%, 36px, 0);
}

.mega-menu:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 0;
    display: block;
    width: 100%;
    height: 12px;
    opacity: 0;
}

#navigation ul li .mega-menu ul {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 0, 0);
    position: relative;
    display: inline-block;
    flex: 1;
    padding: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    top: auto;
    left: auto;
    background-color: transparent;
}

#navigation ul li:hover .mega-menu {
    opacity: 1;
    visibility: visible;
    transform: translate3d(-42%, 23px, 0);
}

#navigation ul li:hover .mega-menu ul {
    opacity: 1;
    visibility: visible;
}

#navigation .mega-menu ul,
#navigation .mega-menu ul li {
    width: 100%;
}

.mega-menu.two-columns {
    width: 429px;
}

.mega-menu.three-columns {
    width: 643px;
}

.mega-menu.four-columns {
    width: 857px;
}

#navigation ul .mega-menu ul a {
    color: #bbb !important;
    padding: 0 !important;
    margin: 0 !important;
    display: block;
    margin-left: 0;
    -wekkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

#navigation ul .mega-menu ul a:hover {
    color: #fff !important;
}

#navigation ul li:hover .mega-menu ul a,
#navigation ul .mega-menu ul a {
    padding: 4px 0 !important;
    line-height: 22px !important;
    transform: translate3d(0, 0, 0) !important;
}

#navigation .mega-menu ul li {
    padding: 0 26px;
}

#navigation .mega-menu:after {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #303030;
}

#navigation ul li a i {
    font-size: 14px;
    position: relative;
    top: 0;
    padding-right: 5px;
}

.mega-menu-section {
    display: inline-block;
    float: left;
    width: 214px;
    padding: 0;
    border-right: 1px solid #444;
}

.mega-menu .mega-menu-section:last-child {
    border: none;
}

#navigation ul .mega-menu ul ul:after {
    display: none;
}

.mega-menu-headline {
    color: #fff;
    opacity: 1 !important;
    font-weight: 600;
    display: block;
    padding: 8px 30px !important;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 21px;
}

.mobile-styles .mm-listview {
    margin: 0 -20px !important;
}

.mobile-styles .mm-listview .mega-menu-headline {
    color: #fff;
    padding: 15px 20px !important;
    background: #222 !important;
}

.mobile-styles .mm-listview li a i {
    padding-right: 5px;
}

.mobile-styles.mm-panel:before {
    height: 10px !important;
}

#navigation ul li .mega-menu ul,
#navigation ul li ul {
    z-index: 100;
}

#navigation ul li:hover .mega-menu,
#navigation ul li:hover ul {
    z-index: 200;
}
