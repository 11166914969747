@import "@app/styles/sass/elements/variables";

.applicant {
    &-img {
        margin-right: 1rem;

        img {
            object-fit: cover;
            object-position: center;
        }
    }

    &-name {
        font-weight: 500;
        color: $darkBlue;
        margin-bottom: 2px;
    }

    &-country {
        color: $btnColorGray;
    }

    &-country,
    &-name {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
    }
}
