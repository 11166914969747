.icon-client-support {
    background: url(../../assets/svg/icons/exceptional_client_support.svg) no-repeat;
}

.icon-profile-picture {
    background: url(../../assets/svg/icons/profile_picture.svg) no-repeat;
}

.icon-global-network {
    background: url(../../assets/svg/icons/global_network.svg) no-repeat;
}

.icon-globe-small {
    background: url(../../assets/svg/icons/globe-small.svg) no-repeat;
}

.icon-arrow-right-s {
    background: url(../../assets/svg/icons/arrow-right-s.svg) no-repeat;
}

.icon-user-search {
    background: url(../../assets/svg/icons/user-search.svg) no-repeat;
}

.icon-pencil-gray {
    background: url(../../assets/svg/icons/pencil.svg) no-repeat;
}

.icon-onboarding {
    background: url(../../assets/svg/icons/onboarding.svg) no-repeat;
}

.icon-positions {
    background: url(../../assets/svg/icons/positions-dark-gray.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/positions-active.svg) no-repeat;
    }

    &.light-gray {
        background: url(../../assets/svg/icons/positions.svg) no-repeat;
    }
}

.icon-introduced-talent {
    background: url(../../assets/svg/icons/introduced_talent.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/blue_talent.svg) no-repeat;
    }
}

.icon-contracts {
    background: url(../../assets/svg/icons/contracts-dark-gray.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/contracts-active.svg) no-repeat;
    }
}

.icon-timelogs {
    background: url(../../assets/svg/icons/timelogs-dark-gray.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/timelogs-active.svg) no-repeat;
    }

    &.grey {
        background: url(../../assets/svg/icons/timelogs.svg) no-repeat;
    }
}

.icon-rate {
    background: url(../../assets/svg/icons/rate.svg) no-repeat;
}

.icon-payments-nav {
    background: url(../../assets/img/assets/icon-dollar-nav.svg);

    &.active {
        // background: url(../../assets/svg/icons/icon-dollar-active.svg) no-repeat;
    }

    &.grey {
        background: url(../../assets/svg/icons/payments-light-gray.svg) no-repeat;
    }
}

.icon-referrals-nav {
    background: url(../../assets/img/assets/icon-referrals-nav.svg);

    &.active {
        // background: url(../../assets/svg/icons/icon-dollar-active.svg) no-repeat;
    }
}

.icon-invoices {
    background: url(../../assets/svg/icons/invoices-dark-gray.svg) no-repeat;
}

.icon-referrals {
    background: url(../../assets/svg/icons/referrals-dark-gray.svg) no-repeat;
}

.icon-referral-stats {
    background: url(../../assets/svg/icons/referral_stats.svg) no-repeat;
}

.icon-insights {
    background: url(../../assets/svg/icons/insights-dark-gray.svg) no-repeat;
}

.icon-settings {
    background: url(../../assets/svg/icons/settings-gray.svg) no-repeat;
}

.icon-documents {
    background: url(../../assets/svg/icons/documents.svg) no-repeat;
}

.icon-insurance {
    background: url(../../assets/svg/icons/insurance-light-gray.svg) no-repeat;
}

.icon-faq {
    background: url(../../assets/svg/icons/faq-dark-gray.svg) no-repeat;
}

.icon-call {
    background: url(../../assets/svg/icons/call.svg) no-repeat;
}

.icon-invite {
    background: url(../../assets/svg/icons/invite.svg) no-repeat;
}

.overview-client-support {
    background: url(../../assets/svg/overview/exceptional_client_support.svg) no-repeat;
}

.icon-calendar {
    background: url(../../assets/svg/icons/calendar.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/calendar-active.svg) no-repeat;
    }

    &.grey {
        background: url(../../assets/img/assets/icon-calendar-grey.svg);
    }

    &.red {
        background: url(../../assets/svg/icons/calendar-red.svg) no-repeat;
    }
}

.icon-delete {
    background: url(../../assets/svg/icons/delete.svg) no-repeat;
}

.icon-edit {
    background: url(../../assets/img//assets/edit.svg) no-repeat;
}

.icon-portal-download {
    background: url(../../assets/svg/icons/icon-portal-download.svg) no-repeat;
}

.icon-job-new {
    background: url(../../assets/svg/icons/job-new.svg) no-repeat;
}

.icon-candidates {
    background: url(../../assets/svg/icons/candidates.svg) no-repeat;
}

.icon-dollar {
    background: url(../../assets/svg/icons/dollar.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/dollar-active.svg) no-repeat;
    }
}

.icon-manage-candidates {
    background: url(../../assets/svg/icons/manage-candidate.svg) no-repeat;
}

.icon-attach {
    background: url(../../assets/svg/icons/icon-attach.svg) no-repeat;
}

.icon-overview {
    background: url(../../assets/svg/icons/icon-overview.svg) no-repeat;
}

.icon-community {
    background: url(../../assets/svg/icons/icon-globe.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/icon-globe-active.svg) no-repeat;
    }
}

.icon-customer {
    background: url(../../assets/svg/icons/introduced_customers.svg) no-repeat;

    &.active {
        // background: url(../../assets/svg/icons/introduced_customers_active.svg) no-repeat;
    }
}

.icon-add {
    background: url(../../assets/img/assets/icon-add.svg) no-repeat;
}

.icon-new-tab {
    background: url(../../assets/svg/icons/icon-new-tab.svg) no-repeat;
}

.icon-writter {
    background: url(../../assets/svg/icons/icon-writter.svg) no-repeat;
}

.icon-mail {
    background: url(../../assets/img/assets/icon-mail.svg) no-repeat;
}

.icon-posts {
    background: url(../../assets/img/assets/icon-posts.svg) no-repeat;
}

.icon-lock {
    background: url(../../assets/img/assets/icon-lock.svg) no-repeat;
}

.icon-linkedin-original {
    background: url(../../assets/img/assets/icon-linkedin-original.svg) no-repeat;
}

.icon-facebook-original {
    background: url(../../assets/img/assets/facebook.svg) no-repeat;
}

.icon-twitter-original {
    background: url(../../assets/img/assets/twitter.svg) no-repeat;
}

.icon-green-dollar-sign {
    background: url(../../assets/img/assets/green-dollar-sign.svg) no-repeat;
}

.icon-dark-grey-mail {
    background: url(../../assets/img/assets/mail.svg) no-repeat;
}

.icon-youtube-original {
    background: url(../../assets/img/assets/icon-youtube-original.svg) no-repeat;
}

.icon-google-original {
    background: url(../../assets/img/assets/icon-google-original.svg) no-repeat;
}

.icon-github-original {
    background: url(../../assets/img/assets/icon-github-original.svg) no-repeat;
}

.icon-inspiration {
    background: url(../../assets/img/assets/icon-inspiration.svg) no-repeat;
}

.icon-timelog-arrow {
    background: url(../../assets/img/assets/tl-arrow.svg) no-repeat;

    &.icon-right {
        transform: rotate(180deg);
    }
}

.icon-hiring-now {
    background: url(../../assets/svg/icons/icon-hiring-now.svg) no-repeat;
}

.icon-facebook {
    background: url(../../assets/svg/icons/icon-facebook.svg) no-repeat;
}

.icon-youtube {
    background: url(../../assets/svg/icons/icon-youtube.svg) no-repeat;
}

.icon-instagram {
    background: url(../../assets/svg/icons/icon-instagram.svg) no-repeat;
}

.icon-behance,
.icon-soc-4 {
    background: url(../../assets/svg/icons/icon-behance.svg) no-repeat;
}

.icon-github,
.icon-soc-2 {
    background: url(../../assets/svg/icons/icon-github.svg) no-repeat;

    &.grey {
        background: url(../../assets/svg/icons/icon-github-gray.svg) no-repeat;
    }
}

.icon-linkedin,
.icon-soc-0 {
    background: url(../../assets/svg/icons/icon-linkedin.svg) no-repeat;

    &.grey {
        background: url(../../assets/svg/icons/icon-linkedin-gray.svg) no-repeat;
    }
}

.icon-twitter,
.icon-soc-1 {
    background: url(../../assets/svg/icons/icon-twitter.svg) no-repeat;
}

.icon-website,
.icon-soc-3 {
    background: url(../../assets/svg/icons/icon-website.svg) no-repeat;

    &.active {
        background: url(../../assets/svg/icons/website-active.svg) no-repeat;
    }

    &.grey {
        background: url(../../assets/svg/icons/icon-website-gray.svg) no-repeat;
    }
}

.icon-dribble {
    background: url(../../assets/svg/icons/icon-dribbble.svg) no-repeat;
}

.icon-upload {
    background: url(../../assets/img/assets/icon-upload.svg) no-repeat;
}

.icon-info {
    background: url(../../assets/img/assets/icon-info.svg) no-repeat;
}

.icon-arrow {
    background: url(../../assets/img/round-chevron_right.svg) no-repeat;

    .icon-right {
    }
}

.icon-my-account {
    background: url(../../assets/img/assets/icon-my-account.svg) no-repeat;
}

.icon-skills {
    background: url(../../assets/img/assets/icon-skills.svg) no-repeat;
}

.icon-social-media {
    background: url(../../assets/img/assets/icon-social-media.svg) no-repeat;
}

.icon-education {
    background: url(../../assets/img/assets/icon-education.svg) no-repeat;
}

.icon-achievement {
    background: url(../../assets/img/assets/icon-achievements.svg) no-repeat;
}

.icon-experience {
    background: url(../../assets/img/assets/icon-experience.svg) no-repeat;
}

.active,
.active-submenu {
    .icon-positions {
        background: url(../../assets/svg/icons/positions-active.svg) no-repeat;
    }

    .icon-introduced-talent {
        background: url(../../assets/svg/icons/blue_talent.svg) no-repeat;
    }

    .icon-contracts {
        background: url(../../assets/svg/icons/contracts-active.svg) no-repeat;
    }

    .icon-timelogs {
        background: url(../../assets/svg/icons/timelogs-active.svg) no-repeat;
    }

    .icon-timeoff {
        background: url(../../assets/svg/icons/timeoff-active.svg) no-repeat;
    }

    .icon-referrals,
    .icon-referrals-nav {
        background: url(../../assets/svg/icons/referrals-active.svg) no-repeat;
    }

    .icon-invoices {
        background: url(../../assets/svg/icons/invoices-active.svg) no-repeat;
    }

    .icon-insights {
        background: url(../../assets/svg/icons/insights-active.svg) no-repeat;
    }

    .icon-settings {
        background: url(../../assets/svg/icons/settings-active.svg) no-repeat;
    }

    .icon-settings {
        background: url(../../assets/svg/icons/settings-active.svg) no-repeat;
    }

    .icon-insurance {
        background: url(../../assets/svg/icons/insurance-blue.svg) no-repeat;
    }

    .icon-documents {
        background: url(../../assets/svg/icons/documents-active.svg) no-repeat;
    }

    .icon-talent-developers {
        background: url(../../assets/svg/icons/talent-developers-active.svg) no-repeat;
    }

    .icon-faq {
        background: url(../../assets/svg/icons/faq-active.svg) no-repeat;
    }

    .icon-dollar,
    .icon-payments-nav {
        background: url(../../assets/svg/icons/dollar-active.svg) no-repeat;
    }

    .icon-overview {
        background: url(../../assets/svg/icons/icon-overview-active.svg) no-repeat;
    }

    .icon-community {
        background: url(../../assets/svg/icons/icon-globe-active.svg) no-repeat;
    }

    .icon-company {
        background: url(../../assets/img/assets/blue_company.svg) no-repeat;
    }

    .icon-hiring-now {
        background: url(../../assets/svg/icons/icon-hiring-now-active.svg) no-repeat;
    }

    .icon-open-positions {
        background: url(../../assets/svg/icons/blue_open_positions.svg) no-repeat;
    }

    .icon-customer {
        background: url(../../assets/svg/icons/introduced_customers_active.svg) no-repeat;
    }
}

.locked {
    .icon-contracts {
        background: url(../../assets/svg/icons/contracts.svg) no-repeat;
    }

    .icon-timelogs {
        background: url(../../assets/svg/icons/timelogs.svg) no-repeat;
    }

    .icon-timeoff {
        background: url(../../assets/svg/icons/timelogs.svg) no-repeat;
    }

    .icon-invoices {
        background: url(../../assets/svg/icons/invoices.svg) no-repeat;
    }

    .icon-referrals {
        background: url(../../assets/svg/icons/referrals.svg) no-repeat;
    }

    .icon-insights {
        background: url(../../assets/svg/icons/insights.svg) no-repeat;
    }

    .icon-settings {
        background: url(../../assets/svg/icons/settings.svg) no-repeat;
    }

    .icon-documents {
        background: url(../../assets/svg/icons/documents.svg) no-repeat;
    }

    .icon-faq {
        background: url(../../assets/svg/icons/faq.svg) no-repeat;
    }

    .icon-positions {
        background: url(../../assets/svg/icons/positions.svg) no-repeat;
    }

    .icon-overview {
        background: url(../../assets/svg/icons/overview-light-gray.svg) no-repeat;
    }

    .icon-community {
        background: url(../../assets/svg/icons/community-light-gray.svg) no-repeat;
    }

    .icon-payments-nav {
        background: url(../../assets/svg/icons/payments-light-gray.svg) no-repeat;
    }

    .icon-referrals-nav {
        background: url(../../assets/svg/icons/referrals-light-gray.svg) no-repeat;
    }

    .icon-hiring-now {
        background: url(../../assets/svg/icons/open-positions-light-gray.svg) no-repeat;
    }
}

.icon-timeoff {
    background: url(../../assets/svg/icons/timeoff.svg) no-repeat;
}

.icon-employees {
    background: url(../../assets/svg/icons/employees.svg) no-repeat;
}

.icon-dark-edit {
    background: url(../../assets/img/assets/dark_edit.svg) no-repeat;
}

.icon-default-edit {
    background: url(../../assets/img/assets/edit.svg) no-repeat;
}

.icon-blue-checkmark {
    background: url(../../assets/img/assets/blue_checkmark.svg) no-repeat;

    &.disabled {
        background: url(../../assets/img/assets/disabled_checkmark.svg) no-repeat;
    }
}

.icon-white-checkmark {
    background: url(../../assets/img/assets/white_checkmark.svg) no-repeat;
}

.icon-blue-support {
    background: url(../../assets/img/assets/blue_support.svg) no-repeat;
}

.icon-empty-groups {
    background: url(../../assets/img/empty_state_groups.svg) no-repeat;
}

.icon-plus {
    background: url(../../assets/img/plus.svg) no-repeat;
}

.icon-plus-white {
    background: url(../../assets/img/plus-white.svg) no-repeat;
}

.icon-search {
    background: url(../../assets/img/search.svg) no-repeat;
}

.icon-filter {
    background: url(../../assets/img/filter.svg) no-repeat;
}

.icon-more {
    background: url(../../assets/img/more.svg) no-repeat;
}

.icon-arrow-right-up {
    background: url(../../assets/img/light_arrow_right_up.svg) no-repeat;
}

.icon-arrow-right-up-grey {
    background: url(../../assets/img/arrow-right-up-grey.svg) no-repeat;
}

.icon-talent-developers {
    background: url(../../assets/svg/icons/talent-developers.svg) no-repeat;
}

.icon-talent-clients {
    background: url(../../assets/svg/icons/talent-clients.svg) no-repeat;
}

.icon-phone {
    background: url(../../assets/img/assets/light_phone.svg) no-repeat;
}

.icon-phone-blue {
    background: url(../../assets/img/assets/phone-blue.svg) no-repeat;
}

.icon-location {
    background: url(../../assets/img/assets/light_location.svg) no-repeat;
}

.icon-status {
    background: url(../../assets/img/assets/light_status.svg) no-repeat;
}

.icon-clock {
    background: url(../../assets/img/assets/light_clock.svg) no-repeat;
}

.icon-company {
    background: url(../../assets/img/assets/company.svg) no-repeat;
}

.icon-onboardings {
    background: url(../../assets/img/assets/onboardings.svg) no-repeat;
}

.icon-active_contracts {
    background: url(../../assets/img/assets/active_contracts.svg) no-repeat;
}

.icon-engagement {
    background: url(../../assets/img/assets/engagement_length.svg) no-repeat;
}

.icon-renewed-contract {
    background: url(../../assets/img/assets/renewed_contracts.svg) no-repeat;
}

.icon-time-to-fill {
    background: url(../../assets/img/assets/time_to_fill.svg) no-repeat;
}

.icon-interviewed-light {
    background: url(../../assets/img/assets/light_interviewed.svg) no-repeat;
}

.icon-hired-light {
    background: url(../../assets/img/assets/light_hired.svg) no-repeat;
}

.icon-rejected-light {
    background: url(../../assets/img/assets/light_rejected.svg) no-repeat;
}

.icon-growth {
    background: url(../../assets/img/assets/growth.svg) no-repeat;
    position: relative;
    top: 4px;
    width: 16px;
}

.icon-decline {
    background: url(../../assets/img/assets/decline.svg) no-repeat;
    position: relative;
    top: 4px;
    width: 16px;
}

.icon-applicants {
    background: url(../../assets/img/applicants.svg) no-repeat;
}

.icon-edit-new {
    background: url(../../assets/img/edit.svg) no-repeat;
}

.icon-info-filled {
    background: url(../../assets/img/info_filled.svg) no-repeat;
    width: 50px;
}

.icon-events {
    background: url(../../assets/svg/icons/events.svg) no-repeat;
}

.icon-onboardings-empty-state {
    background: url(../../assets/svg/icons/onboardings_empty_state.svg) no-repeat;
}

.icon-pending-actions {
    background: url(../../assets/svg/icons/pending_actions.svg) no-repeat;
}

.icon-positions-empty-state {
    background: url(../../assets/svg/icons/positions_empty_state.svg) no-repeat;
}

.icon-team-analytics {
    background: url(../../assets/svg/icons/team_analytcis.svg) no-repeat;
}

.icon-light-calendar-2 {
    background: url(../../assets/svg/icons/light_calendar2.svg) no-repeat;
}

.icon-light-user {
    background: url(../../assets/svg/icons/light_user.svg) no-repeat;
}

.icon-light-attachment {
    background: url(../../assets/svg/icons/light_attachment.svg) no-repeat;
    width: 27px;
    height: 16px;
    background-size: 18px;
}

.icon-notifications {
    background: url(../../assets/svg/icons/notifications.svg) no-repeat;
    width: 20px;
    height: 20px;
    background-size: 17px;
}

.icon-close-light {
    background: url(../../assets/svg/icons/light_close.svg) no-repeat;
}

.icon-drop-down {
    background: url(../../assets/svg/icons/drop_down.svg) no-repeat;
    background-size: cover;
    display: block;
    width: 10px;
    height: 10px;
}

.icon-drop-up {
    background: url(../../assets/svg/icons/drop_up.svg) no-repeat;
    background-size: cover;
    display: block;
    width: 10px;
    height: 10px;
}

.icon-checkmark {
    background: url(../../assets/svg/icons/checkmark.svg) no-repeat;
}

.icon-working-hours {
    background: url(../../assets/svg/icons/light_working_hours.svg) no-repeat;
}

.icon-open-positions {
    background: url(../../assets/svg/icons/open_positions.svg) no-repeat;
}

.icon-approved-candidates {
    background: url(../../assets/svg/icons/approved_candidates.svg) no-repeat;
}

.icon-completed-interviwers {
    background: url(../../assets/svg/icons/completed_interviews.svg) no-repeat;
}

.icon-minus {
    background: url(../../assets/svg/icons/icon-minus.svg) no-repeat;
}

.icon-close-blue {
    background: url(../../assets/svg/icons/blue_close.svg) no-repeat;
}

.icon-light-other {
    background: url(../../assets/svg/icons/light_other.svg) no-repeat;
    width: 27px;
    height: 16px;
    background-size: 18px;
}

.icon-light-monitor {
    background: url(../../assets/svg/icons/light_monitor.svg) no-repeat;
    width: 27px;
    height: 16px;
    background-size: 18px;
}

.icon-light-tablet {
    background: url(../../assets/svg/icons/light_tablet.svg) no-repeat;
    width: 27px;
    height: 16px;
    background-size: 18px;
}

.icon-light-phone {
    background: url(../../assets/svg/icons/light_phone.svg) no-repeat;
    width: 27px;
    height: 16px;
    background-size: 18px;
}

.icon-light-laptop {
    background: url(../../assets/svg/icons/light_laptop.svg) no-repeat;
    width: 27px;
    height: 16px;
    background-size: 18px;
}

.icon-link {
    background: url(../../assets/svg/icons/link.svg) no-repeat;
    min-width: 56px;
    height: 52px;
}

.icon-feedback-link {
    background: url(../../assets/svg/icons/feedback-link.svg) no-repeat;
    min-width: 56px;
    height: 52px;
}

.icon-users {
    background: url(../../assets/svg/icons/users.svg) no-repeat;
}

.icon-blue-clock {
    background: url(../../assets/svg/icons/blue-clock.svg) no-repeat;
}

.icon-user {
    background: url(../../assets/svg/icons/user.svg) no-repeat;
}

.icon-grey-users {
    background: url(../../assets/svg/icons/grey-users.svg) no-repeat;
}

.icon-grey-camera {
    background: url(../../assets/svg/icons/grey-camera.svg) no-repeat;
}

.icon-grey-comments {
    background: url(../../assets/svg/icons/grey-comments.svg) no-repeat;
}

.ascending-link {
    background: url(../../assets/svg/icons/ascending.svg) no-repeat;
    background-size: cover;
    display: block;
    width: 14px;
    height: 14px;
}

.descending-link {
    background: url(../../assets/svg/icons/descending.svg) no-repeat;
    background-size: cover;
    display: block;
    width: 14px;
    height: 14px;
}

.introduced-customers {
    width: 50px;
    height: 50px;
    background: #54628514;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &::after {
        content: "";
        background: url(../../assets/svg/icons/introduced_customers.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.introduced-talent {
    width: 50px;
    height: 50px;
    background: #54628514;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &::after {
        content: "";
        background: url(../../assets/svg/icons/introduced_talent.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.active-talent {
    width: 50px;
    height: 50px;
    background: #54628514;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &::after {
        content: "";
        background: url(../../assets/svg/icons/approved_candidates.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.active-contracts {
    width: 50px;
    height: 50px;
    background: #24b76814;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &::after {
        content: "";
        background: url(../../assets/img/assets/active_contracts.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.month-earnings {
    width: 50px;
    height: 50px;
    background: #198cca14;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &::after {
        content: "";
        background: url(../../assets/svg/icons/dollar-active.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.earnings {
    width: 50px;
    height: 50px;
    background: #576ac714;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &::after {
        content: "";
        background: url(../../assets/svg/icons/total_earnings.svg) no-repeat;
        background-size: cover;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.new-client-card-icon {
    background: url(../../assets/svg/icons/new_client_card.svg) no-repeat;
    background-size: cover;
    display: block;
    width: 100%;
    height: 145px;
}
