.bulk-actions {
    .dropdown-menu {
        display: block;
        top: 50px;
        padding: 10px 0;
        left: unset;
        right: 0;

        .dropdown-item {
            color: #1a284d;
            cursor: pointer;
        }
    }
}
