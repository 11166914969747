.schedule-call-wrapper {
    border: 1px solid #e1e3eb;
    border-radius: 5px;
    padding: 16px;
    box-shadow: 0px 1px 2px 0px rgba(26, 40, 77, 0.08);

    .owner-details {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .full-name {
            font-size: 12px;
            color: #1a284d;
            font-weight: 600;
            line-height: normal;
        }

        .title {
            font-size: 10px;
            color: #546285;
            line-height: normal;
        }
    }

    .description {
        font-size: 12px;
        line-height: 140%;
        font-weight: 600;
        margin-bottom: 28px;
    }

    .icon-phone-blue {
        width: 16px;
        height: 16px;
        background-size: cover;
        display: inline-flex;
        margin-right: 8px;
    }

    .schedule-link {
        font-size: 12px;
        line-height: normal;
        font-weight: 600;
    }
}
