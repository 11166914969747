.criteria-group {
    &:not(.child) {
        border-top: 1px solid #e1e3eb;
        padding-top: 15px;

        .criteria-label {
            font-weight: 600;
        }
    }

    &.child {
        .criteria-label {
            font-weight: normal;
        }
    }

    .criteria-label {
        line-height: 18px;
        padding-bottom: 8px;
        display: block;
        font-weight: 600;
        font-size: 0.8rem;
        margin-bottom: 0px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        visibility: hidden;
    }

    .MuiFormControlLabel-label {
        font-size: 0.8rem;
    }

    .MuiRadio-root {
        padding: 4px 8px;

        & > span {
            color: inherit;
        }
    }
}

.rate {
    padding: 0 10px;
    display: flex;
    position: relative;

    .rating-number {
        width: 25px !important;
        height: 25px;
        margin-right: 10px;
        font-size: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        cursor: pointer;
        color: #ccc;
        padding-bottom: 0;

        &:hover {
            background: #6c757d38;
            color: white !important;
        }
    }

    input:checked + label {
        background: #24b768 !important;
        color: white !important;
    }

    input[type="radio"] {
        position: absolute;
    }
}

.radio-text {
    margin-right: 10px;
    margin-left: 20px;
    font-weight: normal;
}
