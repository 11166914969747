.notes-list {
    display: flex;
    flex-direction: column;

    &-empty {
        padding: 1rem;
        color: #888;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.3px;
    }

    .note-record:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
}
